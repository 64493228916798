import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';

import { localStorage } from 'lib/storage';

import fetchUserById from 'data/queries/user/fetchUserById';

// import { Card, CardChild } from 'components/common/Card/view';
import { LoadingOverlay } from 'components/common';
import Wrapper from 'components/common/Wrapper/view';

import PageNotFound from './404View';

const deals = {
    /* eg:
    'arbritrary-deal-key': {
        title: 'how it appears in the list',
        countries: [], // list of country codes or empty for all countries
        available: true, // boolean to display the deal
        page: () // JSX for the page content
    }
    */
    'secure-parking': {
        title: '10% Off Secure Parking',
        countries: ['AU'],
        available: true,
        page: (
            <Wrapper
              header="mainHeader"
            >
                <h1>Secure Parking Offer</h1>
                <h2>10% Off Secure-a-Spot Online Rates</h2>

                <ul>
                    <li>Hourly Parking</li>
                    <li>Night Parking</li>
                    <li>Weekend Parking</li>
                </ul>

                <p>Valid for any Secure Parking car park in Brisbane, Sydney or Melbourne</p>

                <p>Redeem promotion code <u><strong>ASKABLE</strong></u> at <a className="link" href="https://www.secureparking.com.au" target="_blank" rel="noreferrer noopener">secureparking.com.au</a></p>

                {/* <h3>Book online &amp; save</h3>
                <p>
                    Visit <a className="link" href="https://www.secureparking.com.au" target="_blank" rel="noreferrer noopener">secureparking.com.au</a><br />
                    Redeem promotion code <u><strong>ASKABLE</strong></u>
                </p> */}

                <h3>Instructions:</h3>
                <ol>
                    <li>Go to <a className="link" href="https://www.secureparking.com.au" target="_blank" rel="noreferrer noopener">secureparking.com.au</a></li>
                    <li>Click on the Secure-a-spot button at the top right-hand side</li>
                    <li>Find a city, address or business on the search bar</li>
                    <li>Select the day and time you’re using the carpark</li>
                    <li>Click on “Show me what’s available” button</li>
                    <li>Select which Secure Parking you want to use</li>
                    <li>Click on “Book Now” ($1 booking fee applies)</li>
                    <li>Sign-in to your Secure Parking account or create a new account</li>
                    <li>Confirm your parking details</li>
                    <li>Find the “Redeem a Promotion Code” field at the top right-hand side</li>
                    <li>Enter code: <u><strong>ASKABLE</strong></u></li>
                    <li>Confirm your booking</li>
                    <li>Enjoy your ½ price parking!!</li>
                </ol>

                <p><small>Offers only available online via Secure-a-Spot. Valid only for Hourly, Night and Weekend Parking in Brisbane, Sydney and Melbourne. Subject to space availability and maximum daily booking quota. Offer subject to change and offer may be withdrawn without notice. Booking fee of $1 per transaction applies. Askable is not liable for any booking malfunctions. All issues regarding parking payment can be forwarded to Secure Parking.</small></p>
            </Wrapper>
        )
    }
};

function renderDealListing(deal, key) {
    if (!deal) {
        return null;
    }
    return (
        <div className="upcoming-session-card" key={key}>
            <Link
                to={`/deals/${key}`}
                className="card-child"
            >
                <strong className="fontLarge">{deal.title}</strong>
            </Link>
        </div>
    );
}

function dealIsAvailable(deal, country) {
    return deal.available && (deal.countries.length === 0 || deal.countries.indexOf(country) >= 0);
}

export default function (props) {
    return (
        <Query
            query={fetchUserById}
            variables={{ id: localStorage.get('connectedParticipant') }}
            skip={() => !localStorage.get('participant_access_token')}
        >
        {({ loading, error, data }) => {
            if (loading) return <LoadingOverlay />;
            if (error) {
                console.error(error);
                return <PageNotFound />;
            }
            const country = _.get(data, 'userByID.location.country', null);

            if (_.get(props.match, 'params.deal')) {
                if (deals[props.match.params.deal] && dealIsAvailable(deals[props.match.params.deal], country)) {
                    return deals[props.match.params.deal].page;
                }
                return <PageNotFound />;
            }

            const availableDeals = _.pickBy(deals, deal => dealIsAvailable(deal, country));

            if (_.values(availableDeals).length === 0) {
                return (
                    <Wrapper
                      header="mainHeader"
                    >
                        <h1>There are currently no deals or offers available in your area</h1>
                    </Wrapper>
                );
            }

            return (
                <Wrapper
                  header="mainHeader"
                >
                    <h1>Active deals &amp; offers:</h1>
                    {_.chain(availableDeals).mapValues(renderDealListing).values().value()}
                </Wrapper>
            );
        }}
        </Query>
    );
}
