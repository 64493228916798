import gql from 'graphql-tag';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { compose, graphql, withMutation } from 'react-apollo';
import { useIsKinde } from './useIsKinde';
import { useAddUTMToAppState } from './hooks/useAddUTMToAppState';
import updateMe from './data/mutations/updateMe';
import { localStorage } from './lib/storage';

const withPatchuserMutation = withMutation(gql`
  mutation PatchKindeUser {
    patchKindeUser
  }
`);

function KindePropertyWrapper({ children, token, ...props }) {
  const isKinde = useIsKinde();
  const [isReady, setIsReady] = useState(false);
  const { login, isLoading, isAuthenticated } = useKindeAuth();
  const { addUTMToAppState } = useAddUTMToAppState();

  const { result, mutate } = props;

  const isPatching = result?.loading ?? false;

  const updateMeWithAcquisitionSource = () => {
    const source = localStorage.get('utm_source');
    const campaign = localStorage.get('utm_campaign');
    if (source || campaign) {
      props.updateMe({ source, campaign });
      localStorage.delete('utm_source');
      localStorage.delete('utm_campaign');
    }
  };

  useEffect(() => {
    if (!isKinde || !isAuthenticated) {
      setIsReady(true);
      return;
    }

    if (isLoading) {
      return;
    }

    const decodedToken = jwtDecode(token);

    if (decodedToken.user_properties.askable_user_id?.v && decodedToken.user_properties.askable_user_type?.v) {
      updateMeWithAcquisitionSource();
      setIsReady(true);
      return;
    }

    updateMeWithAcquisitionSource();

    mutate()
      .then(response => {
        if (response.data.patchKindeUser) {
          login({
            app_state: addUTMToAppState(),
          });
        } else {
          setIsReady(true);
        }
      })
      .catch(() => {
        setIsReady(true);
      });
  }, [isLoading, isKinde]);

  if (!isReady || isPatching) {
    return null;
  }

  return children;
}

const updateMeContainer = graphql(updateMe, {
  props: ({ mutate }) => ({
    updateMe: ({ source, campaign }) => {
      return mutate({
        variables: {
          user: {
            meta: {
              acquisition: {
                ...(source && { source }),
                ...(campaign && { campaign }),
              },
            },
          },
        },
      });
    },
  }),
});

export const WrappedKindePropertyWrapper = compose(updateMeContainer)(withPatchuserMutation(KindePropertyWrapper));
