import React, { Component } from 'react';
import _ from 'lodash';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';
import notificationsValues from 'settings/notificationsValues';

import fetchUserById from 'data/queries/user/fetchUserById';

import FlatButton from 'material-ui/FlatButton';
import { Wrapper, LoadingOverlay, ToggleSwitch, SneakyConfirmation } from 'components/common';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useIsKinde } from '../../useIsKinde';

const notificationQuerySchema = notificationsValues.map((notification) => {
    return `${notification.key} { ${notification.channels.map(channel => channel.key).join(' ')} }`;
}).join(' ');

class NotificationControls extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // userID: null,
            // notification: props.notifications || {}
            // notification: Object.assign({}, props.notifications || {})
            notification: props.notifications ? this.notificationStateFromProps(props.notifications) : {}
        };

        this.fireUpdateMutation = this.fireUpdateMutation.bind(this);
        this.renderSwitch = this.renderSwitch.bind(this);

        this.updateUserTimeout = null;
        this.updateUserTimeoutLength = 500;
    }

    // componentWillReceiveProps(newProps) {
        // const notification = newProps.notifications && this.notificationStateFromProps(newProps.notifications);
        // if (notification && JSON.stringify(notification) !== this.state._notification) {
        //     this.setState({
        //         notification: this.notificationStateFromProps(newProps.notifications),
        //         _notification: JSON.stringify(notification)
        //     });
        // }
    // }

    notificationStateFromProps(notifications) {
        const notificationState = {};
        notificationsValues.forEach((notification) => {
            notification.channels.forEach((channel) => {
                const path = `${notification.key}.${channel.key}`;
                _.set(notificationState, path, _.get(notifications, path, true));
            });
        });
        return notificationState;
    }

    fireUpdateMutation() {
        this.setState({ toggleConfirmation: Date.now() });
        if (!this.props.updateMutation) {
            return;
        }
        clearTimeout(this.updateUserTimeout);
        this.updateUserTimeout = setTimeout(() => {
            this.props.updateMutation({
                variables: {
                    user: {
                        settings: { notification: this.state.notification }
                    }
                }
            });
        }, this.updateUserTimeoutLength);
    }

    renderSwitch(notification, channel) {
        if (channel.mandatory) {
            return (
                <ToggleSwitch
                    label={channel.label}
                    value
                    disabled
                />
            );
        }

        return (
            <ToggleSwitch
                label={channel.label}
                value={!!_.get(this.state.notification, `${notification.key}.${channel.key}`)}
                onToggle={(value) => {
                    this.setState((state) => {
                        _.set(state, `notification.${notification.key}.${channel.key}`, !!value);
                        return state;
                    });
                    this.fireUpdateMutation();
                }}
            />
        );
    }

    render() {
        return (
            <Wrapper
                header="mainHeader"
            >
                <h1>Notification settings</h1>
                <SneakyConfirmation
                    open={this.state.toggleConfirmation}
                    onClose={() => { this.setState({ toggleConfirmation: null }); }}
                    style={{ top: '-12px', fontSize: '14px', zIndex: -1 }}
                    className="mbottom30 mtop6"
                >
                    Notification preferences saved!
                </SneakyConfirmation>
                {notificationsValues.map((notification) => {
                    if (notification.key === 'promotional' && utils.getUserRestrictions(this.props.user)) return null;
                    return (
                        <div className="mbottom20" key={notification.key}>
                            <h2>{notification.title}</h2>
                            <p>{notification.description}</p>
                            {notification.channels.map((channel) => {
                                return <div className="mbottom10" key={channel.key}>{this.renderSwitch(notification, channel)}</div>;
                            })}
                        </div>
                    );
                })}
                <FlatButton
                    label="Unsubscribe all"
                    onClick={() => {
                        this.setState((state) => {
                            notificationsValues.forEach((notification) => {
                                notification.channels.forEach((channel) => {
                                    const path = `notification.${notification.key}.${channel.key}`;
                                    _.set(state, path, !!channel.mandatory);
                                });
                            });
                            return state;
                        });
                        this.fireUpdateMutation();
                    }}
                />
            </Wrapper>
        );
    }
}

const NotificationSettings = (props) => {
    const { isAuthenticated } = useKindeAuth();
    const isKinde = useIsKinde();
    const skip = () => (isKinde ? !isAuthenticated : !localStorage.get('participant_access_token'));
    return (
        <Query
            query={fetchUserById}
            variables={{ id: localStorage.get('connectedParticipant') }}
            fetchPolicy="network-only"
            skip={skip()}
        >
            {({ loading, error, data }) => {
                if (loading) return <LoadingOverlay />;
                if (error) {
                    throw new Error(error);
                }
                if (!_.get(data, 'userByID._id')) {
                    localStorage.save('tagetPath', '/notifications');
                    props.history.replace('/login');
                }
                return (
                    <Mutation
                        mutation={gql`mutation notificationsUpdateMe($user: UserInput!) {
                            updateMe(user: $user) {
                              _id
                              settings { notification { ${notificationQuerySchema} } }
                            }
                        }`}
                    >
                    {updateMe => (
                        <NotificationControls
                            updateMutation={updateMe}
                            user={_.get(data, 'userByID')}
                            notifications={_.get(data, 'userByID.settings.notification')}
                        />
                    )}
                    </Mutation>
                );
            }}
        </Query>
    );
};

export default NotificationSettings;
