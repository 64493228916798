import React, { Component } from 'react';

import { localStorage } from 'lib/storage';

import { Button } from 'components/common';
import { HELP_URL } from '../../lib/data/constants';

class ContactAskable extends Component {
  constructor() {
    super();
    this.state = { collapsed: false };
    this.hide = !!localStorage.get('dismissed-contact-askable-banner');
  }
  render() {
    if (this.hide) {
      return null;
    }
    return (
      <div className={`bannerContactAskable ${this.state.collapsed ? 'collapsed' : ''}`}>
        <div className="contents">
          <p>
            For <strong>payment</strong> or <strong>account</strong> issues, please{' '}
            <a href={HELP_URL} className="link">
              contact Askable
            </a>
          </p>
          <Button
            label="Okay, got it"
            labelColor="#fff"
            bgColor="#FF5266"
            type="button"
            className="widthAuto"
            style={{
              fontSize: '11px',
              width: 'auto',
              padding: '8px',
            }}
            onClick={() => {
              this.setState({ collapsed: true });
              localStorage.save('dismissed-contact-askable-banner', 'true');
            }}
          />
        </div>
      </div>
    );
  }
}

export default ContactAskable;
