import React, { Component } from 'react';
import TextField from 'material-ui/TextField';

// Styles
import './styles/InputStyles.scss';

class Input extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            value: null
        });

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        // If onChange is not set it means the component is self managed
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        } else {
            this.setState({
                value: e.target.value
            });
        }
    }

    renderTitle() {
        if (this.props.title) {
            return (
                <span className="inputTitle">{this.props.title}</span>
            );
        }
    }

    renderLeftIcon() {
        if (this.props.leftIcon) {
            return <span className="icon leftIcon">{this.props.leftIcon}</span>;
        }
        return null;
    }

    renderRightIcon() {
        if (this.props.rightIcon) {
            return <span className="icon rightIcon">{this.props.rightIcon}</span>;
        }
        return null;
    }

    render() {
        const dataProps = {};
        if (this.props.type !== 'password' && !(this.props.name || '').match(/password/)) {
            dataProps['data-hj-allow'] = '';
        }

        return (
            <div className={`inputComponent ${this.props.containerStyle || ''} ${this.props.className ? this.props.className : ''}`}>
                {this.props.leftIcon && this.renderLeftIcon()}
                <TextField
                    // {...this.props}
                    {...dataProps}
                    hintText={this.props.hintText ? this.props.hintText : ''}
                    placeholder={this.props.placeholderVanilla}
                    name={this.props.name}
                    type={this.props.type}
                    floatingLabelText={this.props.placeholder}
                    floatingLabelStyle={{ fontFamily: 'Lato', fontWeight: '400', fontSize: 18 }}
                    value={(this.props.value !== null) ? this.props.value : this.state.value}
                    errorText={this.props.errorText ? this.props.errorText : ''}
                    disabled={this.props.disabled ? this.props.disabled : false}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                    rows={this.props.rows ? parseInt(this.props.rows) : 1}
                    rowsMax={this.props.rowsMax ? parseInt(this.props.rowsMax) : 1}
                    multiLine={this.props.multiLine || false}
                    fullWidth
                    autoFocus={this.props.autoFocus}
                    autoComplete={this.props.autoComplete}
                    style={this.props.style}
                    id={this.props.id}
                    ref={this.props.refName ? (input) => {
                        window[this.props.refName] = input;
                    } : undefined}
                    // ref={this.props.refName}
                />
                {this.props.rightIcon && this.renderRightIcon()}
            </div>
        );
    }
}

export default Input;
