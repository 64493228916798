import React from 'react';
import { graphql, compose } from 'react-apollo';
import { localStorage } from 'lib/storage';
import _ from 'lodash';

import fetchUserById from 'data/queries/user/fetchUserById';
// TODO: just get a list of registered bookings (not submissions)
import userRegisteredBookings from 'data/queries/booking/userRegisteredBookings';

import { Wrapper, LoadingOverlay } from 'components/common';

import OpportunitiesList from './opportunityListView';
import { withKinde } from '../../AuthWrapper';

// Styles
import './styles/bookingListStyles.scss';

const BookingsList = props => {
  if (props.fetchUserById.loading || props.userRegisteredBookings.loading) {
    return <LoadingOverlay />;
  }

  return (
    <Wrapper header="mainHeader" className="OpportunitiesList">
      <OpportunitiesList
        user={_.get(props, 'fetchUserById.userByID') || {}}
        registeredBookings={props.userRegisteredBookings.userRegisteredBookings.map(item => item._id)}
      />
    </Wrapper>
  );
};

const UserDetailsContainer = graphql(fetchUserById, {
  name: 'fetchUserById',
  skip: props => !props.isAuthenticated(),
});
const UserRegisteredBookingsContainer = graphql(userRegisteredBookings, {
  name: 'userRegisteredBookings',
  options: () => ({
    variables: { _user_id: localStorage.get('connectedParticipant') },
  }),
});

export default withKinde(compose(UserDetailsContainer, UserRegisteredBookingsContainer)(BookingsList));
