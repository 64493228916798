import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class Overlay extends Component {
    render() {
        const portalNode = document.getElementById('live-overlay-portal');
        if (!portalNode) return null;
        if (React.Children.toArray(this.props.children).length === 0) return null;
        return ReactDOM.createPortal(
            <div className={`overlay-portal-wrapper ${this.props.className || ''}`}>{this.props.children}</div>,
            portalNode
        );
    }
}

Overlay.CamPermissionsPrompt = () => (
    <Overlay className="message-overlay left permission-prompt">
        <img src="/assets/live-cam-permissions-prompt.png" alt="Click here to enable camera and microphone" height="241" />
        <div className="content">
            <h2>Allow camera and mic</h2>
            <p>Click the allow button to give the browser access to your webcam and mic. Don’t worry - you can end the session at any time.</p>
        </div>
    </Overlay>
);

Overlay.CamPermissionsUnblock = () => (
    <Overlay className="message-overlay left permission-denied">
        <img src="/assets/live-cam-permissions-unblock.png" alt="Click here to enable camera and microphone" height="260" />
        <div className="content">
            <h2>Your microphone or camera is blocked</h2>
            <ul>
                <li>
                    Click the lock icon
                    <svg className="inline-svg" viewBox="0 0 13 18" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.375 6C11.806 6 12.2193 6.18061 12.524 6.5021C12.8288 6.82359 13 7.25963 13 7.71429V16.2857C13 16.7404 12.8288 17.1764 12.524 17.4979C12.2193 17.8194 11.806 18 11.375 18H1.625C1.19402 18 0.780698 17.8194 0.475951 17.4979C0.171205 17.1764 0 16.7404 0 16.2857V7.71429C0 6.76286 0.73125 6 1.625 6H2.4375V4.28571C2.4375 3.14907 2.86551 2.05898 3.62738 1.25526C4.38925 0.451529 5.42256 0 6.5 0C7.03349 0 7.56177 0.110853 8.05465 0.326231C8.54754 0.541608 8.99538 0.857291 9.37262 1.25526C9.74986 1.65322 10.0491 2.12568 10.2533 2.64564C10.4574 3.16561 10.5625 3.72291 10.5625 4.28571V6H11.375ZM6.5 1.71429C5.85353 1.71429 5.23355 1.9852 4.77643 2.46744C4.31931 2.94968 4.0625 3.60373 4.0625 4.28571V6H8.9375V4.28571C8.9375 3.60373 8.68069 2.94968 8.22357 2.46744C7.76645 1.9852 7.14646 1.71429 6.5 1.71429Z" fill="white"/>
                    </svg>
                    in your browser’s address bar
                </li>
                <li>Allow microphone and camera and refresh the page</li>
            </ul>
        </div>
    </Overlay>
);

Overlay.DesktopPromptChrome = () => (
    <Overlay className="message-overlay centre screen-share-chrome">
        <div className="content">
            <div className="step left">
                <img src="/assets/instruction-arrow.png" className="arrow" />
                <h2>1. Click the image</h2>
            </div>
            <div className="step right">
                <img src="/assets/instruction-arrow.png" className="arrow" />
                <h2>2. And then click the Share button</h2>
            </div>
        </div>
    </Overlay>
);

export default Overlay;
