import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import gql from 'graphql-tag';

import { graphql, compose, Mutation } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { Wrapper, Button, SneakyConfirmation } from 'components/common';
import { ListItem } from 'material-ui/List';
import CopyIcon from 'material-ui/svg-icons/content/content-copy';

import { utils } from 'lib/utils';
import { localStorage } from 'lib/storage';
import fetchUserById from 'data/queries/user/fetchUserById';

import { SetupIntro, SetupSendLink, SetupTestRoom } from './setup-check/index.view';
import BookingSessionRedirect from './askable-live-booking-session-redirect.view';
import DemoRoom, { DemoHeader } from './askable-live-demo-room.view';
import { withKinde } from '../../AuthWrapper';

import './styles/askable-live.scss';

const MIN_CHROME_VERSION = 80;

class LiveRoot extends Component {
    constructor() {
        super();
        this.pageContents = this.pageContents.bind(this);
        this.messageContents = this.messageContents.bind(this);

        this.state = {};
    }

    pageContents() {
        const { match } = this.props;

        const userAgent = _.get(this.props, 'userAgent.userAgent');

        switch (match.path) {
            case '/live/setup-check':
            case '/live/setup-check/:page':
            case '/live/setup-check/:page+': {
                if (!userAgent) return null;
                if (_.get(userAgent, 'device.type') !== 'desktop') {
                    return { contents: <SetupSendLink />, classes: 'live-full-size setup-check' };
                }
                if (_.get(userAgent, 'browser.name') !== 'Chrome') return this.messageContents('chrome-required');
                if (parseInt(_.get(userAgent, 'browser.version_major', ''), 10) < MIN_CHROME_VERSION) return this.messageContents('browser-version');

                const page = (match.params.page || '').split('/');
                const bookingMatch = (match.params.page || '').match(/(?:booking\/)([0-9a-fA-F]{24})/);
                if (bookingMatch && bookingMatch[1]) {
                    localStorage.save('setup-check-redirect', `/booking/${bookingMatch[1]}`, 'session');
                }

                switch (page[0]) {
                    case undefined:
                    case '':
                        return { contents: <SetupIntro history={this.props.history} />, classes: 'live-full-size setup-check' };
                    case 'in-progress':
                        return { contents: <SetupTestRoom history={this.props.history} userAgent={userAgent} />, classes: 'live-full-size live-dark-bg setup-check' };
                    default:
                        return { contents: <Redirect to="/live/setup-check" /> };
                }
            }
            case '/live/demo/:room_id':
                return { contents: <DemoRoom _room_id={match.params.room_id} />, classes: 'live-full-size live-dark-bg' };
            case '/live/:booking_id':
                return { contents: <BookingSessionRedirect _booking_id={match.params.booking_id} />, classes: 'live-full-size live-dark-bg' };
            case '/live/message/:message':
            case '/live/error/:message':
                if (this.messageContents(match.params.message)) {
                    return this.messageContents(match.params.message);
                }
                return {
                    classes: 'live-full-size',
                    contents: (
                        <div className="centre-message">
                            <h2>{match.params.message}</h2>
                        </div>
                    )
                };
            case '/live':
                // TODO: add a Live homepage?
                return { contents: <Redirect to="/" />, classes: 'live-root' };
            default:
                if (match.url !== '/live') {
                    return { contents: <Redirect to="/live" /> };
                }
        }

        return { contents: null };
    }

    messageContents(message, classes) {
        const { booking_id } = _.get(this.props, 'match.params', {});

        switch (message) {
            case 'desktop-required':
                if (!booking_id) {
                    return { contents: <Redirect to="/live" /> };
                }
                return {
                    classes: classes || 'live-full-size live-dark-bg',
                    contents: (
                        <div className="centre-message narrow">
                            <h2>You need to be on a laptop</h2>
                            <p>Remote video calls won’t work on your device. Please click the button below to email yourself a link to open on a laptop.</p>
                            <p>Your link will be sent to {_.get(this.props, 'userByID.userByID.email') ? <u>{this.props.userByID.userByID.email}</u> : 'your Askable email address'}</p>
                            <div className="mtop20">
                            <Mutation
                                mutation={gql`mutation sendMyselfBookingLiveLink($booking_id: ID!) {
                                    sendMyselfBookingLiveLink(booking_id: $booking_id)
                                }`}
                            >
                            {(sendEmail, { loading, called }) => (
                                <Button
                                    label={called ? 'Link sent' : 'Email me the link'}
                                    labelColor="#fff"
                                    bgColor="#FF5266"
                                    type="button"
                                    className="mtop10"
                                    disabled={loading || called}
                                    onClick={() => {
                                        // console.log(sendEmail, booking_id);
                                        sendEmail({ variables: { booking_id } });
                                    }}
                                />
                            )}
                            </Mutation>
                            </div>
                        </div>
                    )
                };
            case 'chrome-required':
                return {
                    classes: classes || 'live-full-size live-dark-bg',
                    contents: (
                        <div className="centre-message narrow chrome-required">
                            <h2>Please download Google Chrome</h2>
                            <p>Your web browser won’t work for remote video calls, please <a href="https://www.google.com.au/chrome/" target="_blank" rel="noopener noreferrer" className="image-link" title="Dowload Google Chrome">download and install Google Chrome.</a></p>
                            <p>Once that&apos;s done, please copy the link below and then paste it in Google Chrome.</p>
                            <ListItem
                                primaryText={window.location.href}
                                className="job-link"
                                rightIcon={<CopyIcon />}
                                style={{ backgroundColor: '#fff', marginTop: 30 }}
                                onClick={() => {
                                    utils.copy(
                                        window.location.href,
                                        () => { this.setState({ copyConfirmation: new Date().valueOf() }); },
                                        () => { throw new Error('Failed to copy the link'); }
                                    );
                                }}
                            />
                            <SneakyConfirmation
                                open={this.state.copyConfirmation}
                                onClose={() => { this.setState({ copyConfirmation: null }); }}
                                style={{ top: '8px', color: '#fff' }}
                            >
                                Link copied to clipboard!
                            </SneakyConfirmation>
                        </div>
                    )
                };
            case 'browser-version':
                return {
                    classes: classes || 'live-full-size',
                    contents: (
                        <div className="centre-message narrow chrome-required">
                            <h2>Please update your browser</h2>
                            <p>Please update to latest version of Google Chrome to help make sure there won’t be any technical issues with your call.</p>
                            <p><a href="https://support.google.com/chrome/answer/95414" className="link" target="_blank" rel="noreferrer noopener">How to update Google Chrome</a></p>
                        </div>
                    )
                };
            case 'booking-session-redirect':
                return {
                    classes: classes || 'live-full-size live-dark-bg',
                    contents: (
                        <div className="centre-message">
                            <h2>We couldn’t find your session</h2>
                            <p>Please double-check your video call link, and that you’re logged into your account.</p>
                        </div>
                    )
                };
            default:
                return null;
        }
    }

    render() {
        const { contents, classes } = this.pageContents() || {};

        const wrapperClasses = ['askable-live-wrapper'];
        switch (typeof classes) {
            case 'string':
                wrapperClasses.push(classes);
            break;
            case 'object':
                wrapperClasses.push(...classes);
            break;
            default:
        }

        return (
            <Fragment>
                <Wrapper
                    header="mainHeader"
                    className={wrapperClasses.join(' ').replace(/\s+/g, ' ').replace(/^\s|\s$/g, '')}
                    headerContents={this.props.match.path.match(/demo/) ? <DemoHeader /> : null}
                >
                {contents}
                </Wrapper>
                <div id="live-overlay-portal" />
            </Fragment>
        );
    }
}

const UserAgentContainer = graphql(
    gql`query userAgent { userAgent { _id device { type } browser { name version version_major } os { name family } } }`,
    { name: 'userAgent' }
);
const FetchUserByIdContainer = graphql(fetchUserById, {
    name: 'userByID',
    skip: (props) => !props.isAuthenticated(),
});

export default withKinde(compose(
    UserAgentContainer,
    FetchUserByIdContainer,
)(LiveRoot));
