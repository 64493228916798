import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import React from 'react';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

export function withFingerprint(Component) {
  return function WrapperComponent(props) {
    const { getData } = useVisitorData({ extendedResult: true }, { immediate: false });
    const { user, getClaim } = useKindeAuth();
    const getFingerprint = async () => {
      try {
        const askableUserId = getClaim('user_properties')?.value?.askable_user_id?.v || user.id;
        return await getData({ ignoreCache: true, linkedId: askableUserId });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to get fingerprint:', error);
        return null;
      }
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} getFingerprint={getFingerprint} />;
  };
}
