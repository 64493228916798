import React, { useEffect } from 'react';

export const PayPalButton = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.paypalobjects.com/js/external/api.js';
    script.addEventListener('load', () => {
      // Ensure the script has loaded before using it
      //@ts-ignore
      paypal.use(['login'], function (login) {
        login.render({
          appid: import.meta.env.VITE_PAYPAL_CLIENT_ID,
          ...(import.meta.env.VITE_PAYPAL_MODE === 'live' ? {} : { authend: 'sandbox' }),
          scopes: 'openid address email https://uri.paypal.com/services/paypalattributes',
          containerid: 'theButton',
          responseType: 'code',
          locale: 'en-us',
          buttonType: 'CWP',
          buttonShape: 'pill',
          buttonSize: 'lg',
          fullPage: 'true',
          returnurl: `https://${import.meta.env.VITE_PAYPAL_MODE === 'live' ? 'my.askable.com' : 'my-dev.askable.com'}/paypal-verification`,
        });
      });
    });
    document.body.appendChild(script);
  }, []);

  return <span id="theButton"></span>;
};
