import React, { Component } from 'react';
import _ from 'lodash';
import { graphql, compose } from 'react-apollo';

import { SvgIcon } from 'material-ui';
import GiftCardIcon from 'material-ui/svg-icons/action/card-giftcard';

import { Button, LoadingOverlay } from 'components/common';

import listAvailablePayoutMethodsQuery from 'data/queries/listAvailablePayoutMethods';

import PaypalLogo from '../../../icons/paypalLogo';
import { PayPalButton } from './payPalButton';

class PaymentMethod extends Component {
  constructor() {
    super();
    this.state = {
      page: null,
    };

    this.hiddenSubmitRef = React.createRef();

    this.saveValue = this.saveValue.bind(this);
  }

  saveValue(value) {
    const hiddenSubmit = this.hiddenSubmitRef.current;
    this.props.saveValue(value).then(() => {
      hiddenSubmit.click();
    });
  }

  render() {
    const payoutMethods = _.get(this.props, 'listAvailablePayoutMethods.listAvailablePayoutMethods');

    if (!payoutMethods) return <LoadingOverlay />;

    const hiddenSubmit = <input type="submit" ref={this.hiddenSubmitRef} style={{ position: 'absolute', left: '-9999px' }} />;

    const wrapperProps = {
      className: 'payout-method-wrapper',
      'data-methods': payoutMethods.join(','),
    };

    const setLater = (
      <div className="mtop20 textCenter">
        <button
          className="link"
          type="button"
          style={{ color: '#888888' }}
          // role="button"
          onClick={() => {
            this.saveValue({ preferred: null });
          }}
        >
          I’ll set this later
        </button>
      </div>
    );

    let { page } = this.state;
    if (payoutMethods.length === 1) {
      [page] = payoutMethods;
    }

    switch (page) {
      case 'paypal':
        return (
          <div {...wrapperProps}>
            <h1 className="mbottom60">Sign into your PayPal account</h1>
            <div className="textCenter">
              <PayPalButton />
            </div>
            {setLater}
            {hiddenSubmit}
          </div>
        );
      // (check if there was only paypal as an option)
      default:
        return (
          <div {...wrapperProps}>
            <h1 className="mbottom60">How would you prefer to get paid?</h1>
            {this.props.errorMessage}
            {payoutMethods.map(method => {
              switch (method) {
                case 'giftpay':
                  return (
                    <Button
                      label="E-Gift Card"
                      key={method}
                      labelColor="#222222"
                      bgColor="transparent"
                      buttonStyle={{ boxShadow: '0 0 0 2px #666666 inset' }}
                      icon={
                        <SvgIcon>
                          <GiftCardIcon />
                        </SvgIcon>
                      }
                      className="btnNext mbottom20"
                      type="button"
                      onClick={() => {
                        this.saveValue({ preferred: 'giftpay', giftpay: this.props.user.email });
                      }}
                    />
                  );
                case 'paypal':
                  return (
                    <Button
                      label="PayPal"
                      key={method}
                      labelColor="#222222"
                      bgColor="transparent"
                      buttonStyle={{ boxShadow: '0 0 0 2px #666666 inset' }}
                      icon={
                        <SvgIcon>
                          <PaypalLogo />
                        </SvgIcon>
                      }
                      className="btnNext mbottom20"
                      type="button"
                      onClick={() => {
                        this.setState({ page: 'paypal' });
                      }}
                    />
                  );
                default:
                  return null;
              }
            })}
            {setLater}
            {hiddenSubmit}
          </div>
        );
    }
  }
}

const payoutMethodsContainer = graphql(listAvailablePayoutMethodsQuery, {
  name: 'listAvailablePayoutMethods',
});

export default compose(payoutMethodsContainer)(PaymentMethod);
