import gql from 'graphql-tag';

import { verificationResponseFragment } from 'data/fragments/phoneVerificationResponse';

export default gql`
    mutation verifyPhoneSendCode ($id: ID!) {
        verifyPhoneSendCode(_id: $id) { ...verificationResponseFields }
    }
    ${verificationResponseFragment}
`;
