import React, { Component, Fragment } from 'react';
import _ from 'lodash';

// import { graphql, compose } from 'react-apollo';
// import { Link } from 'react-router-dom';
// import dotObject from 'dot-object';

import Dialog from 'components/common/Dialog/view';
import Input from 'components/common/Input/view';
import Checkbox from 'components/common/Checkbox/view';

// dotObject.override = true;

import { BOOKING_TYPE } from '../../../../src/lib/data/constants';

class BookingScheduleConfirmDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canContinue: false,
      // open: false
    };

    // this.sessionsInit = this.sessionsInit.bind(this);
  }

  // componentWillReceiveProps(newProps) {
  //   if (newProps.open !== this.props.open) {
  //     this.setState({ open: newProps.open });
  //   }
  // }

  renderCountryInfoLink(country) {
    switch (country) {
      case 'AU':
        return <p>For more information please refer to the <a className="link" href="https://www.health.gov.au/health-topics/novel-coronavirus-2019-ncov" target="_blank" rel="noopener noreferrer">Department of Health website</a></p>;
      case 'NZ':
        return <p>For more information please refer to the <a className="link" href="https://www.health.govt.nz/our-work/diseases-and-conditions/covid-19-novel-coronavirus" target="_blank" rel="noopener noreferrer">Ministry of Health website</a></p>;
      case 'US':
        return <p>For more information please refer to the <a className="link" href="https://www.cdc.gov/coronavirus" target="_blank" rel="noopener noreferrer">CDC website</a></p>;
      case 'GB':
        return <p>For more information please refer to the <a className="link" href="https://www.gov.uk/coronavirus" target="_blank" rel="noopener noreferrer">GOV.UK website</a></p>;
      default:
        return null;
    }
  }

  render() {
    if (_.get(this.props, 'booking.type') === BOOKING_TYPE.FACE_TO_FACE && !_.get(this.state, 'faceToFaceWarning.confirm')) {
      return (
        <Dialog
          open={this.props.open}
          modal
          // modal={false}
          title="To help prevent the spread of Coronavirus (COVID-19), please confirm the following:"
          customActions={[
            {
              label: 'Continue',
              primary: true,
              disabled: !_.get(this.state, 'faceToFaceWarning.quarantine'),
              onClick: () => { this.setState(({ faceToFaceWarning, ...state }) => ({ ...state, faceToFaceWarning: { ...faceToFaceWarning, confirm: true } })); }
            }
          ]}
          onCancel={this.props.onCancel}
        >
          <div className="mtop6 mbottom30">
            <Checkbox
              onClick={({ checked }) => { this.setState(({ faceToFaceWarning, ...state }) => ({ ...state, faceToFaceWarning: { ...faceToFaceWarning, quarantine: checked } })); }}
              label="I have not been directed by a health organisation or official to self-isolate or quarantine"
              // className="mbottom10"
            />
          </div>
          {this.renderCountryInfoLink(_.get(this.props, 'booking.config.location.country'))}
        </Dialog>
      );
    }

    return (
      <Dialog
        open={this.props.open}
        modal
        title="By confirming, you are agreeing to show up!"
        customActions={[
          {
            label: 'Cancel',
            primary: true,
            onClick: this.props.onCancel
          },
          {
            label: 'Confirm',
            primary: true,
            disabled: !this.state.canContinue,
            onClick: this.props.onConfirm
          }
        ]}
      >
        <p>If you don’t show up, or cancel with less than 24hrs notice you will be blocked from <strong><u>all</u></strong> future opportunities.</p>
        <p>Please type: &apos;<strong>I AGREE</strong>&apos; to continue</p>
        <Input
          id="BookingScheduleConfirmInput"
          onChange={value => this.setState({ canContinue: !!value.match(/^\s*i\s+AGREE\s*$/i) })}
        />
      </Dialog>
    );
  }
}

export default BookingScheduleConfirmDialog;
