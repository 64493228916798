import React from 'react';
import { UAParser } from 'ua-parser-js';
import { ANDROID_STORE_LINK, APPLE_STORE_LINK } from '../../lib/data/constants';
import AppleIcon from '../../icons/apple';
import PlayStoreIcon from '../../icons/playStore';

import './styles.scss';

const MobileAppDownload = () => {
  const useragent = window.navigator.userAgent;

  const device = useragent ? UAParser(useragent).os.name : 'unknown';

  if (device === 'iOS') {
    window.location.assign(APPLE_STORE_LINK);
  } else if (device === 'Android') {
    window.location.assign(ANDROID_STORE_LINK);
  }
  return (
    <div className="mobile-app-page">
      <img width={150} src="/askable-logo.svg" alt="Askable" />
      <h1 className="">
        Download our new <span>app <div className="badge">Beta</div></span>
      </h1>
      <div className="body">
        <p>Get early access to our new mobile app! Download our app from the App Store or Play Store using the links below.</p>
        <div className="button-group">
          <button onClick={() => window.location.assign(APPLE_STORE_LINK)}>
            <AppleIcon height={48} />
          </button>
          <button onClick={() => window.location.assign(ANDROID_STORE_LINK)}>
            <PlayStoreIcon height={48} />
          </button>
        </div>
      </div>
    </div>
  );
};
export default MobileAppDownload;
