import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

import { FlatButton } from 'material-ui';

import { Button, Checkbox, LoadingOverlay } from 'components/common';

class BookingScreenerSummaryView extends Component {
    constructor() {
        super();
        this.state = {};
        this.renderResponse = this.renderResponse.bind(this);
    }

    renderResponseControls({ question, responses }) {
        switch (question.config.type) {
            case 1:
                return (
                    <ul className="response select">
                        {question.options.map(option => (
                            <li key={option._id} className={_.find(responses, ['_answer_id', option._id]) && 'checked'}>
                                {option.label}
                            </li>
                        ))}
                    </ul>
                );
            case 2:
            default:
                return <blockquote className="response free-text">{responses[0].value}</blockquote>;
        }
    }

    renderResponse(questionItem) {
        const [_question_id, responses] = questionItem;
        const question = _.find(this.props.booking.config.question, ['_id', _question_id]);
        return (
            <div className="mbottom20" key={_question_id}>
                <h3 className="mbottom6">{question.title}</h3>
                {question.description && <p className="mbottom6">{question.description}</p>}
                {this.renderResponseControls({ question, responses })}
            </div>
        );
    }

    render() {
        if (this.state.loading) {
            return <LoadingOverlay />;
        }
        return (
            <div className="responses-summary">
                <h2 className="mbottom40">Review your answers</h2>
                {_.toPairs(this.props.values).map(this.renderResponse)}

                <div className="actions textCenter mtop40">
                    <Checkbox
                        onClick={(value) => { this.setState({ confirm_responses: value.checked }); }}
                        label="These answers are correct"
                        className="declaration"
                    />
                    <Button
                        label="Submit"
                        labelColor="#fff"
                        bgColor="#FF5266"
                        className="btnNext mtop20 mbottom20"
                        type="button"
                        onClick={this.props.submitScreener}
                        disabled={!this.state.confirm_responses}
                    />
                    <FlatButton
                        label="or start over"
                        onClick={() => {
                            if (this.props.isPreview) {
                                window.location.reload();
                                return;
                            }
                            this.props.history.push(this.props.bookingBaseUrl);
                        }}
                    />
                    <div id="tstl-container"></div>
                </div>
            </div>
        );
    }
}

export default BookingScreenerSummaryView;
