import React from 'react';
import { Wrapper } from 'components/common';

const LoggedInAsClient = ({ logout }) => {
  return (
    <Wrapper header="mainHeader">
      <h1>Looks like you’re in the wrong place</h1>
      <p>
        <a className="link" href={import.meta.env.VITE_CLIENT_APP_URL || 'https://www.askable.com/login'}>
          Sign in as a client
        </a>{' '}
        or <a className="link" href="/logout">sign out</a> to change accounts.
      </p>
    </Wrapper>
  );
};

export default LoggedInAsClient;
