import React, { useState } from 'react'; 
// @ts-ignore-line
import { Checkbox } from '../common';
import { PayPalButton } from '../userGetDetails/pages/payPalButton';

export const VerifyPayPalMessage: React.FC<{ show: boolean }> = ({ show }) => {
  const [verifyChecked, setVerifyChecked] = useState(false);
  if (!show) return null;
  return (
    <div style={{ margin: '0 -0.6em 1em', padding: '0.6em', border: '1px #ffd935 solid' }}>
      <h3>Verify your PayPal account:</h3>
      <p>
        Follow{' '}
        <a href="https://www.paypal.com/au/cshelp/article/how-do-i-verify-my-paypal-account-help434" className="link" target="_blank" rel="noopener nofollow">
          the instructions
        </a>{' '}
        to verify your bank account or credit card with PayPal.
      </p>
      <p>
        <Checkbox
          onClick={(value: any) => {
            setVerifyChecked(value.checked);
          }}
          label=" I have verified my account"
        />
      </p>
      {verifyChecked && (
        <div className="mbottom20">
          <p>To confirm, please re-connect your PayPal account to Askable:</p>
          <PayPalButton />
        </div>
      )}
    </div>
  );
};
