const localStorage = {
    storageMethods: {
        local: window.localStorage,
        session: window.sessionStorage
    },
    save(id, value, storageMethod = 'local') {
      localStorage.storageMethods[storageMethod].setItem(id, value);
    },
    get(id, storageMethod = 'local') {
      return localStorage.storageMethods[storageMethod].getItem(id);
    },
    delete(id, storageMethod = 'local') {
      localStorage.storageMethods[storageMethod].removeItem(id);
    },
    clear(storageMethod = 'local') {
      localStorage.storageMethods[storageMethod].clear();
    }
};

const clearUserStorage = () => {
  localStorage.delete('connectedParticipant');
  localStorage.delete('participant_access_token');
  localStorage.delete('userBasicDetails');
  localStorage.delete('participant_details');
};

export { localStorage, clearUserStorage };
