import React from 'react';
import { graphql, compose } from 'react-apollo';

import latestAgreementByType from 'data/queries/latestAgreementByType';

import { LoadingOverlay } from 'components/common';
import TermsDoc from 'components/simplePages/termsDocument';

const page = (props) => {
    if (!props.latestAgreementByType.latestAgreementByType) {
        return <LoadingOverlay />;
    }

    return (
        <TermsDoc
          header="mainHeader"
          documentId={props.latestAgreementByType.latestAgreementByType._id}
        />
    );
};

const LatestAgreementContainer = graphql(latestAgreementByType, {
    name: 'latestAgreementByType',
    options: () => ({
        variables: { user_type: { participant: true } },
    }),
});

export default compose(LatestAgreementContainer)(page);
