import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import { bookingSearchFragment } from 'data/fragments/bookingSearchFields';

import { LoadingOverlay } from 'components/common';

import OpportunityListCard from './opportunityListCardView';
import RestrictedUserOptIn from './restrictedUserOptInView';

class RestrictedUserPage extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        if (this.props.restrictedBooking.loading) {
            return <LoadingOverlay />;
        }

        if (_.get(this.props, 'restrictedBooking.bookingByID.status') === 1 && !this.state.seeOtherBookings) {
            const booking = this.props.restrictedBooking.bookingByID;
            return (
                <Fragment>
                    <h1>Continue application:</h1>
                    <OpportunityListCard booking={booking} />
                    <p className="mtop40">
                        <a
                            className="link"
                            href="#other-opportunities"
                            style={{ fontSize: '12px', opacity: 0.7 }}
                            onClick={(e) => {
                                this.setState({ seeOtherBookings: true });
                                e.preventDefault();
                            }}
                        >
                            See other opportunities
                        </a>
                    </p>
                </Fragment>
            );
        }

        return <RestrictedUserOptIn opportunities={this.props.opportunities} />;
    }
}

const restrictedBookingLookupContainer = graphql(gql`
    query bookingRestriction($id:ID!) { bookingByID(id:$id) { ...bookingSearchFields } }
    ${bookingSearchFragment}
`, {
    name: 'restrictedBooking',
    options: props => ({
        variables: { id: _.get(props, 'restrictions._booking_id') },
    }),
});
export default compose(
    restrictedBookingLookupContainer,
)(RestrictedUserPage);