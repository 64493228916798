import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';

import { localStorage } from 'lib/storage';

const CheckUserRegistered = (props) => {
    return (
        <Query
          query={gql`query my_checkUserRegistered($id:ID) { userByIDPublic(id:$id) }`}
          variables={{ id: localStorage.get('queryUserId') }}
        >
            {({ loading, data }) => {
                if (loading) {
                    return null;
                }
                localStorage.delete('queryUserId');
                if (_.get(data, 'userByIDPublic')) {
                    // there is an account for this id, redirect to login
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                search: _.get(props, 'location.search'),
                                state: { from: props.location }
                            }}
                        />
                      );
                }
                // no account for this id, redirect to signup
                return (
                  <Redirect
                      to={{
                          pathname: '/signup',
                          search: _.get(props, 'location.search'),
                          state: { from: props.location }
                      }}
                  />
                );
            }}
        </Query>
    );
};


export default CheckUserRegistered;
