import gql from 'graphql-tag';

import { verificationResponseFragment } from 'data/fragments/phoneVerificationResponse';

export default gql`
    mutation verifyPhone($phone: String!, $country_code: String!) {
        verifyPhone(phone: $phone, country_code: $country_code) { ...verificationResponseFields }
    }
    ${verificationResponseFragment}
`;
