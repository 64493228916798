import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import { localStorage } from 'lib/storage';
import { MESSAGE_TYPE, MESSAGE_DIRECTION } from 'lib/data/constants';

import bookingParticipantMessages from 'data/queries/messages/bookingParticipantMessages';
import messagesSubscription from 'data/subscriptions/messages/messagesSubscription';

import { Header } from 'components/common';

import Conversation from './messagesConversationView';
import ContactAskableBanner from './messagesContactAskableBannerView';

const BookingMessages = (props) => {
    const messageFilters = {
        type: MESSAGE_TYPE.IN_APP,
        _booking_id: props.match.params.id,
        _user_id: localStorage.get('connectedParticipant'),
        direction: {
            revieved: MESSAGE_DIRECTION.CLIENT_TO_PARTICIPANT,
            sent: MESSAGE_DIRECTION.PARTICIPANT_TO_CLIENT,
        }
    };
    return (
        <Query
          query={bookingParticipantMessages}
          variables={_.pick(messageFilters, '_booking_id', '_user_id')}
          fetchPolicy="network-only"
        >
        {({ subscribeToMore, ...result }) => (
            <Conversation
                messages={parseMessageData(_.get(result, 'data.bookingParticipantMessages'))}
                messageFilters={messageFilters}
                header={_.get(
                    props,
                    'header',
                    (
                    <Fragment>
                        <Header
                        context="messagesConversation"
                        _booking_id={messageFilters._booking_id}
                        />
                        <ContactAskableBanner />
                    </Fragment>
                    )
                )}
                wrapperHeader={props.wrapperHeader}
                subscribeToMessages={() => subscribeToMore({
                    document: messagesSubscription,
                    variables: { filter: _.pick(messageFilters, ['_booking_id', '_user_id']) },
                    updateQuery: (prev, { subscriptionData }) => {
                        if (_.get(prev, 'bookingParticipantMessages.messages') && _.get(subscriptionData, 'data.messagesSubscription')) {
                            const next = _.cloneDeep(prev);
                            next.bookingParticipantMessages.messages.push(subscriptionData.data.messagesSubscription);
                            return next;
                        }

                        return prev;
                    }
                })}
                messageSendPrompts={[
                    {
                        pattern: /\b(payment|gift card|paypal|incentive|paid|pay|dollar|dollars|egift)\b|\$/i,
                        body: <p className="fontLarge">Please <Link to="/messages/admin" className="link">contact Askable admin</Link> with questions about incentives or payments.</p>,
                        strict: true
                    }, {
                        pattern: /\b(cancel)\b/i,
                        body: <p className="fontLarge">If you’re wanting to cancel your session or change your availability, please use the options on the <Link to={`/booking/${messageFilters._booking_id}`} className="link">Opportunity Details</Link> page instead.</p>,
                    },
              ]}
            />
        )}
        </Query>
    );
};

function parseHistoryItem(tag, /* booking_participant */) {
    const message = {
        tag
    };

    // early_confirmation_request
    // last_quantitative_application_time
    // session_click_confirm_button
    // session_completed
    // session_completed_feedback_request
    // session_completed_feedback_submitted
    // session_confirmed_cancellation
    // session_invitation_accepted
    // session_invitation_declined
    // session_invitation_notification
    // session_invitation_notification_followup
    // session_opportunity_application
    // session_paid
    // session_participant_cancelled_by_askable
    // session_participant_cancelled_by_client
    // session_quant_completed
    // session_quant_invitation_notification
    // session_reinvite_notification
    // session_start_reminder_2_askable_incentive_message
    // session_start_reminder_action_needed
    // session_start_reminder_action_needed_followup
    // session_start_reminder_confirmation_pending_askable_notification
    // session_start_reminder_last_askable_warning
    // session_start_reminder_no_action

    switch (tag) {
        case 'session_opportunity_application':
            message.tag = 'session_opportunity_application';
            message.body = 'You applied to this opportunity';
            message.icon = 'device-action';
        break;
        case 'last_quantitative_application_time':
            message.tag = 'last_quantitative_application_time';
            message.body = 'You started the task';
            message.icon = 'device-action';
        break;
        case 'session_invitation_accepted':
            message.body = 'You accepted the invitation';
            message.icon = 'device-check';
        break;
        case 'session_invitation_declined':
            message.body = 'You declined the invitation';
            message.icon = 'device-cancel';
        break;
        // case '':
        //     message.body = 'Your invite has been revoked';
        // break;
        case 'session_click_confirm_button':
            message.body = 'You have re-confirmed that you will be attending';
            message.icon = 'calendar-check';
        break;
        // case '':
        //     message.body = 'You accepeted the altered session time';
        // break;
        case 'session_confirmed_cancellation':
            message.body = 'You have cancelled your attendance';
            message.icon = 'calendar-cross';
        break;
        case 'session_participant_cancelled_by_client':
            message.body = 'You have been cancelled by the researcher';
            message.icon = 'calendar-cross';
        break;
        case 'session_participant_cancelled_by_askable':
            message.body = 'You have been cancelled by Askable Admin';
            message.icon = 'calendar-cross';
        break;
        case 'session_completed':
            message.body = 'You have confirmed that you attended the session';
            message.icon = 'user-check';
        break;
        case 'session_quant_completed':
            message.body = 'You have confirmed that you completed the task';
            message.icon = 'checkboxes';
        break;
        case 'session_paid':
            message.body = 'Your payment has been sent';
            message.icon = 'payment';
        break;
        case 'session_completed_feedback_submitted':
            message.body = 'You left feedback';
            message.icon = 'comment';
        break;
        // case '':
        //     message.body = 'You have been added to the waitlist';
        // break;
        default:
            return {};
    }

    return message;
}

function parseMessageData(booking) {
    if (!booking) {
        return [];
    }

    const history = _.chain(booking)
        .get('booking_participant', [])
        .map(booking_participant => (
            _.chain(booking_participant.history)
                .toPairs()
                .filter(h => typeof h[1] === 'number')
                .map(h => ({
                    _id: `${h[0]}`,
                    type: 'history',
                    ...parseHistoryItem(h[0], booking_participant),
                    created: h[1],
                    booking_participant
                }))
                .filter(h => h.tag)
                .value()
        ))
        .flatten()
        .groupBy('_id')
        .map(h => _.minBy(h, 'created'))
        .value();

    // console.log(history);

    return _.chain(booking)
        .get('messages', [])
        .concat(history)
        .value();
}

export default BookingMessages;
