import gql from 'graphql-tag';

export const verificationResponseFragment = gql`
    fragment verificationResponseFields on VerificationResponseType {
        _id
        sent
        resend
        phone
        type
        rate_limited
    }
`;
