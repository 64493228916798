import gql from 'graphql-tag';

export default gql`
    mutation participantRequestHelp($participant_session_id: ID!, $message_body: String!, $message_tag: String) {
      participantRequestHelp(
          participant_session_id: $participant_session_id,
          message_body: $message_body,
          message_tag: $message_tag,
        ) {
            _id status cancel
            help_requested
      }
  }
`;
