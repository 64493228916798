import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

class Modal extends Component {
    constructor(props) {
        super(props);

        this.renderDefaultActions = this.renderDefaultActions.bind(this);
    }

    onSubmitClick() {
        this.props.onSubmit && this.props.onSubmit();
    }

    renderDefaultActions() {
        return (
            <div>
                <FlatButton
                    label="Cancel"
                    primary
                    onClick={() => this.props.onClose()}
                />
                <FlatButton
                    label="Submit"
                    primary
                    onClick={() => this.onSubmitClick()}
                />
            </div>
        );
    }

    render() {
        return (
            <Dialog
                title={this.props.title}
                actions={this.props.actions ? this.props.actions : this.renderDefaultActions()}
                modal={this.props.modal ? this.props.modal : false}
                open={this.props.open}
                onRequestClose={this.props.onClose}
                contentClassName={this.props.className && this.props.className}
            >
                {this.props.children}
            </Dialog>
        );
    }
}

export default Modal;
