import React, { Component } from 'react';
import {
 graphql, compose, Query, Mutation
} from 'react-apollo';
import { Link } from 'react-router-dom';

import _ from 'lodash';
import dot from 'dot-object';
import validator from 'validator';
import moment from 'lib/moment';

import { localStorage } from 'lib/storage';
import { utils, userMeta } from 'lib/utils';
import notificationsValues from 'settings/notificationsValues';

import {
 ToggleSwitch, Input, Select, Button, LoadingOverlay, SneakyConfirmation, CountryCodeSelect
} from 'components/common';

import fetchUserById from 'data/queries/user/fetchUserById';
import updateMe from 'data/mutations/updateMe';
import latestAgreementByType from 'data/queries/latestAgreementByType';
import getIndustryList from 'data/queries/getIndustryList';
import listAvailablePayoutMethodsQuery from 'data/queries/listAvailablePayoutMethods';
import emailMyEarningsReport from 'data/mutations/emailMyEarningsReport';

import ReferFriendDialog from './referralDialogView';
import DeleteAccountDialog from './deleteAccountDialogView';
import LocationAutocompleteDialog from './locationAutocompleteDialog';
import EmploymentDetailsDialog from './employmentDetailsDialogView';
import { withKinde } from '../../AuthWrapper';

import './styles/menu.scss';
import { PayPalButton } from '../userGetDetails/pages/payPalButton';
import { UnlinkPayPalDialog } from './unlinkPayPalDialog';
import { VerifyPayPalMessage } from './verifyPayPalMessage';

dot.override = true;

// The financial years available for earnings reports
const financialYears = [
  { value: 2020, label: 'FY 2019/2020' },
  { value: 2021, label: 'FY 2020/2021' },
  { value: 2022, label: 'FY 2021/2022' },
  { value: 2023, label: 'FY 2022/2023' },
  { value: 2024, label: 'FY 2023/2024' }
];

// The financial year from and to dates for each country
const financialYearPeriod = {
  au: { from: '07-01', to: '06-30' },
  us: { from: '10-01', to: '09-30' },
  uk: { from: '04-06', to: '04-05' },
};

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // userID: null,
      invalidFields: {},
      displayErrors: true,
      loading: false,
      user: null,
      payoutConfirmation: null,
      referralDialog: false,
      updateUser: {},
      employmentDetailsDialog: null,
      earningsReportFY: financialYears[financialYears.length - 1].value,
    };

    this.updateUser = this.updateUser.bind(this);
    this.handleCommonComponentChange = this.handleCommonComponentChange.bind(this);
    this.renderCommonComponent = this.renderCommonComponent.bind(this);
    this.renderLocationDetails = this.renderLocationDetails.bind(this);
    this.renderOccupationDetails = this.renderOccupationDetails.bind(this);
    this.renderEmploymentType = this.renderEmploymentType.bind(this);

    this.updateUserTimeout = null;
    this.birthdateFormat = 'DD/MM/YYYY';

    this.updateMenuState = this.updateMenuState.bind(this);
  }

  componentWillMount() {
    this.updateMenuState(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.updateMenuState(newProps);
  }

  updateMenuState(newProps, checkUserProps = true, force = false) {
    if (!(_.get(newProps, 'fetchUserById.loading') || false) && _.get(newProps, 'fetchUserById.userByID') && (!this.state.user || force)) {
      let properlyLoaded = true;
      if (checkUserProps) {
        _.forEach(userMeta.bigSeven, (prop) => {
          switch (prop) {
            case 'contact.phone.verification.mobile':
              return;
            default:
              if (!_.get(newProps.fetchUserById.userByID, prop)) {
                properlyLoaded = false;
                return false;
              }
          }
        });
        if (!properlyLoaded) {
          // this.setState({ loading: true });
          // newProps.fetchUserById.refetch();
          // return false;
          this.setState({ profileIncomplete: true });
        }
      }
      const parseJSON = JSON.stringify(newProps.fetchUserById.userByID)
        .replace(/^{"_id":".+?",/, '{')
        .replace(/,{0,1}"__typename":".+?"/g, '');
      const user = JSON.parse(parseJSON);
      let parsedPhoneNumber = null;
      if (_.has(user, 'contact.phone.mobile')) {
        parsedPhoneNumber = utils.parsePhoneNumber(user.contact.phone.mobile, _.get(user, 'location.country'));
        if (parsedPhoneNumber) {
          user.contact.phone.mobile = parsedPhoneNumber.format.national;
        }
      }
      const birthday = moment((user.meta.identity && user.meta.identity.birthday && user.meta.identity.birthday.timestamp) || null);
      this.setState({
        // userID: newProps.fetchUserById.userByID._id,
        user,
        updateUser: JSON.parse(parseJSON),
        parsedPhoneNumber,
        birthdayInput: birthday.isValid() ? birthday.format(this.birthdateFormat) : '',
        loading: false,
      });
    }
  }

  updateUser(prop, value, updateServer) {
    const user = { ...this.state.user };
    dot.str(prop, value, user);
    // console.log(JSON.stringify(user, undefined, 1));

    const updateUser = { ...this.state.updateUser };
    switch (prop) {
      case 'contact.phone.mobile':
        {
          const parsedPhoneNumber = utils.parsePhoneNumber(value, this.state.phoneCountry || _.get(this.state.user, 'location.country'));
          if (parsedPhoneNumber && parsedPhoneNumber.valid) {
            _.set(updateUser, prop, parsedPhoneNumber.E164);
          }
          this.setState({ parsedPhoneNumber });
        }
        break;
      case 'location':
        updateUser.location = value;
        break;
      default:
        _.set(updateUser, prop, value);
    }

    this.setState({ user, updateUser });
    // console.log('user', user);
    // console.log('updateUser', prop, value, updateUser);

    if (updateServer) {
      // console.log('updateUser', updateUser);
      clearTimeout(this.updateUserTimeout);
      this.updateUserTimeout = setTimeout(this.props.updateUser, 500, updateUser);
      // this.setState({ updateUser: {} });
      // this.props.updateUser(user);
    }
  }

  handleCommonComponentChange(value, userProp, componentProps) {
    const invalidFields = { ...this.state.invalidFields };
    if (value !== _.get(this.state.user, userProp)) {
      utils.leaveWarningSet('There are unsaved changes on this page, are you sure you want to leave?');
    }
    this.updateUser(userProp, value);
    if (componentProps.validate) {
      invalidFields[userProp] = componentProps.validate(value);
      this.setState({ invalidFields });
    } else if (componentProps.required && value.replace(/^\s+|\s+$/g, '') === '') {
      invalidFields[userProp] = 'This field is required';
      this.setState({ invalidFields });
    } else {
      invalidFields[userProp] = undefined;
      this.setState({ invalidFields });
    }
    if (componentProps.onChange) {
      componentProps.onChange(value);
    }
  }

  renderCommonComponent(component, label, userProp, componentProps = {}) {
    switch (component) {
      case 'toggle':
        return (
          <ToggleSwitch
            label={label}
            value={dot.pick(userProp, this.state.user)}
            onToggle={(value) => {
              this.updateUser(userProp, value, true);
              this.setState({ toggleConfirmation: new Date().valueOf() });
            }}
            key={componentProps.key}
          />
        );
      case 'input': {
        return (
          <Input
            placeholder={label}
            value={dot.pick(userProp, this.state.user) || ''}
            type={componentProps.type}
            disabled={componentProps.disabled}
            key={componentProps.key}
            onChange={(value) => {
              this.handleCommonComponentChange(value, userProp, componentProps);
            }}
            errorText={this.state.displayErrors && this.state.invalidFields[userProp]}
          />
        );
      }
      case 'select':
        return (
          <Select
            placeholder={label}
            value={dot.pick(userProp, this.state.user)}
            values={componentProps.values}
            fullWidth
            orderValues="label"
            key={componentProps.key}
            onChange={(value) => {
              utils.leaveWarningSet('There are unsaved changes on this page, are you sure you want to leave?');
              this.updateUser(userProp, value);
              if (componentProps.onChange) {
                componentProps.onChange(value);
              }
            }}
          />
        );
      case 'objectSelect':
        return (
          <Select
            placeholder={label}
            value={_.findKey(dot.pick(userProp, this.state.user), (value) => value)}
            key={componentProps.key}
            onChange={(value) => {
              utils.leaveWarningSet('There are unsaved changes on this page, are you sure you want to leave?');
              const saveValue = {};
              _.forEach(componentProps.values, (key) => {
                saveValue[key] = key === value;
              });
              this.updateUser(userProp, saveValue);
              if (componentProps.onChange) {
                componentProps.onChange(value);
              }
            }}
            values={componentProps.values.map((key) => {
              return { value: key, label: utils.getKeyDescription(userProp, key) };
            })}
            fullWidth
            orderValues="label"
          />
        );
      default:
        return null;
    }
  }

  renderLocationDetails() {
    const locationLine1 = [];
    if (_.get(this.state.user, 'location.city')) {
      locationLine1.push(this.state.user.location.city);
    }

    const locationLine2 = [];
    if (_.get(this.state.user, 'location.state')) {
      locationLine2.push(this.state.user.location.state);
    }
    if (_.get(this.state.user, 'location.country')) {
      locationLine2.push(this.state.user.location.country);
    }
    if (_.get(this.state.user, 'location.postal_code')) {
      locationLine2.push(this.state.user.location.postal_code);
    }

    return (
      <>
        <span className="menuTitle small">
          <strong>Location</strong>
          &nbsp;&nbsp;&nbsp;
          <button
            className="link"
            onClick={() => {
              this.setState({ locationAutocompleteDialog: true });
            }}
          >
            change
          </button>
        </span>
        {locationLine1.length > 0 ? <span className="menuTitle small">{locationLine1.join(' ')}</span> : null}
        {locationLine2.length > 0 ? <span className="menuTitle small">{locationLine2.join(' ')}</span> : null}

        <LocationAutocompleteDialog
          userLocation={this.state.user.location}
          open={!!this.state.locationAutocompleteDialog}
          onChange={(location) => {
            this.setState({ locationAutocompleteDialog: false });
            if (!location) return;
            this.props
              .updateUser({ location }, [{ query: listAvailablePayoutMethodsQuery }])
              .then(() => {
                if (_.get(this.props, 'fetchUserById.refetch')) {
                  this.props.fetchUserById.refetch().then(() => {
                    this.updateMenuState(this.props, false, true);
                  });
                }
                this.setState({ loading: false, profileConfirmation: new Date().valueOf() });
                utils.leaveWarningDelete();
              })
              .catch((error) => {
                console.error(error);
                this.setState({
                  loading: false,
                  errorMessage: _.get(error, 'graphQLErrors.0.message', 'Failed to save profile'),
                });
                utils.leaveWarningDelete();
              });
          }}
        />
      </>
    );
  }

  renderOccupationDetails() {
    let industryName = null;
    if (_.get(this.state.user, 'meta.work._industry_id')) {
      const doc = _.chain(_.get(this.props, 'getIndustryList.industryList')).find({ _id: this.state.user.meta.work._industry_id }).value();
      if (doc && doc.name) {
        industryName = doc.name;
      }
    }
    if (_.get(this.state.user, 'meta.work._subindustry_id')) {
      const doc = _.chain(_.get(this.props, 'getIndustryList.industryList'))
        .map((industry) => industry.subcategories)
        .flatten()
        .find({ _id: this.state.user.meta.work._subindustry_id })
        .value();
      if (doc && doc.name) {
        industryName = `${industryName} (${doc.name})`;
      }
    }
    return (
      <>
        <span className="menuTitle small">
          <strong>Occupation details</strong>
          &nbsp;&nbsp;&nbsp;
          <button
            className="link"
            onClick={() => {
              this.setState({ employmentDetailsDialog: 'OccupationSelect' });
            }}
          >
            change
          </button>
        </span>
        <span className="menuTitle small">
          <strong>Industry</strong>
:
{industryName || <em>not set</em>}
        </span>
        <span className="menuTitle small">
          <strong>Job title</strong>
:
{_.get(this.state.user, 'meta.work.job_title', <em>not set</em>)}
        </span>
      </>
    );
  }

  renderEmploymentType() {
    let employmentType = null;
    switch (_.get(this.state.user, 'meta.work.employment_type')) {
      case 1:
        employmentType = 'Employee';
        break;
      case 2:
        employmentType = 'Self-employed';
        break;
      case 3:
        employmentType = 'Business owner';
        break;
      default:
    }

    let businessSize = null;
    switch (_.get(this.state.user, 'meta.work.business.size')) {
      case 1:
        businessSize = 'Myself only';
        break;
      case 2:
        businessSize = '2 - 10 employees';
        break;
      case 3:
        businessSize = '11 - 50 employees';
        break;
      case 4:
        businessSize = '51 - 200 employees';
        break;
      case 5:
        businessSize = '201 - 1,000 employees';
        break;
      case 6:
        businessSize = 'More than 1,000 employees';
        break;
      default:
    }

    let businessTurnover = null;
    switch (_.get(this.state.user, 'meta.work.business.turnover')) {
      case 1:
        businessTurnover = 'Less than $100K';
        break;
      case 2:
        businessTurnover = '$100K - $500K';
        break;
      case 3:
        businessTurnover = '$500K - $2M';
        break;
      case 4:
        businessTurnover = '$2M - $5M';
        break;
      case 5:
        businessTurnover = '$5M - $25M';
        break;
      case 6:
        businessTurnover = 'More than $25M';
        break;
      default:
    }

    return (
      <>
        <span className="menuTitle small">
          <strong>Employment type</strong>
:
{employmentType || <em>not set</em>}
          &nbsp;&nbsp;&nbsp;
          <button
            className="link"
            onClick={() => {
              this.setState({ employmentDetailsDialog: 'EmpoymentTypeSelect' });
            }}
          >
            change
          </button>
        </span>
        {businessSize && (
          <span className="menuTitle small">
            <strong>Business size</strong>
:
{businessSize}
          </span>
        )}
        {businessTurnover && (
          <span className="menuTitle small">
            <strong>Turnover</strong>
:
{businessTurnover}
          </span>
        )}
      </>
    );
  }

  render() {
    if (this.state.loading) {
      return <LoadingOverlay />;
    }
    if ((_.get(this.props, 'fetchUserById.loading') || false || !this.state.user) && this.props.menu !== 'mainMenu') {
      return <LoadingOverlay />;
    }
    const latestAgreementLink = _.get(this.props.latestAgreementByType, 'latestAgreementByType.url') ? (
      <a href={this.props.latestAgreementByType.latestAgreementByType.url} target="_blank" rel="noopener noreferrer" className="menuLink small">
        {this.props.latestAgreementByType.latestAgreementByType ? this.props.latestAgreementByType.latestAgreementByType.name : '\u00a0'}
      </a>
    ) : (
      <Link to="/latestAgreement" className="menuLink small">
        {this.props.latestAgreementByType.latestAgreementByType ? this.props.latestAgreementByType.latestAgreementByType.name : '\u00a0'}
      </Link>
    );
    if (!_.get(this.props, 'fetchUserById.userByID') && !(_.get(this.props, 'fetchUserById.loading') || false)) {
      return (
        <div className="menuWrapper">
          <h1>Askable</h1>

          <div className="menuDivider" />

          <Link to="/login" className="menuLink">
            Log in
          </Link>
          <Link to="/signup" className="menuLink">
            Create an account
          </Link>

          <div className="menuDivider" />

          <Link to="/help" className="menuLink small">
            Help
          </Link>

          <div className="menuDivider" />

          {latestAgreementLink}
          <a href="https://www.askable.com/legal?tab=privacy" target="_blank" rel="noopener noreferrer" className="menuLink small">
            Privacy Policy
          </a>
        </div>
      );
    }

    const errorMessage = this.state.errorMessage && <p className="errorMessage">{this.state.errorMessage}</p>;
    const preferredPaymentMethod = _.get(this.state.user, 'settings.billing.preferred');

    const handleUnlinkPayPal = async () => {
      try {
        const user = {
 settings: {
 billing: {
 ...this.state.user.settings.billing, paypal: null, preferred: null, paypal_details: null
}
}
};

        this.setState({ loading: true, errorMessage: null });
        this.props.updateUser(user).then(() => {
          if (_.get(this.props, 'fetchUserById.refetch')) {
            this.props.fetchUserById.refetch().then(() => {
              this.updateMenuState(this.props, false, true);
            });
          }
          this.setState({ loading: false, profileConfirmation: new Date().valueOf() });
          utils.leaveWarningDelete();
        });
      } catch (error) {
        console.error(error);
        this.setState({
          loading: false,
          errorMessage: _.get(error, 'graphQLErrors.0.message', 'Failed to save profile'),
        });
        utils.leaveWarningDelete();
      }

      this.setState({ isUnlinkPayPalModalVisible: false });
    };

    switch (this.props.menu) {
      case 'profile':
        return (
          <div className="menuWrapper">
            <h1>Edit profile</h1>
            {errorMessage}
            <SneakyConfirmation
              open={this.state.profileConfirmation}
              onClose={() => {
                this.setState({ profileConfirmation: null });
              }}
              style={{ top: '-12px', fontSize: '14px', zIndex: -1 }}
            >
              Profile updated!
            </SneakyConfirmation>
            <div className="menuDivider" />
            {this.renderCommonComponent('input', 'First name', 'meta.identity.firstname', { required: true })}
            {this.renderCommonComponent('input', 'Last name', 'meta.identity.lastname', { required: true })}
            {this.renderCommonComponent('input', 'Email address', 'email', {
              type: 'email',
              disabled: this.props.isKinde,
              validate: (value) => {
                return validator.isEmail(value) ? undefined : 'The email address is invalid';
              },
            })}
            <div className="flex flexRow">
              {this.renderCommonComponent('input', 'Mobile phone', 'contact.phone.mobile', {
                type: 'tel',
                validate: (value) => {
                  const parsedPhoneNumber = utils.parsePhoneNumber(value, this.state.phoneCountry || _.get(this.state.user, 'location.country'));
                  this.setState({ parsedPhoneNumber });
                  return parsedPhoneNumber.valid ? undefined : 'The phone number is invalid';
                },
              })}
              <CountryCodeSelect
                readonly
                value={_.get(this.state.user, 'location.country')}
                defaultNumber={this.state.parsedPhoneNumber && this.state.parsedPhoneNumber.E164}
                style={{ marginTop: '24px' }}
              />
            </div>
            {this.renderCommonComponent('select', 'Gender', 'meta.identity.gender', {
              values: [
                { value: 'female', label: 'Female' },
                { value: 'male', label: 'Male' },
                { value: 'non-binary', label: 'Non-binary' },
              ],
            })}
            <Input
              placeholder="Date of birth"
              hintText={this.birthdateFormat}
              value={this.state.birthdayInput}
              onChange={(birthdayInput) => {
                const invalidFields = { ...this.state.invalidFields };
                const valueTime = moment.tz(birthdayInput, this.birthdateFormat, 'UTC');
                if (birthdayInput.replace(/^\s+|\s+$/g, '') === '') {
                  invalidFields['meta.identity.birthday'] = 'This field is required';
                } else if (!valueTime.isValid()) {
                  invalidFields['meta.identity.birthday'] = `The date you enteted is invalid - make sure it's formatted like ${this.birthdateFormat}`;
                } else if (valueTime.year() >= moment().year() || valueTime.year() < moment().year() - 150) {
                  invalidFields['meta.identity.birthday'] = `The date you enteted is invalid - make sure it's formatted like ${this.birthdateFormat}`;
                } else {
                  invalidFields['meta.identity.birthday'] = undefined;
                }
                this.setState({ birthdayInput, invalidFields, birthdayTime: valueTime });
              }}
              errorText={this.state.displayErrors && this.state.invalidFields['meta.identity.birthday']}
            />

            <div className="menuDivider" />
            {this.renderLocationDetails()}
            <div className="menuDivider" />

            {this.renderCommonComponent('objectSelect', 'Family status', 'meta.family.status', {
              values: ['single', 'relationship', 'married', 'divorced', 'widowed'],
            })}
            {this.renderCommonComponent('objectSelect', 'Highest level of education', 'meta.education', {
              values: ['highschool', 'some_university', 'undergraduate', 'postgraduate', 'apprenticeship'],
            })}
            {this.renderCommonComponent('objectSelect', 'Occupation status', 'meta.work.status', {
              values: ['fulltime', 'parttime', 'fulltime_student', 'parttime_student', 'homeduties', 'retired', 'unemployed'],
            })}

            <div className="menuDivider" />
            {this.renderOccupationDetails()}
            <div className="menuDivider" />
            {this.renderEmploymentType()}

            <EmploymentDetailsDialog
              type={this.state.employmentDetailsDialog}
              onChange={(user) => {
                if (!user) {
                  this.setState({ employmentDetailsDialog: null });
                  return false;
                }
                this.setState({ employmentDetailsDialog: null, loading: true, errorMessage: null });
                this.props
                  .updateUser(user)
                  .then(() => {
                    if (_.get(this.props, 'fetchUserById.refetch')) {
                      this.props.fetchUserById.refetch().then(() => {
                        this.updateMenuState(this.props, false, true);
                      });
                    }
                    this.setState({ loading: false, profileConfirmation: new Date().valueOf() });
                    utils.leaveWarningDelete();
                  })
                  .catch((error) => {
                    console.error(error);
                    this.setState({
                      loading: false,
                      errorMessage: _.get(error, 'graphQLErrors.0.message', 'Failed to save profile'),
                    });
                    utils.leaveWarningDelete();
                  });
              }}
            />

            {this.renderCommonComponent('input', 'LinkedIn public profile', 'meta.social.linkedin.profile_url', { required: false })}

            <Button
              label="Save"
              labelColor="#fff"
              bgColor="#FF5266"
              className="mtop20"
              type="button"
              disabled={JSON.stringify(this.state.invalidFields) !== '{}'}
              onClick={() => {
                const user = { ...this.state.updateUser };
                user.email = utils.sanitizeEmail(user.email);

                if (this.state.birthdayTime) {
                  user.meta.identity.birthday = {
                    timestamp: this.state.birthdayTime.valueOf(),
                    day: this.state.birthdayTime.date(),
                    month: this.state.birthdayTime.month() + 1,
                    year: this.state.birthdayTime.year(),
                  };
                }

                this.setState({ loading: true, errorMessage: null });
                this.props
                  .updateUser(user)
                  .then(() => {
                    if (_.get(this.props, 'fetchUserById.refetch')) {
                      this.props.fetchUserById.refetch().then(() => {
                        this.updateMenuState(this.props, false, true);
                      });
                    }
                    this.setState({ loading: false, profileConfirmation: new Date().valueOf() });
                    utils.leaveWarningDelete();
                  })
                  .catch((error) => {
                    console.error(error);
                    this.setState({
                      loading: false,
                      errorMessage: _.get(error, 'graphQLErrors.0.message', 'Failed to save profile'),
                    });
                    utils.leaveWarningDelete();
                  });
                // console.log(JSON.stringify(user, undefined, 1));
                // go back
              }}
            />

            <p className="textCenter mtop40">
              <a
                href="#delete-account"
                onClick={() => {
                  this.setState({ deleteAccountDialog: true });
                }}
                className="link"
              >
                Delete my account
              </a>
            </p>

            <DeleteAccountDialog
              open={!!this.state.deleteAccountDialog}
              onCancel={() => {
                this.setState({ deleteAccountDialog: false });
              }}
            />
          </div>
        );
      case 'notifications':
        return (
          <div className="menuWrapper">
            <h1>Notification preferences</h1>
            {errorMessage}
            <div className="menuDivider" />
            <SneakyConfirmation
              open={this.state.toggleConfirmation}
              onClose={() => {
                this.setState({ toggleConfirmation: null });
              }}
              style={{ top: '-12px', fontSize: '14px', zIndex: -1 }}
              className="mbottom40"
            >
              Notification preferences saved!
            </SneakyConfirmation>
            <div className="mtop10" />

            {notificationsValues.map((notification, notificationIndex) => {
              if (notification.key === 'promotional' && utils.getUserRestrictions(this.state.user)) return null;
              return (
                <React.Fragment key={notification.key}>
                  {notificationIndex > 0 && <div className="menuDivider border" />}
                  <h2>{notification.title}</h2>
                  <p>{notification.description}</p>
                  {notification.channels.map((channel, channelIndex) => {
                    return (
                      <React.Fragment key={channel.key}>
                        {channelIndex > 0 && <div className="menuDivider small" />}
                        {channel.mandatory ? (
                          <ToggleSwitch label={channel.label} value disabled />
                        ) : (
                          this.renderCommonComponent('toggle', channel.label, `settings.notification.${notification.key}.${channel.key}`)
                        )}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </div>
        );
      case 'payout': {
        let payoutInput = null;
        let payoutDescription = null;
        switch (preferredPaymentMethod) {
          case 'paypal':
            {
              const payPalDomain = this.state.user.location.country === 'AUS' ? 'https://www.paypal.com/AU/' : 'https://www.paypal.com/';
              const paypalEmail = _.get(this.state.user, 'settings.billing.paypal');
              const paypalId = _.get(this.state.user, 'settings.billing.paypal_details._id');
              // if the user doesn't have both values, don't consider their paypal 'linked'
              payoutInput = (paypalEmail && paypalId) ? (
                <div style={{ marginTop: 20 }}>
                  <h3>Linked PayPal email:</h3>
                  <div>{paypalEmail}</div>
                  <br />
                  <button onClick={() => this.setState({ isUnlinkPayPalModalVisible: true })} className="link" type="button" style={{ marginTop: 5 }}>
                    Unlink account
                  </button>
                </div>
              ) : (
                <div style={{ marginTop: 20 }}>
                  <div>Link PayPal account:</div>
                  <br />
                  <PayPalButton />
                </div>
              );
              payoutDescription = (
                <div>
                  <VerifyPayPalMessage show={paypalEmail && _.get(this.state.user, 'settings.billing.paypal_details._id') && _.get(this.state.user, 'settings.billing.paypal_details.status') !== true} />
                  <p>
                    <a href={`${payPalDomain}cshelp/article/what-are-the-benefits-of-using-paypal--help333`} target="_blank" rel="noopener noreferrer" className="link">
                      The benefits of using PayPal
                    </a>
                  </p>
                  <h3>How do I withdraw money from PayPal?</h3>
                  <p>
                    Your two main options are to link your PayPal account to your bank account, or to use your PayPal account directly to buy things online.
                  </p>
                </div>
              );
            }
            break;
          case 'giftpay':
            {
              payoutInput = this.renderCommonComponent('input', 'Preferred Email Address', 'settings.billing.giftpay', {
                type: 'email',
                validate: (value) => {
                  return validator.isEmail(value) ? undefined : 'The email address is invalid';
                },
                key: 'input_setttings.billing.giftpay',
              });
              let giftpayLocalCards = null;
              let giftpayInfoURL = null;
              switch (this.state.user.location.country) {
                case 'AU':
                  giftpayInfoURL = 'https://www.giftpay.com.au/egift/info.aspx?gift=ndXyUIfKVw8eeFLqjGSP%2BqRBYBJPPk6Hn9c4dBs85ls%3D&value=0';
                  giftpayLocalCards = 'from a huge range of electronic gift cards, including Woolworths, Myer, Coles, iTunes & Event Cinemas.';
                  break;
                case 'NZ':
                  giftpayInfoURL = 'https://www.giftpay.co.nz/egift/info.aspx?gift=WC2MRK5D8Z&value=0';
                  giftpayLocalCards = 'from a huge range of electronic gift cards, including iTunes, JB Hi-Fi, Domino’s & Farmers.';
                  break;
                default:
                  giftpayInfoURL = 'https://www.giftpay.co.nz/egift/info.aspx';
                  giftpayLocalCards = 'from a huge range of electronic gift cards.';
              }
              payoutDescription = (
                <div>
                  <h3>How does this work?</h3>
                  <p>
                    After you complete an opportunity, you&apos;ll be sent a link to the email address you enter above. This does not have to be the same as the
                    one you use to log in to Askable.
                  </p>
                  <p>
Using the link you receive, you&apos;ll be able to redeem your incentive
{giftpayLocalCards}
                  </p>
                  <p>
                    <a href={giftpayInfoURL} target="_blank" rel="noopener noreferrer" className="link">
                      More information & FAQ
                    </a>
{' '}
<small>(external site)</small>
                  </p>
                </div>
              );
            }
            break;
          default:
        }
        return (
          <div className="menuWrapper">
            <UnlinkPayPalDialog
              isOpen={this.state.isUnlinkPayPalModalVisible}
              onCancel={() => this.setState({ isUnlinkPayPalModalVisible: false })}
              onDelete={handleUnlinkPayPal}
            />
            <h1>Payout preference</h1>
            {errorMessage}
            <SneakyConfirmation
              open={this.state.payoutConfirmation}
              onClose={() => {
                this.setState({ payoutConfirmation: null });
              }}
              style={{ top: '-12px', fontSize: '14px', zIndex: -1 }}
            >
              Payout preferences saved!
            </SneakyConfirmation>
            <div className="menuDivider" />
            <Query query={listAvailablePayoutMethodsQuery}>
              {({ data }) => {
                const labels = {
                  paypal: 'PayPal',
                  giftpay: 'E-Gift Card',
                };

                return this.renderCommonComponent('select', 'How do you prefer to be paid?', 'settings.billing.preferred', {
                  values: _.get(data, 'listAvailablePayoutMethods', [])
                    .filter((value) => labels[value])
                    .map((value) => ({ value, label: labels[value] })),
                  onChange: (value) => {
                    switch (value) {
                      case 'giftpay':
                        if (!_.get(this.state.user, 'settings.billing.giftpay')) {
                          this.setState({ user: dot.str('settings.billing.giftpay', this.state.user.email, this.state.user) });
                        }
                        break;
                      default:
                    }
                  },
                });
              }}
            </Query>
            {payoutInput}
            {preferredPaymentMethod && preferredPaymentMethod !== 'paypal' && (
              <Button
                label="Save"
                labelColor="#fff"
                bgColor="#FF5266"
                className="mtop20"
                type="button"
                disabled={
                  (this.state.invalidFields && JSON.stringify(this.state.invalidFields) !== '{}')
                  || !(_.get(this.state.user, 'settings.billing.preferred') && this.state.user.settings.billing[this.state.user.settings.billing.preferred])
                }
                onClick={() => {
                  const saveBilling = _.pick(_.get(this.state.user, 'settings.billing', {}), ['preferred', 'giftpay', 'paypal']);
                  const user = { settings: { billing: saveBilling } };

                  this.setState({ loading: true, errorMessage: null });
                  this.props
                    .updateUser(user)
                    .then(() => {
                      this.setState({ loading: false, payoutConfirmation: new Date().valueOf() });
                      if (_.get(this.props, 'fetchUserById.refetch')) {
                        this.props.fetchUserById.refetch();
                      }
                      utils.leaveWarningDelete();
                    })
                    .catch((error) => {
                      this.setState({
                        loading: false,
                        errorMessage: _.get(error, 'graphQLErrors.0.message', 'Failed to save preference'),
                      });
                      utils.leaveWarningDelete();
                    });
                }}
              />
            )}
            <div className="menuDivider border" />
            {payoutDescription}
          </div>
        );
      }
      case 'earnings':
        return (
          <Mutation mutation={emailMyEarningsReport}>
            {(requestEarningsMutation, requestEarningsResult) => {
              const renderConfirmationMessage = () => {
                if (requestEarningsResult.loading) return null;
                if (requestEarningsResult.error) {
                  return (
                    <p className="error">
                      {_.get(requestEarningsResult, 'error.graphQLErrors.0.message') || requestEarningsResult.error || 'Failed to send earnings history'}
                    </p>
                  );
                }
                if (_.get(requestEarningsResult, 'data.emailMyEarningsReport.email')) {
                  return (
                    <p>
                    Earnings history emailed to
                    {requestEarningsResult.data.emailMyEarningsReport.email}
                    </p>
                  );
                }
              };
              return (
                <div className="menuWrapper">
                  <h1>Tax obligations</h1>
                  {errorMessage}
                  <div className="menuDivider" />

                  <p>Generally, incentive payments earned via market research activities are taxable in Australia.</p>
                  <p>
                    Find more information
                    {' '}
                    <a
                      href="https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/tax-obligations/tax-obligations-aud.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      here
                    </a>
                  </p>

                  <Select
                    value={this.state.earningsReportFY}
                    values={financialYears}
                    fullWidth
                    orderValues="label"
                    onChange={(value) => {
                      this.setState({ earningsReportFY: value });
                    }}
                    className="mbottom0"
                  />

                  <Button
                    label={requestEarningsResult.loading ? 'Loading...' : 'Request earnings history'}
                    labelColor="#fff"
                    bgColor="#FF5266"
                    className="mtop0 mbottom30"
                    type="button"
                    disabled={requestEarningsResult.loading}
                    onClick={async () => {
                      const year = Number(this.state.earningsReportFY);
                      const country = 'au'; // Hardcoded to AU for now
                      requestEarningsMutation({
                        variables: {
                          request: {
                            from_date: `${year - 1}-${financialYearPeriod[country].from}`,
                            to_date: `${year}-${financialYearPeriod[country].to}`
                          }
                        }
                      }).catch(() => null);
                    }}
                  />
                  {renderConfirmationMessage()}
                </div>
              );
            }}
          </Mutation>
        );
      default:
        if (utils.isDemoUser()) {
          return (
            <div className="menuWrapper">
              <h1>{this.state.user ? `${this.state.user.meta.identity.firstname} ${this.state.user.meta.identity.lastname}` : '\u00A0'}</h1>
              <strong className="small">Askable demo account</strong>
              <div className="menuDivider border" />
              <Link
                to="/help"
                className="menuLink"
                onClick={() => {
                  this.props.toggleDrawer();
                }}
              >
                Help
              </Link>
              <Link
                to="/logout"
                className="menuLink"
                onClick={() => {
                  this.props.toggleDrawer();
                }}
              >
                Log out
              </Link>
              <div className="menuDivider" />
              {latestAgreementLink}
              <a href="https://www.askable.com/legal?tab=privacy" target="_blank" rel="noopener noreferrer" className="menuLink small">
                Privacy Policy
              </a>
            </div>
          );
        }
        return (
          <div className="menuWrapper">
            <h1>{this.state.user ? `${this.state.user.meta.identity.firstname} ${this.state.user.meta.identity.lastname}` : '\u00A0'}</h1>
            <div className="menuDivider border" />

            <strong className="menuTitle small">New near you</strong>
            <Link
              to="/opportunities"
              className="menuLink"
              onClick={() => {
                this.props.toggleDrawer();
              }}
            >
              Find opportunities
            </Link>

            <div className="menuDivider" />

            <strong className="menuTitle small">Your jobs</strong>
            <Link
              to="/bookings/upcoming"
              className="menuLink"
              onClick={() => {
                this.props.toggleDrawer();
              }}
            >
              Upcoming sessions
            </Link>
            <Link
              to="/bookings/pending"
              className="menuLink"
              onClick={() => {
                this.props.toggleDrawer();
              }}
            >
              Pending sessions
            </Link>
            <Link
              to="/bookings/past"
              className="menuLink"
              onClick={() => {
                this.props.toggleDrawer();
              }}
            >
              Past sessions
            </Link>

            <div className="menuDivider" />

            <Link
              to="/messages"
              className="menuLink"
              onClick={() => {
                this.props.toggleDrawer();
              }}
            >
              Messages
            </Link>

            <div className="menuDivider border" />

            {this.state.profileIncomplete ? (
              <Link to="/onboard" className="menuLink">
                Complete profile
              </Link>
            ) : (
              <button
                className="menuLink"
                type="button"
                onClick={() => {
                  this.props.changeMenu('profile', 2);
                }}
              >
                Your profile
              </button>
            )}
            <button
              className="menuLink"
              type="button"
              onClick={() => {
                this.props.changeMenu('notifications', 2);
              }}
            >
              Notification preferences
            </button>
            <button
              className="menuLink"
              type="button"
              onClick={() => {
                this.props.changeMenu('payout', 2);
              }}
            >
              Payout preferences
            </button>

            <div className="menuDivider" />

            <button
              className="menuLink"
              type="button"
              onClick={() => {
                this.setState({ referralDialog: true });
              }}
            >
              Refer a friend
            </button>
            <ReferFriendDialog
              open={!!this.state.referralDialog}
              onCancel={() => {
                this.setState({ referralDialog: false });
              }}
            />
            <Link
              to="/deals"
              className="menuLink"
              onClick={() => {
                this.props.toggleDrawer();
              }}
            >
              Deals & offers
            </Link>

            <div className="menuDivider border" />

            <Link
              to="/logout"
              className="menuLink"
              onClick={() => {
                this.props.toggleDrawer();
              }}
            >
              Log out
            </Link>

            <div className="menuDivider" />

            <Link
              to="/help"
              className="menuLink small"
              onClick={() => {
                this.props.toggleDrawer();
              }}
            >
              Help
            </Link>

            <div className="menuDivider" />

            <button
              className="menuLink small"
              type="button"
              onClick={() => {
                this.props.changeMenu('earnings', 2);
              }}
            >
              Tax obligations
            </button>
            {latestAgreementLink}
            <a href="https://www.askable.com/legal?tab=privacy" target="_blank" rel="noopener noreferrer" className="menuLink small">
              Privacy Policy
            </a>
          </div>
        );
    }
  }
}

const cleanUserObject = (user) => {
  if (_.has(user, '_id')) {
    delete user._id;
  }
  if (_.has(user, 'created')) {
    delete user.created;
  }
  if (_.has(user, 'type')) {
    delete user.type;
  }
  if (_.has(user, 'status')) {
    delete user.status;
  }
  if (_.has(user, 'updated')) {
    delete user.updated;
  }
  if (_.has(user, 'meta.feedback_sample')) {
    delete user.meta.feedback_sample;
  }
  if (_.get(user, 'settings.billing') === '') {
    delete user.settings.billing;
  }
  if (_.has(user, 'settings.billing.paypal_details._id')) {
    delete user.settings.billing.paypal_details._id;
  }
  if (JSON.stringify(_.get(user, 'settings.billing.paypal_details')) === '{}') {
    delete user.settings.billing.paypal_details;
  }
  return user;
};

const UserDetailsContainer = graphql(fetchUserById, {
  name: 'fetchUserById',
  skip: (props) => !props.isAuthenticated(),
});

const updateMeContainer = graphql(updateMe, {
  props: ({ mutate }) => ({
    updateUser: (user, refetchQueries = []) => mutate({
        variables: { user: cleanUserObject(user) },
        refetchQueries: [{ query: fetchUserById, variables: { id: localStorage.get('connectedParticipant') } }, ...refetchQueries],
      }),
  }),
});

const LatestAgreementContainer = graphql(latestAgreementByType, {
  name: 'latestAgreementByType',
  options: () => ({
    variables: { user_type: { participant: true } },
  }),
});

const IndustryListContainer = graphql(getIndustryList, { name: 'getIndustryList', skip: (props) => !props.isAuthenticated() });

export default withKinde(compose(UserDetailsContainer, updateMeContainer, LatestAgreementContainer, IndustryListContainer)(Menu));
