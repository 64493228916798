import gql from 'graphql-tag';

export default gql`query FetchBookingsByParticipant($status: [Int]) {
  bookingsByParticipant(status: $status) {
    _id type status
    config {
        location {
            street1
            street2
            city
            state
        }
        session{
            duration
            time_limit
        }
        incentive {
            currency_code
            currency_symbol
            country_code
            value
        }
        options {
            review_submission
        }
        online_task { type tool }
    }
    submissions (viewer: true) {
        _id
        completed
        user_confirm
    }
    ParticipantSessions (viewer: true) {
        _id
        status
        user_confirm
        cancel
        _session_id
        _booking_id
        completed
        session {
            _id
            start
            end
        }
        history {
            last_quantitative_application_time
            early_confirmation_request
        }
    }
  }
}`;
