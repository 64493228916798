import React, { Component } from 'react';
import { List, makeSelectable } from 'material-ui/List';

const SelectableList = makeSelectable(List);

function wrapState(ComposedComponent) {
    return class SList extends Component {
        constructor(props) {
            super(props);

            this.state = {
                selectedIndex: 0
            };

            this.handleRequestChange = this.handleRequestChange.bind(this);
        }
        componentWillMount() {
            this.setState({ selectedIndex: this.props.defaultValue });
        }

        handleRequestChange(event, index) {
            this.setState({ selectedIndex: index });
        }

        render() {
            return (
                <ComposedComponent
                    style={{ padding: 0 }}
                    value={this.state.selectedIndex}
                    onChange={this.handleRequestChange}
                >
                    {this.props.children}
                </ComposedComponent>
            );
        }
    };
}

export default wrapState(SelectableList);
