import React from 'react';
import { List, ListItem } from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import Divider from 'material-ui/Divider';

import _ from 'lodash';

export default function (props) {
  const listProps = _.omit(props, ['title', 'items', 'itemDefaultProps']);

  return (
      <List
          {...listProps}
      >
          {props.title && (
              <Subheader
                  {...props.title.props || {}}
              >
                  {props.title.text}
              </Subheader>
          )}
          {(props.items || []).map((item, i) => {
            return (
                <div key={`${props.key || 'ListComponent'}-item-${item.key || i}`} >
                    <ListItem
                      {...Object.assign({}, props.itemDefaultProps || {}, item)}
                    />
                    {item.divider ? <Divider /> : null}
                </div>
            );
          })}
      </List>
  );
}

// class ListComponent extends Component {
//     // constructor() {
//     //     super();
//     // }
//     render() {
//         // const listProps = Object.assign({}, this.props, {
//         //   title: undefined
//         // });
//
//     }
// }
//
// export default (ListComponent);
