import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { graphql, compose, Mutation } from 'react-apollo';
import { withRouter } from 'react-router';
// import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import moment from 'lib/moment';
import _ from 'lodash';
import dot from 'dot-object';
import stringSimilarity from 'string-similarity';

import { utils } from 'lib/utils';

import { fetchBookingByIdAdditional } from 'data/queries/booking/fetchBookingById';
import participantRegisterBookingParticipant from 'data/mutations/participantRegisterBookingParticipant';
import declineInvite from 'data/mutations/declineInvite';

import AccessTimeIcon from 'material-ui/svg-icons/device/access-time';
import PersonIcon from 'material-ui/svg-icons/social/person';
import GroupIcon from 'material-ui/svg-icons/social/group';
import HeadsetIcon from 'material-ui/svg-icons/hardware/headset-mic';
import MoneyIcon from 'material-ui/svg-icons/editor/attach-money';
import GiftCardIcon from 'material-ui/svg-icons/action/card-giftcard';

import { Marker } from 'google-maps-react';
import { Map, LoadingOverlay, List, Button, UserAgentTest } from 'components/common';
import { Card, CardChild } from 'components/common/Card/view';
import PhoneIcon from 'material-ui/svg-icons/hardware/phone-android';

import BookingScheduleConfirmDialog from './schedule/bookingScheduleConfirmDialog';
import ErrorDialog from './errorDialogView';
import { isMobileOnlyAskableLive } from './utils/bookingConfig';
import { MobileAppRequiredCallout } from './mobileAppRequiredCallout';

// Styles
import './styles/booking.scss';


class BookingInReviewView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invitedSession: props.invitedSession,
    };

    this.setBookingState = this.setBookingState.bind(this);
  }

  componentWillMount() {
    this.setBookingState(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.setBookingState(newProps);
  }

  setBookingState(props) {
    if (JSON.stringify(props.invitedSession) !== JSON.stringify(this.state.invitedSession)) {
      this.setState({ invitedSession: props.invitedSession });
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingOverlay />;
    }

    const summaryListItems = [];

    if (this.props.sessionDuration) {
      summaryListItems.push({
        key: 'sessionLength',
        primaryText: `${this.props.sessionDuration} session`,
        leftIcon: <AccessTimeIcon />,
      });
    }

    const booking = dot.object(_.merge({}, dot.dot(this.props.booking || {}), dot.dot(_.get(this.props, 'fetchBookingByIdAdditional.bookingByID') || {})));

    const isMobileOnly = isMobileOnlyAskableLive(this.props.booking);
    switch (this.props.booking.type) {
      case 1:
        if (this.props.booking.config.session) {
          switch (this.props.booking.config.session.type) {
            case 1:
              summaryListItems.push({
                key: 'sessionType',
                primaryText: 'Casual, one on one chat',
                leftIcon: <PersonIcon />,
              });
              break;
            case 2:
              summaryListItems.push({
                key: 'sessionType-alt',
                primaryText: 'Small focus group',
                leftIcon: <GroupIcon />,
              });
              break;
            default:
          }
        }
        break;
      case 2:
        summaryListItems.push({
          key: 'sessionType',
          primaryText: `Remote / Video chat ${isMobileOnly ? ' (Mobile only)' : ''}`,
          leftIcon: isMobileOnly ? <PhoneIcon /> : <HeadsetIcon />,
        });
        break;
      default:
    }

    if (this.props.booking.config.incentive) {
      switch (this.props.booking.config.incentive.type) {
        case 1:
          summaryListItems.push({
            key: 'paymentMethod',
            primaryText: 'Paid via PayPal or E-Gift Card',
            leftIcon: <MoneyIcon />,
          });
          break;
        case 2:
          summaryListItems.push({
            key: 'paymentMethod-alt',
            primaryText: 'Paid via gift card / voucher',
            leftIcon: <GiftCardIcon />,
          });
          break;
        default:
      }
    }

    const clientInfo = {};
    switch (this.props.booking.config.session.type) {
      case 1:
        clientInfo.sessionType = '1 on 1 chat';
        break;
      case 2:
        clientInfo.sessionType = 'focus group';
        break;
      default:
        clientInfo.sessionType = null;
    }

    clientInfo.location = null;
    if (this.props.booking.config.location) {
      if (this.props.booking.config.location.city) {
        clientInfo.location = this.props.booking.config.location.city;
      } else if (this.props.booking.config.location.state) {
        clientInfo.location = this.props.booking.config.location.state;
      }
      if (this.props.booking.config.location.state && clientInfo.location > '' && this.props.booking.config.location.state !== clientInfo.location) {
        clientInfo.location += `, ${this.props.booking.config.location.state}`;
      }
    }

    if (_.get(booking, 'config.in_context.location_type')) {
      if (this.props.inContextLocation) {
        switch (booking.config.in_context.location_type) {
          case 1:
            clientInfo.location = `Your home in ${this.props.inContextLocation.city}`;
            break;
          case 2:
            clientInfo.location = `Your workplace/business in ${this.props.inContextLocation.city}`;
            break;
          default:
            clientInfo.location = `Your chosen location at ${this.props.inContextLocation.city}`;
        }
      } else {
        clientInfo.location = null;
      }
    }

    const sessionTime = this.state.invitedSession ? (
      <small>
        <br />
        On {moment(this.state.invitedSession.session.start).formatZoneFallback('dddd Do MMM, h:mm A')}
      </small>
    ) : null;

    let timeAndPlace = null;
    let bookingLocation = _.get(booking, 'config.location', {});
    if (booking.type === 1) {
      if (_.get(booking, 'config.in_context.location_type') && this.props.inContextLocation) {
        bookingLocation = Object.assign({}, this.props.inContextLocation);
      }
      const vagueAddress =
        bookingLocation &&
        (_.get(bookingLocation, 'city') === _.get(bookingLocation, 'name') ||
          _.get(bookingLocation, 'state') === _.get(bookingLocation, 'name') ||
          _.get(bookingLocation, 'postal_code') === _.get(bookingLocation, 'name'));
      if (bookingLocation.name && bookingLocation.street1 && stringSimilarity.compareTwoStrings(bookingLocation.name, bookingLocation.street1) > 0.7) {
        bookingLocation.name = undefined;
      }

      timeAndPlace =
        bookingLocation.city && bookingLocation.latitude && bookingLocation.longitude ? (
          <Fragment>
            <CardChild type="text">
              <div className="card-cols">
                <div className="textLeft card-col-dynamic">
                  <h3>{vagueAddress ? 'Location' : 'Address'}</h3>
                  <address className="address">
                    {_.uniqBy(
                      (vagueAddress ? ['city', 'state'] : ['name', 'level', 'street1', 'street2', 'city', 'state']).map(
                        line =>
                          (bookingLocation[line] && (
                            <span key={`address_${line}`} className={`line line-${line}`}>
                              {bookingLocation[line]}
                            </span>
                          )) ||
                          null,
                      ),
                      elem => elem && elem.props.children,
                    )}
                  </address>
                </div>
                <div className="textRight card-col-fixed mtopauto">
                  <a
                    href={`https://www.google.com/maps/dir//${encodeURIComponent(
                      (bookingLocation.street1 ? ['name', 'street1', 'street2', 'city', 'state'] : ['name', 'city', 'state'])
                        .map(line => bookingLocation[line] || null)
                        .join(' ')
                        .replace(/\s+/g, ' '),
                    )}/${bookingLocation.latitude && bookingLocation.longitude ? `@${bookingLocation.latitude},${bookingLocation.longitude}` : ''}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="card-link textRight"
                  >
                    Get directions
                  </a>
                </div>
              </div>
            </CardChild>
            <CardChild type="media">
              <div className="map-embed">
                <Map className="mapComponent" lat={bookingLocation.latitude} lng={bookingLocation.longitude}>
                  <Marker lat={bookingLocation.latitude} lng={bookingLocation.longitude} />
                </Map>
              </div>
            </CardChild>
          </Fragment>
        ) : null;

      if (_.get(booking, 'config.in_context.location_type')) {
        let locationHeader = 'The researcher will meet you at your chosen location.';
        switch (booking.config.in_context.location_type) {
          case 1:
            locationHeader = 'The researcher will meet you at your home.';
            break;
          case 2:
            locationHeader = 'The researcher will meet you at your business/workplace.';
            break;
          default:
        }
        timeAndPlace = (
          <CardChild type="text">
            <div className="card-cols">
              <div className="textLeft card-col-dynamic">
                <h3>{locationHeader}</h3>
                {/* <address className="address">
                        {
                            _.uniqBy(
                                (
                                    ['name', 'level', 'street1', 'street2', 'city', 'state']
                                )
                                .map(line => (bookingLocation[line] && <span key={`address_${line}`} className={`line line-${line}`}>{bookingLocation[line]}</span>) || null),
                                elem => elem && elem.props.children
                            )
                        }
                        </address> */}
              </div>
            </div>
          </CardChild>
        );
      }
    }

    let confirmCta = (
      <Button
        label="Confirm Invitation"
        labelColor="#fff"
        bgColor="#FF5266"
        type="button"
        disabled={_.get(this.props.booking, 'config.remote.askable_live') && !_.get(this.props.user, 'settings.remote.setup_check')}
        onClick={() => {
          if (this.props.confirmAttendanceAnonymous) {
            utils.window.location.href = `/login?loginDestination=/booking/${this.props.booking._id}`;
            return;
          }
          // ga.event({
          //   category: 'Booking',
          //   action: 'Click join session',
          //   label: this.props.booking._id,
          //   value: 0
          // });
          this.setState({ confirmDialogOpen: true });
        }}
        // className="widthAuto"
      />
    );

    if (_.get(this.props.booking, 'config.remote.askable_live') && !_.get(this.props.user, 'settings.remote.setup_check')) {
      const onDesktop = (
        <Button
          label="Start setup check"
          labelColor="#fff"
          bgColor="#FF5266"
          type="button"
          className="mtop20"
          onClick={() => {
            this.props.history.push(`/live/setup-check/in-progress/booking/${this.props.booking._id}`);
          }}
        />
      );

      const onMobile = (
        <Mutation
          mutation={gql`
            mutation sendMyselfGenericEmail($email: GenericEmailInput!) {
              sendMyselfGenericEmail(email: $email)
            }
          `}
        >
          {(sendEmail, { loading, called }) => (
            <Button
              label={called ? 'Link sent' : 'Email me the link'}
              labelColor="#fff"
              bgColor="#FF5266"
              type="button"
              className="mtop10"
              disabled={loading || called}
              onClick={() => {
                // console.log(sendEmail, booking_id);
                sendEmail({
                  variables: {
                    email: {
                      subject: 'Askable laptop setup link',
                      message_body:
                        'Here’s the link for you to complete the video chat setup check on your laptop.<br /><br >Make sure that you have <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a> installed on your laptop.',
                      button_text: 'Go to laptop setup check',
                      button_path: `/live/setup-check/booking/${this.props.booking._id}`,
                      button_link_auth: true,
                    },
                  },
                });
              }}
            />
          )}
        </Mutation>
      );

      confirmCta = (
        <Fragment>
          <h3 className="mtop60">Laptop setup check</h3>
          <p className="mbottom30">
            Since this session will be a video call, you must do a compulsory laptop setup check before you can accept your invite. It takes 1-2 mins, and
            you’ll only ever have to do it once.
          </p>
          <UserAgentTest validation={ua => _.get(ua, 'device.type') === 'desktop'}>
            {onDesktop}
            {onMobile}
            {onMobile}
          </UserAgentTest>
        </Fragment>
      );
    }

    return (
      <div className="bookingPage bookingOverview bookingRegistered waitlist available">
        {clientInfo.name || clientInfo.sessionType ? (
          <h1 className="pageTitle">
            You’ve been invited to attend:
            <br />
            <small>
              {clientInfo.name}
              {clientInfo.name && clientInfo.sessionType ? ' - ' : ''}
              {clientInfo.sessionType}
            </small>
            {clientInfo.location ? (
              <small>
                <br />
                {clientInfo.location}
              </small>
            ) : null}
            {sessionTime}
          </h1>
        ) : (
          <h1 className="pageTitle">
            You’ve been selected for this session:
            {clientInfo.location ? (
              <small>
                <br />
                {clientInfo.location}
              </small>
            ) : null}
            {sessionTime}
          </h1>
        )}
        {this.props.booking.config.information && this.props.booking.config.information.brief_summary ? (
          <div>
            <h3>Brief summary</h3>
            <ReactMarkdown className="md-block">{this.props.booking.config.information.brief_summary}</ReactMarkdown>
          </div>
        ) : null}
        <div className="waitlist-summary mtop40 mbottom40">
          <Card>
            <CardChild type="text">
              <span className="incentive">
                <strong>
                  {dot.pick('config.incentive.currency_symbol', this.props.booking) || ''}
                  {dot.pick('config.incentive.value', this.props.booking) || ''}
                </strong>{' '}
                incentive
              </span>
            </CardChild>
            <CardChild type="divider" className="mbottom-20" />
            <CardChild type="text" className="card-cols wrap">
              <List
                className="summary-list"
                itemDefaultProps={{
                  disabled: true,
                  className: 'list-item',
                }}
                items={summaryListItems}
              />
              {isMobileOnly ? <MobileAppRequiredCallout /> : null}
            </CardChild>
            {timeAndPlace && (
              <Fragment>
                <CardChild type="divider" />
                {timeAndPlace}
              </Fragment>
            )}
          </Card>
        </div>

        <h3>Can you make it?</h3>
        <p>
          If you would still like to attend this opportunity at{' '}
          <u>{moment(this.state.invitedSession.session.start).formatZoneFallback('h:mm A [on] dddd Do MMM')}</u>, please confirm below:
        </p>
        <div className="subtle-warning-box mleft-20 mright-20 mbottom20">
          <div className="mleft20 mright20 mtop10">
            <h3>Please note:</h3>
            <p>
              You are not currently confirmed to attend this session. Spots are limited, and can fill up fast - if this session is already full, you&rsquo;ll be
              placed on a wait list in case there are any cancellations.
            </p>
          </div>
        </div>
        {confirmCta}
        <p className="textCenter">
          <button
            // label="I can&rsquo;t make it"
            // labelColor={'#999'/* TODO - v1.0 (PPTS-15) */}
            // bgColor="transparent"
            className="link linkgrey mtop30 mbottom10"
            type="button"
            onClick={() => {
              if (this.props.confirmAttendanceAnonymous) {
                utils.window.location.href = `/login?loginDestination=/booking/${this.props.booking._id}`;
                return;
              }
              this.setState({ loading: true });
              this.props
                .declineInvite(this.props.submissionId, this.state.invitedSession._id)
                .then(() => {
                  this.props.participantSessionsByBooking.refetch();
                })
                .catch(error => {
                  console.error(error);
                });
            }}
          >
            I can’t make it to this session
          </button>
        </p>
        <BookingScheduleConfirmDialog
          booking={this.props.booking}
          open={!!this.state.confirmDialogOpen}
          onCancel={() => {
            this.setState({ confirmDialogOpen: false });
          }}
          onConfirm={() => {
            this.setState({ loading: true, confirmDialogOpen: false });
            this.props
              .participantRegisterBookingParticipant([this.state.invitedSession.session._id])
              .then(() => {
                this.props.participantSessionsByBooking.refetch();
              })
              .catch(error => {
                const errorMessage = _.get(error, 'graphQLErrors.0.message', null);
                if (errorMessage) {
                  this.setState({
                    loading: false,
                    confirmDialogOpen: false,
                    errorDialog: errorMessage,
                  });
                } else {
                  console.error(error);
                }
              });
          }}
        />
        <ErrorDialog
          open={!!this.state.errorDialog}
          error={this.state.errorDialog}
          onCancel={() => {
            this.setState({ errorDialog: null });
          }}
        />
      </div>
    );
  }
}

const BookingDetailsContainer = graphql(fetchBookingByIdAdditional, {
  name: 'fetchBookingByIdAdditional',
  options: props => ({
    variables: { id: props.booking._id },
  }),
});
const RegisterBookingParticipantContainer = graphql(participantRegisterBookingParticipant, {
  props: ({ mutate, ownProps }) => ({
    participantRegisterBookingParticipant: session_ids =>
      mutate({
        variables: { booking_id: ownProps.booking._id, session_ids },
      }),
  }),
});
const declineInviteContainer = graphql(declineInvite, {
  props: ({ mutate }) => ({
    declineInvite: (submission_id, session_id) =>
      mutate({
        variables: { submission_id, session_id },
      }),
  }),
});

export default compose(BookingDetailsContainer, RegisterBookingParticipantContainer, declineInviteContainer)(withRouter(BookingInReviewView));
