const { gapi } = window; // eslint-disable-line
const google_client_id = '86041926868-d726jum3k0dnoj99408a2atf5d697464.apps.googleusercontent.com';

const oauth = {
  google: {
    init(onSuccessSignIn) {
        gapi.load('auth2', () => {
            // Retrieve the singleton for the GoogleAuth library and set up the client.
            const params = {
                client_id: google_client_id,
                cookie_policy: 'single_host_origin',
            };
            gapi.load('auth2', () => {
                if (!gapi.auth2.getAuthInstance()) {
                    gapi.auth2.init(params)
                        .then((res) => {
                            if (res.isSignedIn.get() && onSuccessSignIn) {
                                onSuccessSignIn(this.parseResponse(res.currentUser.get()));
                            }
                        });
                }
            });
        });
    },
    parseResponse(res) {
        const basicProfile = res.getBasicProfile();
        const authResponse = res.getAuthResponse();
        const newObj = {};
        newObj.googleId = basicProfile.getId();
        newObj.tokenObj = authResponse;
        newObj.tokenId = authResponse.id_token;
        newObj.accessToken = authResponse.access_token;
        newObj.profile = {
            googleId: basicProfile.getId(),
            imageUrl: basicProfile.getImageUrl(),
            email: basicProfile.getEmail(),
            name: basicProfile.getName(),
            firstname: basicProfile.getGivenName(),
            lastname: basicProfile.getFamilyName()
        };
        return newObj;
    },
    signIn(onSuccessSignIn) {
        const auth2 = gapi.auth2.getAuthInstance();
        auth2.signIn({})
            .then((res) => {
                onSuccessSignIn(this.parseResponse(res));
            }, err => onSuccessSignIn(err));
    },
    signOut(onSuccessSignOut) {
        const auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(() => onSuccessSignOut());
    }
  }
};

export { oauth };
