import React, { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';

import IconOffset from '../IconOffset/view';

class Button extends Component {
    render() {
        const defaultButtonProps = {
            borderRadius: 28,
            height: 55,
            color: this.props.labelColor ? this.props.labelColor : 'inherit'
        };
        const defaultLabelProps = {
            borderRadius: 30,
            fontSize: 16,
            fontFamily: 'inherit',
            fontWeight: 700,
            letterSpacing: '0.07em',
            padding: '28px'
        };
        if (this.props.smallButton) {
            defaultButtonProps.height = 38;
            defaultButtonProps.borderRadius = 19;
            defaultLabelProps.borderRadius = 19;
            defaultLabelProps.fontSize = 12;
        }
        if (this.props.borderColor && this.props.borderWidth) {
          defaultButtonProps.boxShadow = `0 0 0 ${this.props.borderWidth} ${this.props.borderColor} inset`;
        }
        const defaultButtonStyle = {
            ...defaultButtonProps,
            ...this.props.buttonStyle
        };

        return (
            <RaisedButton
                label={this.props.label}
                labelPosition={this.props.labelPosition ? this.props.labelPosition : 'after'}
                labelColor={this.props.labelColor ? this.props.labelColor : ''}
                className={`btn_primary ${this.props.className ? this.props.className : ''}`}
                icon={this.props.icon ? <IconOffset>{this.props.icon}</IconOffset> : null}
                onClick={this.props.onClick}
                onMouseDown={this.props.onMouseDown}
                href={this.props.href ? this.props.href : ''}
                backgroundColor={this.props.bgColor ? this.props.bgColor : ''}
                disabled={this.props.disabled || false}
                buttonStyle={defaultButtonStyle}
                overlayStyle={defaultButtonProps}
                style={defaultButtonProps}
                labelStyle={defaultLabelProps}
                rippleStyle={defaultButtonProps}
                fontFamily="inherit"
                type={this.props.type || null}
                ref={this.props.refName ? (button) => {
                    window[this.props.refName] = button;
                } : undefined}
            />
        );
    }
}

export default Button;
