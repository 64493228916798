import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';

import { utils } from 'lib/utils';
import { SUBMISSION_AGREEMENT_STATUS } from 'lib/data/constants'
import findBookingSubmission from 'data/queries/booking/findBookingSubmission';
import { bookingSubmissionFieldsFragment } from 'data/fragments/bookingSubmissionFields';

import { Dialog, Button, LoadingOverlay } from 'components/common';

// TODO: https://askable.atlassian.net/browse/PRO-994

const ApplyAgreementDialog = ({ booking, submission }) => {
    const [open, setOpen] = useState(false);

    const agreementStatus = _.get(submission, 'agreement.status');

    useEffect(() => {
        setOpen(agreementStatus !== SUBMISSION_AGREEMENT_STATUS.COMPLETED_IN_PROVIDER);
    }, [agreementStatus]);

    return (
        <Dialog open={open} modal customActions={[]}>
            <Query
                query={findBookingSubmission}
                variables={{ userId: utils.getUserId(), bookingId: _.get(booking, '_id') }}
                // skip={!!_.get(submission, 'agreement.link')}
            >
            {submissionQuery => (
                <Mutation
                    mutation={gql`
                        mutation sendParticipantAgreement ($submission_id: ID!) {
                            sendParticipantAgreement(submission_id: $submission_id) {
                                ...bookingSubmissionFields
                            }
                        }
                        ${bookingSubmissionFieldsFragment}
                    `}
                >
                {sendParticipantAgreement => (
                    <DialogContents
                        refetchSubmissionQuery={submissionQuery.refetch}
                        booking={booking}
                        submission={submission}
                        sendParticipantAgreement={sendParticipantAgreement}
                    />
                )}
                </Mutation>
            )}
            </Query>
        </Dialog>
    );
};

const DialogContents = ({
    booking,
    submission,
    sendParticipantAgreement,
    refetchSubmissionQuery,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const agreementLink = _.get(submission, 'agreement.link');
    const agreementStatus = _.get(submission, 'agreement.status');

    let refreshSubmissionQueryInterval;

    const refreshLink = (
        agreementStatus === SUBMISSION_AGREEMENT_STATUS.VIEWED_IN_PROVIDER
        || agreementStatus === SUBMISSION_AGREEMENT_STATUS.COMPLETED_IN_PROVIDER
    );

    useEffect(() => {
        if (!agreementLink) {
            setIsLoading(true);
            sendParticipantAgreement({
                variables: { submission_id: _.get(submission, '_id') }
            });
            if (refetchSubmissionQuery) {
                refreshSubmissionQueryInterval = setInterval(refetchSubmissionQuery, 500);
            }
        } else if (isLoading) {
            setIsLoading(false);
            if (refreshSubmissionQueryInterval) {
                clearTimeout(refreshSubmissionQueryInterval);
            }
        }
    }, [agreementLink]);

    return (
        <div className="bookingPage" style={{ position: 'relative', color: '#444444' }}>
            {isLoading && <LoadingOverlay />}
            <h3>Sign agreement to proceed</h3>
            <p>The facilitator has provided an agreement for you to sign before you can proceed with the opportunity.</p>
            <p>Please read it carefully and sign if you’re satisfied with the terms.</p>
            <div className="subtle-warning-box mtop20 mbottom20">
                <div className="mleft20 mright20 mtop10">
                    <h3>Please note:</h3>
                    <p>You have been invited, but there are still limited spots which may fill up before you have the chance to complete the task.</p>
                    <p>You won’t be paid your {_.get(booking, 'config.incentive.currency_symbol', '')}{_.get(booking, 'config.incentive.value', '')} incentive unless you successfully finish the task.</p>
                </div>
            </div>
            <Button
                label="View Agreement"
                labelColor="#fff"
                bgColor="#FF5266"
                type="button"
                disabled={!agreementLink}
                onClick={() => { window.open(agreementLink, '_self'); }}
                // className="widthAuto"
            />
            {refreshLink && (
                <p
                    className="mtop20 textCenter"
                >
                    <a
                        href="#"
                        className="link"
                        onClick={() => {
                            setIsLoading(true);
                            window.location.reload();
                        }}
                    >
                        I have already signed
                    </a>
                </p>
            )}
        </div>
    );
};

export default ApplyAgreementDialog;
