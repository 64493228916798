import { Component } from 'react';
import _ from 'lodash';

import countryCodeData from 'lib/data/phoneCountryCodes';

import './styles/CountryCodeSelectStyles.scss';

export default class CountryCodeSelect extends Component {
    constructor(props) {
        super(props);

        this.state = this.getStateFromProps(props);

        this.updateStateFromProps = this.updateStateFromProps.bind(this);
    }

    componentWillMount() {
        this.updateStateFromProps(this.props);
    }

    componentWillReceiveProps(newProps) {
        this.updateStateFromProps(newProps);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // if (nextProps.search !== this.props.search && nextProps.search.replace(/\s/g, '') === '') {
        //     clearTimeout(this.debounceTimout);
        //     return true;
        // }
        if (nextState.debounce === this.state.debounce) {
            this.debounceTimout = setTimeout(() => {
                this.setState({ debounce: new Date().valueOf() });
            }, 250);
        }
        if (nextState.debounce !== this.state.debounce) {
            clearTimeout(this.debounceTimout);
            return true;
        }
        return false;
    }

    getStateFromProps(props) {
        return {
            searchPattern: props.search ? new RegExp(props.search, 'i') : null,
            selected: props.selected
        };
    }

    updateStateFromProps(props) {
        if (props.selected !== this.state.selected || props.search !== this.state.search) {
            this.setState(this.getStateFromProps(props));
        }
    }

    render() {
        return _.filter(countryCodeData, (country) => {
            if (this.state.selected && this.state.selected === country.region) {
                return false;
            }
            if (!this.state.searchPattern) {
                return true;
            }
            const searchValue = `${country.name} ${country.region} ${country.country_code}`.replace(/[^a-zA-Z0-9\s]/, '');
            return searchValue.match(this.state.searchPattern);
        }).map(this.props.render);
    }
}
