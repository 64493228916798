import React, { Component } from 'react';

// Styles
import './styles/SneakyConfirmation.scss';

class SneakyConfirmation extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            open: null
        });

        this.timeoutLength = props.timeout || 2000;
        this.openTimeout = null;
        this.savePropsToState = this.savePropsToState.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    componentWillMount() {
        this.savePropsToState(this.props);
    }
    componentWillReceiveProps(newProps) {
        this.savePropsToState(newProps);
    }

    componentWillUnmount() {
        if (this.openTimeout) {
            clearTimeout(this.openTimeout);
            this.openTimeout = null;
        }
    }

    onClose() {
        this.openTimeout = null;
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    setOpen(open) {
        this.setState({ open });
        if (open === false) {
            this.onClose();
        }
    }

    savePropsToState(props) {
        if (props.open && !this.openTimeout && (props.open !== this.state.open)) {
            setTimeout(this.setOpen, 10, true);
            this.openTimeout = setTimeout(this.setOpen, this.timeoutLength, false);
        }
    }

    render() {
        const classNames = ['sneakyConfirmationThing'];
        classNames.push(this.state.open ? 'open' : 'closed');
        if (this.props.className) {
            classNames.push(this.props.className);
        }
        return (
            <div className={classNames.join(' ')} style={this.props.style}>
                <span className="innerThing" style={this.props.innerStyle}>{this.props.children}</span>
            </div>
        );
    }
}

export default SneakyConfirmation;
