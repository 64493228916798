import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { SplitFactory } from '@splitsoftware/splitio-react';

const sdkConfig = {
  core: {
    authorizationKey: import.meta.env.VITE_SPLIT_API_KEY,
    // key represents your internal user id, or the account id that
    // the user belongs to.
    // This could also be a cookie you generate for anonymous users.
    key: 'anonymous',
  },
  ...(import.meta.env.VITE_ENVIRONMENT === 'production'
    ? {
        impressionListener: {
          logImpression: ({ impression }) => {
            datadogRum.addFeatureFlagEvaluation(impression.feature, impression.treatment);
          },
        },
      }
    : {}),
};

export function SplitProvider({ children }) {
  return (
    <SplitFactory config={sdkConfig}>
      {({ isReady }) => {
        if (!isReady) {
          return null;
        }

        return children;
      }}
    </SplitFactory>
  );
}
