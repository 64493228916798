import React, { Component } from 'react';
import { Dialog } from 'components/common';
import _ from 'lodash';

import OccupationSelect from '../userGetDetails/pages/occupationSelectView';
import EmpoymentTypeSelect from '../userGetDetails/pages/employmentTypeSelectView';


class EmploymentDetailsDialog extends Component {
    constructor() {
        super();
        this.state = {
            save: {},
        };
    }
    render() {
        let contents = null;
        switch (this.props.type) {
            case 'OccupationSelect':
                contents = (
                    <div>
                        <h2>What is your occupation?</h2>
                        <OccupationSelect
                            onChangeHandler={(details) => {
                                const save = {};
                                if (_.get(details, '_industry_id')) {
                                    _.set(save, 'meta.work._industry_id', details._industry_id);
                                }
                                if (_.get(details, '_subindustry_id')) {
                                    _.set(save, 'meta.work._subindustry_id', details._subindustry_id);
                                }
                                if (_.get(details, 'job_title')) {
                                    _.set(save, 'meta.work.job_title', details.job_title);
                                }
                                this.setState({
                                    save,
                                    disabled: !(details._industry_id && details._subindustry_id && details.job_title)
                                });
                            }}
                        />
                    </div>
                );
            break;
            case 'EmpoymentTypeSelect':
                contents = (
                    <div>
                        <h2>Are you self-employed or a business owner?</h2>
                        <EmpoymentTypeSelect
                            onChangeHandler={(details) => {
                                const save = {};
                                if (_.get(details, 'employment_type')) {
                                    _.set(save, 'meta.work.employment_type', details.employment_type);
                                }
                                if (_.get(details, 'business.size')) {
                                    _.set(save, 'meta.work.business.size', details.business.size);
                                }
                                if (_.get(details, 'business.turnover')) {
                                    _.set(save, 'meta.work.business.turnover', details.business.turnover);
                                }
                                this.setState({
                                    save,
                                    disabled: !details.employment_type
                                });
                            }}
                        />
                    </div>
                );
            break;
            default:
        }

        // on submit: this.props.onChange(user);

        return (
            <Dialog
                open={!!this.props.type}
                disabled={!!this.state.disabled}
                onCancel={() => {
                    this.props.onChange(null);
                    this.setState({ save: {}, disabled: true });
                }}
                onConfirm={() => {
                    this.props.onChange(this.state.save);
                    this.setState({ save: {}, disabled: true });
                }}
            >
            {contents}
            </Dialog>
        );
    }
}

export default EmploymentDetailsDialog;
