import React, { Component, Fragment } from 'react';
import TwilioVideo from 'twilio-video';
import _ from 'lodash';
import decode from 'jwt-decode';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import { localStorage } from 'lib/storage';

import { Button } from 'components/common';

class SetupCheck extends Component {
    constructor() {
        super();
        this.state = { progress: 0, tests: [] };

        this.createRoom = this.createRoom.bind(this);
        this.ticker = this.ticker.bind(this);
        this.saveNetworkQuality = this.saveNetworkQuality.bind(this);
        this.finishTest = this.finishTest.bind(this);

        this.testLength = 15000;
    }

    componentWillUnmount() {
        if (this.tickerInterval) clearInterval(this.tickerInterval);
    }

    async createRoom() {
        this.setState({ connections: {} });
        setTimeout(() => { this.setState(state => ({ progress: Math.max(state.progress, 0.5) })); }, 1000);
        // this.tickerInterval = setInterval(() => {
        //     if (this.state.progress > 0.5 && this.tickerInterval) {
        //         clearInterval(this.tickerInterval)
        //     }
        //     this.setState(state => ({ progress: state.progress + (Math.random() * 0.03) }));
        // }, 800);

        const setupResult = await this.props.roomSetupMutation({ booking_id: this.props._booking_id, session_id: this.props._session_id })
            .catch((e) => {
                console.error('Error setting room authentication', e);
                // this.pushError('room_auth', e);
                return null;
            });

        if (!setupResult) {
            this.props.errorMessage({ title: 'Authentication failure', body: <p>Failed to connect to the Internet test. Please reload the page and try again.</p> });
            return;
        }

        const connections = { local: null, remote: null };
        const { tracks } = this.props;

        for (const connection of _.get(setupResult, 'data.askableLiveNetworkTestRoomSetup', [])) {
            connection.tokenPayload = decode(connection.token);
            const identity = _.get(connection, 'tokenPayload.grants.identity', '');
            const connectionType = identity && identity.replace(/^.+_/, '');

            connection.roomInstance = await TwilioVideo.connect(connection.token, {
                name: connection.room.uniqueName,
                region: 'gll', // Global low latency,
                networkQuality: connectionType === 'local',
                tracks: connectionType === 'local' ? [tracks.camera, tracks.mic, tracks.desktop] : [tracks.camera, tracks.mic],
            }).catch((e) => {
                console.error('Error connecting to room', e);
                this.props.errorMessage({ title: 'Connection error', body: <p>Failed to start the Internet test. Please reload the page and try again.</p> });
                return;
            })

            connections[connectionType] = connection;
        }

        // console.log('local.roomInstance.localParticipant', _.get(connections, 'local.roomInstance.localParticipant'));

        if (_.get(connections, 'local.roomInstance.localParticipant')) {
            connections.local.roomInstance.localParticipant.setNetworkQualityConfiguration({
                local: 1
            });
            connections.local.roomInstance.localParticipant.on('networkQualityLevelChanged', this.saveNetworkQuality);
        }

        // const room = await TwilioVideo.connect(token, {
        //     name: roomName,
        //     region: 'gll', // Global low latency
        //     tracks: this.props.tracks,
        //     networkQuality: true
        // });
        // room.localParticipant.on('networkQualityLevelChanged', this.saveNetworkQuality);
        // this.setState({ connections, tickerStart: Date.now() - 1000, tickerEnd: Date.now() + 15000 });
        this.setState({ connections });
    }

    ticker() {
        if (this.state.progress >= 1 && this.tickerInterval) {
            clearInterval(this.tickerInterval);
            setTimeout(this.finishTest, 500);
            return;
        }
        this.setState((state) => {
            state.progress = (Date.now() - state.tickerStart) / (state.tickerEnd - state.tickerStart);
            // state.progress %= 1;
            // state.progress = Math.max(0.1, state.progress);
            // console.log(state);
            if (state.localNetworkQuality !== undefined) {
                state.tests.push({ score: state.localNetworkQuality, time: Date.now() });
                // console.log('networkQualityLevel', state.localNetworkQuality, _.meanBy(state.tests, 'score'), state.tests.length);
            }
            return state;
        });
    }

    saveNetworkQuality(score) {
        console.log('saveNetworkQuality', score, _.meanBy(this.state.tests, 'score'), Date.now());
        this.setState((state) => {
            state.localNetworkQuality = score;
            if (!state.tickerEnd) {
                state.tickerStart = Date.now() - (this.testLength * (state.progress / 0.5));
                state.tickerEnd = Date.now() + this.testLength;

                if (this.tickerInterval) clearInterval(this.tickerInterval);
                this.tickerInterval = setInterval(this.ticker, 500);
            }
            return state;
        });
    }

    finishTest() {
        if (_.get(this.state, 'connections.local.roomInstance.disconnect')) {
            this.state.connections.local.roomInstance.disconnect();
            this.state.connections.local.roomInstance.localParticipant.tracks.forEach((publication) => {
                if (publication.unpublish) publication.unpublish();
            });
        }
        if (_.get(this.state, 'connections.remote.roomInstance.disconnect')) {
            this.state.connections.remote.roomInstance.disconnect();
            this.state.connections.remote.roomInstance.localParticipant.tracks.forEach((publication) => {
                if (publication.unpublish) publication.unpublish();
            });
        }
        this.props.disconnectTracks();
        this.setState((state) => {
            const firstTest = _.minBy(state.tests, 'time').time;
            const lastTest = _.maxBy(state.tests, 'time').time;

            // take the average of the last 5 seconds
            state.finalScore = _.chain(state.tests).filter(test => test.time >= (lastTest - 5000)).meanBy('score').value();

            state.tests = _.chain(state.tests).map(test => ({ ...test, time: test.time - firstTest })).sortBy('time').value();

            console.log(state);
            return state;
        });
    }

    renderScoreResult(score) {
        const successAction = (
            <Button
                label="Next"
                labelColor="#fff"
                bgColor="#FF5266"
                type="button"
                className="mtop60 medium"
                onClick={() => { this.props.onContinue(this.state.finalScore); }}
            />
        );

        if (score >= 4) {
            return (
                <Fragment>
                    <p className="score-tag success">Looks good!</p>
                    {successAction}
                </Fragment>
            );
        }

        if (score >= 3) {
            return (
                <Fragment>
                    <p className="score-tag">Average connection</p>
                    {successAction}
                </Fragment>
            );
        }

        const attempts = parseInt(localStorage.get('network-test-attempts', 'session') || 0, '10');

        const failAction = (
            <Button
                label="Try again"
                labelColor="#fff"
                bgColor="#FF5266"
                type="button"
                className="mtop60 medium"
                onClick={() => {
                    localStorage.save('network-test-attempts', attempts + 1, 'session');
                    window.location.reload();
                }}
            />
        );
        const failFinalMessage = (
            <div className="connection-fail">
                <p><strong>Sorry</strong></p>
                <p>It looks like your internet connection is not suitable for this opportunity.</p>
            </div>
        );

        const maxAttempts = 3;

        return (
            <Fragment>
                <p className="score-tag warning">Poor connection</p>
                {(attempts >= maxAttempts - 1) ? failFinalMessage : failAction}
            </Fragment>
        );
    }

    render() {
        if (this.state.connections === undefined) {
            return (
                <div className="centre-message narrow">
                    <h2>Internet quality test</h2>
                    <p>To make sure there will be no hiccups on the day, we’re also going to do a quick net speed test. Click the button below to begin.</p>
                    <Button
                        label="Next"
                        labelColor="#fff"
                        bgColor="#FF5266"
                        type="button"
                        className="mtop20 medium"
                        onClick={this.createRoom}
                    />
                </div>
            );
        }

        if (this.state.finalScore) {
            return (
                <div className="centre-message textCenter network-test">
                    <h2>Internet quality test result</h2>
                    <p className="final-score">{this.state.finalScore.toLocaleString(undefined, { maximumFractionDigits: 1 })} / 5</p>
                    {this.renderScoreResult(this.state.finalScore)}
                </div>
            );
        }

        const progressBarClasses = ['progress'];
        if (this.state.localNetworkQuality === undefined) {
            progressBarClasses.push('warming-up');
        }
        if (this.state.progress >= 1) {
            progressBarClasses.push('complete');
        }

        return (
            <div className="centre-message network-test">
                <h2>{this.state.localNetworkQuality ? 'Testing your internet...' : 'Connecting...'}</h2>
                <div className={progressBarClasses.join(' ')}><div className="bar" style={{ width: `${(this.state.progress * 100).toFixed(2)}%` }} /></div>
            </div>
        );
    }
}

const askableLiveNetworkTestRoomSetup = graphql(gql`
    mutation askableLiveNetworkTestRoomSetup {
        askableLiveNetworkTestRoomSetup {
            token
            room { sid uniqueName status }
        }
    }`, {
    props: ({ mutate }) => ({
        roomSetupMutation: mutate,
    }),
});


export default compose(askableLiveNetworkTestRoomSetup)(SetupCheck);
