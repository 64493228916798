import React from 'react';
import Toggle from 'material-ui/Toggle';

export default function (props) {
    const otherProps = {};
    if (props.controlled === false) {
        otherProps.defaultToggled = !!props.value;
    } else {
        otherProps.toggled = !!props.value;
    }
    otherProps.onToggle = (event, isInputChecked) => {
        props.onToggle(isInputChecked);
    };

    const trackBg = [
        '#a8a8a8', '#FF5065'
    ];
    const style = {
        root: {},
        icon: {
            padding: 0,
            marginTop: '1px'
        },
        thumb: {
            backgroundColor: '#ffffff',
            height: '20px',
            margin: '-1px 0 0'
        },
        track: {
            backgroundColor: trackBg[0],
            boxShadow: `0 0 0 1px ${trackBg[0]}`,
            height: '20px',
            marginTop: '0'
        }
    };
    // style.thumbSwitched = {
    //     ...style.thumb,
    //     backgroundColor: '#ffffff'
    // };
    style.thumbSwitched = style.thumb;
    style.trackSwitched = {
        ...style.track,
        backgroundColor: trackBg[1],
        boxShadow: style.track.boxShadow.replace(trackBg[0], trackBg[1])
    };
    if (props.disabled) {
        style.icon.opacity = 0.5;
        style.root.cursor = 'not-allowed';
        otherProps.onToggle = undefined;
    }

    return (
        <Toggle
            label={props.label}
            labelPosition={props.labelPosition || 'left'}
            style={style.root}
            iconStyle={style.icon}
            thumbStyle={style.thumb}
            thumbSwitchedStyle={style.thumbSwitched}
            trackStyle={style.track}
            trackSwitchedStyle={style.trackSwitched}
            {...otherProps}
        />
    );
}
