import React from 'react';
import { Callout, CalloutBody, CalloutTitle } from '../common/Callout/view';

export const MobileAppPreInviteCallout = ({ className }) => {
  return (
    <Callout className={`${className} mtop10`}>
      <CalloutTitle>Mobile app required</CalloutTitle>
      <CalloutBody>If you're invited to this study, you'll need to download our mobile app to participate.</CalloutBody>
    </Callout>
  );
};
