import React from 'react';
import _ from 'lodash';
import gql from 'graphql-tag';

import { Query } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import { utils } from 'lib/utils';

export default ({ _booking_id }) => (
    <Query
        variables={{
            _booking_id,
            _user_id: utils.getUserId()
        }}
        query={gql`query liveBookingGetSession ($_booking_id: ID, $_user_id: ID) {
            participantSessionsByBooking(
              _booking_id: $_booking_id,
              _user_id: $_user_id,
              status: 1,
              cancel: 0
            ) {
              _id _session_id
            }
          }`
        }
    >
    {({ data, loading }) => {
        if (loading) return null;
        if (_.get(data, 'participantSessionsByBooking.0._session_id')) {
            return <Redirect to={`/live/${_booking_id}/${_.get(data, 'participantSessionsByBooking.0._session_id')}`} />;
        }
        return <Redirect to={{ pathname: '/live/error/booking-session-redirect', search: `?booking=${_booking_id}` }} />;
    }}
    </Query>
);
