import gql from 'graphql-tag';

export const bookingSubmissionFieldsFragment = gql`
    fragment bookingSubmissionFields on Submission {
        _id
        eligibility
        _user_id
        _booking_id
        data {
            _answer_id
            _question_id
        }
        external_survey_completed
        in_context {
            location {
                name
                level
                street1
                street2
                city
                state
                postal_code
                country
                latitude
                longitude
            }
        }
        agreement { status link }
    }
`;
