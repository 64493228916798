import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import validator from 'validator';
import owasp from 'owasp-password-strength-test';
import _ from 'lodash';

import { Link, Redirect } from 'react-router-dom';
import { SvgIcon } from 'material-ui';

import { Input, Button, Wrapper, LoadingOverlay } from 'components/common';

import { oauth } from 'lib/oauth';
import { localStorage } from 'lib/storage';
import { reddit } from 'lib/analytics';

import loginUser from 'data/mutations/loginUser';
import latestAgreementByType from 'data/queries/latestAgreementByType';

import GoogleLogo from '../../icons/googleLogo';
import FacebookLogo from '../../icons/facebookLogo';
import { withKinde, loginWrapper } from '../../AuthWrapper'

// Styles
import './styles/loginStyles.scss';

// const { isAuthenticated } = utils;
owasp.config({
  // ...owasp.configs,
  minLength: 8,
  minOptionalTestsToPass: 2
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: _.get(props, 'location.state.email', ''),
            password: '',
            google_id: '',
            captcha_verified: false,
            inputErrors: {
                email: null,
                password: null
            },
        };

        oauth.google.init();
        this.oauthEnabled = {
          google: true,
          facebook: false
        };

        this.loginPageContents = this.loginPageContents.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.errorMessage = this.errorMessage.bind(this);

        this.loginSubmit = this.loginSubmit.bind(this);
        this.loginSuccess = this.loginSuccess.bind(this);

        this.handleGoogleAuth = this.handleGoogleAuth.bind(this);
        this.handleOAuthSignInSuccess = this.handleOAuthSignInSuccess.bind(this);
    }

    componentDidMount() {
        if(!this.props.isAuthenticated() && !this.props.isLoading) {
            this.props.login({ orgCode: import.meta.env.KINDE_PARTICIPANT_ORG_CODE })
        }
    }

    componentWillReceiveProps(newProps) {
      if (_.get(newProps, 'location.state.error') !== this.state.error) {
          this.setState({ error: _.get(newProps, 'location.state.error', null) });
      }
    }

    loginSubmit() {
        this.setState({
            loading: true
        });

        // If there's no password it should authenticate with Google ID
        // if (this.state.user.password) {
        this.props.loginUser(this.state.email, this.state.password, this.state.googleToken)
            .then(({ data }) => {
                if (_.get(data, 'login.type.participant')) {
                    this.loginSuccess(data.login);
                } else {
                    this.setState({
                        loading: false,
                        error: { type: 'client-user' }
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    error,
                    loading: false
                });
            });
    }

    handleGoogleAuth(e) {
        if (e) e.preventDefault();
        this.setState({ loading: true });
        oauth.google.signIn(this.handleOAuthSignInSuccess);
    }

    handleOAuthSignInSuccess(res) {
      if (res.error) {
          switch (res.error) {
              default:
                this.setState({ loading: false });
          }
          return false;
      }
      this.setState((state) => {
          _.set(state, 'email', res.profile.email);
          _.set(state, 'google_id', res.googleId);
          _.set(state, 'registerUpdate.picture', res.profile.imageUrl);
          _.set(state, 'registerUpdate.meta.identity.firstname', res.profile.firstname);
          _.set(state, 'registerUpdate.meta.identity.lastname', res.profile.lastname);
          _.set(state, 'registerUpdate.meta.social.google.id', res.googleId);
          return state;
      });
      this.props.loginUser(res.profile.email, null, res.tokenId)
        .then(({ data }) => {
            if (_.get(data, 'login.type.participant')) {
                this.loginSuccess(data.login);
            } else {
                this.setState({
                    loading: false,
                    error: { type: 'client-user' }
                });
            }
        })
        .catch(e => console.error(e));
    }

    async loginSuccess(user) {
        localStorage.save('connectedParticipant', user._id);
        localStorage.save('participant_access_token', user.access_token);
        localStorage.save('participant_details', JSON.stringify({
            email: user.email,
            name: {
                firstname: _.get(user, 'meta.identity.firstname'),
          lastname: _.get(user, 'meta.identity.lastname'),
        },
      }),
    );

        localStorage.delete('signup_settings');

        // if (this.state.registerUpdate) {
        //     await this.props.updateMe(this.state.registerUpdate)
        //         .catch((error) => { console.error(error); });
        // }

        // await mixpanel.track({ event: 'Login', _user_id: user._id });
        // await mixpanel.profile(user._id, { $last_login: new Date() });
        await reddit.track('Lead', 1200);

        // localStorage.delete('referred_by');

        const redirect = localStorage.get('tagetPath') || '/';
        localStorage.delete('tagetPath');
        window.location.href = redirect;
    }

    validateEmail(input, submit = false) {
      const valid = validator.isEmail(input);
      let message = null;
      if (input === '') {
        message = 'Email address is required';
      } else if (!valid) {
        message = 'The email address is invalid';
      }

      if (message !== this.state.inputErrors.email && (
          !message || (
              message && (this.state.inputErrors.email || submit)
          )
      )) {
          this.setState((state) => { state.inputErrors.email = message; return state; });
      }

      return valid;
    }

    validatePassword(input, submit = false) {
      const validation = owasp.test(input);
      const messages = [];
      let valid = false;
      if (validation.strong) {
        valid = true;
      } else if (this.state.password === '') {
        messages.push('Password is required');
      } else if (validation.requiredTestErrors.length > 0) {
        messages.push(...validation.requiredTestErrors);
      } else if (validation.optionalTestErrors.length > 0) {
        const errors = validation.optionalTestErrors.map(string => string.replace(/^.*at\sleast\sone\s|\.$/gi, ''));
        messages.push(`The password is too weak - try adding at lease one ${errors.length === 1 ? errors[0] : `${errors.slice(0, -1).join(', ')} or ${errors.slice(-1)}`}`);
      }

      const message = messages.length > 0 ? messages.join(' ') : null;

      if (message !== this.state.inputErrors.password && (
          !message || (
              message && (this.state.inputErrors.password || submit)
          )
      )) {
          this.setState((state) => { state.inputErrors.password = message; return state; });
      }

      return valid;
    }

    errorMessage() {
        if (!this.state.error) {
            return null;
        }
        const message = _.get(this.state.error, 'graphQLErrors.0.message', this.state.error.message);
        const type = _.get(this.state.error, 'type', null);

        switch (type) {
            case 'client-user':
                return (
                    <div className="errorMessage _errorMessage">
                        <div className="error">
                            &quot;{this.state.email}&quot; is registered as a researcher - please head to the <a href="https://app.askable.com/login" className="link">client login page</a>
                        </div>
                    </div>
                );
            default: {
                let extraOptions = null;
                switch (this.props.location.pathname) {
                    case '/login':
                        extraOptions = (
                            <span>
                                <br />
                                Don&apos;t have an account yet? <Link to={{ pathname: '/signup', state: { error: null } }} className="link font--red">create one</Link>
                            </span>
                        );
                    break;
                    case '/signup/email':
                        extraOptions = (
                            <span>
                                <br />
                                Already have an Askable account? <Link to={{ pathname: '/login', state: { error: null } }} className="link font--red">Log in</Link>
                            </span>
                        );
                    break;
                    default:
                        extraOptions = null;
                }
                return (
                    <div className="errorMessage _errorMessage">
                        <div className="error">
                            {message}
                            {extraOptions}
                        </div>
                    </div>
                );
            }
        }
    }

    loginPageContents() {
      const latestAgreementLink = _.get(this.props.latestAgreementByType, 'latestAgreementByType.url') ? (
        <a href={this.props.latestAgreementByType.latestAgreementByType.url} target="_blank" rel="noopener noreferrer" className="link small">
            { this.props.latestAgreementByType.latestAgreementByType ? this.props.latestAgreementByType.latestAgreementByType.name : '\u00a0' }
        </a>
      ) : (
        <Link to="/latestAgreement" className="link small">
            { this.props.latestAgreementByType.latestAgreementByType ? this.props.latestAgreementByType.latestAgreementByType.name : '\u00a0' }
        </Link>
      );
      switch (this.props.match.path) {
        case '/signup/email':
          return (
            <form
                onKeyPress={(event) => {
                    if (event.key.match(/enter|return/i)) {
                        this.validateEmail(this.state.email, true);
                        this.validatePassword(this.state.password, true);
                    }
                }}
                onSubmit={(event) => {
                    event.preventDefault();
                    const validEmail = this.validateEmail(this.state.email, true);
                    const validPassword = this.validatePassword(this.state.password, true);
                    if (validEmail && validPassword) {
                        this.registerSubmit();
                        return true;
                    }
                    return false;
                }}
                className="textCenter"
            >
              <img src="/askable-logo.svg" alt="Askable" width="200" className="mbottom40" />
              <h1 className="mbottom40">Sign up</h1>
              {this.errorMessage()}
              <Input
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={this.state.email}
                  errorText={this.state.inputErrors.email}
                  className="textLeft __inputEmail"
                  required
                  onChange={(value) => {
                    this.setState({ email: value });
                    this.validateEmail(value);
                  }}
              />
              <Input
                  name="password"
                  placeholder="Password"
                  type="password"
                  value={this.state.password}
                  className="textLeft __inputPassword"
                  required
                  errorText={this.state.inputErrors.password}
                  onChange={(value) => {
                    this.setState({ password: value });
                    this.validatePassword(value);
                  }}
              />
              <Button
                  label="Sign up"
                  labelColor="#fff"
                  bgColor="#FF5266"
                  className="btnNext mtop40 mbottom20 __buttonSignupSubmit"
                  type="submit"
                  refName="login-form-submit-button"
                  disabled={!!(this.state.email === '' || this.state.password === '' || this.state.inputErrors.email || this.state.inputErrors.password) || !this.state.captcha_verified}
              />
              <p className="textCenter mtop40">
                Already have an Askable account? <Link to="/login" className="link">Log in</Link>
              </p>
              <p className="textCenter mtop20 fontSmall">
                {latestAgreementLink} | <a href="https://www.askable.com/legal?tab=privacy" target="_blank" rel="noopener noreferrer" className="link">Privacy Policy</a>
              </p>
            </form>
          );
        case '/signup':
          return (
            <div className="textCenter">
              <img src="/askable-logo.svg" alt="Askable" width="200" className="mbottom40" />
              <h1 className="mbottom40">Sign up to continue</h1>
              {this.errorMessage()}
              <Button
                  label="Sign up with email"
                  labelColor="#fff"
                  bgColor="#FF5266"
                  className="btnNext mbottom20 __buttonSignupEmail"
                  type="button"
                  onClick={() => { this.props.history.push('/signup/email'); }}
              />
              <p className="textCenter mbottom20">or sign up with</p>
              { this.oauthEnabled.facebook ? (
                <Button
                  label="Facebook"
                  labelColor="#ffffff"
                  bgColor="#336DB8"
                  borderColor="#336DB8"
                  icon={
                      <SvgIcon>
                        <FacebookLogo />
                      </SvgIcon>
                  }
                  className="btnNext mbottom20 __buttonSignupFacebook"
                />
              ) : null }
              { this.oauthEnabled.google ? (
                <Button
                    label="Google"
                    labelColor="#777777"
                    bgColor="#f9f9f9"
                    icon={
                        <SvgIcon>
                          <GoogleLogo />
                        </SvgIcon>
                    }
                    buttonStyle={{ boxShadow: '0 0 0 2px #777777 inset' }}
                    onClick={this.handleGoogleAuth}
                    className="btnNext mbottom20 __buttonSignupGoogle"
                />
              ) : null }
              <p className="textCenter mtop40">
                Already have an Askable account? <Link to="/login" className="link">Log in</Link>
              </p>
              <p className="textCenter mtop20 fontSmall">
                {latestAgreementLink} | <a href="https://www.askable.com/legal?tab=privacy" target="_blank" rel="noopener noreferrer" className="link">Privacy Policy</a>
              </p>
            </div>
          );
        default: {
            return (
              <div className="textCenter">
                <img src="/askable-logo.svg" alt="Askable" width="200" className="mbottom40" />
                <h1 className="mbottom20">Log in to continue</h1>
                {this.errorMessage()}
                <form onSubmit={(event) => { event.preventDefault(); this.loginSubmit(); }}>
                <Input
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={this.state.email}
                    className="textLeft __inputEmail"
                    onChange={(value) => {
                      this.setState({ email: value });
                    }}
                />
                <Input
                    name="password"
                    placeholder="Password"
                    type="password"
                    value={this.state.password}
                    className="mbottom20 textLeft __inputPassword"
                    onChange={(value) => {
                        this.setState({ password: value });
                    }}
                />
                <Button
                    label="Login"
                    labelColor="#fff"
                    bgColor="#FF5266"
                    className="btnNext mbottom20 __loginButton"
                    disabled={!(this.state.email && this.state.password)}
                    type="submit"
                />
                </form>
                <div className="buttonsContainer">
                    <p className="textCenter mbottom20">or continue with</p>
                    { this.oauthEnabled.facebook ? (
                      <Button
                          label="Facebook"
                          labelColor="#ffffff"
                          bgColor="#336DB8"
                          borderColor="#336DB8"
                          icon={
                              <SvgIcon>
                                <FacebookLogo />
                              </SvgIcon>
                          }
                          className="btnNext mbottom20"
                      />
                    ) : null }
                    { this.oauthEnabled.google ? (
                      <Button
                          label="Google"
                          labelColor="#777777"
                          bgColor="#f9f9f9"
                          icon={
                              <SvgIcon>
                                <GoogleLogo />
                              </SvgIcon>
                          }
                          buttonStyle={{ boxShadow: '0 0 0 2px #777777 inset' }}
                          onClick={this.handleGoogleAuth}
                          className="btnNext mbottom20 __buttonLoginGoogle"
                      />
                    ) : null }
                    <p className="textCenter mtop40">
                      Don&apos;t have an account? <Link to="/signup" className="link">Sign up</Link>
                    </p>
                    <p className="textCenter mtop20 fontSmall">
                      {latestAgreementLink} | <a href="https://www.askable.com/legal?tab=privacy" target="_blank" rel="noopener noreferrer" className="link">Privacy Policy</a>
                    </p>
                </div>
                {this.state.redirect && <Redirect to={this.state.redirect} /> }
              </div>
            );
        }
      }
    }

    render() {
      if (this.props.isAuthenticated()) {
        // this.props.history.replace('/');
        return <Redirect to="/" />;
      }
      if (this.state.redirect) {
          return <Redirect to={this.state.redirect} />;
      }
      if (this.state.loading) {
          return <LoadingOverlay />;
      }
      return (
        <Wrapper
            containerClassName="loginContainer"
            leftContainerSize={{ maxWidth: 600 }}
            loading={this.state.loading}
        >
          {this.loginPageContents()}
        </Wrapper>
      );
    }
}

const loginUserMutation = graphql(loginUser, {
    props: ({ mutate }) => ({
        loginUser: (email, password, googleToken) => mutate({
            variables: { email, password, googleToken }
        }),
    }),
});

// const updateMeMutation = graphql(updateMe, {
//     props: ({ mutate }) => ({
//         updateMe: user => mutate({
//             variables: { user }
//         }),
//     }),
// });

const LatestAgreementContainer = graphql(latestAgreementByType, {
    name: 'latestAgreementByType',
    options: () => ({
        variables: { user_type: { participant: true } },
    }),
});

export default withKinde(compose(
    loginUserMutation,
    // updateMeMutation,
    LatestAgreementContainer
)(Login));
