import React from 'react';

import Dialog from 'components/common/Dialog/view';

export default props => (
    <Dialog
        customActions={[{
            label: 'OK',
            primary: true
        }]}
        title={props.title || 'Error'}
        open={!!props.open}
        modal={false}
        onCancel={props.onCancel}
    >
    {React.Children.count(props.children) ? props.children : (props.error && <p>{props.error}</p>)}
    </Dialog>
);
