import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import dotObject from 'dot-object';

import moment from 'lib/moment';
import _ from 'lodash';

import { localStorage } from 'lib/storage';
import { utils, userMeta } from 'lib/utils';

import fetchUserById from 'data/queries/user/fetchUserById';
import latestAgreementByType from 'data/queries/latestAgreementByType';
import updateMe from 'data/mutations/updateMe';
import deleteMe from 'data/mutations/deleteMe';
import verifyPhone from 'data/mutations/verifyPhone';
import verifyPhoneConfirm from 'data/mutations/verifyPhoneConfirm';

import { Wrapper, Header, LoadingOverlay } from 'components/common';

import TermsDoc from 'components/simplePages/termsDocument';

import FormContents from './formContentsView';
import { withKinde } from '../../AuthWrapper';

// Styles
import './styles/userGetDetails.scss';


class UserGetDetails extends Component {
    constructor(props) {
      super(props);

      this.state = {
        user: {},
        // didCollect: [],
        willCollect: [],
        haveCollected: {},
        submissionAttempts: {},
        userBasicDetails: null,
        loading: false,
        formContentsError: {}

        // validation: {
        //   valid: false,
        //   error: null,
        //   errorMessage: null
        // }
        // valid: {}
      };

      this.userMeta = userMeta.bigSeven;

      this.valueChangeCallback = this.valueChangeCallback.bind(this);
      this.onPageSubmit = this.onPageSubmit.bind(this);
      this.saveStateFromProps = this.saveStateFromProps.bind(this);
      this.formContentsError = this.formContentsError.bind(this);
    }

    componentWillMount() {
        this.saveStateFromProps(this.props);
    }

    componentWillReceiveProps(newProps) {
        this.saveStateFromProps(newProps);
    }

    async onPageSubmit(event, userProp) {
      if (event) {
        event.preventDefault();
      }

      const submissionAttempt = {};
      submissionAttempt[userProp] = moment().valueOf();

      this.setState({
        submissionAttempts: Object.assign({}, this.state.submissionAttempts, submissionAttempt)
      });
      // if (!this.state.validation.valid) {
      if (!this.state.haveCollected[userProp]) {
        // this.setState({
        //   validation: {
        //     ...this.state.validation,
        //     errorMessage: this.state.validation.error || 'This field is required'
        //   }
        // });
        return false;
      }

      const detailIndex = this.state.willCollect.indexOf(userProp);
      if (detailIndex >= this.state.willCollect.length - 1) {
        const userBasicDetails = _.keys(_.pickBy(this.state.haveCollected));
        localStorage.save('userBasicDetails', (userBasicDetails || []).concat(this.state.userBasicDetails || []).join(';'));
        this.setState({ loading: true });
      } else {
        this.props.history.push(`/onboard/${detailIndex + 1}`);
      }

      if (userProp === 'contact.phone.mobile' && _.get(this.state.user, 'contact.phone.mobile')) {
        this.phoneNumber = utils.parsePhoneNumber(this.state.user.contact.phone.mobile);
        if (this.phoneNumber) {
            this.setState({ verificationResponse: null });
            const verifyPhoneResult = await this.props.verifyPhone(this.phoneNumber.E164, this.phoneNumber.countryCode)
                .catch((error) => {
                    const errorMessage = _.get(error, 'graphQLErrors.0.message') || 'Verification failed';
                    this.formContentsError('contact.phone.mobile', errorMessage);
                    this.props.history.replace(`/onboard/${detailIndex}`);
                });
            if (verifyPhoneResult) {
                this.setState({ verificationResponse: _.get(verifyPhoneResult, 'data.verifyPhone') });
            } else {
                return;
            }
        } else {
            throw new Error(`Failed to parse phone number '${this.state.user.contact.phone.mobile}' for verification`);
        }
      }

      if (userProp === 'contact.phone.verification.mobile' && _.get(this.state.user, 'contact.phone.verification.mobile')) {
        if (!this.phoneNumber) {
            throw new Error(`Phone number '${_.get(this.state.user, 'contact.phone.mobile', 'null')}' not parsed`);
        }
        await this.props.verifyPhoneConfirm(
                this.phoneNumber.E164,
                this.phoneNumber.countryCode,
                this.state.user.contact.phone.verification.mobile
            )
            .catch((error) => {
                this.formContentsError(userProp, _.get(error, 'graphQLErrors.0.message') || 'Verification failed');
                this.props.history.replace(`/onboard/${detailIndex}`);
                this.setState({ loading: false });
                console.error('verifyPhoneConfirm', error);
            });

        // mixpanel.track({ event: 'Submit onboarding', payload: { user_field: userProp } });
        if (_.get(this.props, 'fetchUserById.refetch')) {
          this.props.fetchUserById.refetch();
        }
        return;
      }

      this.props.updateUser(this.state.user)
        .then((response) => {
            // mixpanel.track({ event: 'Submit onboarding', payload: { user_field: userProp } });
            if (response.data && response.data.updateMe && userProp === 'settings.agreement._id') {
              localStorage.save('userAgreement', JSON.stringify({
                _id: this.state.user.settings.agreement._id,
                name: this.state.user.settings.agreement.name,
                latest: this.props.latestAgreementByType.latestAgreementByType._id,
                date: moment().valueOf()
              }), 'session');
            }
           if (detailIndex >= this.state.willCollect.length - 1 && this.props.fetchUserById) {
             this.props.fetchUserById.refetch().then();
           }
       })
       .catch((error) => {
          const message = _.get(error, 'graphQLErrors.0.message') || 'Failed to save profile';
          this.setState((state) => {
            state.loading = false;
            state.formContentsError[userProp] = {
              message,
              updated: new Date().valueOf()
            };
            return state;
          });
          this.props.history.goBack();
          // this.props.history.replace(`/onboard/${detailIndex}`);
       });
    }

    saveStateFromProps(newProps) {
      if (_.get(newProps, 'fetchUserById.userByID') && _.get(newProps, 'latestAgreementByType.latestAgreementByType')) {
        const userProps = Object.assign({}, newProps.fetchUserById.userByID);
        if (userProps) {
          if (_.get(userProps, 'email.match') && userProps.email.match(/@temp\.askable\.com/)) {
            userProps.email = null;
          }

          const userHasPropsResult = {};
          utils.objectHasProps(userProps, userMeta.bigSeven, userHasPropsResult);

          const userBasicDetails = Object.keys(userHasPropsResult.values);

          if (userHasPropsResult.values['settings.agreement._id']) {
            const latestAgreement = newProps.latestAgreementByType.latestAgreementByType;
            if (
              latestAgreement &&
              userHasPropsResult.values['settings.agreement._id'].replace(/^\s+|\s+$/g, '') &&
              latestAgreement._id.replace(/^\s+|\s+$/g, '') &&
              userHasPropsResult.values['settings.agreement._id'].replace(/^\s+|\s+$/g, '') === latestAgreement._id.replace(/^\s+|\s+$/g, '')
            ) {
              localStorage.save('userAgreement', JSON.stringify({
                _id: latestAgreement._id,
                name: latestAgreement.name,
                latest: newProps.latestAgreementByType.latestAgreementByType._id,
                date: moment().valueOf()
              }), 'session');
            } else {
              userBasicDetails.splice(userBasicDetails.indexOf('settings.agreement._id'), 1);
              userHasPropsResult.missing.push('settings.agreement._id');
            }
          }

          const mobileVerifyIndex = userHasPropsResult.missing.indexOf('contact.phone.verification.mobile');
          if (mobileVerifyIndex >= 0 && userHasPropsResult.missing.indexOf('contact.phone.mobile') < 0) {
              userHasPropsResult.missing.splice(mobileVerifyIndex, 0, 'contact.phone.mobile');
          }

          if (userHasPropsResult.missing.length === 0 && (userBasicDetails || []).length > 0) {
            localStorage.save('userBasicDetails', userBasicDetails.join(';'));
            this.props.history.replace(utils.privateRouteResume());
          } else {
            localStorage.delete('userBasicDetails');
          }

          if (userHasPropsResult.missing.length > 0 && (userHasPropsResult.missing.length < this.state.willCollect.length)) {
            this.props.history.replace('/onboard');
          }

          this.setState({
            willCollect: userHasPropsResult.missing,
          });
          if (userBasicDetails) {
            this.setState({
              userBasicDetails
            });
          }
        }
      }
    }


    valueChangeCallback(userProp, value, valid) {
      const user = Object.assign({}, this.state.user);
      switch (userProp) {
        case 'meta.identity.birthday.timestamp': {
          const birthday = {};
          if (value) {
            const time = moment.tz(value, 'UTC');
            birthday.timestamp = time.valueOf();
            birthday.year = time.year();
            birthday.month = time.month() + 1;
            birthday.day = time.date();
          }
          user.meta = {
            ...user.meta || {},
            identity: user.meta && user.meta.identity ? {
              ...user.meta.identity,
              birthday
            } : {}
          };
        }
        break;
        case 'location.city': {
          let location = {};
          if (value) {
            location = value;
          }
          user.location = location;
        }
        break;
        case 'contact.phone.mobile': {
          const phoneNumber = valid ? value.E164 : null;
          dotObject.str(userProp, phoneNumber, user);
          if (value && value.countryCode) {
              dotObject.str('contact.phone.country_code', value.countryCode, user);
          }
          break;
        }
        case 'settings.agreement._id':
          if (value && this.props.latestAgreementByType.latestAgreementByType) {
            dotObject.str('settings.agreement', {
              _id: this.props.latestAgreementByType.latestAgreementByType._id,
              date: moment().valueOf()
            }, user);
            setTimeout(() => { this.onPageSubmit(null, userProp); }, 25);
          } else if (value === false) {
            this.setState({ loading: true });
            this.props.deleteMe()
                .then(() => {
                    // console.log('deleteMe', data);
                    localStorage.clear();
                    localStorage.clear('session');
                    utils.window.location.href = '/';
                })
                .catch((err) => {
                    console.error(err);
                    localStorage.clear();
                    localStorage.clear('session');
                    utils.window.location.href = '/';
                });
          }
        break;
        case 'timezone':
            dotObject.str(userProp, value, user);
            setTimeout(() => {
                this.onPageSubmit(null, userProp);
            }, 500);
        break;
        default:
          dotObject.str(userProp, value, user);
      }
      const haveCollected = {};
      haveCollected[userProp] = valid;
      this.setState({
        user,
        haveCollected: Object.assign({}, this.state.haveCollected, haveCollected),
        // validation: {
        //   valid,
        //   error,
        //   // errorMessage: (valid || valid !== this.state.validation.valid) ? null : (error || this.state.validation.errorMessage)
        //   errorMessage: valid || error !== this.state.validation.errorMessage ? null : this.state.validation.errorMessage
        // }
      });
    }

    formContentsError(prop, message) {
        this.setState((state) => {
            state.formContentsError[prop] = {
                message,
                updated: new Date().valueOf()
            };
            return state;
        });
    }

    render() {
        if (_.get(this.props, 'fetchUserById.userByID.type') && !_.get(this.props, 'fetchUserById.userByID.type.participant')) {
          return <Redirect to="/client-redirect" />;
        }
        if (this.props.match.params.page && _.isEmpty(this.state.haveCollected)) {
            return <Redirect to="/onboard" />;
        }
        return (
          <div>
            {
              (this.state.willCollect).map((userProp) => {
                const index = this.state.willCollect.indexOf(userProp);
                const extraProps = {};

                let detailPage = 0,
                activePage = false,
                modalContents = null;

                if (this.props.match.params.page) {
                  detailPage = Math.min(
                    parseInt(this.props.match.params.page.replace(/[^0-9]/, ''), 10),
                    this.state.willCollect.length - 1
                  );
                }

                const classNames = ['userGetDetails'];
                if (index < detailPage) {
                  classNames.push('didCollect');
                } else if (index > detailPage) {
                  classNames.push('willCollect');
                } else {
                  classNames.push('activePage');
                  activePage = true;
                }

                classNames.push(`prop-${userProp.replace(/[^0-9a-zA-Z]+/g, '-')}`);

                if (this.state.loading) {
                  return <LoadingOverlay key={`loading_${userProp}`} />;
                }
                if (userProp === 'settings.agreement._id' && this.props.latestAgreementByType.loading) {
                  return <LoadingOverlay key={`loading_${userProp}_b`} />;
                }

                switch (userProp) {
                    case 'settings.agreement._id':
                        extraProps.latestAgreementByType = this.props.latestAgreementByType.latestAgreementByType;
                        if (this.props.location.hash === '#terms') {
                            modalContents = (
                                <TermsDoc
                                    headerContents={<Header context="closeButton" style={{ marginLeft: 'auto' }} onClick={() => { this.props.history.goBack(); }} />}
                                    documentId={this.props.latestAgreementByType.latestAgreementByType._id}
                                />
                            );
                        }
                    break;
                    case 'contact.phone.mobile':
                    case 'contact.phone.verification.mobile':
                        if (!extraProps.phoneNumber && _.get(this.state.user, 'contact.phone.mobile')) {
                            extraProps.phoneNumber = utils.parsePhoneNumber(this.state.user.contact.phone.mobile);
                        }
                        if (!extraProps.phoneNumber && _.get(this.props, 'fetchUserById.userByID.contact.phone.mobile')) {
                            extraProps.phoneNumber = utils.parsePhoneNumber(this.props.fetchUserById.userByID.contact.phone.mobile);
                        }
                        if (extraProps.phoneNumber && userProp === 'contact.phone.mobile') {
                            extraProps.value = _.get(extraProps.phoneNumber, 'format.international', null);
                        }
                        if (userProp === 'contact.phone.verification.mobile') {
                            extraProps.verifyPhone = this.props.verifyPhone;
                            if (this.state.verificationResponse) {
                                extraProps.verificationResponse = this.state.verificationResponse;
                            }
                        }
                    break;
                    default:
                }
                return (
                  <Wrapper
                    containerClassNames={classNames}
                    key={`wrapper_${userProp}`}
                    modalContents={modalContents}
                  >
                    <form
                      onSubmit={event => this.onPageSubmit(event, userProp)}
                      className="wholePageQuestion"
                    >
                      <input
                        type="submit"
                        value="Submit"
                        style={{
                          position: 'absolute',
                          left: '-9999px',
                          tabIndex: -1
                        }}
                      />
                      <FormContents
                        {...extraProps}
                        userProp={userProp}
                        userPropIndex={index}
                        valueChangeCallback={this.valueChangeCallback}
                        activePage={activePage}
                        submissionAttempt={this.state.submissionAttempts[userProp]}
                        user={_.merge({}, _.get(this.props, 'fetchUserById.userByID') || {}, this.state.user || {})}
                        errorMessage={this.state.formContentsError[userProp] || {}}
                        updateUser={this.props.updateUser}
                      />
                    </form>
                  </Wrapper>
                );
              })
            }
          </div>
        );
    }
}

// export default UserGetDetails;

const UserDetailsContainer = graphql(fetchUserById, {
    name: 'fetchUserById',
    skip: (props) => !props.isAuthenticated(),
});
const LatestAgreementContainer = graphql(latestAgreementByType, {
    name: 'latestAgreementByType',
    options: () => ({
        variables: { user_type: { participant: true } },
    }),
});

const updateMeContainer = graphql(updateMe, {
    props: ({ mutate }) => ({
        updateUser: user => mutate({
            variables: { user }
        }),
    }),
});
const deleteMeContainer = graphql(deleteMe, {
    props: ({ mutate }) => ({
        deleteMe: () => mutate({}),
    }),
});
const verifyPhoneContainer = graphql(verifyPhone, {
    props: ({ mutate }) => ({
        verifyPhone: (phone, country_code) => mutate({
            variables: { phone, country_code }
        }),
    }),
});
const verifyPhoneConfirmContainer = graphql(verifyPhoneConfirm, {
    props: ({ mutate }) => ({
        verifyPhoneConfirm: (phone, country_code, verification_code) => mutate({
            variables: { phone, country_code, verification_code }
        }),
    }),
});

export default withKinde(compose(
  UserDetailsContainer,
  LatestAgreementContainer,
  updateMeContainer,
  deleteMeContainer,
  verifyPhoneContainer,
  verifyPhoneConfirmContainer
)(UserGetDetails));
