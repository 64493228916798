import { useTreatments } from '@splitsoftware/splitio-react';

export function useIsKinde() {
  const { KINDE_AUTH } = useTreatments(['KINDE_AUTH'], { application: 'participants' }, 'anonymous');

  if (localStorage.getItem('participant_access_token')) {
    return false;
  }

  return localStorage.getItem('KINDE_AUTH') === 'on' || KINDE_AUTH.treatment === 'on';
}
