import { print } from 'graphql';
import { ApolloLink, Observable } from 'apollo-link';

import { createClient } from 'graphql-ws';

export class WSLink extends ApolloLink {
  constructor(opts) {
    super();

    this.client = createClient(opts);
  }

  request(operation) {
    return new Observable((sink) => {
      return this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        }
      );
    });
  }
}
