import React, { Component, Fragment } from 'react';

import { Redirect } from 'react-router-dom';

import { Dialog } from 'components/common';

import SetupCheckCamera from './setup-check-camera';
import SetupCheckDesktop from './setup-check-desktop';
import SetupCheckNetwork from './setup-check-network';
import SetupCheckConfirm from './setup-check-confirm';

class SetupCheck extends Component {
    constructor(props) {
        super(props);
        this.state = { page: 'camera' };

        this.disconnectTracks = this.disconnectTracks.bind(this);
        this.errorMessage = this.errorMessage.bind(this);
        this.renderContents = this.renderContents.bind(this);
        this.renderErrorDialog = this.renderErrorDialog.bind(this);
    }

    componentWillUnmount() {
        this.disconnectTracks();
    }

    disconnectTracks() {
        for (const trackName of ['cameraTrack', 'micTrack', 'desktopTrack']) {
            const track = this.state[trackName];
            if (!track) return;
            if (track.stop) track.stop();
            if (track.detach) track.detach();
            this.setState((state) => {
                state[trackName] = undefined;
                return state;
            });
        }
    }

    errorMessage(errorProps) {
        this.setState({ errorDialog: errorProps });
    }

    renderContents() {
        switch (this.state.page) {
            case 'camera':
                return (
                    <SetupCheckCamera
                        onContinue={(cameraTrack, micTrack) => {
                            this.setState({ cameraTrack, micTrack, page: 'desktop' });
                        }}
                    />
                );
            case 'desktop':
                if (!this.state.cameraTrack || !this.state.micTrack) {
                    window.location.reload();
                    return null;
                }
                return (
                    <SetupCheckDesktop
                        onContinue={(desktopTrack) => {
                            this.setState({ desktopTrack, page: 'network' });
                        }}
                    />
                );
            case 'network':
                return (
                    <SetupCheckNetwork
                        tracks={{
                            camera: this.state.cameraTrack,
                            mic: this.state.micTrack,
                            desktop: this.state.desktopTrack,
                        }}
                        onContinue={(networkScore) => { this.setState({ networkScore, page: 'confirm' }); }}
                        disconnectTracks={this.disconnectTracks}
                        errorMessage={this.errorMessage}
                    />
                );
            case 'confirm':
                if (!this.state.networkScore) {
                    window.location.reload();
                    return null;
                }
                return (
                    <SetupCheckConfirm
                        networkScore={this.state.networkScore}
                    />
                );
            default:
                return <Redirect to="/live/setup-check" />;
        }
    }

    renderErrorDialog() {
        if (!this.state.errorDialog) return null;

        if (!this.state.errorDialog.body) return null;

        return (
            <Dialog
                customActions={[]}
                title={this.state.errorDialog.title}
                open
                modal={false}
                onCancel={null}
            >
                {this.state.errorDialog.body}
            </Dialog>
        );
    }

    render() {
        if (this.state.errorDialog) {
            return this.renderErrorDialog();
        }
        return this.renderContents();
    }
}

export default SetupCheck;
