import React from 'react';

import './styles.scss';

export const Callout = ({ className, children }) => {
  return (
    <div className={`${className} callout`}>
      <div className="callout-content">
        {children}
      </div>
    </div>
  );
};

export const CalloutTitle = ({ className, children }) => {
  return <p className={`${className} callout-header`}>{children}</p>;
};

export const CalloutBody = ({ className, children }) => {
  return <div className={`${className} callout-body`}>{children}</div>;
};
