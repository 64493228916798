import React from 'react';
import { Query } from 'react-apollo';
import _ from 'lodash';

import fetchUserById from 'data/queries/user/fetchUserById';

import { localStorage } from 'lib/storage';

import { withKinde } from '../../../AuthWrapper';

function SaveUserDetails(props) {
    if (!props?.isAuthenticated?.() || !localStorage.get('connectedParticipant')) {
        return null;
    }

    const participantDetails = localStorage.get('participant_details') && JSON.parse(localStorage.get('participant_details'));

    if (
        participantDetails &&
        participantDetails.name &&
        participantDetails.name.firstname &&
        participantDetails.name.lastname &&
        participantDetails.email
    ) {
        return null;
    }

    return (
        <Query query={fetchUserById} variables={{ id: localStorage.get('connectedParticipant') }}>
            {({ loading, data }) => {
              if (loading) return null;
              const userById = {};
              userById.email = _.get(data, 'userByID.email');
              if (_.has(data, 'userByID.meta.identity')) {
                  userById.name = {
                      firstname: _.get(data.userByID.meta.identity, 'firstname'),
                      lastname: _.get(data.userByID.meta.identity, 'lastname'),
                  };
              }
              const saveDetails = JSON.stringify(userById, undefined, 2);
              if (saveDetails === '{}') {
                  return null;
              }
              localStorage.save('participant_details', saveDetails);
              return null;
            }}
        </Query>
    );
}

export default withKinde(SaveUserDetails);
