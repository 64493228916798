import React, { Component } from 'react';
import { TextField, FlatButton } from 'material-ui';

import _ from 'lodash';

class MessageInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };

        this.afterTextChange = this.afterTextChange.bind(this);
    }

    componentWillUnmount() {
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }
    }

    afterTextChange() {
        this.setState({
            condensed: _.get(this, 'textField.input.state.height', 0) > 70,
            inputHeight: _.get(this, 'textField.input.state.height')
        });
        this.props.onChangeHeight(this.textField.input.state.height - this.state.inputHeight);
    }

    render() {
        return (
            <div className="messageInput">
                {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                <div
                    className={`inputWrapper
                        ${this.state.condensed ? ' condensed' : ''}
                    `.replace(/\s+/, ' ')}
                    onClick={() => {
                        document.getElementById('ConversationMessageInput').focus();
                    }}
                >
                {/* eslint-enable */}
                    <TextField
                        id="ConversationMessageInput"
                        placeholder="Type a message"
                        multiLine
                        rowsMax={3.75}
                        underlineShow={false}
                        style={{ width: '100%' }}
                        value={this.state.value}
                        ref={(textField) => {
                            this.textField = textField;
                        }}
                        onChange={(event) => {
                            this.setState({ value: event.target.value.replace(/^\s+/, '') }, this.afterTextChange);
                        }}
                    />
                </div>
                <div className="buttonWrapper">
                    {this.state.value > '' && (<FlatButton
                        label="Send"
                        backgroundColor="#5077FF"
                        hoverColor="#758fff"
                        rippleColor="#4062dd"
                        labelStyle={{
                            color: '#ffffff',
                            fontWeight: 'bold',
                            textTransform: 'none'
                        }}
                        style={{
                            minWidth: 'auto',
                            margin: '14px 24px 8px 8px'
                        }}
                        onClick={() => {
                            this.props.sendMessage(this.state.value)
                                .then((sent) => {
                                    if (sent) {
                                        this.setState({ value: '' }, this.afterTextChange);
                                    }
                                });
                        }}
                    />)}
                </div>
            </div>
        );
    }
}

export default MessageInput;
