import React, { Component } from 'react';

import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { Redirect } from 'react-router-dom';

import { localStorage } from 'lib/storage';

import { Button, Checkbox } from 'components/common';

class SetupCheck extends Component {
    constructor() {
        super();
        this.state = { check_consent: false, check_quiet_room: false };
    }

    render() {
        if (this.state.success) {
            localStorage.delete('network-test-attempts', 'session');
            const redirectPath = localStorage.get('setup-check-redirect', 'session');
            if (redirectPath) {
                localStorage.delete('setup-check-redirect', 'session');
                return <Redirect to={redirectPath} push />;
            }
            return (
                <div
                    className="centre-message narrow"
                    style={{
                        // display: 'flex',
                        // flexDirection: 'column',
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        // minHeight: 'unset',
                        // textAlign: 'center'
                    }}
                >
                    <h2>All done. You’re ready to participate with video chat!</h2>
                    <p>You can close the browser now.</p>
                </div>
            );
        }
        return (
            <Mutation
                mutation={gql`mutation updateSetupCheck($timestamp: Float, $networkScore: Float ) {
                    updateMe(user: { settings: { remote: { setup_check: $timestamp, network_quality: $networkScore } } }) {
                        _id settings { remote { setup_check network_quality } }
                    }
                }`}
            >
            {(confirmMutation, { loading, called }) => {
                return (
                    <div className="centre-message narrow">
                        <h2 className="mbottom30">Last couple of things</h2>
                        <Checkbox
                            onClick={(value) => { this.setState({ check_consent: value.checked }); }}
                            label="I consent to being recorded for research purposes"
                            className="mtop10 mbottom10"
                            iconStyle={this.state.check_consent ? null : { fill: '#AAAAAA' }}
                            labelStyle={{ color: 'inherit' }}
                        />
                        <Checkbox
                            onClick={(value) => { this.setState({ check_quiet_room: value.checked }); }}
                            label="I have a quiet room to do the video chat in"
                            className="mtop10 mbottom10"
                            iconStyle={this.state.check_quiet_room ? null : { fill: '#AAAAAA' }}
                            labelStyle={{ color: 'inherit' }}
                        />
                        <Button
                            label="Next"
                            labelColor="#fff"
                            bgColor="#FF5266"
                            type="button"
                            className="mtop40 medium"
                            disabled={loading || called || !(this.state.check_consent && this.state.check_quiet_room)}
                            onClick={() => {
                                confirmMutation({ variables: { timestamp: Date.now(), networkScore: this.props.networkScore } })
                                    .then(() => {
                                        this.setState({ success: true });
                                    })
                                    .catch((e) => {
                                        console.error(e);
                                    });
                            }}
                        />
                    </div>
                );
            }}
            </Mutation>
        );
    }
}

export default SetupCheck;
