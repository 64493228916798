import React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

import CloseIcon from 'material-ui/svg-icons/navigation/close';

// Component created to display confirmation messages
const DialogMessage = (props) => {
    let actions = [];
    if (props.customActions) {
        actions = props.customActions.map((action) => {
            if (!action.onClick && props.onCancel) {
                action.onClick = props.onCancel;
            }
            return (
                <FlatButton
                  {...action}
                />
            );
        });
    } else {
        actions = [
            <FlatButton
                label={(props.customActionLabels && props.customActionLabels.cancel) || 'Cancel'}
                primary
                onClick={props.onCancel}
            />,
            <FlatButton
                label={(props.customActionLabels && props.customActionLabels.confirm) || 'Confirm'}
                disabled={!!props.disabled}
                primary
                onClick={props.onConfirm}
            />
        ];
    }

    let modal = true;
    if (props.modal === false) {
        modal = false;
    }

    const contentClasses = ['materialDialogBox'];
    if (props.className) {
        contentClasses.push(props.className);
    }
    // if (props.fullPage) {
    //     contentClasses.push('fullPage');
    // }

    return (
        <Dialog
            title={props.title}
            actions={actions}
            modal={modal}
            open={props.open}
            contentStyle={props.styles && props.styles}
            onRequestClose={props.onRequestClose || props.onCancel}
            contentClassName={contentClasses.join(' ').replace(/\s+/, ' ')}
            overlayClassName="materialDialogOverlay"
            repositionOnUpdate={props.repositionOnUpdate && props.repositionOnUpdate}
        >
            {props.children}
            {props.closeButton ? (
                <FlatButton
                    className="closeButton"
                    onClick={props.onRequestClose || props.onCancel}
                >
                    <CloseIcon />
                </FlatButton>
            ) : null}
        </Dialog>
    );
};

export default DialogMessage;
