import gql from 'graphql-tag';

export default gql`
    query FetchParticipantByID {
        userByID: viewer {
            created
            updated
            _id
            email
            status
            type { participant }
            contact {
                phone {
                    mobile
                    verification {
                        mobile
                    }
                }
            }
            location {
                postal_code,
                city,
                state,
                country,
                city,
                latitude,
                longitude
                timezone
            }
            timezone
            meta {
                identity {
                    firstname
                    lastname
                    middlename
                    gender
                    birthday {
                      year
                      timestamp
                    }
                  languages {
                      english {
                          speak { native fluent conversational beginner }
                      }
                  }
                }
                family {
                  status {
                    single
                    relationship
                    married
                    divorced
                    widowed
                  }
                }
                education {
                  highschool
                  some_university
                  undergraduate
                  postgraduate
                  apprenticeship
                }
                work {
                    status {
                        fulltime
                        parttime
                        fulltime_student
                        parttime_student
                        unemployed
                        homeduties
                        retired
                    }
                    _industry_id _subindustry_id job_title employment_type
                    business { size turnover }
                }
                social {
                    linkedin { profile_url }
                }
                feedback_sample { recording_url }
            }
            settings {
                agreement {
                    _id
                    date
                }
                billing {
                    preferred
                    bank {
                        account_name
                        branch
                        account_number
                    }
                    giftpay
                    paypal
                    paypal_details { _id status }
                }
                notification {
                    messages { sms email }
                    reminders { sms email }
                    promotional { sms email }
                    policy { sms email }
                    account { sms email }
                }
                tutorial { my }
                restrictions { _booking_id }
                remote { setup_check }
            }
        }
    }
`;
