import React, { Component } from 'react';
import _ from 'lodash';

// import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import FlatButton from 'material-ui/FlatButton';

import { Input, Dialog } from 'components/common';

import countryCodeData from 'lib/data/phoneCountryCodes';

import { utils } from 'lib/utils';

import OptionList from './countryOptionListView';

import './styles/CountryCodeSelectStyles.scss';

export default class CountryCodeSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: null,
            countryDialogOpen: false,
            searchText: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateValue = this.updateValue.bind(this);

        this.guessValue = this.guessValue.bind(this);
        this.renderMenuItem = this.renderMenuItem.bind(this);
    }

    componentWillMount() {
        if (this.props.value) {
            this.updateValue(this.props.value, this.props.onChange);
        }
    }

    componentDidMount() {
        if (!this.props.value) {
            this.guessValue('AU');
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value && this.props.value !== this.state.value) {
            this.updateValue(this.props.value, this.props.onChange);
        }
    }

    updateValue(region, callback) {
        const country = _.find(countryCodeData, ['region', region]);
        this.setState((state) => {
            state.value = country;
            return state;
        });
        if (callback) {
            callback(country);
        }
        return country;
    }

    handleChange(country) {
        this.setState({
            value: country,
            countryDialogOpen: false,
            searchText: ''
        });
        this.props.onChange && this.props.onChange(country);
    }

    async guessValue(fallback) {
        let defaultNumber = null,
        guessByNumber = null;
        if (this.props.defaultNumber) {
            defaultNumber = utils.parsePhoneNumber(this.props.defaultNumber);
            if (defaultNumber && defaultNumber.countryCode) {
                guessByNumber = _.find(countryCodeData, { country_code: defaultNumber.countryCode, pop_rank: 0 });
                if (guessByNumber && guessByNumber.region) {
                    this.handleChange(this.updateValue(guessByNumber.region));
                }
            }
        }
        const geoRegion = 'AU'; // FIXME: Replace with user lookup

        if (!geoRegion) {
            this.handleChange(false);
            return;
        }
        const country = _.find(countryCodeData, { region: geoRegion });
        if (!country) {
            return;
        }
        if (guessByNumber && (guessByNumber.country_code !== country.country_code)) {
            return;
        }
        this.handleChange(this.updateValue(geoRegion || fallback));
    }

    renderMenuItem(country) {
        const itemProps = {
            value: country.region,
            label: country.flag,
            primaryText: `${country.flag} ${country.name}`,
            secondaryText: country.country_code,
        };

        if (this.props.showPhoneCode === false) {
            delete itemProps.secondaryText;
        }
        if (this.props.showFlag === false) {
            itemProps.primaryText = country.name;
        }

        return (
            <MenuItem
                key={country.region}
                {...itemProps}
                className="CountryCodeSelectMenuItem"
                onClick={() => {
                    this.handleChange(country);
                }}
                data-country-code={country.region}
                data-phone-code={country.country_code}
                data-country-name={country.name}
            />
        );
    }

    renderActiveItem() {
        if (!this.state.value) {
            return null;
        }
        return (
            <React.Fragment>
                {this.renderMenuItem(this.state.value)}
                <Divider />
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="CountryCodeSelect" style={{ position: 'relative', ...(this.props.style || {}) }}>
                    {this.props.buttonText ? (
                        <button
                            className="link"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                if (this.props.readonly) return;
                                if (this.props.userActionOverride) {
                                    this.props.userActionOverride();
                                    return;
                                }
                                this.setState({ countryDialogOpen: true });
                            }}
                            disabled={!!this.props.disabled}
                        >
                            {this.props.buttonText}
                        </button>
                    ) : (
                        <FlatButton
                            className="flagButton"
                            label={this.state.value ? this.state.value.flag : '🌐'}
                            onClick={(e) => {
                                e.preventDefault();
                                if (this.props.readonly) return;
                                if (this.props.userActionOverride) {
                                    this.props.userActionOverride();
                                    return;
                                }
                                this.setState({ countryDialogOpen: true });
                            }}
                            disabled={!!this.props.disabled}
                        />
                    )}
                    {this.props.readonly && <span style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }} />}
                </div>
                <Dialog
                    open={!!this.state.countryDialogOpen}
                    className="CountryCodeSelectDialog"
                    modal={false}
                    onCancel={() => { this.setState({ countryDialogOpen: false }); }}
                    customActions={[
                        // { label: 'Cancel' }
                    ]}
                >
                    <div className="contents">
                        <div className="header">
                            <div className="searchInput">
                                <Input
                                    name="CountryCodeSelectSearch"
                                    hintText="Search..."
                                    value={this.state.searchText}
                                    onChange={(value) => {
                                        this.setState({ searchText: value });
                                    }}
                                />
                            </div>
                            {this.renderActiveItem()}
                        </div>
                        <div className="options">
                            <OptionList
                                render={this.renderMenuItem}
                                search={this.state.searchText}
                                selected={this.state.value && this.state.value.region}
                            />
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}
