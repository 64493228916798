import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'lib/moment';

import { utils } from 'lib/utils';

export default function (props) {
    if (props.booking.type === 3) {
        const bookingParticipant = _.minBy(props.bookingParticipants, 'session.end');
        return (
            <div className="upcoming-session-card waitlist">
                <Link
                    to={`/booking/${props.booking._id}`}
                    className="card-child"
                >
                  <div className="card-col-full sessions">{_.get(props.booking, 'config.online_task.type') === 2 ? 'Survey' : 'Online task'}</div>

                  <div className="card-col-dynamic">
                      {bookingParticipant && <div className="date">Closes {moment(bookingParticipant.session.end).formatZoneFallback('h:mm A, MMM D')}</div>}
                  </div>

                  <div className="card-col-fixed textRight">
                      {_.get(props.booking, 'config.session.duration', '') > '' ? <div className="duration">{props.booking.config.session.duration} min task</div> : null }
                      {props.incentive ? <div className="incentive">{props.incentive} incentive</div> : null }
                  </div>
                </Link>
            </div>
        );
    } else if (props.booking.type === 4) {
        const bookingParticipant = _.minBy(props.bookingParticipants, 'session.end');
        return (
            <div className="upcoming-session-card waitlist">
                <Link
                    to={`/booking/${props.booking._id}`}
                    className="card-child"
                >
                  <div className="card-col-full sessions">Multi-stage task</div> {/* DIARY STUDY */}

                  <div className="card-col-dynamic">
                      {bookingParticipant && <div className="date">Ends {moment(bookingParticipant.session.end).formatZoneFallback('MMM D')}</div>}
                  </div>

                  <div className="card-col-fixed textRight">
                      {props.incentive ? <div className="incentive">{props.incentive} incentive</div> : null }
                  </div>
                </Link>
            </div>
        );
    }

    const dates = [
        _.get(_.minBy(props.bookingParticipants, 'session.start'), 'session.start', null),
        _.get(_.maxBy(props.bookingParticipants, 'session.end'), 'session.end', null),
    ];

    return (
        <div className="upcoming-session-card waitlist">
            <Link
                to={`/booking/${props.booking._id}`}
                className="card-child"
            >
              <div className="card-col-full sessions">{props.bookingParticipants.length} {props.bookingParticipants.length === 1 ? 'session' : 'sessions'}</div>

              <div className="card-col-dynamic">
                  <div className="date">{_.uniq(dates.map(timestamp => moment(timestamp).format('Do MMM'))).join(' - ')}</div>
                  <div className="location">{props.booking.type === 2 ? 'Remote / Video chat' : (_.get(props.booking, 'config.location.city') || _.get(props.booking, 'config.location.city') || null)}</div>
              </div>

              <div className="card-col-fixed textRight">
                  {_.get(props.booking, 'config.session.duration', '') > '' ? <div className="duration">{utils.sessionDurationString(props.booking.config.session.duration)} session</div> : null }
                  <div className="incentive">{_.get(props.booking, 'config.incentive.currency_symbol', '$')}{_.get(props.booking, 'config.incentive.value', '?')} incentive</div>
              </div>
            </Link>
        </div>
    );
}
