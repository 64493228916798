import React from 'react';
import { graphql, compose } from 'react-apollo';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';

import documentById from 'data/queries/documentById';

import Wrapper from 'components/common/Wrapper/view';

const TermsDoc = (props) => {
    let body = null;
    if (!props.documentById.loading) {
        if (_.get(props.documentById, 'documentById.url')) {
            body = (
                <p>
                    <a href={props.documentById.documentById.url} target="_blank" rel="noopener noreferrer" className="link">View {props.documentById.documentById.name}</a>&nbsp;
                    <small>(opens in a new page)</small>
                </p>
            );
        } else {
            body = <ReactMarkdown>{props.documentById.documentById.content}</ReactMarkdown>;
        }
    }
    return (
        <Wrapper
            header={props.header}
            headerContents={props.headerContents}
        >
            {body}
        </Wrapper>
    );
};

const DocumentByIdContainer = graphql(documentById, {
    name: 'documentById',
    options: props => ({
        variables: { _id: props.documentId },
    }),
});

export default compose(DocumentByIdContainer)(TermsDoc);
