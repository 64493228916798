import React from 'react';
import { graphql, compose } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import _ from 'lodash';

import { utils } from 'lib/utils';
import { localStorage } from 'lib/storage';

import bookingsByParticipant from 'data/queries/booking/bookingsByParticipant';

import { Wrapper, LoadingOverlay } from 'components/common';
import Onboarding from 'components/onboarding/view';

import BookingsListContents from './bookingsListContents';

// Styles
import './styles/bookingListStyles.scss';

const BookingsList = (props) => {
    if (props.bookingsByParticipant.loading || !props.bookingsByParticipant.bookingsByParticipant) {
      return <LoadingOverlay />;
    }

    const listConfig = {
        '/bookings/upcoming': {
            sections: ['inProgress', 'invited', 'upcoming'],
            title: 'Sessions you’re signed up for:',
            emptyTitle: 'You’re not currently signed up to any upcoming sessions'
        },
        '/bookings/pending': {
            sections: ['waitlist', 'inReview'],
            title: 'Pending sessions',
            emptyTitle: 'You have no pending sessions'
        },
        '/bookings/past': {
            sections: ['completed'],
            title: 'Past sessions',
            emptyTitle: 'You have not completed any sessions yet'
        },
    };
    if (!listConfig[props.location.pathname]) {
        if (
            _.chain(utils.filterBookingsLists(props.bookingsByParticipant.bookingsByParticipant))
            .pick(listConfig['/bookings/upcoming'].sections)
            .pickBy(list => list && list.length > 0)
            .values()
            .value()
            .length > 0
        ) {
            return <Redirect to="/bookings/upcoming" />;
        }
        if (
            _.chain(utils.filterBookingsLists(props.bookingsByParticipant.bookingsByParticipant))
            .pick(listConfig['/bookings/pending'].sections)
            .pickBy(list => list && list.length > 0)
            .values()
            .value()
            .length > 0
        ) {
            return <Redirect to="/bookings/pending" />;
        }
        return <Redirect to="/opportunities" />;
    }

    return (
        <Wrapper
            header="mainHeader"
            className="BookingsList"
        >
            <BookingsListContents
                bookingLists={_.chain(utils.filterBookingsLists(props.bookingsByParticipant.bookingsByParticipant))
                    .pick(listConfig[props.location.pathname].sections)
                    .pickBy(list => list && list.length > 0)
                    .value()}
                title={listConfig[props.location.pathname].title}
                emptyTitle={listConfig[props.location.pathname].emptyTitle}
            />
            <Onboarding tutorial="general-onboarding" />
        </Wrapper>
    );
};

const BookingsByParticipantContainer = graphql(bookingsByParticipant, {
    name: 'bookingsByParticipant',
});

export default compose(BookingsByParticipantContainer)(BookingsList);
