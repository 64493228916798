export const useAddUTMToAppState = () => {
  const addUTMToAppState = (appState: object = {}) => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmSourceStr = searchParams.get('utm_source');
    const utmCampaignStr = searchParams.get('utm_campaign');

    return {
      ...appState,
      utm: JSON.stringify({
        utm_source: utmSourceStr,
        utm_campaign: utmCampaignStr,
      }),
    };
  };

  return { addUTMToAppState };
};
