import gql from 'graphql-tag';

export default gql`
    mutation participantSaveAvailability($booking_id: ID!, $session_ids: [ID!]) {
        participantSaveAvailability(booking_id: $booking_id, session_ids: $session_ids) {
            sessions {
                _id
                status
                cancel
            }
        }
    }
`;
