import React, { useState } from 'react';

import { Wrapper, Button, SwitchList, LoadingOverlay } from 'components/common';

const AgreementConsentScreen = ({ onConsentGranted, onConsentDenied } = {}) => {
    const [consentValue, setConsentValue] = useState();
    const [loading, setLoading] = useState(false);

    if (loading) {
        return <LoadingOverlay />;
    }

    return (
        <div className="screenerPage">
            <Wrapper>
                <h1 className="mbottom40">Are you willing and able to sign a legal agreement if you are selected for this opportunity?</h1>
                <SwitchList
                    type="radio"
                    name="agreement_consent"
                    onValueChange={(value) => { setConsentValue(value[0]); }}
                    value={[consentValue]}
                    options={[
                        {
                            value: 'yes',
                            label: 'Yes',
                            key: 'yes'
                        },
                        {
                            value: 'no',
                            label: 'No',
                            key: 'no'
                        },
                    ]}
                />
                <Button
                    label="Next"
                    labelColor="#fff"
                    bgColor="#FF5266"
                    className="btnNext mtop60"
                    // className={`btnNext mtop20${!this.state.valid ? '' : ' disabled'}`}
                    disabled={!consentValue}
                    type="button"
                    onClick={() => {
                        setLoading(true);
                        if (consentValue === 'yes') {
                            onConsentGranted();
                        } else {
                            onConsentDenied();
                        }
                    }}
                />
            </Wrapper>
        </div>
    );
};

export default AgreementConsentScreen;
