import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
// import { Link } from 'react-router-dom';
// import dotObject from 'dot-object';

import moment from 'lib/moment';
import _ from 'lodash';

import { utils } from 'lib/utils';

import participantReconfirmSession from 'data/mutations/participantReconfirmSession';
import participantCancelSession from 'data/mutations/participantCancelSession';

import Dialog from 'components/common/Dialog/view';
import Input from 'components/common/Input/view';
import Button from 'components/common/Button/view';

// dotObject.override = true;

/* ---

// props minimum requirements:

{
  user_confirm: <timestamp>,
  session: {
    start: <timestamp>
  }
  booking: {
    config: {
      location: { ... }
    }
  }
  booking_participant: <ObjectId>,
  updateAttendance: <mutator>
}

--- */

class ConfirmAttendance extends Component {
  constructor(props) {
    super(props);

    // console.log(props);

    // const _2HoursOut = props.session.start - (2 * 3600000);
    // const _6HoursOut = props.session.start - (6 * 3600000);
    // const _12HoursOut = props.session.start - (12 * 3600000);
    const _24HoursOut = props.session.start - (24 * 3600000);
    // const _72HoursOut = props.session.start - (72 * 3600000);
    // TODO - v0.1: early confirmation check (PPTS-14)

    let viewStage = null;
    if (moment().valueOf() >= _24HoursOut && props.user_confirm < _24HoursOut) {
      viewStage = 'confirm24';
    }

    this.state = {
      viewStage,
      stageHistory: [viewStage],
      cancelReason: ''
    };

    this.viewStagePush = this.viewStagePush.bind(this);
    this.viewStageBack = this.viewStageBack.bind(this);
  }

  viewStagePush(viewStage) {
    const stageHistory = this.state.stageHistory.concat(viewStage);
    this.setState({
      viewStage,
      stageHistory
    });
  }
  viewStageBack() {
    this.setState({
      viewStage: this.state.stageHistory.slice(-2, 1)[0],
      stageHistory: this.state.stageHistory.slice(0, -1)
    });
  }

  // componentWillReceiveProps(newProps) {
  //   if (newProps.open !== this.props.open) {
  //     this.setState({ open: newProps.open });
  //   }
  // }

  render() {
    if (!this.state.viewStage) {
      return null;
    }

    let sessionTimeAndPlace = null;
    switch (this.props.booking.type) {
        case 1:
            if (_.get(this.props.booking, 'config.in_context.location_type')) {
                if (this.props.inContextLocation) {
                    const inContextAddress = (
                        <address className="mtop6 mbottom20">
                          {this.props.inContextLocation.street1 ? <span className="line">{this.props.inContextLocation.street1}</span> : null }{' '}
                          {this.props.inContextLocation.street2 ? <span className="line">{this.props.inContextLocation.street2}</span> : null }{' '}
                          {this.props.inContextLocation.city || this.props.inContextLocation.state ? <span className="line">{`${this.props.inContextLocation.city || ''} ${this.props.inContextLocation.state || ''}`.replace(/^\s+|\s+$/g, '')}</span> : null }
                        </address>
                    );
                    switch (this.props.booking.config.in_context.location_type) {
                        case 1:
                            sessionTimeAndPlace = <h3>You&apos;ve signed up to participate in a session on {moment(this.props.session.start).formatZoneFallback('dddd Do MMM, h:mm A')} at your home: {inContextAddress}</h3>;
                        break;
                        case 2:
                            sessionTimeAndPlace = <h3>You&apos;ve signed up to participate in a session on {moment(this.props.session.start).formatZoneFallback('dddd Do MMM, h:mm A')} at your business/workplace: {inContextAddress}</h3>;
                        break;
                        default:
                            sessionTimeAndPlace = <h3>You&apos;ve signed up to participate in a session on {moment(this.props.session.start).formatZoneFallback('dddd Do MMM, h:mm A')} at {inContextAddress}</h3>;
                    }
                }
            } else {
                sessionTimeAndPlace = (
                    <h3>
                        You&apos;ve signed up to participate in a session on {moment(this.props.session.start).formatZoneFallback('dddd Do MMM, h:mm A')} at:
                        <address className="mtop6 mbottom20">
                          {this.props.booking.config.location.street1 ? <span className="line">{this.props.booking.config.location.street1}</span> : null }{' '}
                          {this.props.booking.config.location.street2 ? <span className="line">{this.props.booking.config.location.street2}</span> : null }{' '}
                          {this.props.booking.config.location.city || this.props.booking.config.location.state ? <span className="line">{`${this.props.booking.config.location.city || ''} ${this.props.booking.config.location.state || ''}`.replace(/^\s+|\s+$/g, '')}</span> : null }
                        </address>
                    </h3>
                );
            }
        break;
        case 2:
            sessionTimeAndPlace = <h3>You&apos;ve signed up to participate in a remote session on {moment(this.props.session.start).formatZoneFallback('dddd Do MMM, h:mm A')}</h3>;
        break;
        default:
    }

    return (
      <div>
        <Dialog
          open={['confirm24'].indexOf(this.state.viewStage) >= 0}
          className="confirmAttendanceDialog"
          modal
          customActions={[]}
        >
          <div className="textCenter">
            <h3>{sessionTimeAndPlace}</h3>
            <h2>Are you still good for this session?</h2>
            <Button
              label="Yes, I&apos;m still good"
              labelColor="#fff"
              bgColor="#FF5266"
              type="button"
              onClick={this.props.confirmAction ? this.props.confirmAction : () => {
                this.props.participantReconfirmSession(this.props.booking_participant);
                this.viewStagePush('finished');
                // window.location.reload();
              }}
              className="widthAuto mtop20"
            />
            <br />
            <Button
              label="No, I can't make it"
              labelColor={'#999'/* TODO - v1.0 (PPTS-15) */}
              bgColor="transparent"
              type="button"
              onClick={
                  (this.props.confirmAttendanceAnonymous && this.props.cancelAction) ?
                  this.props.confirmAction :
                  () => { this.viewStagePush('cancel'); }
              }
              className="widthAuto mtop20"
            />
          </div>
        </Dialog>
        <Dialog
          open={this.state.viewStage === 'cancel'}
          className="confirmAttendanceDialog"
          modal
          customActions={[]}
        >
          <div className="textCenter">
            <h2>Are you sure you want to cancel?</h2>
            <p>By cancelling at this point, you may be banned from ever attending ever again.</p>
            <Input
                value={this.state.cancelReason}
                onChange={(value) => { this.setState({ cancelReason: value }); }}
                multiLine
                rowsMax="10"
                hintText="Please enter your reason for cancelling"
            />
            <Button
              label="I agree, cancel"
              labelColor="#fff"
              bgColor="#FF5266"
              type="button"
              disabled={this.state.cancelReason.replace(/^\s+|\s+$/g, '').length < 1}
              onClick={this.props.cancelAction ? this.props.cancelAction : () => {
                this.props.participantCancelSession(this.props.booking_participant, this.state.cancelReason)
                    .then(() => { utils.window.location.reload(); })
                    .catch((error) => { console.error(error); });
                this.viewStagePush('finished');
                // window.location.reload();
              }}
              className="widthAuto mtop40"
            />
            <br />
            <Button
              label="Go back"
              labelColor={'#999'/* TODO - v1.0 (PPTS-15) */}
              bgColor="transparent"
              type="button"
              onClick={this.viewStageBack}
              className="widthAuto mtop20"
            />
          </div>
        </Dialog>
      </div>
    );
  }
}


const participantReconfirmSessionContainer = graphql(participantReconfirmSession, {
    props: ({ mutate }) => ({
        participantReconfirmSession: participant_session_id => mutate({
            variables: { participant_session_id }
        }),
    }),
});
const participantCancelSessionContainer = graphql(participantCancelSession, {
    props: ({ mutate }) => ({
        participantCancelSession: (participant_session_id, cancel_reason) => mutate({
            variables: { participant_session_id, cancel_reason }
        }),
    }),
});

export default compose(participantReconfirmSessionContainer, participantCancelSessionContainer)(ConfirmAttendance);
