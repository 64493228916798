import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';

import { Dialog, Input, LoadingOverlay, SwitchList } from 'components/common';

import participantCancelReason from 'data/mutations/participantCancelReason';

class NoshowReasonDialog extends Component {
    constructor() {
        super();
        this.state = {
            reason: null,
            reasonDetails: '',
            loading: false,
            finished: false,
        };

        this.reasons = {
            FORGOT: 'I forgot',
            DIDNT_WANT_TO: 'I didn’t want to come anymore',
            TRANSPORT: 'Transport issues',
            SCHEDULE: 'Schedule clash',
            EMERGENCY: 'There was an emergency',
            OTHER: 'Something else',
        };

        this.moreInfoPlaceholder = this.moreInfoPlaceholder.bind(this);
    }

    componentDidMount() {
        if (this.props.open) {
            // mixpanel.track({
            //     event: 'Display no-show reason dialog',
            //     payload: {
            //         _booking_id: this.props.booking._id
            //     }
            // });
        }
    }

    moreInfoPlaceholder() {
        if (!this.state.reason) return null;
        switch (this.state.reason) {
            case 'FORGOT':
            case 'DIDNT_WANT_TO':
                return null;
            case 'TRANSPORT':
                return 'Please enter some extra information about your transport issues';
            case 'SCHEDULE':
                return 'Please enter some extra information about your schedule clash';
            case 'EMERGENCY':
                return 'Please enter some extra information about the emergency';
            default:
                return 'Please enter some extra information about what happened';
        }
    }

    render() {
        return (
            <Dialog
                customActions={[{
                    label: 'Done',
                    primary: true,
                    disabled: this.state.loading || !(this.state.reason && (this.moreInfoPlaceholder() ? this.state.reasonDetails.replace(/\s/g, '') : true)),
                    onClick: () => {
                        let value = `"${this.reasons[this.state.reason]}"`;
                        if (this.state.reasonDetails) value += `: ${this.state.reasonDetails}`;
                        this.setState({ loading: true });
                        // mixpanel.track({
                        //     event: 'Save no-show reason',
                        //     payload: {
                        //         _booking_id: this.props.booking._id,
                        //         reason: this.reasons[this.state.reason]
                        //     }
                        // });
                        this.props.participantCancelReason(this.props.bookingParticipant._id, value)
                            .then(() => {
                                this.setState({ finished: true });
                            });
                    }
                }]}
                title="It looks like you missed your session"
                open={!!(this.props.open && !this.state.finished)}
                modal={false}
            >
                <div style={{ position: 'relative' }}>
                    <p>We get that life happens and thigns come up. If you can just let us know what happened, it will really help us improve Askable for you and for the researchers.</p>
                    <p><strong>What happened?</strong></p>
                    <SwitchList
                        type="radio"
                        name="noshowreason"
                        options={Object.keys(this.reasons).map(value => ({ value, label: this.reasons[value] }))}
                        onValueChange={(value) => { this.setState({ reason: value[0], reasonDetails: '' }); }}
                    />
                    {this.moreInfoPlaceholder() && (
                        <Input
                            value={this.state.reasonDetails}
                            onChange={(value) => { this.setState({ reasonDetails: value }); }}
                            multiLine
                            rowsMax="8"
                            hintText={this.moreInfoPlaceholder()}
                            disabled={this.state.loading}
                            className="mtop20"
                        />
                    )}
                    {this.state.loading && <LoadingOverlay />}
                </div>
            </Dialog>
        );
    }
}

const participantCancelReasonContainer = graphql(participantCancelReason, {
    props: ({ mutate }) => ({
        participantCancelReason: (participant_session_id, cancel_reason) => mutate({
            variables: { participant_session_id, cancel_reason }
        }),
    }),
});

export default compose(participantCancelReasonContainer)(NoshowReasonDialog);
