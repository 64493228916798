import React, { Component } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { Redirect } from 'react-router-dom';
import { utils } from 'lib/utils';
import { localStorage } from 'lib/storage';
import { withKinde } from '../../AuthWrapper';
import { LoadingOverlay } from '../common';

class Logout extends Component {
    constructor(props) {
        super(props);
        const params = utils.urlParams(props.location.search);
        this.state = {
            params,
            loading: true,
        };
    }

    componentDidMount() {
        this.logUserOut()
    }

    async logUserOut() {
        datadogRum.clearUser();
        if (this.props.isKinde) {
            await this.props.logout();
        } else {
            this.setState({ loading: false });
        }

        localStorage.clear();
        localStorage.clear('session');
    }

    render() {
        if (this.state.loading) {
            return <LoadingOverlay />;
        }

        if (this.state.params._user_id && this.state.params._token) {
            return <Redirect to={`/?_user_id=${this.state.params._user_id}&_token=${this.state.params._token}`} />;
        }
        if (this.state.params.redirect) {
            return <Redirect to={this.state.params.redirect} />;
        }
        return <Redirect to="/login" />;
    }
}

export default withKinde(Logout);
