import gql from 'graphql-tag';

const fetchBookingByIdOverview = gql`
  query FetchBookingById($id: ID, $status: Int, $user: ID) {
    bookingByID(id: $id, status: $status, user: $user) {
      _id
      status
      _project_id
      _team_id
      type
      participant_is_excluded
      total_participants
      config {
        location {
          city
          region
          state
          country
          latitude
          longitude
        }
        demo
        timezone
        incentive {
          type
          currency_code
          currency_symbol
          country_code
          value
        }
        session {
          slot_min
          slot_max
          duration
          type
        }
        criteria {
          meta_identity_birthday_year {
            field
            value
            operator
          }
          meta_identity_gender {
            field
            value
            operator
          }
          meta_family_status {
            field
            value
            operator
          }
          meta_education {
            field
            value
            operator
          }
          custom {
            _question_id
            value
            operator
          }
          exclude_previous_participant
          # external_url # deprecated for quant jobs, currently unused for moderated
          locations {
            ParticipantIsEligible
          }
        }
        question {
          _id
          title
          description
          config {
            display_participant
            multiple_selection
            none_of_the_above
            type
          }
          options {
            _id
            label
            screen_in
          }
          previous_responses(search: { _user_id: $user })
        }
        options {
          show_company
          review_submission
        }
        information {
          brief_summary
          special_instructions
          short_description
        }
        participant_category
        in_context {
          location_type
        }
        remote {
          askable_live
          tool
        }
        online_task {
          tool
          type
          required_device
        }
        longitudinal_study {
          participant_workload {
            time
            measure
            frequency
          }
          period {
            time
            frequency
          }
        }
        participant_agreement {
          type
        }
        countries
      }
      session {
        _id
        start
        end
        slot_min
        slot_max
        status
        MeetingLink
      }
    }
  }
`;

const fetchBookingByIdAdditional = gql`
  query FetchBookingById($id: ID, $status: Int) {
    bookingByID(id: $id, status: $status) {
      _id
      status
      config {
        location {
          street1
          street2
          postal_code
          country
          latitude
          longitude
          name
          level
        }
        contact {
          name
          phone
        }
      }
    }
  }
`;

export { fetchBookingByIdOverview, fetchBookingByIdAdditional };
