import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Button } from 'components/common';

export default () => (
    <div className="centre-message narrow">
        <img
            src="/assets/setup-check-header.png"
            alt="Laptop setup check"
            style={{
                margin: '0 auto 20px',
                display: 'block',
            }}
        />
        <h2>Laptop setup check</h2>
        <p>To make sure there won’t be any technical issues on the day, you need to do a compulsory setup check. <em>You only need to do this once.</em></p>
        <p>You need to be on your laptop using Google Chrome to continue the setup check. Click the button below to send yourself an email to open the link on a laptop.</p>
        <Mutation
            mutation={gql`mutation sendMyselfGenericEmail($email: GenericEmailInput!) {
                sendMyselfGenericEmail(email: $email)
            }`}
        >
        {(sendEmail, { loading, called }) => (
            <Button
                label={called ? 'Link sent' : 'Email me the link'}
                labelColor="#fff"
                bgColor="#FF5266"
                type="button"
                className="mtop10"
                disabled={loading || called}
                onClick={() => {
                    // console.log(sendEmail, booking_id);
                    sendEmail({
                        variables: {
                            email: {
                                subject: 'Askable laptop setup link',
                                message_body: 'Here’s the link for you to complete the video chat setup check on your laptop.<br /><br >Make sure that you have <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a> installed on your laptop.',
                                button_text: 'Go to laptop setup check',
                                button_path: '/live/setup-check',
                                button_link_auth: true
                            }
                        }
                    });
                }}
            />
        )}
        </Mutation>
    </div>
);
