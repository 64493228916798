import React from 'react';
import { Button } from 'components/common';

export default function (props) {
    return (
        <div className="wrapperContainerFooterContents bookingFooter">
            <div className="info">
                <h2 className="mbottomauto">Get {props.incentive}</h2>
                <span>{props.durationString || `${props.duration} ${props.bookingType === 3 ? 'task' : 'session'}`}</span>
            </div>
            <div className="registerButton">
                <Button
                    label="Register now"
                    labelColor="#fff"
                    bgColor="#FF5266"
                    type="button"
                    onClick={props.registerClick}
                />
            </div>
        </div>
    );
}
