import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';

import moment from 'lib/moment';

import { Input, LoadingOverlay } from 'components/common';

import { PHONE_VERIFICATION_TYPE } from 'lib/data/constants';

import verifyPhoneSendCode from 'data/mutations/verifyPhoneSendCode';
import { getNullableType } from 'graphql';

const PhoneVerificationMutation = (props) => {
    if (!_.get(props, 'verificationResponse._id')) return <LoadingOverlay />;
    return (
        <Mutation
            mutation={verifyPhoneSendCode}
            variables={{ id: _.get(props, 'verificationResponse._id') }}
        >
        {(sendCode, mutation) => (
            <PhoneVerification
                {...props}
                sendCode={sendCode}
                mutation={mutation}
            />
        )}
        </Mutation>
    );
};

const PhoneVerification = ({
    verificationResponse = {},
    mutation,
    sendCode,
    ...props
}) => {
    const [resendTime, setResendTime] = useState(verificationResponse.resend);
    const [resendWait, setResendWait] = useState(getNullableType);
    const [loading, setLoading] = useState(true);

    let resendTimeout = null;

    const mutationResend = _.get(mutation, 'data.verifyPhoneSendCode.resend');

    const verificationId = _.get(verificationResponse, '_id');
    const verificationType = _.get(verificationResponse, 'type');

    useEffect(() => {
        setResendTime(mutationResend || verificationResponse.resend || null);
    }, [verificationResponse.resend, mutationResend]);

    useEffect(() => {
        if (!setResendWait) {
            setResendWait(resendTime - Date.now());
        }
        if (resendTimeout) {
            clearTimeout(resendTimeout);
        }
        if (resendTime > Date.now()) {
            resendTimeout = setTimeout(() => {
                setResendWait(resendTime - Date.now());
            }, 250);
        } else {
            setResendWait(0);
        }
        // cleanup
        return () => { if (resendTimeout) clearTimeout(resendTimeout); };
    }, [resendWait, resendTime]);

    useEffect(() => {
        if (!mutation.called && verificationId && verificationType === PHONE_VERIFICATION_TYPE.SMS) {
            sendCode();
        }
    }, [verificationId, verificationType, mutation.called]);

    useEffect(() => {
        setLoading(mutation.loading || !verificationId);
    }, [verificationId, mutation.loading]);

    const errorMessage = _.get(mutation, 'error.message');
    useEffect(() => {
        if (errorMessage) {
            setLoading(true);
            window.alert('There was an error sending your verification. Please try again');
            window.location.href = '/';
        }
    }, [errorMessage]);

    if (errorMessage) return <LoadingOverlay />;

    const ResendLink = ({ type }) => {
        let verb = type === PHONE_VERIFICATION_TYPE.PHONE_CALL
            ? (
                <span>
                    Call me on <strong>{props.phoneNumber ? props.phoneNumber.format.national : '?'}</strong>
                </span>
            ) : 'Send code';
        if (mutation.called) {
            verb = type === PHONE_VERIFICATION_TYPE.PHONE_CALL ? 'Call again' : 'Resend code';
        }
        // waitTime: null
        if (resendWait || loading) {
            return (
                <span>
                    <a className="link disabled linkgrey" href="#resend-code" onClick={(e) => { e.preventDefault() }}>{verb}</a>
                    &nbsp;
                    {resendWait ? <small>(please wait {moment(resendWait).format('m:ss')})</small> : null}
                </span>
            );
        }
        return <a className="link" href="#resend-code" onClick={sendCode}>{verb}</a>
    };

    switch (verificationType) {
        case PHONE_VERIFICATION_TYPE.PHONE_CALL:
            return (
                <div>
                    <p>Click the button to receive a phone call, then enter the 6-digit verification code</p>

                    {mutation.called ? (
                        <div className="mbottom30">
                            <p>Calling {props.phoneNumber ? props.phoneNumber.format.national : '...'}</p>
                            <Input
                                name={props.userProp}
                                type="tel"
                                value={props.inputValue}
                                onChange={props.onChangeHandler}
                                autoComplete="off"
                                placeholderVanilla="Enter your code here"
                                autoFocus
                            />
                        </div>
                    ) : null }

                    <p className="mtop20 mbottom30">
                        <ResendLink type={verificationType} />
                    </p>

                    <p className="mbottom6"><Link className="link" to={`/onboard/${(props.userPropIndex - 1)}`}>Edit my mobile number</Link></p>
                    <p className="mbottom6"><Link className="link" to="/help">Help</Link></p>
                </div>
            );
        case PHONE_VERIFICATION_TYPE.SMS:
        default:
            return (
                <div>
                    <p>Enter the 6-digit code sent to <strong>{props.phoneNumber ? props.phoneNumber.format.national : '?'}</strong></p>
                    <Input
                        name={props.userProp}
                        type="tel"
                        value={props.inputValue}
                        onChange={props.onChangeHandler}
                        autoComplete="off"
                        placeholderVanilla="Enter your code here"
                    />
                    {_.get(props, 'errorMessage') || null}
                    <div className="mtop60 mbottom20">
                        <p className="mbottom6">
                            <ResendLink type={verificationType} />
                        </p>
                        <p className="mbottom6"><Link className="link" to={`/onboard/${(props.userPropIndex - 1)}`}>Edit my mobile number</Link></p>
                        <p className="mbottom6"><Link className="link" to="/help">Help</Link></p>
                    </div>
                </div>
            );
    }
};

export default PhoneVerificationMutation;
