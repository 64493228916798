import { useMonocle } from '@spur.us/monocle-react';
import React from 'react';

export function withMonocle(Component) {
  return function WrapperComponent(props) {
    const monocle = useMonocle();
    const getMonocleBundle = async () => {
      await monocle.refresh();
      return monocle.getBundle();
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} getMonocleBundle={getMonocleBundle} />;
  };
}
