import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
// import { Link } from 'react-router-dom';
// import dotObject from 'dot-object';
import moment from 'lib/moment';
import _ from 'lodash';

import participantFindAvailabileSession from 'data/queries/booking/participantFindAvailabileSession';
import participantRegisterBookingParticipant from 'data/mutations/participantRegisterBookingParticipant';

import { Wrapper, LoadingOverlay, Button } from 'components/common';

import BookingScheduleConfirmDialog from './bookingScheduleConfirmDialog';

// dotObject.override = true;

class BookingScheduleConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
        availableSessions: _.sortBy(props.availableSessions || [], booking_participant => booking_participant.session.start),
        confirmedSession: null
    };
    // console.log('BookingScheduleConfirm CONSTRUCTING');
    // this.sessionsInit = this.sessionsInit.bind(this);
  }

  componentWillReceiveProps(newProps) {
      if (
          this.state.confirmedSession === null
          && _.get(newProps, 'participantFindAvailabileSession.participantFindAvailabileSession') !== undefined
      ) {
          let confirmedSession = false;
          if (newProps.participantFindAvailabileSession.participantFindAvailabileSession) {
              confirmedSession = _.find(this.props.participantSessionsByBooking.participantSessionsByBooking, [
                  'session._id', newProps.participantFindAvailabileSession.participantFindAvailabileSession
              ]);
          }
          this.setState({ confirmedSession });
      }
  }

  render() {
      if (
          this.state.loading
          || this.props.participantFindAvailabileSession.loading
      ) {
          return <LoadingOverlay />;
      }

      if (!this.state.confirmedSession) {
          return (
              <Wrapper>
                  <h1>
                      Bummer. {this.state.availableSessions.length === 1 ?
                      'The session you picked is full' :
                      'The sessions you picked are full' } :&#40;
                  </h1>
                  {this.state.availableSessions.map((booking_participant) => {
                      return (
                          <h2 key={booking_participant._id}>
                              {
                              moment(booking_participant.session.start)
                              .formatZoneFallback('dddd Do MMMM, h:mm A')
                              }
                          </h2>
                      );
                  })}
                  <p className="mtop20 mbottom60">
                      Would you like to join the waitlist for {this.state.availableSessions.length === 1 ?
                      'this session' :
                      'these sessions' }, or try go back some other times slots?
                  </p>
                  <div>
                    <Button
                      label={`Join the waitlist${
                          this.state.availableSessions.length === 1 ? '' : 's'
                      }`}
                      labelColor="#fff"
                      bgColor="#FF5266"
                      type="button"
                      onClick={() => {
                        // ga.event({
                        //   category: 'Booking',
                        //   action: 'Click join session',
                        //   label: this.props.booking._id,
                        //   value: 0
                        // });
                        this.setState({ loading: true });
                        this.props.participantRegisterBookingParticipant(this.state.availableSessions.map(booking_participant => booking_participant.session._id))
                            .then(() => { this.props.participantSessionsByBooking.refetch(); })
                            .catch((error) => { console.error('Error on bookingScheduleConfirmView:92'); console.error(error); });
                      }}
                      // className="widthAuto"
                    />
                  </div>
                  <div className="mtop20">
                    <Button
                      label="Go back"
                      labelColor={'#999'/* TODO - v1.0 (PPTS-15) */}
                      bgColor="transparent"
                      type="button"
                      onClick={this.props.history.goBack}
                    />
                  </div>
              </Wrapper>
          );
      }

      return (
          <Wrapper>
              <h1>Your allocated session:</h1>
              <h2>
                  {
                  moment(this.state.confirmedSession.session.start)
                  .formatZoneFallback('dddd Do MMMM, h:mm A')
                  }
              </h2>
              <div className="mtop40 mbottom40">
                  <Button
                    label="Confirm"
                    labelColor="#fff"
                    bgColor="#FF5266"
                    type="button"
                    onClick={() => {
                      // ga.event({
                      //   category: 'Booking',
                      //   action: 'Click join session',
                      //   label: this.props.booking._id,
                      //   value: 0
                      // });
                      this.setState({ confirmDialogOpen: true });
                    }}
                    // className="widthAuto"
                  />
              </div>
              <div className="fontSmall fontItalic">
                  <p>Please note: We take these confirmations super seriously.</p>
                  <p>The session facilitator will set aside time to see you, so if you don&apos;t show up, you&apos;ll be wasting their time, and they&apos;ll just be sitting around twiddling their thumbs.</p>
                  <p>So please make every effort to attend your session, but if for some reason you can&apos;t, we&apos;d appreciate as much notice as possible, so we can attempt to find someone else.</p>
              </div>
              <BookingScheduleConfirmDialog
                  booking={this.props.booking}
                  open={!!this.state.confirmDialogOpen}
                  onCancel={() => { this.setState({ confirmDialogOpen: false }); }}
                  onConfirm={() => {
                      this.setState({ loading: true, confirmDialogOpen: false });
                      this.props.participantRegisterBookingParticipant([this.state.confirmedSession.session._id])
                        .then(() => { this.props.participantSessionsByBooking.refetch(); })
                        .catch((error) => { console.error('Error on bookingScheduleConfirmView:149'); console.error(error); });
                  }}
              />
          </Wrapper>
      );

      // return (
      //     <pre style={{ fontSize: '12px', overflow: 'visible' }}>
      //         {/* {JSON.stringify(this.props, undefined, 2)} */}
      //         {JSON.stringify(this.state, undefined, 2)}
      //         {/* {JSON.stringify(this.state.availableSessions.map((booking_participant) => {
      //             return {
      //                 _id: booking_participant._id,
      //                 _session_id: booking_participant.session._id,
      //                 start: moment(booking_participant.session.start)
      //             }
      //         }), undefined, 2)} */}
      //     </pre>
      // );
  }
}

const ParticipantFindContainer = graphql(participantFindAvailabileSession, {
    name: 'participantFindAvailabileSession',
    options: props => ({
        variables: { booking_id: props.booking._id, session_ids: props.availableSessions.map(booking_participant => booking_participant.session._id) },
        // variables: props.queryVariables.participantFindAvailabileSession,
        fetchPolicy: 'network-only'
    }),
});
const RegisterBookingParticipantContainer = graphql(participantRegisterBookingParticipant, {
    props: ({ mutate, ownProps }) => ({
        participantRegisterBookingParticipant: session_ids => mutate({
            variables: { booking_id: ownProps.booking._id, session_ids },
        }),
    }),
});

export default compose(
    ParticipantFindContainer,
    RegisterBookingParticipantContainer
)(BookingScheduleConfirm);
