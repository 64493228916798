import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import _ from 'lodash';

import { localStorage } from 'lib/storage';

import fetchUserById from 'data/queries/user/fetchUserById';
import updateMe from 'data/mutations/updateMe';

import DialogSeries from 'components/common/DialogSeries/view';

import { withKinde } from '../../AuthWrapper';
import './styles/onboarding.scss';

class Onboarding extends Component {
    constructor(props) {
        super(props);

        this.state = {
            completedTutorials: _.get(props, 'userByID.userByID.settings.tutorial.my') || [],
            loading: false
        };

        this.onboardingFinishSeries = this.onboardingFinishSeries.bind(this);
    }

    componentWillReceiveProps(newProps) {
        const completedTutorials = _.get(newProps, 'userByID.userByID.settings.tutorial.my') || [];
        // console.log(this.state.completedTutorials, completedTutorials, _.union(this.state.completedTutorials, completedTutorials));
        if (completedTutorials.length > 0 && completedTutorials !== this.state.completedTutorials) {
            this.setState({ completedTutorials, loading: false });
        }
    }

    onboardingFinishSeries() {
        this.setState({ loading: true });
        this.props.updateUser({ settings: { tutorial: { my: this.state.completedTutorials.concat(this.props.tutorial) } } })
            // .then(() => { this.setState({ loading: false }); })
            .catch((error) => { console.error(error); });
    }

    render() {
        if ((_.get(this.props, 'userByID.loading') || false) || this.state.loading) {
            return null;
        }
        if (this.state.completedTutorials.indexOf(this.props.tutorial) >= 0) {
            return null;
        }

        switch (this.props.tutorial) {
            case 'general-onboarding':
                return (
                    <DialogSeries
                        onFinishSeries={this.onboardingFinishSeries}
                    >
                        <div key="onboarding-1" className="onboardingDialog" >
                            <img src="/assets/onboarding-general-01.svg" alt="" className="illustraion" />
                            <h2>You’re ready to start participating!</h2>
                            <p>Here’s a quick intro on what to expect as an Askable participant.</p>
                        </div>
                        <div key="onboarding-2" className="onboardingDialog" >
                            <img src="/assets/onboarding-general-02.svg" alt="" className="illustraion" />
                            <h2>We’ll SMS you with new opportunities</h2>
                            <p>When a paid opportunity comes up, we’ll send you an SMS with a link to apply.</p>
                        </div>
                        <div key="onboarding-3" className="onboardingDialog" >
                            <img src="/assets/onboarding-general-03.svg" alt="" className="illustraion" />
                            <h2>Getting paid</h2>
                            <p>By default, we pay participants with e-gift card, which you can use with lots of different retailers.</p>
                            <div className="ignore-margin"><img src="/assets/giftcard-examples.png" alt="Examples of gift cards" width="100%" /></div>
                        </div>
                        <div key="onboarding-4" className="onboardingDialog" >
                            <p>Or if you prefer, you can get paid via PayPal. You can set this in your payout preferences later.</p>
                            <p><img src="/assets/onboarding-general-paypal.png" alt="PayPal logo" /></p>
                        </div>
                        <div key="onboarding-5" className="onboardingDialog" >
                            <img src="/assets/onboarding-general-04.svg" alt="" className="illustraion" />
                            <p>If you have any questions, concerns or just wanna talk to us, you can do so by clicking on the <Link className="link" to="/help">help page</Link>.</p>
                        </div>
                    </DialogSeries>
                );
            case 'booking-registraion':
                return (
                    <DialogSeries
                        onFinishSeries={this.onboardingFinishSeries}
                    >
                        <div key="onboarding-1" className="onboardingDialog" >
                            <img src="/assets/onboarding-registered-01.svg" alt="" className="illustraion" />
                            <h2>Congrats! You’ve been selected for your first paid opportunity!</h2>
                            <p>Here’s a quick rundown on what to expect next.</p>
                        </div>
                        <div key="onboarding-2" className="onboardingDialog" >
                            <img src="/assets/onboarding-registered-02.svg" alt="" className="illustraion" />
                            <h2>Mark your calendar</h2>
                            <p>Mark your calendar with the session you’re attending. We’ll send you SMS reminders as well.</p>
                        </div>
                        <div key="onboarding-3" className="onboardingDialog" >
                            <img src="/assets/onboarding-registered-03.svg" alt="" className="illustraion" />
                            <h2>The day before</h2>
                            <p>The day before your session, we’ll send you a link via SMS asking you to confirm. You need to go to the link, and click the confirm button.</p>
                        </div>
                        <div key="onboarding-4" className="onboardingDialog" >
                            <img src="/assets/onboarding-registered-04.svg" alt="" className="illustraion" />
                            <h2>Attending your session</h2>
                            <p>Please follow our <a className="link" href="https://www.askable.com/blog/etiquette-guide-for-participants" target="_blank" rel="noopener noreferrer">etiquitte guide</a> for during your session. You will be rated by the researcher afterwards. You may or may not be recorded for internal research purposes as well.</p>
                        </div>
                        <div key="onboarding-5" className="onboardingDialog" >
                            <img src="/assets/onboarding-registered-05.svg" alt="" className="illustraion" />
                            <h2>After your session & payment</h2>
                            <p>After your session is over, you’ll get another link via SMS to confirm that you’ve finished. After you confirm, you’ll be able to receive your payment.</p>
                            <p>You can set your payment preferences here on the next page, or in the main menu.</p>
                        </div>
                    </DialogSeries>
                );
            default:
                return null;
        }
    }
}

const FetchUserByIdContainer = graphql(fetchUserById, {
    name: 'userByID',
    skip: (props) => !props.isAuthenticated()
});

const updateMeContainer = graphql(updateMe, {
    props: ({ mutate }) => ({
        updateUser: user => mutate({
            variables: { user },
            refetchQueries: [{
                query: fetchUserById,
                variables: { id: localStorage.get('connectedParticipant') }
            }]
        }),
    }),
});

export default withKinde(compose(FetchUserByIdContainer, updateMeContainer)(Onboarding));
