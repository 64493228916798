import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
// import { LoadingOverlay } from 'components/common';
// import axios from 'axios';

import { Header, LoadingOverlay, DrawerContents } from 'components/common';

import Menu from 'components/menu/view';

import { utils } from 'lib/utils';

// Styles
import './styles/WrapperStyles.scss';

class Wrapper extends Component {
    constructor(props) {
        super(props);

        const buildInfo = utils.window.location.hostname.match(/^my-dev\.|\bdev\b|^localhost$/) ? utils.getBuildInfo() : null;

        this.state = {
          loading: false,
          modalContents: props.modalContents,
          modalOpen: !!props.modalContents,
          footerContents: props.footerContents,
          footerOpen: !!props.footerContents,
          ...this.menuState(props.location.hash),
          buildInfo
        };

        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.changeMenu = this.changeMenu.bind(this);

        this.scrollTimeout = null;

        utils.window.setMenuState = (hash) => {
            this.setState({
                ...this.menuState(hash)
            });
        };
    }

    // TODO - v1.0 (PPTS-22) - scroll handle & control -- if scrolled to the top or bottom, move back 1px to avoid overscroll issues
    // componentDidMount() {
    //     const wrapperContainerBodyScroll = (e) => {
    //         console.log(e.target);
    //         this.setState({ footerContents: <pre>{e.target.scrollTop}</pre> });
    //         if (e.target.scrollTop === 0) {
    //             clearTimeout(this.scrollTimeout);
    //             this.scrollTimeout = setTimeout(() => { if (e.target.scrollTop === 0) e.target.scrollTop = 1; }, 150);
    //             // e.target.scrollTop = 10;
    //         }
    //     };
    //     const wrapperContainerBodyScrollContainers = document.getElementsByClassName('wrapperContainerBodyScrollable');
    //     for (let i = 0; i < wrapperContainerBodyScrollContainers.length; i += 1) {
    //         wrapperContainerBodyScrollContainers.item(i).addEventListener('scroll', wrapperContainerBodyScroll);
    //     }
    // }

    componentWillReceiveProps(newProps) {
      if (newProps.modalContents !== this.state.modalContents) {
        if (newProps.modalContents) {
          this.setState({ modalContents: newProps.modalContents });
          if (!this.state.modalOpen) {
            setTimeout(() => this.setState({ modalOpen: true }), 50);
            // this.setState({ modalOpen: true });
          }
        } else {
          this.setState({ modalOpen: false });
          setTimeout(() => this.setState({ modalContents: null }), 500);
          // this.setState({ modalContents: null });
        }
      }
      if (newProps.footerContents !== this.state.footerContents) {
        if (newProps.footerContents) {
          this.setState({ footerContents: newProps.footerContents });
          if (!this.state.footerOpen) {
            setTimeout(() => this.setState({ footerOpen: true }), 50);
            // this.setState({ footerOpen: true });
          }
        } else {
          this.setState({ footerOpen: false });
          setTimeout(() => this.setState({ footerContents: null }), 500);
          // this.setState({ footerContents: null });
        }
      }
    }


    toggleDrawer() {
        if (this.state.drawerOpen) {
            setTimeout(() => {
                this.setState({
                    menu: 'mainMenu',
                    secondaryMenu: null
                });
            }, 200);
        }
        this.setState({
            drawerOpen: !this.state.drawerOpen,
            drawerPage: 1,
        });
    }

    drawerNavigate(delta) {
      this.setState({
        drawerPage: this.state.drawerPage + delta
      });
    }

    changeMenu(menu, drawerPage) {
      this.setState({
        secondaryMenu: drawerPage === 1 ? null : menu,
        drawerPage
      });
    }

    menuState(hash) {
        // eg #menu=open/profile
        const menu = hash.match(/menu=(.+)?(&|$)/);
        const menuState = {
            drawerOpen: false,
            drawerPage: 1,
            menu: 'mainMenu',
            secondaryMenu: null
        };
        if (menu) {
            const menuPath = menu[1].split('/');
            menuState.drawerOpen = menuPath.length > 0;
            menuState.drawerPage = menuPath.length;
            menuState.menu = menuPath[0] || menuState.menu;
            menuState.secondaryMenu = menuPath[1] || menuState.secondaryMenu;
        }
        // console.log(menuState);
        return menuState;
    }

    render() {
        const {
            containerClassNames = (this.props.className || '').split(' '),
            header = null,
            headerProgress = null,
            children
        } = this.props;

        const loading = this.state.loading || this.props.loading;

        if (loading === 'all' || loading === true) {
          return <LoadingOverlay />;
        }

        containerClassNames.push('wrapperRoot');

        let headerContents = this.props.headerContents || null;
        if (!this.props.headerContents) {
            switch (header) {
              case 'mainHeader':
                headerContents = (<Header
                  progress={headerProgress}
                  menuClick={this.toggleDrawer}
                  context={header}
                />);
              break;
              case 'previewHeader':
                headerContents = (<Header
                  progress={headerProgress}
                  menuClick={() => {}}
                  context={header}
                />);
              break;
              default:
                headerContents = null;
            }
        }

        const constDrawerClasses = ['wrapperDrawerWrapper'];
        if (this.state.drawerOpen) {
          constDrawerClasses.push('drawerOpen');
        }
        if (this.state.drawerPage > 1) {
          constDrawerClasses.push(`drawerPage${this.state.drawerPage}`);
        }

        let buildInfo = null;
        if (this.state.buildInfo) {
            buildInfo = (
                <pre
                    style={{
                        fontSize: '12px',
                        margin: 'auto 6px 12px 6px',
                        overflowX: 'auto',
                        overflowY: 'visible',
                        flexShrink: 0,
                        color: 'rgba(0,0,0,0.5)'
                    }}
                >
                    {JSON.stringify(this.state.buildInfo, undefined, 1)}
                </pre>
            );
        }

        let headerBannerLinks = null;
        if (this.props.headerBannerLinks && this.props.headerBannerLinks.length > 0) {
            headerBannerLinks = (
                <div className="headerBannerLinks">
                    {this.props.headerBannerLinks.map((bannnerLink) => {
                        if (!bannnerLink.key || !bannnerLink.title) {
                            return null;
                        }
                        if (!bannnerLink.link) {
                            return <span key={bannnerLink.key} className="bannnerItem">{bannnerLink.title}</span>;
                        }
                        return <Link key={bannnerLink.key} className="bannnerItem bannnerLink" to={bannnerLink.link}>{bannnerLink.title}</Link>;
                    })}
                </div>
            );
        }

        return (
            <div className={containerClassNames.join(' ')}>
              <div className="wrapperContainer">
                {headerContents ? (
                  <div className="wrapperContainerHeader">
                    {headerContents}
                  </div>
                ) : null}
                <div className="wrapperContainerBody">
                  <div className="wrapperContainerBodyScrollable">
                    {headerBannerLinks}
                    <div className="wrapperContainerBodyContents">
                      {children}
                    </div>
                  </div>
                  {this.state.modalContents ? (
                    <div className={`wrapperContainerModal${this.state.modalOpen ? ' modalOpen' : ''}`}>
                      {this.state.modalContents}
                    </div>) : null}
                </div>
                {this.state.footerContents ? (
                  <div className={`wrapperContainerFooter${this.state.footerOpen ? '' : ' footerHidden'}`} onTouchMove={(e) => { if (e) e.preventDefault(); }}>
                      <div className="wrapperContainerFooterContents">
                          {this.state.footerContents}
                      </div>
                  </div>
                ) : null}
              </div>
              <div className={constDrawerClasses.join(' ')}>
                <div className="wrapperDrawer">
                  <div className="wrapperDrawerPage wrapperDrawerPrimary">
                    <DrawerContents
                      active={this.state.drawerPage === 1 && this.state.drawerOpen}
                    >
                      <Header
                        context="drawer"
                        drawerPage={1}
                        menuClick={() => {
                            if (utils.leaveWarningPrompt()) {
                                this.toggleDrawer();
                            } else {
                                return false;
                            }
                        }}
                      />
                      <Menu menu={this.state.menu} changeMenu={this.changeMenu} toggleDrawer={this.toggleDrawer} />
                    </DrawerContents>
                    {buildInfo}
                  </div>
                  <div className="wrapperDrawerPage wrapperDrawerSecondary">
                    <DrawerContents
                      active={this.state.drawerPage === 2 && this.state.drawerOpen}
                    >
                      <Header
                        context="drawer"
                        drawerPage={2}
                        menuClick={() => {
                            if (utils.leaveWarningPrompt()) {
                                this.drawerNavigate(-1);
                            } else {
                                return false;
                            }
                        }}
                      />
                      <Menu menu={this.state.secondaryMenu} changeMenu={this.changeMenu} />
                    </DrawerContents>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}

export default withRouter(Wrapper);
