import React, { Component } from 'react';
import Linkify from 'react-linkify';

import _ from 'lodash';
import moment from 'lib/moment';

import { utils } from 'lib/utils';

import { MESSAGE_TYPE, MESSAGE_DIRECTION } from 'lib/data/constants';

function getMessageIcon(message) {
    if (message.icon) return message.icon;
    if (!message.tag) return null;

    if (message.type === MESSAGE_TYPE.SYSTEM && message.tag.match(/reminder/i)) {
        return 'reminder';
    }

    if (message.type === MESSAGE_TYPE.SYSTEM && message.tag.match(/CANCELLED/i)) {
        return 'calendar-cross';
    }

    if (message.type === MESSAGE_TYPE.SYSTEM && message.tag.match(/INVITATION/i)) {
        return 'askable-logo';
    }

    if (message.type === MESSAGE_TYPE.BATCH) {
        return 'askable-logo';
    }

    if (message.tag === 'participant_agreement_link') {
        return 'askable-logo';
    }

    if (message.type === 'history') {
        return 'information';
    }

    return null;
}

const linkifyDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
    </a>
);

class Message extends Component {
    constructor() {
        super();
        this.state = {
            open: false
        };
        this._user_id = utils.getUserId();
    }

    componentDidMount() {
        if (this.props.subscribeToSeen) this.unsubscribe = this.props.subscribeToSeen();
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    render() {
        const { message } = this.props;
        let { body } = message;

        const displayProps = {
            messageType: 'Default'
        };

        if (message.created) {
            displayProps.time = <span className="time">{moment(message.created).formatZoneFallback('h:mm A')}</span>;
        }

        if (message.type === MESSAGE_TYPE.IN_APP) {
            displayProps.messageType = 'InApp';
        }
        if (message.type === MESSAGE_TYPE.ADMIN) {
            displayProps.messageType = 'InApp';
        }
        if (message.type === MESSAGE_TYPE.BATCH && message.direction === MESSAGE_DIRECTION.CLIENT_TO_PARTICIPANT) {
            displayProps.messageType = 'InApp';
        }

        if (message.type === MESSAGE_TYPE.SYSTEM) {
            switch (message.tag) {
                case 'SESSION_START_REMINDER':
                    displayProps.title = 'Reminder - your session is in 3 days'; // TODO
                    displayProps.collapsible = true;
                break;
                default:
            }
        }

        if (displayProps.messageType !== 'InApp') {
            displayProps.icon = getMessageIcon(message);
        }

        if (message._from_user_id === this._user_id) {
            displayProps.directionClass = 'Sent';
            displayProps.seen = message.seen ? <span className="seen">Seen</span> : null;
        } else {
            displayProps.directionClass = 'Received';
            displayProps.markAsSeen = message.seen ? 'INLINE MUTATION' : null; // TODO: this might be an option
        }

        switch (message.status) {
            case 'sending':
                displayProps.statusClass = 'statusSending';
                displayProps.statusText = <span className="status">Sending...</span>;
                displayProps.time = null;
            break;
            case 'failed':
                displayProps.statusClass = 'statusFailed';
                displayProps.statusText = <span className="status">Failed</span>;
                displayProps.time = null;
            break;
            default:
        }

        return (
            <div
                className={`
                    message
                    message${displayProps.messageType}
                    direction${displayProps.directionClass}
                    ${displayProps.collapsible ? 'collapsible' : ''}
                    ${this.state.open ? 'open' : ''}
                    ${displayProps.statusClass ? displayProps.statusClass : ''}
                `.replace(/\s+/g, ' ')}
            >
                {displayProps.icon && <span className="icon" style={{ backgroundImage: `url(/message-icons/${displayProps.icon}.png)` }} />}
                <div className="contents">
                    <strong className="sender">{_.get(message, 'UserFrom.meta.identity.firstname')}</strong>
                    {displayProps.title && <span className="title">{displayProps.title}</span>}
                    <span className="body">
                        <span className="bodyContents">
                            <Linkify componentDecorator={linkifyDecorator}>{body}</Linkify>
                        </span>
                    {displayProps.collapsible && (
                        <a className="collapseLink" href="#" onClick={() => { this.setState((state) => { state.open = !state.open; return state; }); }}>
                            {this.state.open ? 'Hide full message' : 'See full message'}
                        </a>
                    )}
                    </span>
                    <span className="footer">
                        {displayProps.time}
                        {displayProps.seen}
                        {displayProps.statusText}
                    </span>
                </div>
            </div>
        );
    }
}

export default Message;
