import React from 'react';
import { graphql, compose } from 'react-apollo';
import { Link } from 'react-router-dom';

import _ from 'lodash';
import moment from 'lib/moment';

import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';

import bookingsSearch from 'data/queries/booking/bookingsSearch';

import { LoadingOverlay } from 'components/common';

import OpportunityListCard from './opportunityListCardView';
import RestrictedUserPage from './restrictedUserView';

// Styles
import './styles/bookingListStyles.scss';

const BookingsList = (props) => {
    if (props.bookingsSearch.loading) {
        return <LoadingOverlay />;
    }

    const hiddenOpportunities = JSON.parse(localStorage.get('hidden_opportunities')) || [];

    const bookingSearchResults = _.filter(props.bookingsSearch.opportunitiesListSearch, booking => (
        props.registeredBookings.indexOf(booking._id) < 0 &&
        _.find(booking.session, session => session.status === 1 || session.status === null) &&
        !_.find(hiddenOpportunities, _id => booking._id === _id)
    ));

    if (utils.getUserRestrictions(props.user)) {
        return (
            <RestrictedUserPage
                opportunities={bookingSearchResults}
                restrictions={utils.getUserRestrictions(props.user)}
            />
        );
    }

    let unhideOpportunities = null;

    if (hiddenOpportunities.length > 0) {
        unhideOpportunities = (
            <p className="mtop40">
                <Link to="/opportunities" onClick={() => { localStorage.delete('hidden_opportunities'); }} className="link">Show {hiddenOpportunities.length} hidden {hiddenOpportunities.length === 1 ? 'opportunity' : 'opportunities'}</Link>
            </p>
        );
    }

    if (bookingSearchResults.length === 0) {
        return (
            <div>
                <h1>No opportunities found</h1>
                <p>Sorry, we couldn&apos;t find any current opportunities that we think you&apos;ll be eligible for. We&apos;ll keep you posted as more opportunities come up.</p>
                {unhideOpportunities}
            </div>
        );
    }
    // FILTER BOOKINGS BY ONES ALREADY REGISTERED
    return (
        <div>
            <h1>Opportunities you may be eligible for:</h1>
            {bookingSearchResults.slice(0, 25)
            .map(booking => <OpportunityListCard key={booking._id} booking={booking} />)}
            {unhideOpportunities}
        </div>
    );
};

const bookingSearchParams = _.memoize(({ user }) => {
    const search = {
        dateMin: moment().valueOf() + (3600000 * 2)
    };

    if (_.get(user, 'location.latitude')) {
        search.locationRadius = 20;
    }

    if (user) {
        search._user_id = user._id;
    }

    return { search };
}, ({ user }) => user._id);

const BookingsSearchContainer = graphql(bookingsSearch, {
    name: 'bookingsSearch',
    options: props => ({
        variables: bookingSearchParams(props)
    }),
});

export default compose(BookingsSearchContainer)(BookingsList);
