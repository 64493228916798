import gql from 'graphql-tag';
import { messagesFieldsFragment } from 'data/fragments/messages/messagesFields';

export default gql`
    query bookingParticipantMessages ($_booking_id: ID!, $_user_id: ID!) {
        bookingParticipantMessages (_booking_id: $_booking_id, _user_id: $_user_id) {
            _id type
            config {
              incentive {
                currency_code
                currency_symbol
                country_code
                value
              }
              location { country city region }
              in_context { location_type }
              online_task { type tool }
            }
            messages { ...messagesFields }
            booking_participant {
              _id status cancel
              session { _id start }
              history {
                  early_confirmation_request
                  last_quantitative_application_time
                  session_click_confirm_button
                  session_completed
                  session_completed_feedback_request
                  session_completed_feedback_submitted
                  session_confirmed_cancellation
                  session_invitation_accepted
                  session_invitation_declined
                  session_invitation_notification
                  session_invitation_notification_followup
                  session_opportunity_application
                  session_paid
                  session_participant_cancelled_by_askable
                  session_participant_cancelled_by_client
                  session_quant_completed
                  session_quant_invitation_notification
                  session_reinvite_notification
                  session_start_reminder_2_askable_incentive_message
                  session_start_reminder_action_needed
                  session_start_reminder_action_needed_followup
                  session_start_reminder_confirmation_pending_askable_notification
                  session_start_reminder_last_askable_warning
                  session_start_reminder_no_action
              }
            }
        }
    }
    ${messagesFieldsFragment}
`;
