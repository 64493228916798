import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import _ from 'lodash';
import { getWidth, getHeight } from 'viewport-size';

import './styles/SelectStyles.scss';

export default class Select extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || null
        };

        this.handleChange = this.handleChange.bind(this);
        this.setValues = this.setValues.bind(this);

        this.setValues(props.values);
    }

    componentWillMount() {
        this.setState({
            value: this.props.value
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.value && newProps.value !== this.state.value) {
            this.setState({ value: newProps.value });
        }
        if (JSON.stringify(newProps.values) !== JSON.stringify(this.props.values)) {
            this.setValues(newProps.values);
        }
    }

    setValues(values) {
        switch (this.props.orderValues) {
            case 'label':
                this.values = _.orderBy(values, 'label', 'asc');
            break;
            default:
                this.values = values;
        }
    }

    handleChange(event, index, value) {
        this.setState({
            value
        });

        this.props.onChange && this.props.onChange(value, index);
    }

    render() {
        if (this.props.responsive === false || (getWidth() > 750 && getHeight() > 750)) {
            return (
                <SelectField
                    floatingLabelText={this.props.placeholder}
                    value={this.state.value}
                    onChange={this.handleChange}
                    className={`selectField ${this.props.className}`}
                    fullWidth={this.props.fullWidth}
                    style={this.props.style}
                    id={this.props.id}
                >
                    {_.map(this.values, (val) => {
                        if (this.props.renderItem) {
                            return this.props.renderItem(val);
                        }

                        return (
                            <MenuItem
                                value={val.value}
                                primaryText={val.label}
                                key={val.value}
                            />
                        );
                    })}
                </SelectField>
            );
        }

        return (
            <div
                style={{ position: 'relative', padding: '37px 0px 8px' }}
                className={`selectField ${this.props.className}`}
            >
                <select
                    style={{
                        borderBottom: '1px solid #e0e0e0',
                        padding: '0 24px 4px 0',
                        borderRadius: '0',
                        fontSize: '16px',
                        lineHeight: '22px',
                        width: this.props.fullWidth ? '100%' : 'auto',
                        maxWidth: '100%',
                        position: 'relative',
                        backgroundColor: 'transparent',
                        zIndex: 1,
                        ...(this.props.style || {})
                    }}
                    id={this.props.id}
                    value={this.state.value || ''}
                    onChange={(event) => { this.handleChange(event, null, event.target.value); }}
                >
                {this.props.placeholder && this.props.placeholder > '' && <option value="">{this.props.placeholder}</option>}
                {_.map(this.values, (val) => {
                    return (
                        <option
                            value={val.value}
                            key={val.value}
                        >
                        {val.label}
                        </option>
                    );
                })}
                </select>
                <span
                    style={{
                        position: 'absolute',
                        zIndex: 0,
                        right: 0,
                        width: '24px',
                        textAlign: 'center',
                        lineHeight: '22px',
                        color: '#dadada',
                    }}
                >▾
                </span>
            </div>
        );
    }
}
