import React, { Component, Fragment } from 'react';
import TwilioVideo from 'twilio-video';
import _ from 'lodash';

// import { graphql, compose, Mutation } from 'react-apollo';
import Spinner from 'react-spinkit';
import { Button, AudioVisualiser } from 'components/common';

import Overlay from '../askable-live-overlay-portal.view';

class SetupCheck extends Component {
    constructor() {
        super();
        this.state = { overlay: 'pending' };

        this.shareDesktop = this.shareDesktop.bind(this);
        this.renderOverlay = this.renderOverlay.bind(this);
    }

    async shareDesktop() {
        this.setState({ overlay: 'screen-share' });
        const ScreenShare = await window.navigator.mediaDevices.getDisplayMedia()
            .catch((e) => {
                if (e.message === 'Permission denied by system' && _.get(this.props, 'userAgent.browser.name') === 'Chrome') {
                    this.setState({ error: 'chrome-system-denied', overlay: null });
                    return false;
                }

                this.setState({ error: 'denied', overlay: null });
                return false;
            });

        if (ScreenShare) {
            const track = new TwilioVideo.LocalVideoTrack(
                ScreenShare.getTracks()[0],
                { name: 'participant_desktop' }
            );

            this.setState({ success: true, overlay: null, error: null, desktop_track: track });
            this.navTimeout = setTimeout(() => { this.props.onContinue(track); }, 1500);
        }
    }

    renderOverlay() {
        switch (this.state.overlay) {
            case 'screen-share':
                return <Overlay.DesktopPromptChrome />;
            default:
                return null;
        }
    }

    render() {
        if (this.state.error) {
            switch (this.state.error) {
                case 'chrome-system-denied':
                    return (
                        <div className="centre-message narrow">
                            <h2>Can’t share your screen...</h2>
                            <p>Your browser might not have permissions to share your screen. To grant permission, open your <a href="x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture" target="_blank" className="link">System Privacy Preferences</a> and allow Google Chrome under Screen Recording.</p>
                            <p>Once you’ve enabled permissions, reload this page.</p>
                        </div>
                    );
                default:
                    return (
                        <div className="centre-message narrow">
                            <h2>That didn’t work...</h2>
                            <p>Please try sharing your screen again</p>
                            <Button
                                label="Try again"
                                labelColor="#fff"
                                bgColor="#FF5266"
                                type="button"
                                className="mtop20 medium"
                                onClick={() => {
                                    this.setState({ error: null });
                                }}
                            />
                        </div>
                    );
            }
        }
        if (this.state.success) {
            return (
                <div
                    className="centre-message"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <h2>Awesome, that worked!</h2>
                    <Spinner
                        fadeIn="none"
                        name="three-bounce"
                        color="#FF5266"
                    />
                </div>
            );
        }
        return (
            <div className="centre-message narrow">
                <h2>Enable screen sharing</h2>
                <p>Next, let’s enable screen sharing.<br />After you click next, another popup will appear.</p>
                <Button
                    label="Next"
                    labelColor="#fff"
                    bgColor="#FF5266"
                    type="button"
                    className="mtop20 medium"
                    onClick={this.shareDesktop}
                />
                {this.renderOverlay()}
            </div>
        );
    }
}

export default SetupCheck;
