import React from 'react';
import { Link } from 'react-router-dom';
import Wrapper from 'components/common/Wrapper/view';
import { utils } from 'lib/utils';
import { HELP_URL } from '../../lib/data/constants';

export default function (props) {
    let pageContents = null;
    switch (props.match.params.error) {
        case 'account-disabled':
            pageContents = (
                <Wrapper
                  header="mainHeader"
                >
                    <h1>Account Disabled</h1>
                    <p>Your account has been disabled. <a href={HELP_URL} className="link">Please contact Askable</a> for help.</p>
                </Wrapper>
            );
        break;
        case 'demo-account-access': {
            const backButton = (
                <a
                    href="#back"
                    className="link"
                    role="button"
                    onClick={(e) => {
                        e.preventDefault();
                        props.history.goBack();
                    }}
                >
                    Go back
                </a>
            );
            pageContents = (
                <Wrapper
                  header="mainHeader"
                >
                    <h1>Page disabled</h1>
                    <p>You’re logged in as a demo user, and don’t have access to this page.</p>
                    <p>{backButton} to the previous page, or <Link className="link" to="/logout?redirect=/signup" replace>create a full account</Link>.</p>
                </Wrapper>
            );
        }
        break;
        default:
            pageContents = (
                <Wrapper
                  header="mainHeader"
                >
                    <h1>Error</h1>
                    <p>Something went wrong... <a href={HELP_URL} className="link">please contact Askable</a> for help.</p>
                </Wrapper>
            );
    }
    return pageContents;
}
