import React from 'react';
// @ts-expect-error Could not find a declaration file for module '../../../data/queries/user/deleteMe'.
import { Dialog, Input } from 'components/common';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onDelete: () => void;
};

export const UnlinkPayPalDialog = ({ isOpen, onCancel, onDelete }: Props) => {
  return (
    <Dialog
      open={isOpen}
      onCancel={onCancel}
      modal={false}
      title="Unlink your PayPal account?"
      customActions={[
        {
          label: 'Cancel',
        },
        {
          label: 'Unlink',
          primary: true,
          onClick: onDelete,
        },
      ]}
      className="referral-dialog"
    >
      <p>Are you sure you want to unlink your account?</p>
    </Dialog>
  );
};
