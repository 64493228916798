import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Step, Stepper, StepLabel, StepContent } from 'material-ui/Stepper';

class StepperComponent extends Component {
    constructor() {
        super();

        this.renderStep = this.renderStep.bind(this);
        this.onClickStep = this.onClickStep.bind(this);
    }

    onClickStep(index, link) {
        this.props.history.replace({ pathname: link });
    }

    renderStep(value, index) {
        return (
            <Step
                key={index}
                completed={value.completed}
            >
                <StepLabel
                    style={{ color: this.props.labelColor ? this.props.labelColor : '#ffffff' }}
                    onClick={() => this.onClickStep(index, value.link)}
                >
                    {value.label}
                </StepLabel>
                <StepContent
                    style={{ color: this.props.labelColor ? this.props.labelColor : '#ffffff' }}
                >
                    {value.content}
                </StepContent>
            </Step>
        );
    }

    render() {
        // Check what's the active step by cross matching the URL
        const activeStep = this.props.values.filter(value => this.props.location.pathname === value.link)[0].index;
        return (
            <div className={this.props.className ? this.props.className : ''}>
                <Stepper
                    activeStep={activeStep}
                    orientation={this.props.orientation}
                    style={{ cursor: 'pointer' }}
                >
                    {this.props.values.map(this.renderStep)}
                </Stepper>
            </div>
        );
    }
}

export default withRouter((StepperComponent));
