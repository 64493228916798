import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import PlayStoreIcon from '../../icons/playStore';
import AppleIcon from '../../icons/apple';
import { Callout, CalloutBody } from '../common/Callout/view';
import { ANDROID_STORE_LINK, APPLE_STORE_LINK } from '../../lib/data/constants';

import './styles/appRequired.scss';

export const MobileAppRequiredCallout = () => {
  const host = window.location.host;
  return (
    <Callout className="mtop20 flex flexColumn appCallout">
      <CalloutBody className="flex">
        <article>
          <h3>Mobile app download required</h3>
          <p>Askable now has a mobile app for both Android and iOS. To participate in this opportunity please:</p>
          <ol>
            <li>Download the app by scanning the QR code</li>
            <li>Log in with your Askable account</li>
          </ol>
          <div className="icons">
            <button onClick={() => window.location.assign(APPLE_STORE_LINK)}>
              <AppleIcon />
            </button>
            <button onClick={() => window.location.assign(ANDROID_STORE_LINK)}>
              <PlayStoreIcon />
            </button>
          </div>
        </article>
        <aside className="qr">
          <QRCodeSVG value={`${host}/mobile`} marginSize={4} />
        </aside>
      </CalloutBody>
    </Callout>
  );
};
