import React from 'react';

export default () => (
  <svg viewBox="0 0 50 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.2046 56.9998L15.227 50.5049L12.9495 50.4521H2.07422L9.63198 2.52593C9.65544 2.38125 9.73168 2.24634 9.84311 2.15054C9.95454 2.05474 10.0973 2.00195 10.2458 2.00195H28.5831C34.6707 2.00195 38.8719 3.26888 41.0653 5.76949C42.0936 6.94257 42.7485 8.16844 43.0652 9.51748C43.3975 10.933 43.4034 12.6242 43.0789 14.6868L43.0554 14.8374V16.1591L44.0837 16.7417C44.9498 17.2011 45.6379 17.7271 46.1657 18.3293C47.0454 19.3322 47.6143 20.607 47.8548 22.1183C48.1031 23.6726 48.021 25.5222 47.6143 27.6161C47.1451 30.0248 46.3866 32.1227 45.3622 33.8393C44.42 35.421 43.2196 36.7329 41.7945 37.7496C40.4339 38.7154 38.8171 39.4486 36.9893 39.9178C35.2181 40.3792 33.1987 40.6119 30.9837 40.6119H29.5566C28.5362 40.6119 27.545 40.9795 26.7669 41.6383C25.9869 42.3109 25.4708 43.2298 25.3125 44.2347L25.205 44.8193L23.3986 56.2666L23.3165 56.687C23.295 56.8199 23.2578 56.8864 23.2031 56.9314C23.1542 56.9724 23.0839 56.9998 23.0154 56.9998H14.2046Z" fill="#253B80"/>
    <path d="M45.0571 14.9897C45.0024 15.3397 44.9398 15.6975 44.8695 16.0651C42.4512 28.4821 34.178 32.7717 23.6115 32.7717H18.2316C16.9394 32.7717 15.8505 33.7101 15.6491 34.9849L12.1146 57.4083C11.9836 58.2451 12.6287 58.9997 13.4733 58.9997H23.0153C24.1452 58.9997 25.1051 58.1786 25.283 57.0642L25.3769 56.5793L27.1734 45.177L27.2888 44.5513C27.4647 43.433 28.4265 42.6118 29.5565 42.6118H30.9836C40.2285 42.6118 47.4656 38.858 49.5809 27.9953C50.4645 23.4574 50.007 19.6684 47.6689 17.0035C46.9612 16.2 46.0835 15.5333 45.0571 14.9897Z" fill="#179BD7"/>
    <path d="M42.5278 13.9809C42.1583 13.8733 41.7771 13.7756 41.3861 13.6876C40.9931 13.6016 40.5904 13.5253 40.176 13.4589C38.7254 13.2242 37.1361 13.1128 35.4333 13.1128H21.0607C20.7068 13.1128 20.3706 13.193 20.0695 13.3376C19.4068 13.6563 18.9141 14.2839 18.7949 15.0523L15.7374 34.4198L15.6494 34.9848C15.8508 33.7101 16.9397 32.7716 18.2319 32.7716H23.6118C34.1783 32.7716 42.4515 28.4801 44.8698 16.065C44.9421 15.6975 45.0027 15.3397 45.0574 14.9897C44.4455 14.6652 43.7828 14.3875 43.0693 14.151C42.8933 14.0923 42.7115 14.0356 42.5278 13.9809Z" fill="#222D65"/>
    <path d="M18.7947 15.0526C18.9139 14.2842 19.4066 13.6566 20.0693 13.3399C20.3723 13.1952 20.7066 13.115 21.0604 13.115H35.4331C37.1358 13.115 38.7252 13.2265 40.1758 13.4611C40.5902 13.5276 40.9929 13.6038 41.3859 13.6898C41.7768 13.7778 42.1581 13.8756 42.5275 13.9831C42.7113 14.0378 42.8931 14.0945 43.071 14.1512C43.7846 14.3878 44.4473 14.6674 45.0592 14.99C45.7786 10.4013 45.0533 7.277 42.5725 4.44792C39.8376 1.3334 34.9014 0 28.585 0H10.2477C8.95747 0 7.85684 0.938463 7.65744 2.21516L0.0195275 50.634C-0.131002 51.592 0.607962 52.4562 1.5737 52.4562H12.8947L18.7947 15.0526Z" fill="#253B80"/>
  </svg>
);
