import React from 'react';

export default () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <g id="Google-Button">
      <g id="btn_google_light_normal" data-name="btn google light normal">
        <g id="logo_googleg_48dp" data-name="logo googleg 48dp">
          <path id="Shape" d="M31.64,23.2a10.34,10.34,0,0,0-.16-1.84H23v3.48h4.84A4.14,4.14,0,0,1,26,27.56v2.26H29a8.78,8.78,0,0,0,2.68-6.62h0Z" transform="translate(-14 -14)" fill="#4285f4" />
          <path id="Shape-2" data-name="Shape" d="M23,32a8.59,8.59,0,0,0,6-2.18L26,27.56A5.43,5.43,0,0,1,18,24.71H15V27a9,9,0,0,0,8,5h0Z" transform="translate(-14 -14)" fill="#34a853" />
          <path id="Shape-3" data-name="Shape" d="M18,24.71a5.32,5.32,0,0,1,0-3.42V19H15A9,9,0,0,0,15,27l3-2.33h0Z" transform="translate(-14 -14)" fill="#fbbc05" />
          <path id="Shape-4" data-name="Shape" d="M23,17.58a4.86,4.86,0,0,1,3.44,1.35L29,16.34A8.65,8.65,0,0,0,23,14a9,9,0,0,0-8,5l3,2.33a5.36,5.36,0,0,1,5-3.71h0Z" transform="translate(-14 -14)" fill="#ea4335" />
          <path id="Shape-5" data-name="Shape" d="M14,14H32V32H14V14Z" transform="translate(-14 -14)" fill="none" />
        </g>
      </g>
    </g>
  </svg>
);
