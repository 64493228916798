import React from 'react';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import BookingCard from './bookingCard';
import BookingWaitlistCard from './bookingWaitlistCard';

// Styles
import './styles/bookingListStyles.scss';

const BookingsListContents = (props) => {
    const sectionCount = _.values(props.bookingLists).length;
    const sections = [];

    if (sectionCount === 0) {
        return (
            <React.Fragment>
              <h1>{props.emptyTitle}</h1>
              <div className="mtop40">
                  <p>You may be eligible for <Link to="/opportunities" className="link">more opportunities</Link>!</p>
              </div>
            </React.Fragment>
        );
    }

    _.forIn(props.bookingLists, (items, list) => {
          let title = null;
          switch (list) {
              case 'inProgress':
                  title = sectionCount === 1 ? 'Sessions in progress:' : 'In Progress:';
              break;
              case 'upcoming':
                  title = sectionCount === 1 ? 'Upcoming sessions you’re signed up for:' : 'Upcoming:';
              break;
              case 'waitlist':
                  title = 'You’re on the wait list for:';
              break;
              case 'inReview':
                  title = sectionCount === 1 ? 'Sessions pending approval:' : 'Pending approval:';
              break;
              case 'invited':
                  title = sectionCount === 1 ? 'You’ve been invited to attend:' : 'New invitations:';
              break;
              case 'cancelled':
                  title = sectionCount === 1 ? 'Your upcoming sessions have been cancelled:' : 'Cancelled sessions:';
              break;
              case 'completed':
                  // title = sectionCount === 1 ? 'Your upcoming sessions have been cancelled:' : 'Cancelled sessions:';
                  title = 'Past sessions:';
              break;
              default:
          }
          sections.push({
              key: list,
              title,
              cards: items.map((item) => {
                  switch (list) {
                      case 'inProgress':
                      case 'upcoming':
                      case 'invited':
                      case 'completed': {
                          return (<BookingCard
                              key={item.booking._id}
                              section={list}
                              className={`confirmed ${list}`}
                              booking={item.booking}
                              bookingParticipants={item.relevantDocs}
                          />);
                      }
                      default:
                          return (<BookingWaitlistCard
                              key={item.booking._id}
                              section={list}
                              className={list}
                              booking={item.booking}
                              bookingParticipants={item.relevantDocs}
                              incentive={_.get(item, 'booking.config.incentive.value') ? `${_.get(item, 'booking.config.incentive.currency_symbol', '$')}${item.booking.config.incentive.value}` : null}
                          />);
                  }
              })
          });
  });

  if (sectionCount === 1) {
      return (
          <React.Fragment>
            <h1>{sections[0].title}</h1>
            {sections[0].cards}
            <div className="mtop40">
                <p>You may be eligible for <Link to="/opportunities" className="link">more opportunities</Link>!</p>
            </div>
          </React.Fragment>
      );
  }

  return (
      <React.Fragment>
        <h1>{props.title}</h1>
        {sections.map((section) => {
            return (
              <div key={section.key} className={`listSection section-${section.key} mbottom20`}>
                  <h2>{section.title}</h2>
                  {section.cards}
              </div>
            );
        })}
        <div className="mtop20">
            <p>You may be eligible for <Link to="/opportunities" className="link">more opportunities</Link>!</p>
        </div>
      </React.Fragment>
  );
};

export default BookingsListContents;
