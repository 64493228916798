import gql from 'graphql-tag';

export default gql`query quantBookingParticipantTimelimit($_id: ID!) {
  findParticipantSessionById(_id: $_id) {
    _id
    history {
      last_quantitative_application_time
    }
    booking {
      _id
      config {
        session {
          time_limit
        }
      }
    }
  }
}`;
