import React, { useEffect, useState } from 'react';
import validatePayPal from '../../../data/queries/user/validatePayPal';
import { compose, graphql, withApollo } from 'react-apollo';
//@ts-expect-error Could not find a declaration file for module '../../common/LoadingOverlay/view.jsx'.
import LoadingOverlay from '../../common/LoadingOverlay/view.jsx';
import { PayPalButton } from './payPalButton.js';
import { RouteComponentProps } from 'react-router-dom';
//@ts-expect-error
import { Wrapper } from '../../../components/common';

interface ValidatePayPalResult {
  isValidPayPalLogin: boolean;
}

interface ValidatePayPal {
  validatePayPal: ValidatePayPalResult;
  error?: any;
}

interface PaypalVerificationProps extends RouteComponentProps {
  validatePayPal: ValidatePayPal;
}

const params = new URLSearchParams(window.location.search);
const clientCode = params.get('code');

const PaypalVerification = (props: PaypalVerificationProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (!props.validatePayPal?.validatePayPal && !props.validatePayPal?.error) {
      return;
    }
    if (props.validatePayPal?.validatePayPal?.isValidPayPalLogin || props.validatePayPal?.error) {
      setIsLoading(false);
    }
    const isValidPayPalLogin = !!props.validatePayPal?.validatePayPal?.isValidPayPalLogin;
    setIsValid(isValidPayPalLogin);
    if (isValidPayPalLogin) {
      window.location.href = '/';
    }
  }, [props.validatePayPal]);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (isValid) {
    return null;
  }

  return (
    <Wrapper className="userGetDetails">
      <h1 className="mbottom60">Sign into your PayPal account</h1>
      {clientCode && (
        <>
          <div className="mbottom10" style={{ color: 'red', fontWeight: 'bold' }}>
            Could not validate your PayPal account:
          </div>
          <div className="mbottom60" style={{ color: 'red' }}>
            {props.validatePayPal?.error?.graphQLErrors[0]?.message}
          </div>
          <br />
        </>
      )}
      <div className="textCenter">
        <PayPalButton />
      </div>
      <div className="mtop20 textCenter">
        <button onClick={() => props.history.push('/')} className="link" type="button" style={{ color: '#888888' }}>
          I’ll set this later
        </button>
      </div>
    </Wrapper>
  );
};

export default graphql(validatePayPal, {
  name: 'validatePayPal',
  options: (props: PaypalVerificationProps) => ({
    variables: { client_code: clientCode },
  }),
})(PaypalVerification);
