import React, { Component } from 'react';
import { graphql, compose, Query } from 'react-apollo';
import gql from 'graphql-tag';

import ReactMarkdown from 'react-markdown';

import moment from 'lib/moment';
import _ from 'lodash';
import dot from 'dot-object';

import { utils } from 'lib/utils';
import { ga } from 'lib/analytics';

import { fetchBookingByIdAdditional } from 'data/queries/booking/fetchBookingById';
import participantSaveAvailability from 'data/mutations/participantSaveAvailability';
import participantCancelSession from 'data/mutations/participantCancelSession';
import completeSurvey from 'data/mutations/completeSurvey';

import AccessTimeIcon from 'material-ui/svg-icons/device/access-time';
import PersonIcon from 'material-ui/svg-icons/social/person';
import GroupIcon from 'material-ui/svg-icons/social/group';
import HeadsetIcon from 'material-ui/svg-icons/hardware/headset-mic';
import MoneyIcon from 'material-ui/svg-icons/editor/attach-money';
import PhoneIcon from 'material-ui/svg-icons/hardware/phone-android';
import GiftCardIcon from 'material-ui/svg-icons/action/card-giftcard';

import { LoadingOverlay, List, Dialog, StaticMap } from 'components/common';
import { Card, CardChild } from 'components/common/Card/view';

import BookingScheduleSessionSelectView from './schedule/bookingScheduleSessionSelectView';
import { isMobileOnlyAskableLive } from './utils/bookingConfig';
import { MobileAppPreInviteCallout } from './mobileAppPreInviteCallout';

// Styles
import './styles/booking.scss';

class BookingInReviewView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booking: props.booking,
      loading: false,
      bookingAdditional: false,
      availability: this.availability(props.bookingParticipants),
      // selectableSessions: props.booking.session
      selectableSessions: this.selectableSessions(props.booking.session, props.bookingParticipants),
      newAvailability: []
    };

    // utils.fbPixelTrack('ViewContent', {
    //   content_ids: props.booking._id,
    //   content_type: 'Booking - registered',
    // });

    // this.selectableSessions = this.selectableSessions.bind(this);
    if (utils.urlParam(props.location.search, 'surveyCompleted')) {
        props.completeSurvey()
            .then(() => {
                props.findBookingSubmission.refetch();
                props.history.replace(props.bookingBaseUrl);
            });
    }

    this.setBookingState = this.setBookingState.bind(this);
  }

  componentWillMount() {
      this.setBookingState(this.props);
  }

  componentWillReceiveProps(newProps) {
      this.setBookingState(newProps);
  }

  setBookingState(props) {
    if (
        !this.state.bookingAdditional &&
        props.fetchBookingByIdAdditional.bookingByID &&
        !props.fetchBookingByIdAdditional.loading &&
        this.state.booking
    ) {
      const flatBooking = Object.assign({}, dot.dot(this.state.booking), dot.dot(props.fetchBookingByIdAdditional.bookingByID));
      this.setState({
        // booking: dot.object(Object.assign({}, dot.dot(this.state.booking), dot.dot(props.fetchBookingByIdAdditional.bookingByID))),
        booking: flatBooking ? dot.object(flatBooking) : {},
        bookingAdditional: true
      });
    }

    if (JSON.stringify(props.bookingParticipants) !== JSON.stringify(this.state.bookingParticipants)) {
        this.setState({
            availability: this.availability(props.bookingParticipants),
            selectableSessions: this.selectableSessions(this.state.booking.session, props.bookingParticipants)
        });
        // this.setState({ availability: props.bookingParticipants.filter(booking_participant => booking_participant.status === 3 && (booking_participant.cancel === 0 || booking_participant.cancel === 3)) });
    }
  }

  availability(booking_participants) {
      if (!booking_participants) {
          return [];
      }
      return booking_participants.filter(booking_participant => booking_participant.status === 3 && booking_participant.session.start > moment().valueOf());
  }

  selectableSessions(sessions, booking_participants) {
      return sessions.filter(session => (
          session.start > moment().valueOf() &&
          !_.find(booking_participants, booking_participant => (
              booking_participant.session._id === session._id &&
              (
                  booking_participant.status !== 3 ||
                  (booking_participant.cancel !== 0 && booking_participant.cancel !== 3)
              )
          ))
      ));
  }

  render() {
    if (!this.state.bookingAdditional || this.state.loading) {
      return <LoadingOverlay />;
    }

    const summaryListItems = [];

    if (this.props.sessionDuration) {
      summaryListItems.push({
        key: 'sessionLength',
        primaryText: `${this.props.sessionDuration} session`,
        leftIcon: <AccessTimeIcon />
      });
    }
    const isMobileOnly = isMobileOnlyAskableLive(this.props.booking);
    switch (this.props.booking.type) {
        case 1:
            if (this.props.booking.config.session) {
              switch (this.props.booking.config.session.type) {
                case 1:
                  summaryListItems.push({
                    key: 'sessionType',
                    primaryText: 'Casual, one on one chat',
                    leftIcon: <PersonIcon />
                  });
                break;
                case 2:
                  summaryListItems.push({
                    key: 'sessionType-alt',
                    primaryText: 'Small focus group',
                    leftIcon: <GroupIcon />
                  });
                break;
                default:
              }
            }
        break;
        case 2:
            summaryListItems.push({
              key: 'sessionType',
              primaryText: `Remote / Video chat ${isMobileOnly ? ' (Mobile only)' : ''}`,
              leftIcon: isMobileOnly ? <PhoneIcon /> : <HeadsetIcon />
            });
        break;
        default:
    }

    if (this.props.booking.config.incentive) {
      switch (this.props.booking.config.incentive.type) {
        case 1:
          summaryListItems.push({
            key: 'paymentMethod',
            primaryText: 'Paid via PayPal or E-Gift Card',
            leftIcon: <MoneyIcon />
          });
        break;
        case 2:
          summaryListItems.push({
            key: 'paymentMethod-alt',
            primaryText: 'Paid via gift card / voucher',
            leftIcon: <GiftCardIcon />
          });
        break;
        default:
      }
    }


    const clientInfo = {};
    switch (this.props.booking.config.session.type) {
        case 1:
            clientInfo.sessionType = '1 on 1 chat';
        break;
        case 2:
            clientInfo.sessionType = 'focus group';
        break;
        default:
            clientInfo.sessionType = null;
    }

    clientInfo.location = null;
    if (this.props.booking.config.location) {
        if (this.props.booking.config.location.city) {
            clientInfo.location = this.props.booking.config.location.city;
        } else if (this.props.booking.config.location.state) {
            clientInfo.location = this.props.booking.config.location.state;
        }
        if (this.props.booking.config.location.state && clientInfo.location > '' && this.props.booking.config.location.state !== clientInfo.location) {
            clientInfo.location += `, ${this.props.booking.config.location.state}`;
        }
    }

    if (_.get(this.props.booking, 'config.in_context.location_type')) {
        if (this.props.inContextLocation) {
            switch (this.props.booking.config.in_context.location_type) {
                case 1:
                    clientInfo.location = `Your home in ${this.props.inContextLocation.city}`;
                break;
                case 2:
                    clientInfo.location = `Your workplace/business in ${this.props.inContextLocation.city}`;
                break;
                default:
                    clientInfo.location = `Your chosen location at ${this.props.inContextLocation.city}`;
            }
        } else {
            clientInfo.location = null;
        }
    }

    let availabilityBlock = null;
    if (this.state.availability) {
        if (this.state.availability.filter(booking_participant => booking_participant.cancel === 0).length === 0) {
            availabilityBlock = <h2 className="mtop40">Your available times have been cancelled</h2>;
        } else {
            availabilityBlock = (
                <div className="mtop40">
                    <h2>Your available times:</h2>
                    <ul className="session-list">
                        {_.uniqBy(
                            this.state.availability.filter(booking_participant => booking_participant.cancel === 0),
                            booking_participant => booking_participant.session && booking_participant.session.start
                        )
                            .map(booking_participant => (
                                <li key={booking_participant._id}>
                                    <span>{moment(booking_participant.session.start).format('dddd Do MMM')}</span>
                                    <strong>{moment(booking_participant.session.start).formatZoneFallback('h:mm A')}</strong>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            );
        }
    }

    let locationInfo = null;
    if (this.props.booking.type === 1) {
      const locationItems = [];
      if (_.get(this.props.booking, 'config.location.region')) {
        locationItems.push(this.props.booking.config.location.region);
      } else if (_.get(this.props.booking, 'config.location.city')) {
        locationItems.push(this.props.booking.config.location.city);
      } else if (_.get(this.props.booking, 'config.location.state')) {
        locationItems.push(this.props.booking.config.location.state);
      }

      let locationMap = null;
      if (_.get(this.props.booking, 'config.location.latitude') && _.get(this.props.booking, 'config.location.longitude')) {
        locationMap = (
          <div className="mtop30 mbottom20">
            <StaticMap
              latitude={this.props.booking.config.location.latitude}
              longitude={this.props.booking.config.location.longitude}
              height={300}
              maxWidth={580}
              openMap
              tracking={{
                _booking_id: this.props.booking._id,
                'Booking page type': 'In review'
              }}
            />
          </div>
        );
      };

      locationInfo = (
        <div className="mtop30">
          <h3>Location</h3>
          <p>The sessions will be held in {_.uniq(locationItems).join(', ')}</p>
          {locationMap}
          <p>Exact location information is provided if you get selected to attend.</p>
        </div>
      );
    }


    return (
      <div className="bookingPage bookingOverview bookingRegistered waitlist available">
          {
              (clientInfo.name || clientInfo.sessionType) ?
              <h1 className="pageTitle">
                  You’ve applied for:
                  <br />
                  <small>{clientInfo.name}{(clientInfo.name && clientInfo.sessionType ? ' - ' : '')}{clientInfo.sessionType}</small>
                  { clientInfo.location ? <small><br />{clientInfo.location}</small> : null }
              </h1> :
              <h1 className="pageTitle">
                  You’ve applied for this session:
                  { clientInfo.location ? <small><br />{clientInfo.location}</small> : null }
              </h1>
          }
          {this.props.booking.config.information && this.props.booking.config.information.brief_summary ? (
              <div>
                  <h3>Brief summary</h3>
                  <ReactMarkdown className="md-block">{this.props.booking.config.information.brief_summary}</ReactMarkdown>
              </div>
          ) : null}
          <div className="subtle-warning-box mleft-20 mright-20">
              <div className="mtop20 mleft20 mright20">
                <h3>Please note</h3>
                <p>You&rsquo;re not currently confirmed to attend this opportunity. If an opening comes up, you&rsquo;ll receive an invitation via SMS</p>
              </div>
              {_.get(this.props.booking, 'config.remote.askable_live') && !_.get(this.props.user, 'settings.remote.setup_check') && (
                <div className="mtop30 mleft20 mright20">
                  <h3>Laptop setup check</h3>
                  <p>If you are invited to this session you’ll need to have completed your setup check in order to accept.</p>
                  <p><a href="/live/setup-check" className="link" target="_blank">Start the laptop setup check now</a></p>
                </div>
              )}
          </div>
          <div className="waitlist-summary mtop40">
              <Card>
                  <CardChild type="text">
                      <span className="incentive"><strong>{dot.pick('config.incentive.currency_symbol', this.props.booking) || ''}{dot.pick('config.incentive.value', this.props.booking) || ''}</strong> incentive</span>
                  </CardChild>
                  <CardChild type="divider" />
                  <CardChild type="text" className="card-cols wrap">
                      <List
                        className="summary-list"
                        itemDefaultProps={{
                          disabled: true,
                          className: 'list-item'
                        }}
                        items={summaryListItems}
                      />
                      {isMobileOnly ? <MobileAppPreInviteCallout /> : null}
                  </CardChild>
              </Card>
          </div>

          {locationInfo}

          {availabilityBlock}
          {this.state.selectableSessions.length > 0 ? (
              <div>
                  <a
                    className="card-link"
                    href="#"
                    onClick={() => {
                        if (this.props.confirmAttendanceAnonymous) {
                            utils.window.location.href = `/login?loginDestination=/booking/${this.props.booking._id}`;
                            return;
                        }
                        ga.event({ category: 'Booking', action: 'Click Change availability', label: this.props.booking.id });
                        this.setState({ changeAttendance: true });
                    }}
                  >
                    {this.props.confirmAttendanceAnonymous ? 'Log in to change availability' : 'Change availability'}
                  </a>
              </div>
          ) : null}
          <Query
              query={gql`query ParticipantIsExcluded($_booking_id:ID) {
                bookingByID(id:$_booking_id) {
                  participant_is_excluded
                }
              }`}
              fetchPolicy="no-cache"
              variables={{
                  _booking_id: _.get(this.props, 'booking._id')
              }}
          >
          {({ loading, error, data }) => {
              if (loading) {
                  return null;
              }
              if (error) {
                  console.error(error);
                  return null;
              }
              if (_.get(data, 'bookingByID.participant_is_excluded')) {
                  // return `${this.props.bookingBaseUrl}/apply/error/previous-attendance`;
                  return (
                      <Dialog
                          customActions={[{
                              label: 'OK',
                              primary: true
                          }]}
                          title="Change availability"
                          open={this.state.changeAttendance || false}
                          modal={false}
                          onCancel={() => { this.setState({ changeAttendance: false }); }}
                      >
                        <p>Sorry you’re not currently able to change your availability for this opportunity due to your recent involvement in other sessions with this researcher.</p>
                      </Dialog>
                  );
              }
              return (
                  <Dialog
                      // customActions={[{
                      //     label: 'OK',
                      //     primary: true
                      // }]}
                      title="Change availability"
                      open={this.state.changeAttendance || false}
                      modal={false}
                      onCancel={() => { this.setState({ changeAttendance: false }); }}
                      onConfirm={() => {
                          this.setState({ loading: true });
                          // Cancel deseleted availability
                          const deseleted = _.filter(
                              this.state.availability,
                              booking_participant => !_.find(
                                  this.state.newAvailability,
                                  session_id => session_id === booking_participant.session._id
                              )
                          );
                          if (deseleted && deseleted.length > 0) {
                              _.forEach(deseleted, (booking_participant) => {
                                  this.props.participantCancelSession(booking_participant._id);
                              });
                          }
                          // Add selected availability
                          this.props.participantSaveAvailability(this.state.booking._id, this.state.newAvailability)
                              .then(() => {
                                  utils.window.location.reload();
                              })
                              .catch((error) => { console.error(error); });
                      }}
                  >
                      <BookingScheduleSessionSelectView
                        sessions={this.state.selectableSessions}
                        defaultChecked={this.state.availability.filter(booking_participant => booking_participant.cancel === 0).map(booking_participant => booking_participant.session._id)}
                        selectionChangeCallback={(sessions) => { this.setState({ newAvailability: sessions }); }}
                      />
                  </Dialog>
              );
          }}
          </Query>
      </div>
    );
  }
}

const BookingDetailsContainer = graphql(fetchBookingByIdAdditional, {
    name: 'fetchBookingByIdAdditional',
    options: props => ({
        variables: { id: props.booking._id },
    }),
});
const participantSaveAvailabilityContainer = graphql(participantSaveAvailability, {
    props: ({ mutate }) => ({
        participantSaveAvailability: (booking_id, session_ids) => mutate({
            variables: { booking_id, session_ids }
        }),
    }),
});
const participantCancelSessionContainer = graphql(participantCancelSession, {
    props: ({ mutate }) => ({
        participantCancelSession: (participant_session_id, cancel_reason) => mutate({
            variables: { participant_session_id, cancel_reason }
        }),
    }),
});

const completeSurveyContainer = graphql(completeSurvey, {
    props: ({ mutate, ownProps }) => ({
        completeSurvey: () => mutate({
            variables: { booking_id: ownProps.booking._id }
        }),
    }),
});

export default compose(
  BookingDetailsContainer,
  participantSaveAvailabilityContainer,
  participantCancelSessionContainer,
  completeSurveyContainer
)(BookingInReviewView);
