import * as React from 'react';
import { ErrorBoundary as REBoundary } from 'react-error-boundary';
import { datadogRum } from '@datadog/browser-rum';

import './error-boundary.scss';

function FallBack() {
  const ddContext = datadogRum.getInternalContext();

  return (
    <div className="boundary-container">
      <div>
        <h1 className="boundary-title">An error has occurred</h1>
        <p className="boundary-support">Signing out and back in may fix your issue. Otherwise contact support.</p>
        <div className="boundary-link-container">
          <a href="/logout" className="boundary-link">
            Sign out
          </a>
          <a href="https://askable.com/contact" className="boundary-link">
            Contact support
          </a>
        </div>
        {(ddContext?.view?.id || ddContext?.session_id) && (
          <span className="view-id">
            ID:
            {ddContext?.view?.id ?? ddContext?.session_id}
          </span>
        )}
      </div>
    </div>
  );
}

export function ErrorBoundary({ children }) {
  return <REBoundary fallback={<FallBack />}>{children}</REBoundary>;
}
