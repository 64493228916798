import React, { Component } from 'react';

import _ from 'lodash';

// import { utils } from 'lib/utils';
import { Select } from 'components/common';


class OccupationSelect extends Component {
    constructor() {
        super();
        this.state = {
            employment_type: 1,
            business: {}
        };

        this.options = {
            employment_type: {},
            'business.size': {},
            'business.turnover': {},
        };

        this.options.employment_type['1'] = 'No, I’m an employee';
        this.options.employment_type['2'] = 'Yes, I’m a sole-trader / freelancer';
        this.options.employment_type['3'] = 'Yes, I’m a business owner';

        this.options['business.size']['1'] = 'Myself only';
        this.options['business.size']['2'] = '2 - 10 employees';
        this.options['business.size']['3'] = '11 - 50 employees';
        this.options['business.size']['4'] = '51 - 200 employees';
        this.options['business.size']['5'] = '201 - 1,000 employees';
        this.options['business.size']['6'] = 'More than 1,000 employees';

        this.options['business.turnover']['1'] = 'Less than $100K';
        this.options['business.turnover']['2'] = '$100K - $500K';
        this.options['business.turnover']['3'] = '$500K - $2M';
        this.options['business.turnover']['4'] = '$2M - $5M';
        this.options['business.turnover']['5'] = '$5M - $25M';
        this.options['business.turnover']['6'] = '$25M+';

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.renderBusinessDetails = this.renderBusinessDetails.bind(this);
    }

    componentDidMount() {
        setTimeout(this.onChangeHandler, 50);
    }

    onChangeHandler() {
        if (this.props.onChangeHandler) {
            this.props.onChangeHandler(_.pick(this.state, ['employment_type', 'business']));
        }
    }

    renderBusinessDetails() {
        if (this.state.employment_type !== 3) {
            return null;
        }
        return (
            <div>
                <Select
                    value={_.get(this.state, 'business.size', '').toString()}
                    values={_.mapValues(this.options['business.size'], (label, value) => ({ label, value }))}
                    fullWidth
                    placeholder="Number of employees (optional)"
                    onChange={(value) => {
                        this.setState((state) => {
                            state.business.size = parseInt(value, 10);
                            this.onChangeHandler(state);
                            return state;
                        });
                    }}
                />
                <Select
                    value={_.get(this.state, 'business.turnover', '').toString()}
                    values={_.mapValues(this.options['business.turnover'], (label, value) => ({ label, value }))}
                    fullWidth
                    placeholder="Annual turnover (optional)"
                    onChange={(value) => {
                        this.setState((state) => {
                            state.business.turnover = parseInt(value, 10);
                            this.onChangeHandler(state);
                            return state;
                        });
                    }}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="mbottom40">
                <Select
                    value={this.state.employment_type.toString()}
                    values={_.mapValues(this.options.employment_type, (label, value) => ({ label, value }))}
                    fullWidth
                    onChange={(value) => {
                        this.setState((state) => {
                            state.employment_type = parseInt(value, 10);
                            this.onChangeHandler(state);
                            return state;
                        });
                    }}
                />
                {this.renderBusinessDetails()}
            </div>
        );
    }
}

export default OccupationSelect;
