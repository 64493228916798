import React, { Component } from 'react';
import _ from 'lodash';

class RemoteTrack extends Component {
    constructor() {
        super();
        this.containerRef = this.containerRef.bind(this);
        this.attachTrack = this.attachTrack.bind(this);
        this.resizeContainer = this.resizeContainer.bind(this);
        this.openFullscreen = this.openFullscreen.bind(this);
    }
    componentDidMount() {
        this.attachTrack();
    }
    componentWillUnmount() {
        if (this.attachTrackTimeout) {
            clearTimeout(this.attachTrackTimeout);
        }
    }
    containerRef(container) {
        this.container = container;
    }
    attachTrack() {
        if (!this.attachTrackAttempt(this.props.track)) {
            this.attachTrackTimeout = setTimeout(this.attachTrack, 20);
            return;
        }
        if (this.container && window.ResizeObserver) {
            const resizeObserver = new window.ResizeObserver(this.resizeContainer);
            resizeObserver.observe(this.container.parentNode);
        }
    }
    attachTrackAttempt(track) {
        // console.log('attachTrackAttempt', this.props.id, track);
        if (!this.props.id) return false;
        const container = document.getElementById(`MediaTrack_${this.props.id}`);
        if (!container) return false;
        if (!track.attach) return false;

        container.appendChild(track.attach());
        return true;
    }
    resizeContainer([entry]) {
        if (!this.container) return;
        if (!_.get(entry, 'contentRect.width') && !_.get(entry, 'contentRect.height')) return;
        if (!_.get(this.props.track, 'dimensions.width') && !_.get(this.props.track, 'dimensions.height')) return;
        const containerRatio = entry.contentRect.width / entry.contentRect.height;
        const contentRatio = this.props.track.dimensions.width / this.props.track.dimensions.height;
        const styles = { width: `${Math.ceil(entry.contentRect.width)}px`, height: `${Math.ceil(entry.contentRect.height)}px` };
        if (containerRatio > contentRatio) {
            styles.width = `${(entry.contentRect.width * (contentRatio / containerRatio)).toFixed(1)}px`;
        } else {
            styles.height = `${(entry.contentRect.height * (containerRatio / contentRatio)).toFixed(1)}px`;
        }
        for (const k in styles) {
            this.container.style.setProperty(k, styles[k]);
        }
    }
    openFullscreen() {
        if (!this.container) return;
        const elem = this.container.querySelector('video');
        if (!elem) return;

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }
    render() {
        if (!this.props.track) return null;

        const trackActions = [];
        if (this.props.fullscreen) {
            trackActions.push((
                <a role="button" onClick={this.openFullscreen} key="fullscreen">
                    <span className="icon">
                    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2H7V4H4V7H2V2ZM11 2H16V7H14V4H11V2ZM14 11H16V16H11V14H14V11ZM7 14V16H2V11H4V14H7Z" fill="white" />
                    </svg>
                    </span>
                    Full screen
                </a>
            ));
        }

        return (
            <div className="track-container" ref={this.containerRef}>
                <div className="track-contents" id={`MediaTrack_${this.props.id}`} />
                {trackActions.length > 0 && (
                    <div className="settings track-actions">
                        {trackActions}
                    </div>
                )}
            </div>
        );
    }
}

export default RemoteTrack;
