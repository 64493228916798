import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { utils } from 'lib/utils';
// import { localStorage } from 'lib/storage';
import { ONLINE_TASK_REQUIRED_DEVICE } from 'lib/data/constants';

import { Button, LoadingOverlay } from 'components/common';

import sendMyselfBookingLink from 'data/mutations/sendMyselfBookingLink';

export default (props) => {
    const {
        onContinue,
        booking,
        user,
        history
    } = props;


    const emailLinkButton = (
        <Mutation
            mutation={sendMyselfBookingLink}
        >
        {(sendEmail, { loading }) => (
            <Button
                label="Email me the link"
                labelColor="#fff"
                bgColor="#FF5266"
                type="button"
                className="_widthAuto"
                disabled={!!loading}
                onClick={() => {
                    sendEmail({
                        variables: { booking_id: booking._id, email: true }
                    })
                    .then(() => {
                        history.push(`/booking/${booking._id}/apply/message/task-sent-email`);
                    });
                }}
            />
        )}
        </Mutation>
    );

    return (
        <Query
            query={gql`query userDevice { userDevice { type } }`}
        >
        {({ loading, data }) => {
            if (loading) return <LoadingOverlay />;

            const deviceType = _.get(data, 'userDevice.type');

            let failedRequirement = null;
            for (const requirement of _.get(booking, 'config.online_task.required_device', [])) {
                switch (requirement) {
                    case ONLINE_TASK_REQUIRED_DEVICE.DESKTOP:
                        if (deviceType === 'desktop') {
                            onContinue();
                            return null;
                        }
                        failedRequirement = requirement;
                    break;
                    case ONLINE_TASK_REQUIRED_DEVICE.MOBILE:
                        if (deviceType === 'smartphone' || deviceType === 'tablet') {
                            onContinue();
                            return null;
                        }
                        failedRequirement = requirement;
                    break;
                    default:
                        console.warn(`Unhandled device type requirement: ${requirement}`);
                }
            }


            switch (failedRequirement) {
                case ONLINE_TASK_REQUIRED_DEVICE.DESKTOP:
                    return (
                        <Fragment>
                            <div className="mtop30 mbottom20 textCenter"><img src="/assets/quant-icon-desktop.svg" alt="Laptop icon" height="48" /></div>
                            <h2 className="textCenter">In order to do this task, you will need to be on your&nbsp;laptop</h2>
                            <div className="mtop30 mbottom40">{emailLinkButton}</div>
                            <p className="fontLarge">We’ll send you an email to <u>{user.email}</u> to continue the task on your&nbsp;laptop</p>
                            <p className="fontSmall">
                                Has your email address changed?&nbsp;
                                <a
                                    className="link"
                                    href="#"
                                    onClick={() => { utils.setMenuState('menu=profile'); }}
                                >
                                    Update it by clicking here.
                                </a>
                            </p>
                        </Fragment>
                    );
                case ONLINE_TASK_REQUIRED_DEVICE.MOBILE:
                    return (
                        <Fragment>
                            <div className="mtop30 mbottom20 textCenter"><img src="/assets/quant-icon-mobile.svg" alt="Mobile phone icon" height="48" /></div>
                            <h2 className="textCenter">In order to do this task, you will need to be on a mobile&nbsp;device</h2>
                            <div className="mtop30 mbottom40">{emailLinkButton}</div>
                            <p className="fontLarge">We’ll send you an email to <u>{user.email}</u> to continue the task on mobile</p>
                            <p className="fontSmall">
                                Has your email address changed?&nbsp;
                                <a
                                    className="link"
                                    href="#"
                                    onClick={() => { utils.setMenuState('menu=profile'); }}
                                >
                                    Update it by clicking here.
                                </a>
                            </p>
                        </Fragment>
                    );
                default:
                    return null;
            }
        }}
        </Query>
    );
};
