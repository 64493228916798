import gql from 'graphql-tag';

export default gql`
    query FetchDocumentById($_id: ID!) {
      documentById(_id: $_id) {
          _id
          name
          type
          issue_date
          content
          url
      }
    }
`;
