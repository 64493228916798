import React from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';

import BYOLanding from './byoLandingView';

export default (props) => {
    switch (_.get(props, 'match.params.page')) {
        case 'researcher-share':
            return <BYOLanding {...props} />;
        default:
            // return <pre>{JSON.stringify(props, undefined, 2)}</pre>;
            return <Redirect to={`/booking/${_.get(props, 'match.params.id')}`} />;
    }
};
