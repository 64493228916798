import React from 'react';

export function WhatToExpect() {
  return (
    <>
      <p className="quantPageSubtitle">What to expect</p>
      <ul>
        <li>You’ll be interviewed by an AI researcher</li>
        <li>Make sure you’re somewhere quiet</li>
        <li>You’ll need to provide access to your camera and microphone</li>
        <li>The interview will be recorded. Your face must be visible</li>
      </ul>
    </>
  );
}
