import gql from 'graphql-tag';
import { bookingSubmissionFieldsFragment } from 'data/fragments/bookingSubmissionFields';

export default gql`
  query FindBookingSubmission($userId: ID, $bookingId: ID) {
    findBookingSubmission(userId: $userId, bookingId: $bookingId) {
      ...bookingSubmissionFields
    }
  }
  ${bookingSubmissionFieldsFragment}
`;
