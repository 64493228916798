import { useEffect, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

import { useHubble } from './hooks/useHubble';

export function IdentifyUser() {
  const { user, isAuthenticated, getClaim } = useKindeAuth();
  const [hasIdentified, setHasIdentified] = useState(false);
  const hubble = useHubble();
  const askableUserId = getClaim?.('user_properties')?.value?.askable_user_id?.v || user?.id;

  const identifyUser = () => {
    if (import.meta.env.VITE_ENVIRONMENT !== 'production' || !isAuthenticated || hasIdentified) {
      return;
    }

    datadogRum.setUser({
      id: askableUserId,
    });

    hubble.identify(user.id, { email: user.email });

    setHasIdentified(true);
  };

  useEffect(() => {
    identifyUser();
  }, [user]);

  return null;
}
