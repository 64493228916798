import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import _ from 'lodash';

import { utils } from 'lib/utils';

import uuidv5 from 'uuid/v5';

class LocationAutocompleteQuery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: { input: '', sessionToken: this.generateUUID(props.token || Date.now()), ...this.getSearchFromProps(props) },
        };

        this.searchDebounce = 400;

        this.setSearchFromProps = this.setSearchFromProps.bind(this);
    }

    componentDidMount() {
        this.setSearchFromProps(this.props);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.token !== this.props.token) {
            this.setSearchFromProps({ sessionToken: this.generateUUID(newProps.token) });
        }
        if (this.searchTimeout) {
            return;
        }
        this.searchTimeout = setTimeout(() => {
            this.setSearchFromProps(this.props);
            clearTimeout(this.searchTimeout);
            this.searchTimeout = null;
        }, this.searchDebounce);
    }

    getSearchFromProps(props) {
        const search = {};
        if (props.input) {
            search.input = props.input;
        }
        if (props.types) {
            search.types = props.types;
        }
        if (_.get(props, 'location.country')) {
            search.country = props.location.country;
        }
        if (_.get(props, 'location.latitude')) {
            search.latitude = props.location.latitude;
        }
        if (_.get(props, 'location.longitude')) {
            search.longitude = props.location.longitude;
        }
        if (props.searchRadius !== undefined) {
            search.searchRadius = props.searchRadius;
        }
        if (props.searchRadiusStrict) {
            search.searchRadiusStrict = true;
        }
        return search;
    }

    setSearchFromProps(props) {
        this.setState((state) => {
            Object.assign(state.search, this.getSearchFromProps(props));
            return state;
        });
    }

    generateUUID(token) {
        const namespace = uuidv5('https://my.askable.com', uuidv5.URL);
        return uuidv5(`LocationAutocompleteQuery|${utils.getUserId() || 'anon'}|${token}`, namespace);
    }

    render() {
        return (
            <Query
                query={gql`query LocationAutocomplete ($search:LocationAutoCompleteSearch!) {
                  locationAutocomplete (search:$search) {
                    description placeId descriptionMatches { text match } sessionToken
                  }
                }`}
                variables={{ search: { ...this.state.search } }}
            >
            {this.props.children}
            </Query>
        );
    }
}

export default LocationAutocompleteQuery;
