import ReactGA from 'react-ga';
import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';

// Google Analytics
const ga = {
    init() {
        // Function to initialize the google analytics with the right gaTrackingID
        ReactGA.initialize('UA-103680445-5', {
          titleCase: false
        });
    },
    pageview() {
        const page = utils.window.location.pathname + utils.window.location.search;
        if (page === utils.window.gaPage) {
            return false;
        }
        utils.window.gaPage = page;
        utils.window.gaRouteEvents = {};
        // console.log('GA pageview');
        ReactGA.set({ userId: localStorage.get('connectedParticipant') || null });
        ReactGA.pageview(page); // eslint-disable-line
    },
    event(param, routeLimit = null) {
        if (routeLimit !== null) {
            if (utils.window.gaRouteEvents && (utils.window.gaRouteEvents[param.action] >= routeLimit)) {
                return false;
            }
            if (!utils.window.gaRouteEvents[param.action]) {
                utils.window.gaRouteEvents[param.action] = 0;
            }
            utils.window.gaRouteEvents[param.action] += 1;
        }
        // console.log('GA Event', param);
        ReactGA.set({ userId: localStorage.get('connectedParticipant') || null });
        ReactGA.event({
            category: param.category,
            action: param.action,
            label: param.label,
            value: param.value
        });
    }
};


const reddit = {
    init: () => {
        !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
        window.rdt('init', 't2_zfuimt7');
        // window.rdt('track', 'PageVisit');
    },
    track: (event, wait) => new Promise((resolve) => {
        if (!window.rdt) {
            reddit.init();
        }
        window.rdt('track', event);

        if (wait) {
            setTimeout(resolve, wait);
        } else {
            resolve();
        }
    })
};

export { ga, reddit };
