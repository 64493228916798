import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import Beforeunload from 'react-beforeunload';
import _ from 'lodash';
import moment from 'lib/moment';

import { utils } from 'lib/utils';
import { localStorage } from 'lib/storage';

import fetchUserById from 'data/queries/user/fetchUserById';
import { withKinde } from '../../AuthWrapper';
import { WrappedKindePropertyWrapper } from '../../KindeProperties';
import { LoadingOverlay } from '../common';

class App extends Component {
  constructor(props) {
    super(props);
    this.navListener = this.navListener.bind(this);
  }

  componentDidMount() {
    utils.parentMessageListen(this.navListener, 'admin_iframe', window);
  }

  navListener(message) {
    if (!message) return;
    if (message.logout) {
      localStorage.clear();
    } else if (message.login) {
      localStorage.clear();
      localStorage.save('connectedParticipant', message.login._id);
      localStorage.save('participant_access_token', message.login.access_token);
    }
    if (message.path) {
      this.props.history.push(message.path);
    }
    if (message.getPath) {
      utils.parentPostMessage({ participantAppPath: window.location.href });
    }
  }

  render() {
    const { props } = this;
    if (props.userByID && props.userByID.userByID && !(props.userByID.userByID.status === null || props.userByID.userByID.status === 1)) {
      localStorage.clear();
      localStorage.clear('session');
      // if (utils.window.FS) {
      //     utils.window.FS.identify(false);
      // }
      utils.window.location.href = '/error/account-disabled';
      return null;
    }

    return (
      <>
        {this?.props?.userByID?.loading ? (
          <LoadingOverlay />
        ) : (
          <WrappedKindePropertyWrapper token={this.props.token}>
            <div className="appRoot">
              <Beforeunload
                onBeforeunload={e => {
                  const leaveWarning = utils.leaveWarningGet();
                  if (leaveWarning) {
                    e.preventDefault();
                    return leaveWarning;
                  }
                  return null;
                }}
              />
              {props.children}
            </div>
          </WrappedKindePropertyWrapper>
        )}
      </>
    );
  }
}

const FetchUserByIdContainer = graphql(fetchUserById, {
  name: 'userByID',
  skip: props => {
    return !props.isAuthenticated();
  },
  options: () => ({
    onCompleted: ({ userByID }) => {
      if (_.get(userByID, 'location.timezone')) {
        moment.tz.setDefault(_.get(userByID, 'location.timezone'));
      }

      localStorage.save('connectedParticipant', userByID._id);
    },
  }),
});

export default withKinde(compose(FetchUserByIdContainer)(App));
