import React from 'react';

export default function (props) {
    return (
        <span
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            margin: 0,
            overflow: 'hidden',
          }}
          className="iconOffset"
        >
          {props.children}
        </span>
    );
}
