import gql from 'graphql-tag';

export const bookingSearchFragment = gql`
  fragment bookingSearchFields on Booking {
    _id
    name
    status
    type
    config {
      location {
        city
        postal_code
        state
        country
      }
      incentive {
        currency_code
        currency_symbol
        country_code
        value
      }
      session {
        duration
        type
      }
      criteria {
        meta_identity_birthday_year {
          field
          value
          operator
        }
        meta_identity_gender {
          field
          value
          operator
        }
        meta_family_status {
          field
          value
          operator
        }
        meta_education {
          field
          value
          operator
        }
        meta_work_status {
          field
          value
          operator
        }
        custom {
          _question_id
          value
          operator
        }
      }
      online_task {
        tool
        type
      }
      longitudinal_study {
        participant_workload {
          time
          measure
          frequency
        }
        period {
          time
          frequency
        }
      }

      information {
        short_description
      }
    }
    session {
      _id
      start
      end
      status
    }
  }
`;
