import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { localStorage } from 'lib/storage';
import { withKinde } from '../../AuthWrapper';

const CheckUserAgent = (props) => {
    if (!props?.isAuthenticated?.()) return null;
    if (localStorage.get('useragent', 'session')) return null;
    return (
        <Query
            query={gql`query userAgent { userAgent { device { type } browser { name } } }`}
            onCompleted={({ userAgent }) => {
                localStorage.save('useragent', JSON.stringify(userAgent), 'session');
            }}
        >
        {() => null}
        </Query>
    );
};

export default withKinde(CheckUserAgent);
