import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { graphql, compose, Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
// import { Link } from 'react-router-dom';
// import dotObject from 'dot-object';

// import moment from 'lib/moment';

import participantReconfirmSession from 'data/mutations/participantReconfirmSession';
import participantCancelSession from 'data/mutations/participantCancelSession';

import { Dialog, Input, Button, LoadingOverlay } from 'components/common';

import BookingScheduleSessionSelectView from './schedule/bookingScheduleSessionSelectView';

// dotObject.override = true;

/* ---

// props minimum requirements:

{
  user_confirm: <timestamp>,
  session: {
    start: <timestamp>
  }
  booking: {
    config: {
      location: { ... }
    }
  }
  booking_participant: <ObjectId>,
  updateAttendance: <mutator>
}

--- */

class ChangeAttendance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changeAttendanceReason: '',
      changeAttendanceDialog: 0,
    };

    this.renderButtons = this.renderButtons.bind(this);
    this.renderContents = this.renderContents.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.open && !this.props.open) {
      this.setState({ changeAttendanceDialog: 1, page: null });
    }
  }

  renderButtons({ confirm = {}, back = {} }) {
    return (
      <div className="flex flexCenter flexColumn mtop20">
        <Button
            type="button"
            labelColor="#fff"
            bgColor="#FF5266"
            className="widthAuto mbottom20"
            disabled={!this.state.changeAttendanceReason.replace(/\s+/, '')}
            buttonStyle={{ minWidth: '220px', whiteSpace: 'nowrap' }}
            {...(confirm.props || {})}
            onClick={confirm.onClick}
            label={confirm.label || 'Confirm'}
        />
        <button
          type="button"
          {...(back.props || {})}
          onClick={() => {
            if (back.onClick) back.onClick();
            if (this.state.reloadOnClose) {
              this.setState({ loading: true });
              window.location.reload();
            } else {
              this.setState({ changeAttendanceDialog: 0 });
              this.props.onClose();
            }
          }}
        >
          <strong>{back.label || 'Back'}</strong>
        </button>
      </div>
    );
  }

  renderContents() {
    const loadingBlock = (
      <div
        style={{
          position: 'relative',
          height: '240px'
        }}
      >
        <LoadingOverlay />
      </div>
    );
    if (this.state.loading) {
      return loadingBlock;
    }
    switch (this.state.page) {
      case 'reshedule-prompt':
        return (
          <Fragment>
            <p className="mbottom40">Please note that if you do reschedule, you’ll have to wait to be re-invited by the researcher. We can’t guarantee that the researcher will be able to accomodate the new time.</p>
            {this.renderButtons({
              confirm: {
                label: 'Reschedule',
                onClick: () => {
                  // mixpanel.track({
                  //   event: 'Click reschedule prompt button',
                  //   payload: {
                  //     _booking_id: this.props.booking._id,
                  //   }
                  // });
                  this.setState({ page: 'reshedule-sessions' });
                },
              },
              back: { label: 'Dont’t reschedule' }
            })}
          </Fragment>
        );
      case 'reshedule-sessions':
        return (
          <Query
            query={gql`query checkEmptySessions ($ids:[ID]!) {
              bookingSessionsByID(ids:$ids) {
                _id start end
                UserStatusCount { completed confirmed scheduled in_progress }
              }
            }`}
            variables={{
              ids: _.chain(this.props)
                .get('booking.session', [])
                .filter(session => session.start > Date.now() + (1000 * 60 * 60))
                .map('_id')
                .value()
            }}
          >
          {({ loading, data, error }) => {
            if (loading) return loadingBlock;
            const sessions = _.chain(data)
              .get('bookingSessionsByID', [])
              .filter((session) => {
                const participants = _.chain(session.UserStatusCount)
                  .values()
                  .reduce((sum, v) => sum + (v && typeof v === 'number' ? v : 0), 0)
                  .value();
                return participants === 0;
              })
              .value();
            return (
              <Fragment>
                <BookingScheduleSessionSelectView
                  sessions={sessions}
                  selectionChangeCallback={(availability) => { this.setState({ newAvailability: availability }); }}
                />
                <Mutation
                  mutation={gql`mutation participantRescheduleAvailability($booking:ID!,$sessions:[ID]!) {
                      participantRescheduleAvailability(booking_id: $booking, available_sessions: $sessions) {
                        _id
                      }
                    }`}
                >
                {rescheduleRequest => this.renderButtons({
                    confirm: {
                      label: 'Reschedule',
                      onClick: () => {
                        this.setState({ loading: true });
                        // mixpanel.track({
                        //   event: 'Click reschedule confirm button',
                        //   payload: {
                        //     _booking_id: this.props.booking._id,
                        //   }
                        // });
                        rescheduleRequest({ variables: { booking: this.props.booking._id, sessions: this.state.newAvailability } })
                          .then(() => {
                            window.location.reload();
                          });
                      },
                      props: {
                        disabled: _.get(this.state, 'newAvailability', []).length === 0
                      }
                    },
                    back: { label: 'Dont’t reschedule' }
                  })
                }
                </Mutation>
              </Fragment>
            );
          }}
          </Query>
        );
      default:
        return (
          <Fragment>
            <p>We understand sometimes life happens, but please be considerate when cancelling your attendance. Try and give at least 24 hours notice so we have time to find a replacement.</p>
            <Input
                value={this.state.changeAttendanceReason}
                onChange={(value) => { this.setState({ changeAttendanceReason: value }); }}
                multiLine
                rowsMax="8"
                hintText="Please provide a reason for your cancellation"
            />
            {this.renderButtons({
              confirm: {
                label: 'Cancel attendance',
                onClick: () => {
                  this.setState({ loading: true, reloadOnClose: true });
                  // mixpanel.track({
                  //   event: 'Click cancel attendance button',
                  //   payload: {
                  //     _booking_id: this.props.booking._id,
                  //   }
                  // });
                  this.props.participantCancelSession(this.props.bookedSession._id, this.state.changeAttendanceReason)
                      .then(() => {
                        this.setState({ page: 'reshedule-prompt', loading: false });
                      })
                      .catch((error) => {
                          console.error(error);
                      });
                },
              },
              back: { label: 'Dont’t cancel' }
            })}
          </Fragment>
        );
    }
  }

  render() {
    let title = null;
    switch (this.state.page) {
      case 'reshedule-prompt':
        title = 'Reschedule to another time?';
      break;
      case 'reshedule-sessions':
        title = 'What times can you reschedule to?';
      break;
      default:
        title = 'Cancel your attendance?';
    }
    return (
      <Dialog
        open={!!this.state.changeAttendanceDialog}
        className="confirmAttendanceDialog"
        modal={false}
        title={title}
        customActions={[]}
      >
        {this.renderContents()}
      </Dialog>
    );
  }
}

const participantReconfirmSessionContainer = graphql(participantReconfirmSession, {
    props: ({ mutate }) => ({
        participantReconfirmSession: participant_session_id => mutate({
            variables: { participant_session_id }
        }),
    }),
});
const participantCancelSessionContainer = graphql(participantCancelSession, {
    props: ({ mutate }) => ({
        participantCancelSession: (participant_session_id, cancel_reason) => mutate({
            variables: { participant_session_id, cancel_reason }
        }),
    }),
});

export default compose(
  participantReconfirmSessionContainer,
  participantCancelSessionContainer
)(ChangeAttendance);
