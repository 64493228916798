import { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { localStorage } from 'lib/storage';
// import moment from 'lib/moment';
import decode from 'jwt-decode';


import updateMe from 'data/mutations/updateMe';

class TestCredentials extends Component {
    constructor(props) {
        super(props);
        const token = decode(localStorage.get('participant_access_token'));
        if (!(token && token.roles.indexOf('participant') >= 0)) {
            localStorage.delete('participant_access_token');
        }
        props.updateUser({ testCredentials: undefined })
            .then(() => {
                localStorage.delete('loginFollowup');
                window.location.reload();
            })
            .catch((error) => {
                localStorage.clear();
                localStorage.clear('session');
                console.error('TestCredentials fail:', error);
                alert('Sorry, there was a problem with your session. Please log in again.');
                window.location.href = '/login';
            });
    }
    render() {
        return null;
    }
}

const updateMeContainer = graphql(updateMe, {
    props: ({ mutate }) => ({
        updateUser: user => mutate({
            variables: { user }
        }),
    }),
});

export default compose(updateMeContainer)(TestCredentials);
