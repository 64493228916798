import gql from 'graphql-tag';

export default gql`query ParticipantSessionsByBooking($_booking_id: ID, $_user_id: ID) {
  participantSessionsByBooking(_booking_id: $_booking_id, _user_id: $_user_id) {
    _id
    status
    user_confirm
    cancel
    cancel_reason
    completed
    session {
      _id
      start
      end
      slot_max
      MeetingLink
    }
    history {
      last_quantitative_application_time
      quantitative_help_request_followup
    }
    transaction {
      _id
      payment_due
      suspended
      transactions {
        _id created
        provider { name reference url }
      }
    }
    _online_task_link_id
    OnlineTaskLink { _id url }
    help_requested
  }
}`;
