import React, { Component } from 'react';

class DrawerContents extends Component {
  constructor(props) {
      super(props);

      this.state = {
        active: props.active
      };

      this.timeoutActive = null;
  }

  componentDidMount() {
      this._ismounted = true;
  }

  componentWillReceiveProps(newProps) {
      if (this._ismounted) {
          if (newProps.active) {
            clearTimeout(this.timeoutActive);
            this.setState({ active: true });
          } else {
            clearTimeout(this.timeoutActive);
            this.timeoutActive = setTimeout(() => {
                if (this._ismounted) {
                    this.setState({ active: false });
                }
            }, 1000);
          }
      }
  }

  componentWillUnmount() {
      this._ismounted = false;
  }

  render() {
    if (this.state.active) {
      return (
        <div className="drawerContents active">
          {this.props.children}
        </div>
      );
    }
    return (
      <div className="drawerContents inactive" />
    );
  }
}

export default DrawerContents;
