import React, { Component, Fragment } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Checkbox from 'material-ui/Checkbox';

import { Button, LoadingOverlay } from 'components/common';

import './styles/restrictedUserOptIn.scss';

/* eslint-disable jsx-a11y/label-has-for */

class RestrictedUserOptIn extends Component {
    constructor() {
        super();
        this.state = {
            // checkboxes: []
            check_optin: false,
            check_terms: false,
        };
    }

    render() {
        return (
            <Mutation
                mutation={gql`mutation participantOptIn { participantOptIn { _id } }`}
                refetchQueries={['FetchParticipantByID']}
                awaitRefetchQueries
            >
            {(participantOptIn, { loading, called }) => {
                if (loading || called) {
                    return <LoadingOverlay />;
                }
                return (
                    <div className="RestrictedUserOptIn">
                        <h1>You may be eligible for more paid opportunities!</h1>
                        <p>Would you like to opt in to see more opportunities from different researchers?</p>
                        <div className="p10 mtop40 mbottom40">
                            <div className="custom-checkbox-row">
                                <Checkbox
                                    className="checkbox-switch"
                                    id="checkbox_check_optin"
                                    checked={this.state.check_optin}
                                    onCheck={(event, isInputChecked) => { this.setState({ check_optin: isInputChecked }); }}
                                />
                                <div className="label">
                                    <label htmlFor="checkbox_check_optin">Yes, show me the other paid opportunities</label>
                                </div>
                            </div>
                            <div className="custom-checkbox-row">
                                <Checkbox
                                    className="checkbox-switch"
                                    id="checkbox_check_terms"
                                    checked={this.state.check_terms}
                                    onCheck={(event, isInputChecked) => { this.setState({ check_terms: isInputChecked }); }}
                                />
                                <div className="label">
                                    <label htmlFor="checkbox_check_terms">
                                        <span>I’ve read and agree to the </span>
                                        <a
                                            className="link"
                                            href="https://www.askable.com/legal?tab=terms"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={(event) => {
                                                // event.preventDefault();
                                                event.stopPropagation();
                                            }}
                                        >
                                            terms &amp; conditions
                                        </a>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Button
                            label="See Other Opportunities"
                            labelColor="#fff"
                            bgColor="#FF5266"
                            type="button"
                            disabled={!(this.state.check_optin && this.state.check_terms)}
                            onClick={participantOptIn}
                        />
                    </div>
                );
            }}
            </Mutation>
        );
    }
}

export default RestrictedUserOptIn;
