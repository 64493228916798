import React, { Component, Fragment } from 'react';
import { compose, graphql, Query } from 'react-apollo';
import gql from 'graphql-tag';

// import moment from 'lib/moment';
import _ from 'lodash';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import SearchIcon from 'material-ui/svg-icons/action/search';
import Spinner from 'react-spinkit';

import { utils } from 'lib/utils';
import { Input, LocationAutocompleteQuery, CountryCodeSelect } from 'components/common';

import countryData from 'lib/data/phoneCountryCodes';

import { withMonocle } from '../../../MonocleWrapper';
import { localStorage } from '../../../lib/storage';
import verifyAndSetCountry from '../../../data/mutations/verifyAndSetCountry';
import fetchUserById from '../../../data/queries/user/fetchUserById';
import ErrorIcon from 'material-ui/svg-icons/alert/error';
import { ListItem } from 'material-ui/List';
import { HELP_URL } from '../../../lib/data/constants';

// import './styles/SelectStyles.scss';

const spinner = <div className="mtop10 mbottom10 flex flexCenter"><Spinner fadeIn="none" name="three-bounce" color="#FF5266" /></div>;

class LocationAutocomplete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: '',
            country: {},
            settingCountry: false,
            verifying: false,
        };

        this.renderLocationQuery = this.renderLocationQuery.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    componentDidMount() {
      if (this.props.userLocation?.country) {
        console.log('Setting country')
        const country = _.find(countryData, ['region', this.props.userLocation.country]);
        this.setState({ country });
      }
    }

  parseLocationResult(location) {
    if (!location) return null;
    let parsed = utils.stripGqlTypename(location);
    if (!parsed) return null;

    parsed = _.pick(parsed, [
      'name',
      'postcode',
      'city',
      'state',
      'country',
      'street1',
      'street2',
      'latitude',
      'longitude',
      'level',
      'limit',
      'timezone',
      'postal_code',
      'region',
      'google_location',
    ]);

    if (parsed.google_location) {
      parsed.google_location = _.pick(parsed.google_location, [
        'place_id', 'name', 'address_components', 'geometry'
      ]);
      if (Object.keys(parsed.google_location).length === 0) {
        delete parsed.google_location;
      }
    }

    return parsed;
  }

    renderLocationQuery(input = '') {
      if (input.replace(/\s/g, '') === '') {
          return null;
      }
      return (
        <LocationAutocompleteQuery
          input={input}
          location={{
            country: _.get(this.state, 'country.region'),
          }}
          queriedPlace={this.state.queriedPlace}
          token={this.state.autocompleteToken}
          types={this.props.types}
        >
          {({ data, networkStatus }) => {
            if (!(data.locationAutocomplete && data.locationAutocomplete.filter(location => !!location).length > 0)) {
              if (input === '') {
                return null;
              }
              if (networkStatus === 7) {
                return <p>No results for “{input}”</p>;
              }
              return spinner;
            }
            // if (networkStatus !== 7) {
            //     return spinner;
            // }

            return (
              <Fragment>
                <RadioButtonGroup
                  type="radio"
                  name="inContextAdressSearchResults"
                  onChange={(event) => {
                    const { value } = event.target;
                    this.setState({ selectedPlace: _.find(data.locationAutocomplete, result => result.placeId === value) });
                  }}
                  valueSelected={_.get(this.state, 'selectedPlace.placeId')}
                >
                  {data.locationAutocomplete
                    .map(location => (
                      <RadioButton
                        key={location.placeId}
                        value={location.placeId}
                        label={location.descriptionMatches.map(({ text, match }, i) => (match ? <strong key={i}>{text}</strong> : <span key={i}>{text}</span>))}
                        // label={JSON.stringify(_.pick(location, ['description', 'sessionToken']))}
                        className="switchOption"
                        // checked={location.placeId === this.state.selectedPlace}
                      />
                    ))
                  }
                </RadioButtonGroup>
                {networkStatus !== 7 && spinner}
              </Fragment>
            );
          }}
        </LocationAutocompleteQuery>

      );
  }

  async setLocation(turnstileToken) {
      this.setState({ settingCountry: true });
      const monocleBundle = await this.props.getMonocleBundle();
      try {
        const country_data = await this.props.verifyAndSetCountry(turnstileToken, monocleBundle);
        const countryCode = country_data?.data?.verifyAndSetCountry?.country;
        if (countryCode) {
          const country = _.find(countryData, ['region', countryCode]);
          this.setState({ country, settingCountry: false });
        } else {
          this.setState({ settingCountryError: 'There was an unknown error getting your country please reload and try again.', settingCountry: false });
        }
      } catch (e) {
        console.error(JSON.stringify(e));
        if (e.graphQLErrors?.[0]) {
          this.setState({ settingCountryError: e.graphQLErrors?.[0].message, settingCountry: false });
        } else {
          this.setState({ settingCountryError: 'There was an unknown error getting your country please reload and try again.', settingCountry: false });
        }
      }
  }

  verify() {
    this.setState({ verifying: true });
    if(!window.turnstile || !import.meta.env.VITE_TURNSTILE_SITE_KEY) {
      console.error('Turnstile not loaded or site key not set');
      return setLocation(null);
    }

    window.turnstile.render('#tstl-container', {
      sitekey: import.meta.env.VITE_TURNSTILE_SITE_KEY,
      action: 'set_country',
      callback: (token) => {
        this.setLocation(token);
      },
    });
  }

  renderSetCountry() {
      return (
        <div className="LocationAutocomplete confirm">
          <h2>Set your location</h2>
          <p>Help us find the right opportunities for you by setting your location.</p>
          { !this.state.settingCountry && <div style={{ textAlign: 'center' }} ref={() => { if (!this.state.verifying) this.verify(); }} id="tstl-container" /> }
          {this.state.settingCountry && <Spinner style={{ textAlign: 'center' }} />}
          { this.state.settingCountryError && (
            <ListItem primaryText={ this.state.settingCountryError } disabled className="referral-link error" rightIcon={<ErrorIcon />}/>
          )}
        </div>
      );
  }

  renderForm() {
    if (_.get(this.state, 'country.region') === undefined) {
      return (<Input
        name="locationAutocomplete"
        type="text"
        autoComplete="off"
        value=""
          />);
      }
      return (
        <Fragment>
            <Input
                type="text"
                placeholder={this.props.placeholder || 'Search...'}
                value={this.state.search}
                onChange={(value) => {
                    this.setState({ search: value, selectedPlace: null });
                }}
                autoComplete="off"
                rightIcon={<SearchIcon />}
            />
            <div className="mtop20 mbottom20 LocationAutocompleteSearchResults">
                {this.renderLocationQuery(this.state.search)}
            </div>
        </Fragment>
      );
    }

    render() {
      if (this.state.country?.region === undefined) {
        return this.renderSetCountry();
      }
      if (this.state.selectedPlace) {
        return (
          <Query
            query={gql`query getLocationFromGooglePlaceId ($placeId:String, $sessionToken:String) {
              getLocationFromGooglePlaceId (placeId:$placeId, sessionToken:$sessionToken) {
                  formatted_address name street1 street2 city state
                  country latitude longitude level postal_code timezone google_location_types
                  google_location {
                      place_id name
                      address_components { short_name long_name types }
                      geometry { latitude_ne longitude_ne latitude_sw longitude_sw width height radius_min radius_max }
                  }
              }
            }`}
            variables={_.pick(this.state.selectedPlace, ['placeId', 'sessionToken'])}
            onCompleted={(data) => {
              const location = this.parseLocationResult(data.getLocationFromGooglePlaceId);
              if (location && this.props.onChange) {
                this.props.onChange(location);
              }
            }}
          >
          {({ loading, data, error }) => {
            if (loading) {
                return spinner;
            }

            return (
              <div className="LocationAutocomplete confirm">

                {_.get(data, 'getLocationFromGooglePlaceId.formatted_address')
                  && <p><strong>{data.getLocationFromGooglePlaceId.formatted_address}</strong></p>
                }

                <button
                    className="link"
                    type="button"
                    onClick={(e) => {
                        this.setState({
                          selectedPlace: null,
                          autocompleteToken: Date.now(),
                          search: '',
                        });
                        if (this.props.onChange) {
                          this.props.onChange({});
                        }
                    }}
                >
                    Back to search
                </button>
              </div>
            );
          }}
          </Query>
        );
      }

      let countryHelp = ' ';
      if (_.get(this.state, 'country.name') || _.get(this.state, 'country.region')) {
        countryHelp = `Not in ${_.get(this.state, 'country.name') || _.get(this.state, 'country.region')}?`;
      } else if (this.state.country) {
        countryHelp = 'Help';
      } else if (this.state.country === false) {
        countryHelp = 'Could not determine your location, please contact Askable';
      }

      return (
          <div className="LocationAutocomplete">
            {this.renderForm()}
            <CountryCodeSelect
                value={this.state.country.region}
                onChange={(value) => {
                    this.setState({
                      country: value,
                      geolocation: null,
                      autocompleteToken: Date.now()
                    });
                }}
                buttonText={countryHelp}
                showPhoneCode={false}
                showFlag={false}
                userActionOverride={() => { window.location.href = HELP_URL; }}
            />
          </div>
      );
    }
}

const setCountryContainer = graphql(verifyAndSetCountry, {
  props: ({ mutate }) => ({
    verifyAndSetCountry: (turnstileToken, monocleBundle) =>
      mutate({
        variables: { challenge: turnstileToken, vpn_info: monocleBundle },
        refetchQueries: [
          {
            query: fetchUserById,
            variables: { id: localStorage.get('connectedParticipant') },
          },
        ],
      }),
  }),
});

export default compose(setCountryContainer)(withMonocle(LocationAutocomplete));
