import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import _ from 'lodash';

import { utils } from 'lib/utils';
import { Input, Select, LoadingOverlay } from 'components/common';

import getIndustryList from 'data/queries/getIndustryList';


class OccupationSelect extends Component {
    constructor() {
        super();
        this.state = {
            _industry_id: null,
            _subindustry_id: null,
            job_title: null
        };

        this.renderSubindustry = this.renderSubindustry.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    onChangeHandler() {
        if (this.props.onChangeHandler) {
            this.props.onChangeHandler(_.pick(this.state, ['_industry_id', '_subindustry_id', 'job_title']));
        }
    }

    renderSubindustry(subindustries) {
        if (!(subindustries && subindustries.length > 0)) return null;
        return (
            <Select
                placeholder={this.state._subindustry_id ? null : 'Select job type'}
                value={this.state._subindustry_id || ''}
                values={subindustries.map(industry => ({ label: industry.name, value: industry._id }))}
                fullWidth
                onChange={(value) => {
                    this.setState((state) => {
                        state._subindustry_id = value;
                        this.onChangeHandler(state);
                        return state;
                    });
                }}
            />
        );
    }

    render() {
        return (
            <Query
                query={getIndustryList}
            >
            {({ loading, data }) => {
                if (loading) return <LoadingOverlay />;
                return (
                    <div className="mbottom40">
                        <Select
                            placeholder={this.state._industry_id ? null : 'Select industry'}
                            value={this.state._industry_id}
                            values={_.get(data, 'industryList', []).map(industry => ({ label: industry.name, value: industry._id }))}
                            fullWidth
                            orderValues="label"
                            onChange={(value) => {
                                this.setState((state) => {
                                    state._industry_id = value;
                                    state._subindustry_id = null;
                                    this.onChangeHandler(state);
                                    return state;
                                });
                            }}
                        />
                        {this.renderSubindustry(_.chain(data)
                            .get('industryList', [])
                            .find(industry => this.state._industry_id && this.state._industry_id === industry._id)
                            .get('subcategories', [])
                            .value())}
                        {(this.state._industry_id || this.state.job_title) && (
                            <Input
                                name="job_title"
                                type="text"
                                placeholder="Please enter in your job title"
                                value={this.state.job_title || ''}
                                onChange={(value) => {
                                    this.setState((state) => {
                                        state.job_title = value;
                                        this.onChangeHandler(state);
                                        return state;
                                    });
                                }}
                                autoComplete="off"
                            />
                        )}
                    </div>
                );
            }}
            </Query>
        );
    }
}

export default OccupationSelect;
