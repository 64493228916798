import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'lib/moment';

import { utils } from 'lib/utils';
import { HELP_URL } from '../../lib/data/constants';

function renderAddress({ booking, section }) {
  switch (booking.type) {
    case 1:
      switch (section) {
        case 'inProgress':
        case 'upcoming':
        case 'completed':
          return (
            <address>
              {booking.config.location.street1 ? <span>{booking.config.location.street1}</span> : null}{' '}
              {booking.config.location.street2 ? <span>{booking.config.location.street2}</span> : null}{' '}
              {booking.config.location.city || booking.config.location.state ? (
                <span>{`${booking.config.location.city || ''} ${booking.config.location.state || ''}`.replace(/^\s+|\s+$/g, '')}</span>
              ) : null}
            </address>
          );
        default:
          return (
            <address>
              {booking.config.location.city || booking.config.location.state ? (
                <span>{`${booking.config.location.city || ''} ${booking.config.location.state || ''}`.replace(/^\s+|\s+$/g, '')}</span>
              ) : null}
            </address>
          );
      }
    case 2:
      return <address>Remote / Video chat</address>;
    case 3:
      switch (_.get(booking, 'config.online_task.type')) {
        case 3:
          return <address>Self-managed video call</address>;
        case 2:
          return <address>Survey</address>;
        default:
          return <address>Online task</address>;
      }
    case 4:
      return <address>Multi-stage task</address>; // DIARY STUDY
    default:
      return null;
  }
}

function renderMessage(bookingParticipant) {
  switch (bookingParticipant.cancel) {
    case 0:
      if (bookingParticipant.session.end < moment().valueOf() && bookingParticipant.user_confirm < bookingParticipant.session.end) {
        return (
          <Link className="card-child card-message message-warning" to={`/booking/${bookingParticipant._booking_id}`}>
            Please confirm that you attended this session
          </Link>
        );
      }
      return null;
    case 1:
    case 2:
      return (
        <div className="card-child card-message">
          <p>This session was cancelled by admin</p>
          <a className="link" href={HELP_URL}>
            Help
          </a>
        </div>
      );
    case 3:
      return (
        <div className="card-child card-message">
          <p>You cancelled this session</p>
          <a className="link" href={HELP_URL}>
            Help
          </a>
        </div>
      );
    case 4:
      return (
        <div className="card-child card-message message-danger">
          <p>You didn’t attend this session, and will not be receiving payment</p>
          <a className="link" href={HELP_URL}>
            Dispute this
          </a>
        </div>
      );
    default:
      return null;
  }
}

export default function (props) {
  const fields = {};
  const bookingParticipant = _.minBy(props.bookingParticipants, 'session.start');

  switch (props.booking.type) {
    case 3:
    case 4: // DIARY STUDY
      if (props.section === 'completed') {
        fields.since = `Completed ${moment(bookingParticipant.user_confirm).fromNow()}`;
        fields.date = moment(bookingParticipant.user_confirm).format('ddd MMM D');
      } else {
        fields.date = `Ends ${moment(bookingParticipant.session.end).format('h:mm A')}`;
        fields.time = moment(bookingParticipant.session.end).formatZoneFallback('ddd MMM D');
      }
      break;
    default:
      if (props.section === 'completed') {
        fields.since = moment(bookingParticipant.session.end).fromNow();
      } else {
        fields.time = moment(bookingParticipant.session.start).formatZoneFallback('h:mm A');
      }
      fields.date = moment(bookingParticipant.session.start).format('dddd Do MMM');
  }

  fields.duration = utils.sessionDurationString(props.booking.config.session.duration);
  fields.incentive = (
    <span className="incentive">
      {props.booking.config.incentive.currency_symbol ? <span className="currency">{props.booking.config.incentive.currency_symbol}</span> : null}
      {props.booking.config.incentive.value}
    </span>
  );

  if (props.section === 'completed' && props.booking.type !== 3) {
    fields.message = renderMessage(bookingParticipant);
  }

  return (
    <div className={`upcoming-session-card ${props.className}`}>
      <Link to={`/booking/${props.booking._id}`} className="card-child">
        <div className="card-col-dynamic">
          {fields.since ? <div className="since">{fields.since}</div> : null}
          {fields.date ? <div className="date">{fields.date}</div> : null}
          {fields.time ? <div className="time">{fields.time}</div> : null}
        </div>

        <div className="card-col-fixed textRight">
          {fields.duration ? <div className="duration">{fields.duration}</div> : null}
          {fields.incentive || null}
        </div>

        <div className="card-col-full address">{renderAddress(props)}</div>
      </Link>
      {fields.message || null}
    </div>
  );
}
