import moment from 'lib/moment';
import _ from 'lodash';
import decode from 'jwt-decode';
import axios from 'axios';
import dot from 'dot-object';
import { PhoneNumberUtil, PhoneNumberType, PhoneNumberFormat } from 'google-libphonenumber';

import { localStorage } from './storage';

import phoneCountryCodes from './data/phoneCountryCodes.json';

// console.log(JSON.encode(PhoneNumberType, undefined, 1));

const userMeta = {
  bigSeven: [
    'settings.tutorial.my',
    'meta.identity.firstname',
    'meta.identity.lastname',
    'meta.identity.gender',
    'email',
    'location.city',
    // 'meta.identity.birthday.year',
    'meta.identity.birthday.timestamp',
    'meta.work.status',
    'contact.phone.mobile',
    'contact.phone.verification.mobile',
    'settings.agreement._id',
    'settings.billing',
    // 'timezone'
  ],
  mediumThree: [
    'meta.family.status',
    'meta.education',
    // 'location.address1'
    'meta.identity.languages.english.speak'
  ]
};

const phoneUtil = PhoneNumberUtil.getInstance();


class CountdownTimer {
    constructor(params) {
        this.endpoint = moment(params.endpoint || false);
        this.duration = null;
        this.period = params.period || 500;
        this.callback = params.callback;

        this.start = this.start.bind(this);
    }
    setEndpoint(date = null) {
        this.endpoint = moment(date);
        this.clearTimeout();
        this.start();
    }
    setCallback(callback) {
        this.callback = callback;
        this.clearTimeout();
        this.start();
    }
    clearTimeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }
    getDurationAsTimer(format) {
        return utils.getDurationAsTimer(this.duration, format);
    }
    start(params = {}) {
        if (params.endpoint) {
            this.endpoint = moment(params.endpoint || false);
        }
        if (params.callback) {
            this.callback = params.callback;
        }
        if (params.period) {
            this.period = params.period;
        }
        if (this.endpoint && !this.endpoint.isValid()) {
            this.endpoint = null;
        }
        if (this.endpoint) {
            this.duration = moment.duration(this.endpoint.diff(moment()));
        } else {
            this.duration = null;
        }
        this.timeout = setTimeout(this.start, this.period);
        if (this.callback) {
            this.callback(this.endpoint, this.duration);
        }
    }
}

const utils = {
    window, // eslint-disable-line
    escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },
    formatTimestampToDate(timestamp, format = 'DD/MM/YYYY hh:mma') {
        const dateInNumber = parseInt(timestamp, 10);
        return moment(dateInNumber).formatZoneFallback(format);
    },
    formatTimeFromX(startTimestamp, endTimestamp) {
        return moment(startTimestamp).from(moment(endTimestamp), true);
    },
    formatTimeToNow(startTimestamp) {
        return moment(startTimestamp).toNow(true);
    },
    camelize(str) {
        if (str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
                if (+match === 0) return '';
                return index === 0 ? match.toLowerCase() : match.toUpperCase();
            });
        }
        return str;
    },
    capitalize(str) {
        if (str === undefined) {
            return str;
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
    removeAllBlankSpaces(str) {
        return str.replace(/ /g, '');
    },
    getCurrentYear() {
        return new Date().getFullYear();
    },
    decodedToken() {
        const participant_access_token = localStorage.get('participant_access_token');
        if (!participant_access_token) return null;
        return decode(participant_access_token);
    },
    tokenIsExpired() {
        const token = utils.decodedToken();
        if (!token) return null;
        return token.exp < Date.now() / 1000;
    },
    userHasRoles(...roles) { // match any
        // check if there's a match between token roles and roles in argument
        return _.intersection(_.get(utils.decodedToken(), 'roles', []), roles).length > 0;
    },
    isDemoUser() {
        if (!utils.isAuthenticated()) return null;
        return utils.userHasRoles('demo_participant');
    },
    isAuthenticated() {
        const connectedParticipant = localStorage.get('connectedParticipant');
        const participant_access_token = localStorage.get('participant_access_token');
        if (connectedParticipant && participant_access_token) {
            if (utils.tokenIsExpired()) {
                return false;
            }
            if (!utils.userHasRoles('participant', 'demo_participant')) {
                localStorage.delete('connectedParticipant');
                localStorage.delete('participant_access_token');
                return false;
            }
            return true;
        }
        return false;
    },
    getUserId() {
        return localStorage.get('connectedParticipant') || null;
    },
    hasBigSeven(missing = []) {
        const userBasicDetails = localStorage.get('userBasicDetails') || '';
        for (let i = 0; i < userMeta.bigSeven.length; i += 1) {
          if (!userBasicDetails.match(new RegExp(userMeta.bigSeven[i].replace('.', '\\.')))) {
            missing.push(userMeta.bigSeven[i]);
          }
        }
        // console.log('missing.indexOf', missing.indexOf('settings.agreement._id'));
        if (missing.indexOf('settings.agreement._id') < 0) {
          const userAgreement = JSON.parse(localStorage.get('userAgreement', 'session') || 'null');
          // console.log('userAgreement', userAgreement);
          if (userAgreement) {
            if (userAgreement._id !== userAgreement.latest || userAgreement.date < moment().subtract(2, 'weeks').valueOf()) {
              // console.log({
              //   'userAgreement._id': userAgreement._id,
              //   'userAgreement.latest': userAgreement.latest,
              //   'userAgreement.date': userAgreement.date,
              //   '2weeksAgo': moment().subtract(2, 'weeks').valueOf()
              // });
              missing.push('settings.agreement._id');
              // console.warn('not the latest agreement || agreement expired');
            }
          } else {
            missing.push('settings.agreement._id');
            // console.warn('no user agreement saved');
          }
        }

        return missing.length === 0;
    },
    objectHasProps(baseObject, props, result = { values: {}, missing: [] }) {
      result.values = {};
      result.missing = [];
      props.forEach((prop) => {
        const value = this.objectGetProp(baseObject, prop);
        if (value) {
          result.values[prop] = value;
        } else {
          result.missing.push(prop);
        }
      });

      return result.missing.length === 0;
    },
    objectGetProp(baseObject, prop) {
      let reference = Object.assign({}, baseObject);
      const propParts = prop.split('.');
      for (let i = 0; i < propParts.length; i += 1) {
        if (reference[propParts[i]]) {
          reference = reference[propParts[i]];
        } else {
          reference = undefined;
          break;
        }
      }
      return reference;
    },
    // objectSetProp(object, prop, value) { // Mutates original object!
    //   const propParts = prop.split('.');
    //   let i = 0;
    //   for (i; i < propParts.length - 1; i += 1) {
    //     if (object[propParts[i]] === undefined) {
    //       object[propParts[i]] = {};
    //     }
    //   }
    //   object[propParts[i + 1]] = value;
    // },
    parsePhoneNumber(input, regionCode = null) {
      const result = {
        input,
        regionCode,
        numberType: null,
        E164: null,
        format: {},
        countryCode: null,
        valid: false
      };

      try {
        const number = phoneUtil.parseAndKeepRawInput(input, regionCode);
        result.valid = phoneUtil.isValidNumberForRegion(number, regionCode);
        result.numberType = _.invert(PhoneNumberType)[phoneUtil.getNumberType(number)];
        result.E164 = phoneUtil.format(number, PhoneNumberFormat.E164);
        result.format.international = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
        result.format.national = phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
        result.countryCode = `+${number.getCountryCode()}`;
      } catch (e) {
        result.error = e;
      }

      return result;
    },
    privateRouteInterrupt(targetPath) {
        if (localStorage.get('tagetPath') && targetPath.match(/^\/*$/)) {
            return;
        }
    //   if (!localStorage.get('tagetPath')) {
        localStorage.save('tagetPath', targetPath);
    //   }
    },
    privateRouteResume() {
      const path = localStorage.get('tagetPath');
      if (path) {
        localStorage.delete('tagetPath');
      }
      return path || '/';
    },
    sessionDurationString(minutes) {
      let sessionDuration = null;
      if (minutes) {
        sessionDuration = `${minutes} min`;
        if (minutes > 60 && minutes !== 90) {
          const duration = moment.duration(minutes, 'minutes');
          sessionDuration = `${duration.get('hours')} hr ${duration.get('minutes')} min`;
        }
      }
      return sessionDuration;
    },
    getKeyDescription(category, key) {
        const dictionary = {
            'meta.education': {
                highschool: 'Highschool',
                some_university: 'University Degree',
                undergraduate: 'Undergraduate Degree',
                postgraduate: 'Postgraduate Degree',
                apprenticeship: 'Apprenticeship',
            },
            'meta.family.status': {
                single: 'Single',
                relationship: 'In a Relationship',
                married: 'Married / Defacto',
                divorced: 'Divorced',
                widowed: 'Widowed',
            },
            'meta.work.status': {
                fulltime: 'Full time work',
                parttime: 'Part time / casual work',
                fulltime_student: 'Full time student',
                parttime_student: 'Part time student',
                homeduties: 'Full time home duties',
                retired: 'Retired',
                unemployed: 'Unemployed',
                // selfemployed: 'Self-employed'
            },
            'meta.identity.languages.english.speak': {
                native: 'Native',
                fluent: 'Fluent',
                conversational: 'Conversational',
                beginner: 'Beginner'
            },
            'meta.work.business.size': {
                1: 'Myself only',
                2: '2 - 10 employees',
                3: '11 - 50 employees',
                4: '51 - 200 employees',
                5: '201 - 1,000 employees',
                6: 'More than 1,000 employees',
            },
            'meta.work.business.turnover': {
                1: 'Less than $100K',
                2: '$100K - $1M',
                3: '$1M - $5M',
                4: '$5M - $25M',
                5: '$25M - $100M',
                6: 'More than $100M',
            },
        };
        return dictionary[category] && dictionary[category][key];
  },
  userPropValues: (userProp) => {
    const values = {
        'meta.education': [
            'highschool',
            'undergraduate',
            'postgraduate',
            'apprenticeship',
        ],
        'meta.family.status': [
            'single',
            'relationship',
            'married',
            'divorced',
            'widowed',
        ],
        'meta.work.status': [
            'fulltime',
            'parttime',
            'fulltime_student',
            'parttime_student',
            'homeduties',
            'retired',
            'unemployed',
        ],
        'meta.identity.languages.english.speak': [
            'native',
            'fluent',
            'conversational',
            'beginner',
        ],
    };
    return values[userProp] || [];
  },
  sanitizeEmail(input) {
      const email = input.replace(/^[\s.]+|[\s.]+$/g, '');
      const domain = input.replace(/^.+@/, '');
      if (domain === email) {
        return null;
      }
      if (domain.match(/^(gmail\.com|hotmail\.com|outlook\.com|yahoo\.com|yahoo\.com\.au|icloud\.com)$/i)) {
        return email.toLowerCase();
      }
      return email.replace(domain, domain.toLowerCase());
  },
  urlParams(searchString) {
      const findParams = searchString.match(/(?:^|\?|&)([^?&]+?)(?=&|$)/g);
      const params = {};
      if (findParams) {
          let parts = null;
          for (let i = 0; i < findParams.length; i += 1) {
              parts = decodeURIComponent(findParams[i]).match(/([^?&=]+)/g);
              if (parts) {
                  params[parts[0]] = parts[1]; // eslint-disable-line
              }
          }
      }
      return params;
  },
  urlParam(searchString, key) {
      const match = searchString.match(new RegExp(`(?:^|\\?|&)(?:${key}=)(.+?)(?=&|$)`));
      return match ? decodeURIComponent(match[1]) : null;
  },
  fbPixelTrack(event, params = null) {
      if (window.fbq) { // eslint-disable-line
          if (params) {
              window.fbq('track', event, params); // eslint-disable-line
          } else {
              window.fbq('track', event); // eslint-disable-line
          }
      }
  },
  geoBoundingBox(latitude, longitude, km) {
    const degLatKm = 111;
    const degLngKm = Math.cos(latitude * (Math.PI / 180)) * 111;

    return {
        minLatitude: latitude - (km / degLatKm),
        maxLatitude: latitude + (km / degLatKm),
        minLongitude: longitude - (km / degLngKm),
        maxLongitude: longitude + (km / degLngKm)
    };
  },
  getEligibilitySummary(criteria) {
      const eligibilitySummary = [];
      const ageConditions = [];

      const minAgeTs = _.find(criteria.meta_identity_birthday_year, ['operator', 'lt']);
      const maxAgeTs = _.find(criteria.meta_identity_birthday_year, ['operator', 'gt']);

      if (minAgeTs) {
        const years = moment().diff(parseInt(minAgeTs.value), 'years');
        ageConditions.push({ years, bound: 'min' });
      }
      if (maxAgeTs) {
        const years = moment().diff(parseInt(maxAgeTs.value), 'years');
        if (years <= 65) ageConditions.push({ years, bound: 'max' });
      }

      switch (ageConditions.length) {
          case 1:
            eligibilitySummary.push({
                key: 'meta_identity_birthday_year',
                description: ageConditions[0].bound === 'max' ? `Under ${ageConditions[0].years} years old` : `${ageConditions[0].years}+ years old`
            });
          break;
          case 2:
            if (ageConditions[0].years === ageConditions[1].years) {
                eligibilitySummary.push({
                    key: 'meta_identity_birthday_year',
                    description: `${ageConditions[0].years} years old`
                });
            } else {
                eligibilitySummary.push({
                    key: 'meta_identity_birthday_year',
                    description: `${ageConditions[0].years} - ${ageConditions[1].years} years old`
                });
            }
          break;
          default:
      }

      if (criteria.meta_identity_gender && criteria.meta_identity_gender.length === 1) {
          const genderValue = criteria.meta_identity_gender[0].value;
          eligibilitySummary.push({
            key: 'meta_identity_gender',
            description: `${genderValue.substring(0, 1).toUpperCase()}${genderValue.substring(1)}`
          });
      }

      if (criteria.meta_family_status && criteria.meta_family_status.length > 0) {
          const familyConditions = criteria.meta_family_status.map((condition) => {
              const key = condition.field.replace(/^.+\./, '');
              return {
                 key,
                 description: utils.getKeyDescription('meta.family.status', key)
              };
          });
          eligibilitySummary.push({
            key: 'meta_family_status',
            description: `Family Status: ${familyConditions.length === 1 ? familyConditions[0].description : ''}`,
            children: familyConditions.length > 1 ? familyConditions : []
          });
      }

      if (criteria.meta_education && criteria.meta_education.length > 0) {
          const educationConditions = criteria.meta_education.map((condition) => {
              const key = condition.field.replace(/^.+\./, '');
              return {
                 key,
                 description: utils.getKeyDescription('meta.education', key)
              };
          });
          eligibilitySummary.push({
            key: 'meta_education',
            description: `Education Level: ${educationConditions.length === 1 ? educationConditions[0].description : ''}`,
            children: educationConditions.length > 1 ? educationConditions : []
          });
      }

      if (criteria.meta_work_status && criteria.meta_work_status.length > 0) {
          const workStatusConditions = criteria.meta_work_status.map((condition) => {
              const key = condition.field.replace(/^.+\./, '');
              return {
                 key,
                 description: utils.getKeyDescription('meta.work.status', key)
              };
          });
          eligibilitySummary.push({
            key: 'meta_work_status',
            description: `Occupation Status: ${workStatusConditions.length === 1 ? workStatusConditions[0].description : ''}`,
            children: workStatusConditions.length > 1 ? workStatusConditions : []
          });
      }

      return eligibilitySummary;
  },
  leaveWarningSet(message) {
      // console.log('leaveWarningSet', message);
      window.leaveWarning = message;
  },
  leaveWarningDelete() {
      // console.log('leaveWarningDelete');
      if (window.leaveWarning) {
          delete window.leaveWarning;
      }
  },
  leaveWarningGet() {
      // console.log('leaveWarningGet');
      return window.leaveWarning || false;
  },
  leaveWarningPrompt() {
      // console.log('leaveWarningPrompt');
      if (window.leaveWarning) {
          const proceed = window.confirm(window.leaveWarning);
          if (proceed) {
              delete window.leaveWarning;
          }
          return proceed;
      }
      return true;
  },
  setMenuState(hash) {
      window.setMenuState(hash);
  },
  hideOpportunity(bookingId) {
      const hidden = JSON.parse(localStorage.get('hidden_opportunities')) || [];
      if (hidden.push) {
          hidden.push(bookingId);
      }
      localStorage.save('hidden_opportunities', JSON.stringify(hidden));
  },
  urlPatternReplace(url, data) {
    let treatedUrl = url;
    const patterns = url.match(/{{(.+?)}}/g);
    if (patterns) {
        for (let i = 0; i < patterns.length; i += 1) {
            treatedUrl = treatedUrl.replace(patterns[i], _.get(data, patterns[i].replace(/^{+|}+$/g, ''), ''));
        }
    }
    return treatedUrl;
  },
  async copy(text, onSuccess, onError) {
    if (!window.navigator.clipboard) {
      const textArea = document.createElement('textarea');
      document.body.appendChild(textArea);
      textArea.value = text;
      textArea.focus();
      textArea.setSelectionRange(0, text.length);

      try {
        const successful = document.execCommand('copy');
        if (!successful) {
            throw new Error('document.execCommand(\'copy\') returned false');
        }
        if (onSuccess) {
            onSuccess();
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
        textArea.blur();
        document.body.removeChild(textArea);
        if (onError) {
            onError();
        }
      }
      textArea.blur();
      document.body.removeChild(textArea);
      return;
    }

    let nativeSuccessful = true;
    await window.navigator.clipboard.writeText(text)
        .catch((err) => {
          console.error('Async: Could not copy text: ', err);
          nativeSuccessful = false;
          if (onError) {
              onError();
          }
        });

    if (nativeSuccessful && onSuccess) {
        onSuccess();
    }
  },

  getCountryByCode(region) {
      return _.find(phoneCountryCodes, { region }) || {};
  },

  filterBookingsLists(bookingsByParticipant) {
    const sections = {
        inProgress: [],
        upcoming: [],
        waitlist: [],
        inReview: [],
        invited: [],
        cancelled: [],
        completed: [],
        _other: []
    };

    bookingsByParticipant.forEach((booking) => {
        const filterItem = { booking, relevantDocs: [] };

        if ([1, 5, 7].indexOf(booking.status) < 0) {
            return;
        }

        if (booking.type === 3) {
            filterItem.relevantDocs = _.filter(booking.ParticipantSessions, bookingParticipant => bookingParticipant.completed && bookingParticipant.cancel === 0);
            if (filterItem.relevantDocs.length > 0) {
                sections.completed.push(filterItem);
                return;
            }
            if (booking.status !== 1) {
                return;
            }

            const futureDocs = _.filter(booking.ParticipantSessions, bookingParticipant => _.get(bookingParticipant, 'session.end', Date.now()) >= Date.now());
            if (futureDocs.length === 0) {
                return;
            }

            if (_.get(booking, 'config.options.review_submission')) {
                filterItem.relevantDocs = _.filter(futureDocs, bookingParticipant => bookingParticipant.status === 5 && bookingParticipant.cancel === 0);
                if (filterItem.relevantDocs.length > 0) {
                    sections.inProgress.push(filterItem);
                    return;
                }

                filterItem.relevantDocs = _.filter(futureDocs, bookingParticipant => bookingParticipant.status === 3 && bookingParticipant.cancel === 0);
                if (filterItem.relevantDocs.length > 0) {
                    sections.inReview.push(filterItem);
                    return;
                }
            } else {
                filterItem.relevantDocs = _.filter(futureDocs, bookingParticipant => (bookingParticipant.status === 3 || bookingParticipant.status === 5) && bookingParticipant.cancel === 0);
                if (filterItem.relevantDocs.length > 0) {
                    sections.inProgress.push(filterItem);
                    return;
                }
            }
            filterItem.relevantDocs = _.filter(futureDocs, bookingParticipant => bookingParticipant.status === 2 && bookingParticipant.cancel === 0);
            if (filterItem.relevantDocs.length > 0) {
                sections.waitlist.push(filterItem);
                return;
            }
            filterItem.relevantDocs = _.filter(futureDocs, bookingParticipant => bookingParticipant.status === 4 && bookingParticipant.cancel === 0);
            if (filterItem.relevantDocs.length > 0) {
                sections.invited.push(filterItem);
                return;
            }
            filterItem.relevantDocs = futureDocs;
            if (filterItem.relevantDocs.length > 0) {
                sections.cancelled.push(filterItem);
                return;
            }
            return;
        }

        filterItem.relevantDocs = _.filter(booking.ParticipantSessions, bookingParticipant => (
            (bookingParticipant.status === 1
                && (bookingParticipant.completed === true || _.get(bookingParticipant, 'session.end', Date.now()) < Date.now())
            ) && bookingParticipant.cancel === 0
        ));
        if (filterItem.relevantDocs.length > 0) {
            sections.completed.push(filterItem);
            return;
        }
        if (booking.status !== 1) {
            sections._other.push(filterItem);
            return;
        }

        filterItem.relevantDocs = _.filter(booking.ParticipantSessions, bookingParticipant => bookingParticipant.status === 1 && _.get(bookingParticipant, 'session.start', Date.now()) < Date.now() && bookingParticipant.cancel === 0);
        if (filterItem.relevantDocs.length > 0) {
            sections.inProgress.push(filterItem);
            return;
        }

        const futureDocs = _.filter(booking.ParticipantSessions, bookingParticipant => _.get(bookingParticipant, 'session.end', Date.now()) >= Date.now());

        filterItem.relevantDocs = _.filter(futureDocs, bookingParticipant => bookingParticipant.status === 1 && bookingParticipant.cancel === 0);
        if (filterItem.relevantDocs.length > 0) {
            sections.upcoming.push(filterItem);
            return;
        }
        filterItem.relevantDocs = _.filter(futureDocs, bookingParticipant => bookingParticipant.status === 2 && bookingParticipant.cancel === 0);
        if (filterItem.relevantDocs.length > 0) {
            sections.waitlist.push(filterItem);
            return;
        }
        filterItem.relevantDocs = _.filter(futureDocs, bookingParticipant => bookingParticipant.status === 4 && bookingParticipant.cancel === 0);
        if (filterItem.relevantDocs.length > 0) {
            sections.invited.push(filterItem);
            return;
        }
        filterItem.relevantDocs = _.filter(futureDocs, bookingParticipant => bookingParticipant.status === 3 && bookingParticipant.cancel === 0);
        if (filterItem.relevantDocs.length > 0) {
            sections.inReview.push(filterItem);
            return;
        }
        filterItem.relevantDocs = _.filter(futureDocs, bookingParticipant => bookingParticipant.cancel !== 0);
        if (filterItem.relevantDocs.length > 0) {
            sections.cancelled.push(filterItem);
            return;
        }

        sections._other.push(filterItem);
    });

    return _.mapValues(sections, (list, section) => {
        switch (section) {
            case 'completed':
                return _.orderBy(list, item => _.get(_.maxBy(item.relevantDocs, 'user_confirm'), 'user_confirm'), 'desc');
            case '_other':
                return list;
            default:
                return _.sortBy(list, item => _.get(_.minBy(item.relevantDocs, 'session.start'), 'session.start', Date.now()));
        }
    });
  },

  waitForDomElement(selector) {
      const el = document.querySelector(selector);
      if (el) {
          return el;
      }
      return new Promise((resolve) => {
          setTimeout(() => {
              resolve(utils.waitForDomElement(selector));
          }, 250);
      });
  },

  wait(timeout) {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
  },

  async keepTrying(f, timeout = 100, attempts = 5) {
    let success = true;
    let result = null;
    let error = null;
    console.debug(`Trying ${f}; ${attempts} left`);
    try {
        result = await f();
    } catch (e) {
        success = false;
        error = e;
    }
    if (success) return result;
    if (attempts <= 0) {
        console.error(`Failed final attempt of ${f}`);
        throw error;
    }
    await utils.wait(timeout);
    return utils.keepTrying(f, timeout, attempts - 1);
  },

  expose(name, value) {
      if (!window.__expose) {
          window.__expose = {};
      }
      window.__expose[name] = value;
  },

  parentMessageListener(event) {
      if (event.target.message_listeners) {
          event.target.message_listeners.forEach((listener) => {
              if (listener.callback && event.data) {
                  try {
                      const data = JSON.parse(event.data);
                      if (data.event === listener.id) listener.callback(data);
                  } catch (err) {
                      listener.callback(event.data);
                  }
              }
          });
      }
  },

  parentMessageListen(callback, id = '_default', win = window) {
      if (!win.message_listeners) {
          win.message_listeners = [];
      }
      if (!_.find(win.message_listeners, ['id', id])) {
          win.message_listeners.push({ id, callback });
      }
      // win.removeEventListener('message', utils.parentMessageListener);
      win.addEventListener('message', utils.parentMessageListener);
  },

  parentPostMessage(data) {
      try {
          const message = JSON.stringify(data);
          window.top.postMessage(message, '*');
      } catch (err) {
          console.error('parentPostMessage', err);
      }
  },

  stripGqlTypename(object) {
      const flat = dot.dot(object);
      return dot.object(_.pickBy(flat, (v, k) => !k.match(/__typename$/)));
  },

  roundFactor(value, factor, method = 'round') {
    return Math[method](value / factor) * factor;
  },

  firstKey(object) {
    return _.chain(object)
        .keys()
        .first()
        .value();
  },

  lastKey(object) {
    return _.chain(object)
        .keys()
        .last()
        .value();
  },

    getDurationAsTimer(duration, format) {
        if (!duration) {
            return null;
        }
        switch (format) {
            case 'h:mm':
                return [
                    Math.floor(duration.asHours()),
                    Math.abs(duration.minutes()).toString(10).padStart(2, '0')
                ].join(':');
            case 'h:mm:ss':
                return [
                    Math.floor(duration.asHours()),
                    Math.abs(duration.minutes()).toString(10).padStart(2, '0'),
                    Math.abs(duration.seconds()).toString(10).padStart(2, '0')
                ].filter(value => value).join(':');
            default:
                return duration.valueOf();
        }
    },

  getBuildInfo() {
      if (window.location.hostname.match(/localhost/)) {
          return {
              version: '0.0.0',
              date: new Date().toString(),
              src: '0000000000000000000000000000000000000000',
              test: true,
          };
      }
      let buildInfo = null;
      try {
          buildInfo = JSON.parse(document.lastChild.nodeValue);
      } catch (err) {
          buildInfo = null;
      }

      return buildInfo || {};
  },

    getNearestAncestor(el, matchProp, matchVal, i = 0) {
        if (el[matchProp] === matchVal) {
            return el;
        }
        if (!el.parentNode || i > 300) return null;
        return utils.getNearestAncestor(el.parentNode, matchProp, matchVal, i + 1);
    },

    getUserRestrictions(user) {
        const resrictions = _.chain(user)
            .get('settings.restrictions', {})
            .pickBy((value, key) => {
                return value && key !== '__typename';
            })
            .value();
        return _.isEmpty(resrictions) ? null : resrictions;
    },

    mapToArray(map) {
        const array = [];
        map.forEach((value) => { array.push(value); });
        return array;
    },

    diaryStudyDuration(booking) {
        const longitudinalConfig = {
            workload_time: _.get(booking, 'config.longitudinal_study.participant_workload.time'),
            period_time: _.get(booking, 'config.longitudinal_study.period.time'),
        };

        switch (_.get(booking, 'config.longitudinal_study.participant_workload.measure')) {
            case 1:
                longitudinalConfig.workload_measure = 'min';
            break;
            case 2:
                longitudinalConfig.workload_measure = 'hour';
            break;
            default:
        }
        switch (_.get(booking, 'config.longitudinal_study.participant_workload.frequency')) {
            case 1:
                longitudinalConfig.workload_frequency = 'day';
            break;
            case 2:
                longitudinalConfig.workload_frequency = 'week';
            break;
            case 3:
                longitudinalConfig.workload_frequency = 'month';
            break;
            default:
        }
        switch (_.get(booking, 'config.longitudinal_study.period.frequency')) {
            case 1:
                longitudinalConfig.period_frequency = 'day';
            break;
            case 2:
                longitudinalConfig.period_frequency = 'week';
            break;
            case 3:
                longitudinalConfig.period_frequency = 'month';
            break;
            default:
        }

        if (longitudinalConfig.workload_time
            && longitudinalConfig.period_time
            && longitudinalConfig.workload_measure
            && longitudinalConfig.workload_frequency
            && longitudinalConfig.period_frequency
        ) {
            return `${longitudinalConfig.workload_time} ${longitudinalConfig.workload_measure}${longitudinalConfig.workload_time === 1 ? '' : 's'} per ${longitudinalConfig.workload_frequency} over ${longitudinalConfig.period_time} ${longitudinalConfig.period_frequency}${longitudinalConfig.period_time === 1 ? '' : 's'}`;
        }
        return null;
    },


  CountdownTimer

};

if (!window.getExposed) {
    window.getExposed = (name) => {
        if (!window.__expose) {
            return undefined;
        }
        return window.__expose[name];
    };
}

export { utils, userMeta };
