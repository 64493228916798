import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from 'react-spinkit';

import _ from 'lodash';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import SearchIcon from 'material-ui/svg-icons/action/search';

import { Input, Button, SwitchList, LocationAutocompleteQuery } from 'components/common';

const nextButtonProps = {
    label: 'Next',
    labelColor: '#fff',
    bgColor: '#FF5266',
    className: 'btnNext mtop60',
    type: 'submit'
};

const spinner = <div className="mtop10 mbottom10 flex flexCenter"><Spinner fadeIn="none" name="three-bounce" color="#FF5266" /></div>;

class InContextLocationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // stage: 'address', // TODO: REMOVE THIS REMOVE THIS REMOVE THIS !!!
            // consent: 'yes',
            addressSearch: '',
            selectedPlace: null,
            location_additional: ''
        };

        this.searchTimeout = null;

        this.renderLocationQuery = this.renderLocationQuery.bind(this);
    }

    componentWillUnmount() {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
    }

    renderLocationQuery(input) {
        if (input.replace(/\s/g, '') === '') {
            return null;
        }
        return (
            <LocationAutocompleteQuery
                input={input}
                location={_.get(this.props, 'user.location', {})}
                queriedPlace={this.state.queriedPlace}
                token={this.state.autocompleteToken}
            >
            {({ data, networkStatus }) => {
                if (!(data.locationAutocomplete && data.locationAutocomplete.filter(location => !!location).length > 0)) {
                    if (input === '') {
                        return null;
                    }
                    if (networkStatus === 7) {
                        return <p>No results for “{input}”</p>;
                    }
                    return spinner;
                }
                // if (networkStatus !== 7) {
                //     return spinner;
                // }

                return (
                    <React.Fragment>
                        <RadioButtonGroup
                          type="radio"
                          name="inContextAdressSearchResults"
                          onChange={(event) => {
                              const { value } = event.target;
                              this.setState({ selectedPlace: _.find(data.locationAutocomplete, result => result.placeId === value) });
                          }}
                          valueSelected={_.get(this.state, 'selectedPlace.placeId')}
                        >
                        {data.locationAutocomplete
                            .map(location => (
                                <RadioButton
                                    key={location.placeId}
                                    value={location.placeId}
                                    label={location.descriptionMatches.map(({ text, match }, i) => (match ? <strong key={i}>{text}</strong> : <span key={i}>{text}</span>))}
                                    className="switchOption"
                                    // checked={location.placeId === this.state.selectedPlace}
                                />
                            ))
                        }
                        </RadioButtonGroup>
                        {networkStatus !== 7 && spinner}
                    </React.Fragment>
                );
            }}
            </LocationAutocompleteQuery>
        );
    }

    render() {
        const queryVariables = {
            country: this.props.booking.config.location.country,
        };
        const headings = {};
        switch (this.props.booking.config.in_context.location_type) {
            case 1:
                headings.consent = 'Are you willing for the researcher to come to your home for the interview?';
                headings.consentDescription = 'The researcher has chosen to do in-home research and needs your permission.';
                headings.addressSearch = 'What’s the street address of your home?';

                queryVariables.types = ['street_address', 'premise', 'subpremise'];
                headings.addressPrecisionError = 'doesn’t look like a home address. Try adding a street, house or unit number.';
                headings.additionalInfoLabel = 'Please enter any additional instructions for this address, eg. building access or parking';
            break;
            case 2:
                headings.consent = 'Are you willing for the researcher to come to your business or workplace for the interview?';
                headings.consentDescription = 'The researcher has chosen to do in-context research and needs your permission.';
                headings.addressSearch = 'What’s the street address of your business/workplace?';

                queryVariables.types = ['street_address', 'premise', 'subpremise', 'point_of_interest', 'establishment'];
                headings.addressPrecisionError = 'is not accurate enough. Try adding a street number or business name.';
                headings.additionalInfoLabel = 'Please enter any additional instructions for this address, eg. parking, reception information, etc';
            break;
            default:
                headings.consent = 'Are you willing to meet the researcher at a location near you (e.g. Cafe, Library)?';
                headings.consentDescription = 'The researcher has chosen to do in-context research and needs your permission.';
                headings.addressSearch = 'Please pick a netural location near you (e.g. Cafe, Library)';

                queryVariables.types = ['street_address', 'premise', 'subpremise', 'point_of_interest', 'establishment'];
                headings.addressPrecisionError = 'is not accurate enough. Try adding a street or house number or a place name.';
                headings.additionalInfoLabel = 'Please enter any additional instructions for this address, eg. building access or parking';
        }

        switch (this.state.stage) {
            case 'address-confirm': {
                return (
                    <div>
                        <h1 className="mbottom40">{headings.addressSearch}</h1>
                        <Query
                            query={gql`query getLocationFromGooglePlaceId ($placeId:String, $sessionToken:String) {
                              getLocationFromGooglePlaceId (placeId:$placeId, sessionToken:$sessionToken) {
                                  formatted_address name street1 street2 city state
                                  country latitude longitude level postal_code google_location_types
                              }
                            }`}
                            variables={_.pick(this.state.selectedPlace, ['placeId', 'sessionToken'])}
                        >
                        {({ loading, data, error }) => {
                            if (loading) {
                                return spinner;
                            }
                            const location = _.get(data, 'getLocationFromGooglePlaceId');
                            if (error || !location) {
                                console.error(JSON.stringify({ error, location }));
                                return (
                                    <div>
                                        <p>There was an error finding that address, please go back and try again</p>
                                        <Button
                                            {...nextButtonProps}
                                            label="Back"
                                            type="button"
                                            onClick={() => { this.setState({ stage: 'address' }); }}
                                        />
                                    </div>
                                );
                            }
                            if (_.intersection(queryVariables.types, location.google_location_types).length === 0 || !location.street1) {
                                return (
                                    <div>
                                        <p>The address “{this.state.selectedPlace.description}” {headings.addressPrecisionError}</p>
                                        <Button
                                            {...nextButtonProps}
                                            label="Back"
                                            type="button"
                                            onClick={() => { this.setState({ stage: 'address' }); }}
                                        />
                                    </div>
                                );
                            }
                            return (
                                <div>
                                    <p>
                                        {location.name && location.name !== location.street1 && <strong>{location.name}<br /></strong>}
                                        {location.formatted_address}
                                    </p>

                                    <Input
                                        value={this.state.location_additional}
                                        onChange={(value) => { this.setState({ location_additional: value }); }}
                                        multiLine
                                        rowsMax="6"
                                        hintText={headings.additionalInfoLabel}
                                    />

                                    <Button
                                        {...nextButtonProps}
                                        label="Confirm"
                                        type="button"
                                        onClick={() => {
                                            if (!this.props.onLocationUpdate) {
                                                console.error('Location is update method missing');
                                            }
                                            this.props.onLocationUpdate(_.pick(location, [
                                                'name', 'city', 'state', 'country', 'postal_code',
                                                'level', 'street1', 'street2',
                                                'longitude', 'latitude',
                                            ]), this.state.location_additional);
                                        }}
                                    />
                                    <p className="textCenter mtop20">
                                        <a href="#" onClick={(event) => { event.preventDefault(); this.setState({ stage: 'address' }); }}>Go back</a>
                                    </p>
                                </div>
                            );
                        }}
                        </Query>
                    </div>
                );
            }
            case 'address': {
                return (
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            if (!_.get(this.state, 'selectedPlace.placeId')) {
                                window.reload();
                                return false;
                            }

                            this.setState({ autocompleteToken: Date.now(), stage: 'address-confirm' });
                        }}
                    >
                        <h1>{headings.addressSearch}</h1>
                        <Input
                            type="text"
                            placeholder="Search address..."
                            value={this.state.addressSearch}
                            onChange={(value) => {
                                this.setState({ addressSearch: value, selectedPlace: null });
                            }}
                            autoComplete="off"
                            rightIcon={<SearchIcon />}
                        />
                        <div className="mtop20 mbottom20 inContextAdressSearchResults">
                            {this.renderLocationQuery(this.state.addressSearch)}
                        </div>
                        <Button
                            {...nextButtonProps}
                            disabled={!_.get(this.state, 'selectedPlace.placeId')}
                        />
                    </form>
                );
            }
            default: // consent screen
                return (
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            switch (this.state.consent) {
                                case 'yes':
                                    this.setState({ stage: 'address' });
                                break;
                                case 'no':
                                    this.props.history.push(`${this.props.bookingBaseUrl}/apply/message/in-context-non-consent`);
                                break;
                                default:
                                    return null;
                            }
                        }}
                    >
                        <h1>{headings.consent}</h1>
                        <p>{headings.consentDescription}</p>
                        <div className="mtop40">
                            <SwitchList
                              type="radio"
                              name="inContextConsent"
                              onValueChange={([value]) => {
                                  this.setState({ consent: value });
                              }}
                              defaultChecked={[this.state.consent]}
                              options={[
                                  {
                                      value: 'yes',
                                      label: 'Yes, that’s fine',
                                      key: 'yes'
                                  },
                                  {
                                      value: 'no',
                                      label: 'No I’m not comfortable with that',
                                      key: 'no'
                                  },
                              ]}
                            />
                        </div>
                        <Button
                            {...nextButtonProps}
                            disabled={!this.state.consent}
                        />
                    </form>
                );
        }
    }
}

export default InContextLocationPage;
