import React, { Component, Fragment } from 'react';
import { graphql, compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import moment from 'lib/moment';
import _ from 'lodash';
import dot from 'dot-object';
import stringSimilarity from 'string-similarity';

import { utils } from 'lib/utils';

import { fetchBookingByIdAdditional } from 'data/queries/booking/fetchBookingById';

// import FlatButton from 'material-ui/FlatButton';

import { LoadingOverlay } from 'components/common';
import { Card, CardChild } from 'components/common/Card/view';

import ConfirmAttendance from './confirmAttendanceFinishedView';
import { withKinde } from '../../AuthWrapper';

// Styles
import './styles/booking.scss';
import { HELP_URL } from '../../lib/data/constants';


class BookingFinishedView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booking: props.booking,
      bookingAdditional: false,
      confirmAttendanceStage: (
          this.props.bookedSession.session.end < moment().valueOf() &&
          this.props.bookedSession.user_confirm < this.props.bookedSession.session.end &&
          'confirm-1'
      )
    };

    // utils.fbPixelTrack('ViewContent', {
    //   content_ids: props.booking._id,
    //   content_type: 'Booking - registered',
    // });
    // this.reloadBooking = this.reloadBooking.bind(this);
    this.setBookingState = this.setBookingState.bind(this);
  }

  componentWillMount() {
      this.setBookingState(this.props);
  }

  componentWillReceiveProps(newProps) {
      this.setBookingState(newProps);
  }

  setBookingState(props) {
      if (
          !this.state.bookingAdditional &&
          props.fetchBookingByIdAdditional.bookingByID &&
          !props.fetchBookingByIdAdditional.loading &&
          this.state.booking
      ) {
        const flatBooking = Object.assign({}, dot.dot(this.state.booking), dot.dot(props.fetchBookingByIdAdditional.bookingByID));
        this.setState({
          // booking: dot.object(Object.assign({}, dot.dot(this.state.booking), dot.dot(props.fetchBookingByIdAdditional.bookingByID))),
          booking: flatBooking ? dot.object(flatBooking) : {},
          bookingAdditional: true
        });
      }
  }

  render() {
    if (this.state.loading) {
      return <LoadingOverlay />;
    }
    if (!this.state.bookingAdditional) {
      return <LoadingOverlay />;
    }

    const clientInfo = {};
    switch (this.props.booking.config.session.type) {
        case 1:
            clientInfo.sessionType = '1 on 1 chat';
        break;
        case 2:
            clientInfo.sessionType = 'focus group';
        break;
        default:
            clientInfo.sessionType = null;
    }

    const summaryInfo = [];
    if (
        this.props.booking.config.information &&
        this.props.booking.config.information.brief_summary &&
        this.props.booking.config.information.brief_summary > ''
    ) {
        summaryInfo.push({
            title: 'Opportunity details:',
            body: <ReactMarkdown className="md-block">{this.props.booking.config.information.brief_summary}</ReactMarkdown>
        });
    }
    summaryInfo.push({
        title: 'Session',
        body: (
            <div>
                {moment(this.props.bookedSession.session.start).formatZoneFallback('dddd Do MMM, h:mm A')}<br />
                {this.props.sessionDuration}
            </div>
        )
    });
    switch (this.props.booking.type) {
        case 1: {
            let bookingLocation = Object.assign({}, this.state.booking.config.location);
            if (_.get(this.props.booking, 'config.in_context.location_type') && this.props.inContextLocation) {
                bookingLocation = Object.assign({}, this.props.inContextLocation);
            }
            const vagueAddress = bookingLocation && (
                _.get(bookingLocation, 'city') === _.get(bookingLocation, 'name') ||
                _.get(bookingLocation, 'state') === _.get(bookingLocation, 'name') ||
                _.get(bookingLocation, 'postal_code') === _.get(bookingLocation, 'name')
            );
            if (bookingLocation.name && bookingLocation.street1 && stringSimilarity.compareTwoStrings(bookingLocation.name, bookingLocation.street1) > 0.7) {
                bookingLocation.name = undefined;
            }
            if (bookingLocation) {
                summaryInfo.push({
                    title: vagueAddress ? 'Location' : 'Address',
                    body: (
                        <address className="address">
                          {
                              _.uniqBy(
                                  (
                                      vagueAddress ? ['city', 'state'] : ['name', 'level', 'street1', 'street2', 'city', 'state']
                                  )
                                  .map(line => (bookingLocation[line] && <span key={`address_${line}`} className={`line line-${line}`}>{bookingLocation[line]}</span>) || null),
                                  elem => elem && elem.props.children
                              )
                          }
                        </address>
                    )
                });
            }
        }
        break;
        default:
    }

    let paymentPref = null; // eslint-disable-line
    let payoutTransactionDetails = null;
    let paymentTime = ' within 3 business days';

    let payoutTransactionVerb = 'Sending';
    if (_.get(this.props.bookedSession, 'transaction.transactions.provider.reference')) {
        payoutTransactionVerb = 'Sent';
        if (_.get(this.props.bookedSession, 'transaction.transactions.created')) {
            paymentTime = moment(this.props.bookedSession.transaction.transactions.created).format(' [on] MMM D [at] h:mm A');
        }
    }

    switch (
        _.get(this.props.bookedSession, 'transaction.transactions.provider.name')
        || _.get(this.props.user, 'settings.billing.preferred')
    ) {
      case 'paypal':
          paymentPref = 'PayPal';
          payoutTransactionDetails = `${payoutTransactionVerb} via PayPal${paymentTime}`;
      break;
      case 'giftpay':
          paymentPref = 'eGiftCard';
          payoutTransactionDetails = `${payoutTransactionVerb} via email${paymentTime}`;
          if (_.get(this.props.bookedSession, 'transaction.transactions.provider.url')) {
              payoutTransactionDetails = (
                  <Fragment>
                      {payoutTransactionDetails}<br />
                      <a href={this.props.bookedSession.transaction.transactions.provider.url} className="link" target="_blank" rel="noopener noreferrer">View in browser</a>
                  </Fragment>
              );
          }
      break;
      case 'bank':
          paymentPref = 'bank transfer';
      break;
      default:
          paymentPref = null;
    }

    let paymentCard = null;
    let payoutDetails = null;

    if (paymentPref) {
        payoutDetails = (
            <div>
                <CardChild type="text" className="card-cols payment-content">
                  <div className="card-col-dynamic">
                    {this.props.bookedSession.completed ? (
                        <Fragment>
                            <h3>Payment via {paymentPref}</h3>
                            {payoutTransactionDetails && <p>{payoutTransactionDetails}</p>}
                        </Fragment>
                    ) : (
                        <p>Confirm your attendance to submit your payment for processing</p>
                    )
                    }
                  </div>
                  <div className="card-col-fixed">
                    <h3>{this.state.booking.config.incentive.currency_symbol || ''}{this.state.booking.config.incentive.value}</h3>
                  </div>
                </CardChild>
                {_.get(this.props.bookedSession, 'transaction.payment_due') > Date.now() && (
                    <div>
                        <CardChild type="divider" />
                        <CardChild type="actions" className="textRight payment-prefs">
                          <button className="card-link textRight" onClick={() => { utils.setMenuState('menu=payout'); }} >Change payout<br />preferences</button>
                        </CardChild>
                    </div>
                )}
            </div>
        );
    } else {
        payoutDetails = (
          <CardChild type="text" className="card-cols payment-content">
              <div className="card-col-dynamic">
                <h3>No payout method set</h3>
                <p>We won&apos;t be able to process your payment until you select a method</p>
              </div>
              <div className="card-col-dynamic textRight">
                <button className="card-link textRight mtop10" onClick={() => { utils.setMenuState('menu=payout'); }} >Set payout method</button>
              </div>
          </CardChild>
        );
    }

    if (_.get(this.props.bookedSession, 'transaction.suspended') && !_.get(this.props.bookedSession, 'transaction._id')) {
        payoutDetails = (
            <CardChild type="text" className="card-cols payment-content">
                <div className="subtle-warning-box mtop-20 mleft-20 mright-20 mbottom-20">
                    <div className="mleft20 mright20 mtop20 mbottom20">
                        <p>Your payment has been suspended because there’s a problem with your submission</p>
                        <p>For more information, <a href={HELP_URL} className="link">contact Askable</a></p>
                    </div>
                </div>
            </CardChild>
        );
    }

    paymentCard = (
        <Card>
          <CardChild type="text" className="card-cols payment-header">
            <h1>Payment</h1>
            <h1>{this.state.booking.config.incentive.currency_symbol || ''}{this.state.booking.config.incentive.value}</h1>
          </CardChild>
          <CardChild type="divider" />
          {payoutDetails}
        </Card>
    );

    return (
      <div className="bookingPage bookingRegistered">
        {
            (clientInfo.name || clientInfo.sessionType) ?
            <h1 className="pageTitle">Completed session details:<br /><small>{clientInfo.name}{(clientInfo.name && clientInfo.sessionType ? ' - ' : '')}{clientInfo.sessionType}</small></h1> :
            <h1 className="pageTitle">Completed session details:</h1>
        }
        <div className="bookingInformation noStyle mbottom0">
        {summaryInfo.map((information, i) => {
          const title = information.title && information.title.replace(/^\s+|\s+$/, '');
          return (
            /* eslint-disable react/no-array-index-key */
            <div className="bookingInformationItem" key={`summaryInfo_${i}`}>
            {/* eslint-enable react/no-array-index-key */}
              {title ? <span className="title">{title}</span> : null}
              <span className="body">{information.body}</span>
            </div>
          );
        })}
        </div>
        {paymentCard}

        <div className="textContent">
          { this.props.isPreview ? <Link to='?registered'>Need help?</Link> : <a href={HELP_URL}>Need help?</a> }
        </div>
        <ConfirmAttendance
            viewStage={this.state.confirmAttendanceStage}
            confirmAttendanceAnonymous={!this.props?.isAuthenticated()}
            booking={{
                ...this.state.booking,
                booking_participant: this.props.bookedSession,
                user: this.props.user,
                time: moment(this.props.bookedSession.session.start),
                location: _.get(_.find(summaryInfo, section => section.body.type === 'address'), 'body'),
            }}
            callbacks={{
                viewStage: (confirmAttendanceStage) => {
                    this.setState({ confirmAttendanceStage });
                }
            }}
            participantSessionsByBooking={this.props.participantSessionsByBooking}
        />
      </div>
    );
  }
}

const BookingDetailsContainer = graphql(fetchBookingByIdAdditional, {
    name: 'fetchBookingByIdAdditional',
    options: props => ({
        variables: { id: props.booking._id },
    }),
});

export default withKinde(compose(BookingDetailsContainer)(BookingFinishedView));
