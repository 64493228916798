import React, { Component } from 'react';
import { graphql, compose, Query } from 'react-apollo';

import _ from 'lodash';
import moment from 'lib/moment';

import { utils } from 'lib/utils';

import messagesSeen from 'data/queries/messages/messagesSeen';
import messagesSeenSubscription from 'data/subscriptions/messages/messagesSeenSubscription';
import createMessage from 'data/mutations/createMessage';
import markMultipleMessagesAsSeen from 'data/mutations/markMultipleMessagesAsSeen';

import { LoadingOverlay, Wrapper, Dialog } from 'components/common';

import Message from './messagesMessageView';
import MessageInput from './messagesInputView';

// Styles
import './styles/messages.scss';

class Conversation extends Component {
    constructor() {
        super();

        this.state = {
            autoScrolled: false
        };

        this.onInputChangeHeight = this.onInputChangeHeight.bind(this);
        this.onClickSend = this.onClickSend.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.autoScroll = this.autoScroll.bind(this);
        this.groupDayMessages = this.groupDayMessages.bind(this);

        this.renderConversationContents = this.renderConversationContents.bind(this);
    }

    componentDidMount() {
        this.autoScroll('100%');
        if (this.props.subscribeToMessages) this.unsubscribe = this.props.subscribeToMessages();
        this.emptyStateTimeout = setTimeout(() => {
            if (!_.get(this.props, 'messages.length')) this.setState({ empty: true });
        }, 2000);
    }

    getSnapshotBeforeUpdate(prevProps) {
        const snapshot = {};
        if (
            (_.get(this.props, 'messages.length') > 0 &&
            _.get(prevProps, 'messages.length') === 0)
        ) {
            snapshot.firstLoadMessages = true;
            return snapshot;
        }

        if (
            (_.get(this.props, 'messages.length') >
            _.get(prevProps, 'messages.length'))
        ) {
            snapshot.newMessage = true;
        }

        if (snapshot.newMessage) {
            const el = document.querySelector('.messagesConversationContents.wrapperRoot .wrapperContainerBodyScrollable');
            snapshot.scrollBottom = (el.scrollHeight - el.clientHeight) - el.scrollTop;
        }

        return snapshot;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.firstLoadMessages) {
            this.autoScroll('100%');
        }
        if (snapshot.newMessage) {
            /* eslint-disable */
            this.setState({ outgoingMessage: null });
            /* eslint-enable */
        }
        if (_.has(snapshot, 'scrollBottom') && snapshot.scrollBottom < 160) {
            this.autoScroll('100%', true);
        }
        // mark unseen messages as seen
        const _messages_id = _.chain(this.props.messages)
            .filter(message => (
                message._to_user_id === utils.getUserId()
                && !message.seen
            ))
            .map(message => message._id)
            .value();
        if (_messages_id && _messages_id.length > 0) {
            this.props.markMultipleMessagesAsSeen(_messages_id);
        }
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }
        if (this.emptyStateTimeout) {
            clearTimeout(this.emptyStateTimeout);
        }
    }

    onInputChangeHeight(delta) {
        if (Number.isNaN(delta)) {
            return;
        }
        if (delta === 0) {
            return;
        }
        this.autoScroll(delta, true);
    }

    onClickSend(body) {
        return new Promise((resolve) => {
            if (this.props.messageSendPrompts) {
                for (let i = 0; i < this.props.messageSendPrompts.length; i += 1) {
                    const prompt = this.props.messageSendPrompts[i];
                    if (body.match(prompt.pattern)) {
                        this.setState({
                            messageSendPrompt: {
                                body: prompt.body,
                                message: body,
                                inputResolver: resolve,
                                strict: prompt.strict
                            }
                        });
                        return false;
                    }
                }
            }
            this.sendMessage(body);
            resolve(true);
        });
    }

    sendMessage(body) {
        const message = {
            type: this.props.messageFilters.type,
            direction: this.props.messageFilters.direction.sent,
            _from_user_id: utils.getUserId(),
            body,
            context: _.pick(this.props.messageFilters, ['_booking_id', '_user_id', '_live_demo_room_id'])
        };
        this.setState({ outgoingMessage: { ...message, _id: 'outgoingMessage', status: 'sending' } });

        this.props.createMessage(message)
            .then(() => {
                this.setState({ outgoingMessage: null });
            })
            .catch((error) => {
                this.setState({ outgoingMessage: { ...message, _id: 'outgoingMessage', status: 'failed' } });
                console.error(`createMessage error: ${error.message}`);
            });
        this.autoScroll('100%', true);
    }

    async autoScroll(delta = '100%', smooth = false) {
        if (this.scrollTimeout || this.manualScroll) {
            return false;
        }
        await utils.waitForDomElement('.messagesConversationContents.wrapperRoot .wrapperContainerBodyScrollable .dayMessages');
        const el = document.querySelector('.messagesConversationContents.wrapperRoot .wrapperContainerBodyScrollable');
        if (!el) {
            return false;
        }
        el.style.scrollBehavior = smooth ? 'smooth' : 'unset';
        if (delta === '100%') {
            this.scrollTimeout = setTimeout(() => {
                el.scrollTop = el.scrollHeight;
                this.scrollTimeout = null;
                this.setState({ autoScrolled: true });
            }, 150);
            return el;
        }
        if (!Number.isNaN(delta)) {
            this.scrollTimeout = null;
            el.scrollTop += delta;
            this.setState({ autoScrolled: true });
            return el;
        }
        return null;
    }

    groupDayMessages(input) {
        const messages = _.chain(input)
            .sortBy('created')
            .groupBy(item => moment(item.created).startOf('day').format())
            .map((group, day) => [
                moment(day),
                group
            ])
            .sortBy('day')
            .value();

        if (this.state.outgoingMessage && messages.length > 0) {
            messages[messages.length - 1][1].push(this.state.outgoingMessage);
        }

        return messages;
    }

    renderDayMessages([date, messages]) {
        return (
            <div className="dayMessages" key={date.format()}>
                <h6>
                {moment(date).calendar(null, {
                    sameDay: '[Today]',
                    lastDay: '[Yesterday]',
                    lastWeek: '[Last] dddd',
                    sameElse: 'D MMMM YYYY'
                })}
                </h6>
                {messages.map((message) => {
                    if (message.seen || typeof message.type !== 'number') {
                        return <Message key={message._id} message={message} />;
                    }
                    return (
                        <Query
                          query={messagesSeen}
                          variables={{ _messages_id: [message._id] }}
                          // fetchPolicy="network-only"
                          key={`subscription_${message._id}`}
                        >
                        {({ subscribeToMore }) => {
                            return (
                                <Message
                                    message={message}
                                    subscribeToSeen={() => subscribeToMore({
                                        document: messagesSeenSubscription,
                                        variables: { _messages_id: [message._id] },
                                        updateQuery: (prev, { subscriptionData }) => {
                                            console.log({ prev, subscriptionData });
                                            return prev;
                                        }
                                    })}
                                />
                            );
                        }}
                        </Query>
                    )
                })}
            </div>
        );
    }

    renderConversationContents(messages = []) {
        if (messages.length === 0) {
            return (
                <div className="dayMessages emptyState" />
            );
        }
        return this.groupDayMessages(messages).map(this.renderDayMessages);
    }

    render() {
        return (
            <Wrapper
                header={_.get(this.props, 'wrapperHeader', 'mainHeader')}
                className="messagesConversationWrapper"
                footerContents={<MessageInput sendMessage={this.onClickSend} onChangeHeight={this.onInputChangeHeight} />}
            >
                <Wrapper
                    className="messagesConversationContents"
                    headerContents={this.props.header}
                >
                    {this.renderConversationContents(this.props.messages)}
                    {(!this.state.autoScrolled || (!this.state.empty && this.props.messages.length === 0 && !this.state.outgoingMessage))
                        && <LoadingOverlay />
                    }
                </Wrapper>
                <Dialog
                  open={!!this.state.messageSendPrompt}
                  // className="confirmAttendanceDialog finished onboardingDialog"
                  customActions={[{ label: 'Back' }]}
                  modal={false}
                  onCancel={() => {
                      if (_.get(this.state, 'messageSendPrompt.inputResolver', null)) {
                          this.state.messageSendPrompt.inputResolver(false);
                      }
                      this.setState({ messageSendPrompt: null });
                  }}
                  closeButton
                  title="Whoops"
                >
                {_.get(this.state, 'messageSendPrompt.body', null)}
                {!_.get(this.state, 'messageSendPrompt.strict', null)
                    && _.get(this.state, 'messageSendPrompt.message', null)
                    && (
                        <p>
                            <a
                                onClick={() => {
                                    this.sendMessage(this.state.messageSendPrompt.message);
                                    if (this.state.messageSendPrompt.inputResolver) {
                                        this.state.messageSendPrompt.inputResolver(true);
                                    }
                                    this.setState({ messageSendPrompt: null });
                                }}
                                href="#"
                                className="link"
                            >
                                No, I want to send this message to the session facilitator
                            </a>
                        </p>
                    )
                }
                </Dialog>
            </Wrapper>
        );
    }
}

const createMessageContainer = graphql(createMessage, {
    props: ({ mutate }) => ({
        createMessage: message => mutate({
            variables: { message }
        }),
    }),
});
const markSeenContainer = graphql(markMultipleMessagesAsSeen, {
    props: ({ mutate }) => ({
        markMultipleMessagesAsSeen: _messages_id => mutate({
            variables: { _messages_id }
        }),
    }),
});

export default compose(createMessageContainer, markSeenContainer)(Conversation);
