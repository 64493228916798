import gql from 'graphql-tag';

export default gql`
    mutation login($email:String, $password: String, $googleToken: String) {
        login(email: $email, password: $password, googleToken: $googleToken) {
            _id
            access_token
            type {
                participant
            }
            email
            meta {
                identity {
                    firstname
                    lastname
                }
                acquisition {
                    source
                    campaign
                }
            }
        }
    }
`;
