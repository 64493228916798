import React, { Component } from 'react';
// import moment from 'lib/moment';
import uuidv5 from 'uuid/v5';
import _ from 'lodash';

import AddToCalendar from 'react-add-to-calendar';

import { utils } from '../../../../src/lib/utils';
// import { mixpanel } from '../../../../src/lib/analytics';
import { BOOKING_TYPE, BOOKING_IN_CONTEXT_LOCATION_TYPE } from '../../../../src/lib/data/constants';

import './styles/AddSessionToCalendar.scss';

class AddSessionToCalendar extends Component {
    constructor() {
        super();
        const namespace = uuidv5('https://my.askable.com', uuidv5.URL);
        this._id = `AddSessionToCalendar-${uuidv5(`AddSessionToCalendar|${Date.now().toString(16)}`, namespace)}`;
    }

    componentDidMount() {
        document.getElementById(this._id).addEventListener('click', this.clickListener);
    }

    componentWillUnmount() {
        document.getElementById(this._id).removeEventListener('click', this.clickListener);
    }

    clickListener(event) {
        let className = null;
        const link = utils.getNearestAncestor(event.target, 'tagName', 'A');
        if (link) {
            className = Array.from(link.classList).sort().join(' ');
        }
        const eventProps = {};
        switch (className) {
            case 'AddSessionToCalendar-link card-link':
                eventProps.Action = 'Open options list';
            break;
            case 'AddSessionToCalendar-link card-link open':
                eventProps.Action = 'Close options list';
            break;
            case 'outlook-link':
                eventProps.Action = 'Click option';
                eventProps['Calendar option'] = 'Calendar app';
            break;
            case 'google-link':
                eventProps.Action = 'Click option';
                eventProps['Calendar option'] = 'Google Calendar';
            break;
            default:
        }

        // if (eventProps.Action) {
        //     mixpanel.track({ event: 'Add Session to Calendar', payload: eventProps });
        // }
    }

    render() {
        const { booking, session, location } = this.props;

        const event = {
            startTime: new Date(session.start).toJSON(),
            endTime: new Date(session.end || (session.start + 60000)).toJSON(),
            description: ''
        };

        const title = ['Askable session'];
        switch (booking.type) {
            case BOOKING_TYPE.FACE_TO_FACE:
                if (_.get(booking, 'config.in_context.location_type')) {
                    switch (booking.config.in_context.location_type) {
                        case BOOKING_IN_CONTEXT_LOCATION_TYPE.HOME:
                            title.push('@ your home');
                        break;
                        case BOOKING_IN_CONTEXT_LOCATION_TYPE.WORKPLACE:
                            title.push('@ your workplace');
                        break;
                        // case BOOKING_IN_CONTEXT_LOCATION_TYPE.NEUTRAL:
                        default:
                            title.push('(In-context)');
                    }
                } else if (_.get(location, 'name')) {
                    title.push(' - ');
                    title.push(location.name);
                } else if (_.get(location, 'city')) {
                    title.push(' - ');
                    title.push(location.city);
                }
            break;
            case BOOKING_TYPE.REMOTE:
                title.push('(remote)');
            break;
            default:
        }

        if (location) {
            event.location = `
                ${location.level || ''}
                ${location.street1 || ''}
                ${location.street2 || ''},
                ${location.city || ''}
                ${location.state || ''}
                ${location.postal_code || ''}
            `;

            // event.location = event.location.replace();
            event.location = event.location.replace(/\s+,/g, ',', event.location);
            event.location = event.location.replace(/\s+/g, ' ', event.location);
            event.location = event.location.replace(/^[\s,]+|[\s,]+$/g, '', event.location);
        }

        event.title = title.join(' ');

        const buttonClass = 'card-link AddSessionToCalendar-link';

        // const contactDetails = _.filter([
        //     _.get(booking, 'config.contact.name'),
        //     _.get(booking, 'config.contact.phone'),
        //     _.get(booking, 'config.contact.email'),
        // ]);
        // console.log('contactDetails', contactDetails);
        // if (contactDetails.length > 0) {
        //     event.description += `Session contact:\\n${contactDetails.join('\\n')}\\n\\n`;
        // }

        // if (_.get(booking, 'config.information.special_instructions')) {
        //     event.description += `Special instructions\\n${JSON.stringify(booking.config.information.special_instructions)}\\n\\n`;
        // }

        event.description += `https://my.askable.com/booking/${booking._id}`;

        return (
            <div className="AddSessionToCalendar" id={this._id}>
                <AddToCalendar
                    event={event}
                    buttonLabel="Add to my calendar"
                    buttonClassClosed={buttonClass}
                    buttonClassOpen="open"
                    listItems={[{ outlook: 'Calendar App' }, { google: 'Google Calendar' }]}
                    displayItemIcons={false}
                />
            </div>
        );
    }
}

export default AddSessionToCalendar;
