import React from 'react';
import _ from 'lodash';
import moment from 'lib/moment';
import { utils } from 'lib/utils';

import BookingOpportunityCard from './bookingOpportunityCard';

const OpportunityListCard = ({ booking }) => {
  const sessions = _.sortBy(booking.session, 'start');
  const dates = [moment(sessions[0].start).format('ddd Do MMM'), moment(sessions[sessions.length - 1].end).format('ddd Do MMM')];

  let location = null;
  let sessionType = null;
  let dateString = dates[0] === dates[1] ? dates[0] : `${dates[0].replace(dates[1].replace(/^.+\s.+(?=\s)/, ''), '')} - ${dates[1]}`;
  let durationString = utils.sessionDurationString(booking.config.session.duration);

  switch (booking.config.session.type) {
    case 1:
      sessionType = 'One on one';
      break;
    case 2:
      sessionType = 'Group session';
      break;
    case 3:
      sessionType = 'Multi-group';
      break;
    default:
  }
  switch (booking.type) {
    case 1:
      if (booking.config.location) {
        location = booking.config.location.city || booking.config.location.state || null;
      }
      break;
    case 2:
      location = 'Remote / Video chat';
      break;
    case 3:
      location = 'Online task';
      if (_.get(booking, 'config.online_task.type') === 2) {
        location = 'Survey';
      }
      if (_.get(booking, 'config.online_task.type') === 3) {
        location = 'Self-managed video call';
      }
      sessionType = null;
      dateString = `Until ${moment(sessions[sessions.length - 1].end).formatZoneFallback('ddd Do MMM, h:mm A')}`;
      durationString = `About ${durationString}`;
      break;
    case 4:
      location = 'Multi-stage task'; // DIARY STUDY
      sessionType = null;
      dateString = `Until ${moment(sessions[sessions.length - 1].end).formatZoneFallback('ddd Do MMM')}`;
      durationString = utils.diaryStudyDuration(booking);
      break;
    default:
  }

  return (
    <BookingOpportunityCard
      key={booking._id}
      bookingId={booking._id}
      bookingType={booking.type}
      eligibilitySummary={booking.config.criteria ? utils.getEligibilitySummary(booking.config.criteria) : []}
      date={dateString}
      short_description={_.get(booking, 'config.information.short_description')}
      location={location}
      duration={durationString}
      sessionType={sessionType}
      className="opportunity"
      incentive={
        <span className="incentive">
          {booking.config.incentive.currency_symbol ? <span className="currency">{booking.config.incentive.currency_symbol}</span> : null}
          {booking.config.incentive.value}
        </span>
      }
    />
  );
};

export default OpportunityListCard;
