import gql from 'graphql-tag';
import { messagesFieldsFragment } from 'data/fragments/messages/messagesFields';

export default gql`
    query participantBookingsWithMessages ($_user_id: ID!) {
      participantBookingsWithMessages (_user_id: $_user_id) {
        _id type status
        config {
          incentive {
            currency_code
            currency_symbol
            country_code
            value
          }
          location { country city region }
          in_context { location_type }
          contact { name }
          online_task { type tool }
        }
        messages { ...messagesFields }
        booking_participant {
          _id status cancel
          session { _id start end }
        }
      }
    }
    ${messagesFieldsFragment}
`;
