import React from 'react';
import _ from 'lodash';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

export default ({ validation, children }) => (
    <Query
        query={gql`query userAgent { userAgent { device { type } browser { name } } }`}
    >
    {({ data }) => {
        if (!validation) return null;
        if (React.Children.count(children) === 0) return null;

        const outcomes = React.Children.toArray(children);
        if (data && data.userAgent) {
            if (!validation(data.userAgent)) return outcomes[1]; // return the second child if the test fails
            return outcomes[0]; // return the first child if the useragent test passes, or if waiting on data
        }
        if (outcomes[2] !== undefined) return outcomes[2]; // if data hasn't loaded, return third child
        return outcomes[0]; // or fall back to first child
    }}
    </Query>
);
