import React, { Component } from 'react';
import { Dialog, Button } from '../index';

import './styles/DialogSeries.scss';

// Component created to display confirmation messages
class DialogSeries extends Component {
    constructor(props) {
        super(props);

        this.state = { dialogIndex: 0 };
        this.children = this.props.children.length ? this.props.children : [this.props.children];
    }

    mainButton(props) {
        return (
            <Button
                labelColor="#fff"
                bgColor="#FF5266"
                type="button"
                // style={{
                //     display: 'block',
                //     margin: '40px auto 10px',
                //     maxWidth: '200px'
                // }}
                className="dialogSeriesMainButton"
                {...props}
            />
        );
    }

    render() {
        return this.children.map((child, i) => {
            // console.log(child);
            // return <div key={child.key}>{child}</div>;
            return (
                <Dialog
                    key={child.key}
                    open={this.state.dialogIndex === i}
                    className="dialogSeriesDialog"
                    customActions={[]}
                    {...child.props || {}}
                >
                    {child.props.children}
                    {
                        this.mainButton(i < this.children.length - 1 ? {
                            label: 'Next',
                            onClick: () => {
                                // this.setState({ dialogIndex: null });
                                // setTimeout(() => {
                                //     this.setState({ dialogIndex: i + 1 });
                                // }, 50);
                                this.setState({ dialogIndex: i + 1 });
                            }
                        } : {
                            label: 'Finish',
                            onClick: () => {
                                this.setState({ dialogIndex: null });
                                if (this.props.onFinishSeries) {
                                    this.props.onFinishSeries();
                                }
                            }
                        })
                    }
                </Dialog>
            );
        });
    }
}

export default DialogSeries;
