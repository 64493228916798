import React, { useEffect } from 'react';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { LoadingOverlay } from 'components/common';

const sessionAppUrl = (() => {
  const SESSION_DEV_URL = 'https://sessions-dev.askable.com';
  const SESSION_PROD_URL = 'https://sessions.askable.com';
  const SESSION_STAGING_URL = 'https://sessions-staging.askable.com';
  const SESSION_LOCAL_URL = 'http://localhost:3001';
  const hostname = window.location.hostname.replace(/^([^-]+)-([^.-]*)\.my/, '$1.my');
  if (hostname === 'my.askable.com') {
    return SESSION_PROD_URL;
  }
  if (hostname === 'my-dev.askable.com') {
    return SESSION_DEV_URL;
  }
  if (hostname.match(/.+\.my\.askable\.com/)) {
    const instanceMatch = hostname.match(/(.+)\.my\.askable\.com/);
    switch (instanceMatch[1]) {
      case 'iframe':
      case 'beta':
        return SESSION_PROD_URL;
      default:
        return SESSION_STAGING_URL;
    }
  }
  return SESSION_LOCAL_URL;
})();

export function AskableSessionsView({ match, history }) {
  const { isLoading } = useKindeAuth();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const { sessionCode } = match.params;

    if (!sessionCode) {
      history.replace('/');
      return;
    }

    window.location.replace(`${sessionAppUrl}/api/auth/login?post_login_redirect_url=${sessionAppUrl}/${sessionCode}`);
  }, []);

  return <LoadingOverlay />;
}
