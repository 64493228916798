import React, { Component, Fragment } from 'react';
import moment from 'lib/moment';

import { utils } from 'lib/utils';

import './styles/RecordingIndicator.scss';

class RecordingIndicator extends Component {
    constructor() {
        super();
        this.state = { blink: true };

        this.blinkTime = 700;
        this.getElapsedTime = this.getElapsedTime.bind(this);
    }

    componentDidMount() {
        this.setState({ startTime: this.props.startTime || Date.now() }); // eslint-disable-line
        this.getElapsedTime(this.props.delay);
    }

    componentWillUnmount() {
        if (this.timeout) window.clearTimeout(this.timeout);
    }

    getElapsedTime(delay = 100) {
        this.setState((state) => {
            if (!state.startTime) return;
            state.elapsed = moment.duration(Date.now() - state.startTime);
            state.blink = !Math.round((state.elapsed / (this.blinkTime * 2)) % 1);
            return state;
        });
        this.timeout = window.setTimeout(this.getElapsedTime, delay, delay);
    }

    render() {
        return (
            <div className="RecordingIndicator">
                <span className={`blink ${this.state.blink ? 'on' : 'off'}`}>●</span>
                <span className="timer">
                    {utils.getDurationAsTimer(this.state.elapsed, 'h:mm:ss')}
                </span>
            </div>
        );
    }
}

export default RecordingIndicator;
