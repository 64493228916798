import React from 'react';
import { Link } from 'react-router-dom';
import Wrapper from 'components/common/Wrapper/view';
import { HELP_URL } from '../../lib/data/constants';

export default function () {
    return (
        <Wrapper
          header="mainHeader"
        >
            <h1>Page not found</h1>
            <p>If you think this is a mistake, <a href={HELP_URL} className="link">please contact Askable</a>.</p>
        </Wrapper>
    );
}
