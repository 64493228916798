import gql from "graphql-tag";

export default gql`
  mutation participantConfirmOpportunity(
    $booking_id: ID!
    $session_ids: [ID]!
  ) {
    participantConfirmOpportunity(
      _booking_id: $booking_id
      _session_ids: $session_ids
    ) {
      _id
    }
  }
`;
