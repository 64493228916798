import React, { Component } from 'react';
import TwilioVideo from 'twilio-video';
import _ from 'lodash';

// import { graphql, compose, Mutation } from 'react-apollo';
import { Button, AudioVisualiser } from 'components/common';

import Overlay from '../askable-live-overlay-portal.view';
import MediaTrack from '../askable-live-media-track.view';

class SetupCheckCamera extends Component {
    constructor() {
        super();
        this.state = { overlay: 'pending' };

        this.connectLocalTracks = this.connectLocalTracks.bind(this);
    }

    componentDidMount() {
        this.connectLocalTracks();
    }

    componentWillUnmount() {
        if (this.state.audioStream && this.state.audioStream.getTracks) {
            for (const track of this.state.audioStream.getTracks()) {
                if (track.stop) track.stop();
                if (this.state.audioStream.removeTrack) this.state.audioStream.removeTrack(track);
            }
        }
    }

    async connectLocalTracks() {
        const nullStateTimeout = setTimeout(() => {
            this.setState({ overlay: 'prompt' });
        }, 1000);


        const localTracks = await TwilioVideo.createLocalTracks({
            audio: true,
            video: true
        })
            .catch((e) => {
                console.error('Error setting up video tracks', e);
                if (nullStateTimeout) {
                    clearTimeout(nullStateTimeout);
                }
                this.setState({ overlay: 'denied' });
                return false;
            });

        if (nullStateTimeout) {
            clearTimeout(nullStateTimeout);
        }

        if (!localTracks) {
            return false;
        }

        const audioStream = await window.navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        })
            .catch((e) => {
                console.error('Error setting up video audio stream', e);
                return false;
            });

        this.setState({ localTracks, audioStream, overlay: null });
    }

    render() {
        let overlay = null;
        switch (this.state.overlay) {
            case 'denied':
                overlay = <Overlay.CamPermissionsUnblock />;
            break;
            case 'prompt':
                overlay = <Overlay.CamPermissionsPrompt />;
            break;
            case 'pending':
                overlay = <Overlay className="message-overlay left"><div className="content" /></Overlay>;
            break;
            default:
        }

        const camTrack = _.find(this.state.localTracks || [], ['kind', 'video']);
        const micTrack = _.find(this.state.localTracks || [], ['kind', 'audio']);

        return (
            <div className="centre-message local-track-check">
                <div className="flex">
                    <div className="col camera">
                        <div className="track" id="setup-check-local-track-camera">
                            {camTrack && <MediaTrack track={camTrack} id={camTrack.id} />}
                        </div>
                        <h2>Webcam</h2>
                        <p>Make sure the camera is working and that you can see yourself.</p>
                        <p><a href="https://askb.co/live-troubleshoot-camera" className="link" target="_blank" rel="noopener noreferrer">Webcam troubleshooting</a></p>
                    </div>
                    <div className="col microphone">
                        <div className="track" id="setup-check-local-track-microphone">
                            {this.state.audioStream && (
                                <AudioVisualiser
                                    audio={this.state.audioStream}
                                    className="mic-visualizer"
                                    width={360}
                                    height={240}
                                    canvas={{
                                        lineWidth: 2,
                                        strokeStyle: '#1CFF40',
                                    }}
                                />
                            )}
                        </div>
                        <h2>Microphone</h2>
                        <p>Say something and make sure the lines are moving.</p>
                        <p><a href="https://askb.co/live-troubleshoot-mic" className="link" target="_blank" rel="noopener noreferrer">Microphone troubleshooting</a></p>
                    </div>
                </div>

                <Button
                    label="Next"
                    labelColor="#fff"
                    bgColor="#FF5266"
                    type="button"
                    className="mtop10 medium"
                    onClick={() => { this.props.onContinue(camTrack, micTrack); }}
                />
                {overlay}
            </div>
        );
    }
}

export default SetupCheckCamera;
