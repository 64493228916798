import React, { Component } from 'react';
import _ from 'lodash';

import {
    LocationAutocomplete,
    Dialog,
} from 'components/common';

class LocationAutocompleteDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Dialog
                open={!!this.props.open}
                disabled={!_.get(this.state, 'location.city')}
                onCancel={() => {
                    this.props.onChange(null);
                    this.setState({ location: null });
                }}
                onConfirm={() => {
                    this.props.onChange(this.state.location);
                    this.setState({ location: null });
                }}
                title="What town/suburb do you live in?"
            >
              <LocationAutocomplete
                types="(regions)"
                onChange={(location) => {
                  this.setState({ location });
                }}
                userLocation={this.props.userLocation}
              />
            </Dialog>
        );
    }
}

export default LocationAutocompleteDialog;
