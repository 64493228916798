import React, { Component } from 'react';
// import { graphql, compose } from 'react-apollo';
// import { Redirect } from 'react-router-dom';
// import dotObject from 'dot-object';
import _ from 'lodash';
import moment from 'lib/moment';

import SwitchList from 'components/common/SwitchList/view';

// dotObject.override = true;

class BookingScheduleSessionSelectView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.sessionsInit(props),
      defaultChecked: this.props.defaultChecked || [],
    };

    this.sessionsInit = this.sessionsInit.bind(this);
    this.updateSelection = this.updateSelection.bind(this);

    this.updateStateFromProps = this.updateStateFromProps.bind(this);
  }

  componentWillMount() {
      this.updateStateFromProps(this.props);
  }

  componentDidMount() {
    this.sessionsInit(this.props);
  }

  componentWillReceiveProps(newProps) {
      this.updateStateFromProps(newProps);
  }

  updateStateFromProps(newProps) {
    if (
      JSON.stringify(newProps.sessions) !== JSON.stringify(this.props.sessions)
    ) {
      this.setState({ ...this.sessionsInit(newProps) });
    }
  }

  sessionsInit(props) {
    const dailySessions = {};
    const dailyDefaults = {};
    const sessions = _.orderBy(props.sessions, ['start', 'asc'], ['end', 'asc']);

    _.forEach(sessions, (session) => {
      const date = moment(session.start).format('YYYY-MM-DD');
      const time = `${moment(session.start).format('YYYYMMDDHHmm')}${moment(session.end).format('YYYYMMDDHHmm')}`;
      if (!dailySessions[date]) {
        dailySessions[date] = {};
      }
      if (!dailySessions[date][time]) {
        dailySessions[date][time] = [];
      }
      dailySessions[date][time].push(session);
      if (props.defaultChecked && props.defaultChecked.indexOf(session._id) > -1) {
          if (!dailyDefaults[date]) {
            dailyDefaults[date] = {};
          }
          if (!dailyDefaults[date][time]) {
            dailyDefaults[date][time] = [];
          }
          dailyDefaults[date][time].push(session._id);
      }
    });
    return {
      selectedSessions: {},
      dailySessions,
      dailyDefaults
    };
  }

  updateSelection(date, selected) {
    const selectedSessions = this.state ? Object.assign({}, this.state.selectedSessions) : {};
    selectedSessions[date] = [];
    _.forEach(selected, (sessions) => {
        _.forEach(JSON.parse(sessions), (_id) => {
            selectedSessions[date].push(_id);
        });
    });
    this.setState({ selectedSessions });
    this.props.selectionChangeCallback(_.uniq(_.concat(..._.values(selectedSessions))));
  }

  render() {
    if (_.keys(this.state.dailySessions).length === 0) {
        return (
            <div className="textCenter">
              <h1>There are no more available sessions</h1>
            </div>
        );
    }
    return (
      <div className="mbottom20">
          <div className="selectSesssionsContainer">
              <div className="listColumn">
                {_.keys(this.state.dailySessions).map((date) => {
                  return (
                    <div key={`sessions_${date}`}>
                      <h3>{moment(date).format('dddd Do MMM')}</h3>
                      <SwitchList
                        type="checkbox"
                        name={`switchList_${date}`}
                        className="mbottom20"
                        onValueChange={(selected) => { this.updateSelection(date, selected); }}
                        defaultChecked={this.state.dailyDefaults[date] ? _.values(_.mapValues(this.state.dailyDefaults[date], sessions => JSON.stringify(sessions))) : []}
                        options={_.values(_.mapValues(this.state.dailySessions[date], (sessions, time) => {
                          return {
                            value: JSON.stringify(sessions.map(session => session._id)),
                            // value: sessions[0]._id,
                            label: `${moment(sessions[0].start).format('h:mm A')} - ${moment(sessions[0].end).format('YYYY-MM-DD') !== date ? moment(sessions[0].end).format('dddd Do MMM ') : ''}${moment(sessions[0].end).formatZoneFallback('h:mm A')}`,
                            key: time
                          };
                        }))}
                      />
                    </div>
                  );
                })}
              </div>
          </div>
      </div>
    );
  }
}

export default BookingScheduleSessionSelectView;
