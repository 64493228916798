import React, { Component } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';

export const MAPS_API_KEY = 'AIzaSyAl-1qGaviMNX0L8BYB2lxSqrFAunlOgAU';

class MapComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lat: '',
            lng: ''
        };

        this.updateState = this.updateState.bind(this);
    }

    componentWillMount() {
        this.updateState(this.props);
    }

    componentWillReceiveProps(newProps) {
        this.updateState(newProps);
    }

    shouldComponentUpdate(nextProps) {
        if (this.state.lat !== nextProps.lat) {
            return true;
        }
        return false;
    }

    updateState(props) {
        if (props.lat !== this.state.lat) {
            this.setState({
                lat: props.lat,
                lng: props.lng
            });
        }
    }

    render() {
        const uiOptions = this.props.ui || {};
        /* {
            disableDefaultUI: boolean,
            zoomControl: boolean,
            mapTypeControl: boolean,
            scaleControl: boolean,
            streetViewControl: boolean,
            rotateControl: boolean,
            fullscreenControl: boolean
        } */

        return (
            <Map
                google={this.props.google || null}
                zoom={this.props.zoom || 15}
                className={`map ${this.props.className}`}
                center={{ lat: this.state.lat, lng: this.state.lng }}
                initialCenter={{ lat: this.state.lat, lng: this.state.lng }}
                onReady={this.fetchPlaces}
                scrollwheel={this.props.scrollwheel ? this.props.scrollwheel : false}
                gestureHandling={this.props.gestureHandling ? this.props.gestureHandling : 'cooperative'}
                {...uiOptions}
            >
                {this.props.children}
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: MAPS_API_KEY
})(MapComponent);
