import React from 'react';
import Spinner from 'react-spinkit';

// Styles
import './styles/LoadingOverlay.scss';

const LoadingOverlay = (props) => {
    return (
        <div className="overlay-container" style={props.style ? props.style : { Zindex: 999 }}>
            <div id="loading-overlay" />
            <Spinner fadeIn="none" name="three-bounce" color="#FF5266" />
        </div>
    );
};

export default LoadingOverlay;
