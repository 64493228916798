import gql from 'graphql-tag';

export default gql`
  query validatePayPal($client_code: String!) {
    validatePayPal(client_code: $client_code) {
      isValidPayPalLogin
      email
    }
  }
`;
