

export const isMobileOnlyAskableLive = (booking) => {
  const requiredDevice = booking?.config?.online_task?.required_device?.[0];
  // 1 === desktop, 2 === mobile, null === any
  const isMobileOnly = requiredDevice === 2;

  const isAskableLive = booking?.config?.remote?.askable_live === true;

  return isMobileOnly && isAskableLive;
}