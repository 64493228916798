import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

import { SwitchList, Button, Input } from 'components/common';

class BookingScreenerQuestionView extends Component {
  constructor(props) {
    super(props);

    this.state = {
        valid: false
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.questionBody = this.questionBody.bind(this);
  }

  onValueChange(value) {
    if (!this.props.onValueChange) {
      return null;
    }
    this.setState({ valid: value.length > 0 });
    this.props.onValueChange(this.props.question, value);
  }

  onFormSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    event.preventDefault();
    this.props.onSubmit();
  }

  questionBody(question) {
      if (!question.config) {
          return null;
      }
      if (question.config.type === 2) {
          return (
              <div>
                  <Input
                      name={`input_${this.props.question._id}`}
                      value={this.state.value || ''}
                      hintText="Enter your answer here"
                      multiLine
                      rowsMax={10}
                      onChange={(value) => {
                          const cleanValue = value.replace(/^s+|\s+$/, '');
                          this.setState({ value, valid: cleanValue.length > 0 });
                          if (this.props.onValueChange) {
                            this.props.onValueChange(this.props.question, cleanValue);
                          }
                      }}
                  />
              </div>
          );
      }
      if (question.options) {
          return (
              <div className="mtop40">
                  {this.props.question.config.multiple_selection ? <p className="fontSmall mbottom12">Choose as many as you like</p> : null}
                  <SwitchList
                    type={this.props.question.config.multiple_selection ? 'checkbox' : 'radio'}
                    name={`switchList_${this.props.question._id}`}
                    onValueChange={this.onValueChange}
                    options={this.props.question.options.map((option) => {
                      return {
                          value: option._id,
                          label: option.label,
                          key: option._id,
                          exclusive: this.props.question.config.none_of_the_above && option.label === 'None of the above'
                      };
                    })}
                  />
              </div>
          );
      }
  }

  render() {
    return (
      <form onSubmit={this.onFormSubmit} className="wholePageQuestion">
        <input
          type="submit"
          value="Submit"
          style={{
            position: 'absolute',
            left: '-9999px',
            tabIndex: -1
          }}
        />
        <h1>{this.props.question.title}</h1>
        {
            (this.props.question.description || '').replace(/^\s+|\s+$/g, '') > '' ?
            <p>{this.props.question.description}</p> :
            null
        }
        {this.questionBody(this.props.question)}
        <Button
            label="Next"
            // label={`Next ${this.this.props.activePage}`}
            labelColor="#fff"
            bgColor="#FF5266"
            className="btnNext mtop60"
            // className={`btnNext mtop20${!this.state.valid ? '' : ' disabled'}`}
            disabled={!this.state.valid}
            type="submit"
        />
      </form>
    );
  }
}

export default BookingScreenerQuestionView;
