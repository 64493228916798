import React, { Component, Fragment } from "react";

import { FlatButton, IconButton, Dialog } from "material-ui";
import { Circle } from "google-maps-react";
import Map, { MAPS_API_KEY } from "../Map/view";

import NavigationClose from "material-ui/svg-icons/navigation/close";

import { utils } from "lib/utils";

import "./styles/StaticMapStyles.scss";

class StaticMap extends Component {
  constructor() {
    super();
    this.state = {
      fullscreen: false,
    };
  }

  render() {
    const { latitude, longitude, height, maxWidth, openMap } = this.props;
    const staticMapUrl = new URL(
      "https://maps.googleapis.com/maps/api/staticmap"
    );
    staticMapUrl.searchParams.append("center", `${latitude},${longitude}`);
    staticMapUrl.searchParams.append("zoom", "14");
    staticMapUrl.searchParams.append("size", `${maxWidth}x${height + 40}`);
    staticMapUrl.searchParams.append("format", "png");
    staticMapUrl.searchParams.append("key", MAPS_API_KEY);
    return (
      <Fragment>
        <div
          className="staticMapContainer"
          style={{ width: `${maxWidth}px`, height: `${height}px` }}
        >
          <img
            src={staticMapUrl.toString()}
            height={height + 40}
            alt="Approximate location that the sessions will be held"
          />
          <span className="bubble" />
          {openMap && (
            <div className="button">
              <FlatButton
                label="Open map"
                onClick={() => {
                  // mixpanel.track({
                  //     event: 'Open full-screen map',
                  //     payload: this.props.tracking
                  // });
                  this.setState({ fullscreen: true });
                }}
              />
            </div>
          )}
        </div>
        {this.state.fullscreen && (
          <Dialog open contentClassName="fullScreenDialogContent">
            <div className="fullScreenMap">
              <Map
                lat={latitude}
                lng={longitude}
                ui={{ fullscreenControl: false }}
              >
                <Circle
                  center={{ lat: latitude, lng: longitude }}
                  radius={300}
                  strokeColor="#FF5266"
                  strokeWeight={2}
                  fillColor="#FF5266"
                  fillOpacity={0.1}
                />
              </Map>
              <div className="button">
                <IconButton
                  onClick={() => {
                    this.setState({ fullscreen: false });
                  }}
                >
                  <NavigationClose />
                </IconButton>
              </div>
            </div>
          </Dialog>
        )}
      </Fragment>
    );
  }
}

export default StaticMap;
