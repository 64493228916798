import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query, graphql, compose } from 'react-apollo';
import { Redirect, Link } from 'react-router-dom';

import _ from 'lodash';

import { utils } from 'lib/utils';

import { LoadingOverlay, Wrapper, Header, Button } from 'components/common';

import PopupCheckDevice from 'components/booking/apply/quant/apply-quant-device-check';
import PopupRequirementNotices from 'components/booking/apply/quant/apply-quant-requirement-screens';

import participantStartLongitudinalTask from 'data/mutations/participantStartLongitudinalTask';
import participantJoinQuantWaitlist from 'data/mutations/participantJoinQuantWaitlist';

import AgreementDialog from './apply-agreement-dialog';

const ScreenerPage = props => (
    <div className="screenerPage">
        <Wrapper>{props.children}</Wrapper>
        <Header context="closeButton" />
    </div>
);

class ApplyQuant extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        if (!props.bookingParticipant && (_.get(props, 'bookingSubmission.eligibility') === 1 || _.get(props.booking, 'config.options.review_submission'))) {
            this.missingBookingParticipantMessage = setTimeout(() => {
                console.error('Missing booking_participant (quant)');
                console.log('Attempting to register booking_participant (quant)');
                this.props.participantSaveAvailability(props.booking._id, props.booking.session.map(session => session._id))
                    .then(() => {
                        window.location.reload();
                    })
                    .catch((error) => {
                        const errorMessage = _.get(error, 'graphQLErrors.0.message', 'Failed to save availability');
                        console.error('Error on apply-quant-view:37');
                        console.error(JSON.stringify(errorMessage));
                    });
            }, 5000);
        }

        this.renderPopupContents = this.renderPopupContents.bind(this);
    }

    componentWillReceiveProps(newProps) {
        if (this.missingBookingParticipantMessage && newProps.bookingParticipant) {
            console.log('Got booking_participant (quant)');
            clearTimeout(this.missingBookingParticipantMessage);
        }
    }

    componentWillUnmount() {
        if (this.missingBookingParticipantMessage) {
            clearTimeout(this.missingBookingParticipantMessage);
        }
    }

    renderPopupContents(key, props = {}) {
        const {
            bookingParticipant,
            booking,
            user,
            bookingSubmission
        } = props;

        const hideOpportunity = (
            <Link
                to="/opportunities"
                className="link"
                style={{ color: 'inherit', opacity: 0.5 }}
                onClick={() => { utils.hideOpportunity(this.props.fetchBookingById.bookingByID._id); }}
            >
                Hide this opportunity
            </Link>
        );

        // console.log(key);

        switch (key) {
            case 'popup_hard_full':
                return (
                    <React.Fragment>
                        <h1>Sorry, this opportunity is now closed</h1>
                        <p>If you missed out, don’t worry - there are always lots of new opportunities which you can find <Link to="/opportunities" className="link">here</Link>.</p>
                        <p>{hideOpportunity}</p>
                    </React.Fragment>
                );
            case 'popup_cherrypicking':
                return (
                    <React.Fragment>
                        <h1>Registration submitted</h1>
                        <p className="fontLarge mtop10 mbottom40">Your eligibility answers have been submitted to the researcher.</p>
                        <p><strong>Now what?</strong></p>
                        <p>The researcher will look over all of the applicants.</p>
                        <p>If you’re chosen to participate, you’ll get a notification with further instructions.</p>
                        <p>If you don’t get picked, don’t worry - there are always lots of other new opportunities popping up on the <Link to="/opportunities" className="link">opportunities</Link> page.</p>
                    </React.Fragment>
                );
            case 'popup_ineligible':
                return (
                    <React.Fragment>
                        <h1>Sorry, you’re not eligible for this opportunity</h1>
                        <p>Don’t worry - there are always lots of new opportunities which you can find <Link to="/opportunities" className="link">here</Link>.</p>
                        <p>{hideOpportunity}</p>
                    </React.Fragment>
                );
            case 'popup_join_waitlist':
                return (
                    <React.Fragment>
                        <h1>This opportunity is currently full</h1>
                        <p className="fontLarge mtop20">Do you want to join the waitlist?</p>
                        <p>If you join the waitlist, you’ll get an SMS letting you know when a spot opens up.</p>
                        <Button
                            label="Add me to the wait list"
                            labelColor="#fff"
                            bgColor="#FF5266"
                            type="button"
                            className="mtop20 widthAuto"
                            onClick={() => {
                                this.setState({ loading: true });
                                this.props.participantJoinQuantWaitlist(bookingParticipant._id)
                                    .then(() => {
                                        this.props.participantSessionsByBooking.refetch();
                                        this.props.findBookingSubmission.refetch();
                                    })
                                    .catch((mutationError) => {
                                        console.error('Error on apply-quant-view:124');
                                        const errorMessage = _.get(mutationError, 'graphQLErrors.0.message', null);
                                        console.error(`Failed to join waitlist (${JSON.stringify(errorMessage)})`, mutationError);
                                    });
                            }}
                        />
                    </React.Fragment>
                );
            case 'popup_check_device':
                return (
                    <PopupCheckDevice
                        booking={booking}
                        user={user}
                        onContinue={() => {
                            this.setState({ required_device_check: true });
                        }}
                        history={this.props.history}
                    />
                );
            case 'popup_check_features':
                return (
                    <PopupRequirementNotices
                        booking={booking}
                        onContinue={() => {
                            this.setState({ required_features_check: true });
                        }}
                    />
                );
            case 'popup_start_task': {
                return (
                    <React.Fragment>
                        <h1>You’re nearly good to go!</h1>
                        <p>Click the button below to proceed.</p>
                        <p>The next page will contain the instructions you need to get started, but if anything needs further clarification, don’t hesitate to contact the researcher.</p>
                        <div className="subtle-warning-box mtop20">
                            <div className="mleft20 mright20 mtop10">
                                <h3>Please note:</h3>
                                <p>Please read all instructions carefully, as there may be several steps to completing this task.</p>
                                <p><strong>You won’t be paid your {_.get(this.props.booking, 'config.incentive.currency_symbol', '')}{_.get(this.props.booking, 'config.incentive.value', '')} incentive unless you succesfully finish all the steps.</strong></p>
                            </div>
                        </div>
                        <Button
                            label="Begin"
                            labelColor="#fff"
                            bgColor="#FF5266"
                            type="button"
                            className="mtop40 widthAuto"
                            onClick={() => {
                                this.setState({ loading: true });
                                this.props.participantStartLongitudinalTask(bookingParticipant._id)
                                    .then(() => {
                                        this.props.history.push(this.props.bookingBaseUrl);
                                    })
                                    .catch((mutationError) => {
                                        const errorMessage = _.get(mutationError, 'graphQLErrors.0.message', null);
                                        console.error(`Failed to start task (${JSON.stringify(errorMessage)})`);
                                        this.props.history.push(`${this.props.bookingBaseUrl}/apply/error/other?error=${(errorMessage && typeof errorMessage === 'string') ? errorMessage : ''}`);
                                    });
                            }}
                        />
                        {_.get(booking, 'config.participant_agreement._template_id') && <AgreementDialog booking={booking} submission={bookingSubmission} />}
                    </React.Fragment>
                );
            }
            default:
                return <Redirect to={this.props.bookingBaseUrl} />;
        }
    }

    render() {
        if (this.state.loading) {
            return <LoadingOverlay />;
        }

        const { bookingSubmission, bookingParticipant } = this.props;

        if (!bookingSubmission) {
            return <Redirect to={`${this.props.bookingBaseUrl}/apply`} />;
        }

        if (this.props.booking.type !== 4 || this.props.booking.status !== 1) {
            return <Redirect to={this.props.bookingBaseUrl} />;
        }

        if (bookingSubmission.eligibility !== 1 && !_.get(this.props.booking, 'config.options.review_submission')) {
            return <ScreenerPage>{this.renderPopupContents('popup_ineligible')}</ScreenerPage>;
        }

        if (bookingParticipant && !(bookingParticipant.status === 3 || bookingParticipant.status === 4)) {
            return <Redirect to={this.props.bookingBaseUrl} />;
        }

        return (
            <ScreenerPage>
                <Query
                    query={gql`query my_quantCheckBookingAvailability($_booking_id: ID!) {
                      bookingByID (id: $_booking_id) {
                        _id type status total_participants
                        config {
                            options { review_submission } session { time_limit }
                            online_task { required_device required_features }
                            information { brief_summary special_instructions }
                            participant_agreement { _template_id }
                        }
                        ParticipantSessionsCount {
                          in_progress scheduled completed
                        }
                        participant_is_excluded
                      }
                    }
                    `}
                    variables={{
                        _booking_id: this.props.booking._id
                    }}
                >
                {
                    ({ loading, error, data }) => {
                        if (loading) {
                            return <LoadingOverlay />;
                        }
                        if (error) {
                            console.error(error);
                            return <LoadingOverlay />;
                        }

                        const booking = data.bookingByID;
                        if (!booking || booking.type !== 4 || booking.status !== 1) {
                            return <Redirect to={this.props.bookingBaseUrl} />;
                        }

                        // const debugStuff = <pre>{`${JSON.stringify(loading)}\n${JSON.stringify(error, undefined, 2)}\n${JSON.stringify(data, undefined, 2)}`}</pre>;

                        if (!this.props.isPreview && booking.participant_is_excluded) {
                            return <Redirect to={`${this.props.bookingBaseUrl}/apply/error/previous-attendance`} />;
                        }

                        if (!bookingParticipant) {
                            return <LoadingOverlay />;
                        }

                        if ((
                            _.get(booking, 'ParticipantSessionsCount.completed', 0) +
                            _.get(booking, 'ParticipantSessionsCount.scheduled', 0)
                        ) >= booking.total_participants) {
                            return this.renderPopupContents('popup_hard_full');
                        }

                        if (_.get(booking, 'config.options.review_submission') && bookingParticipant.status === 3) {
                            return this.renderPopupContents('popup_cherrypicking');
                        }

                        if (bookingSubmission.eligibility !== 1 && !_.get(booking, 'config.options.review_submission')) {
                            return this.renderPopupContents('popup_ineligible');
                        }

                        if ((
                            _.get(booking, 'ParticipantSessionsCount.completed', 0) +
                            _.get(booking, 'ParticipantSessionsCount.scheduled', 0) +
                            _.get(booking, 'ParticipantSessionsCount.in_progress', 0)
                        ) >= booking.total_participants) {
                            return this.renderPopupContents('popup_join_waitlist', { bookingParticipant });
                        }

                        if (_.get(booking, 'config.online_task.required_device.length', 0) > 0 && !_.get(this.state, 'required_device_check')) {
                            return this.renderPopupContents('popup_check_device', { booking, user: this.props.user });
                        }
                        if (_.get(booking, 'config.online_task.required_features.length', 0) > 0 && !_.get(this.state, 'required_features_check')) {
                            return this.renderPopupContents('popup_check_features', { booking, bookingParticipant });
                        }

                        return this.renderPopupContents('popup_start_task', { booking, bookingParticipant, bookingSubmission });
                    }
                }
                </Query>
            </ScreenerPage>
        );
    }
}

const participantStartLongitudinalTaskContainer = graphql(participantStartLongitudinalTask, {
    props: ({ mutate }) => ({
        participantStartLongitudinalTask: participant_session_id => mutate({
            variables: { participant_session_id }
        }),
    }),
});
const participantJoinQuantWaitlistContainer = graphql(participantJoinQuantWaitlist, {
    props: ({ mutate }) => ({
        participantJoinQuantWaitlist: participant_session_id => mutate({
            variables: { participant_session_id }
        }),
    }),
});

export default compose(participantStartLongitudinalTaskContainer, participantJoinQuantWaitlistContainer)(ApplyQuant);
