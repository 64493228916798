import React, { Component } from 'react';

import _ from 'lodash';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { Checkbox } from 'material-ui';

import './styles/switchListStyles.scss';

class Switches extends Component {
    constructor(props) {
        super(props);

        this.state = {
          value: this.props.defaultChecked || [],
          exclusiveValueChecked: false
          // defaultChecked: this.props.defaultChecked || []
        };

        this.renderCheckbox = this.renderCheckbox.bind(this);
        this.renderRadioButton = this.renderRadioButton.bind(this);
        this.onChange = this.onChange.bind(this);

        // if (this.props.onValueChange) {
        //   this.props.onValueChange(['test']);
        // }
        if (this.props.onValueChange) {
            setTimeout(() => {
                this.props.onValueChange(this.state.value);
            }, 1);
        }
    }

    onChange(event) {
      let value = null;
      switch (this.props.type) {
        case 'radio':
          value = [event.target.value];
        break;
        case 'checkbox': {
          const option = _.find(this.props.options, ['value', event.target.value]);
          if (event.isInputChecked) {
              if (option.exclusive) {
                  value = [event.target.value];
              } else if (this.state.exclusiveValueChecked) {
                  value = [event.target.value];
              } else {
                  value = this.state.value.concat(event.target.value);
              }
          } else {
            value = this.state.value.filter(val => val !== event.target.value);
          }
          this.setState({ exclusiveValueChecked: event.isInputChecked && option.exclusive });
        }
        break;
        default:
          return false;
      }
      if (value) {
        this.setState({ value: _.uniq(value) });
        if (this.props.onValueChange) {
          this.props.onValueChange(value);
        }
      }
    }

    renderRadioButton(value, index) {
      return (
        <RadioButton
            {...value.props || {}}
            key={value.key || index}
            value={value.value}
            label={value.label}
            className="switchOption"
        />
      );
    }

    renderCheckbox(value, index) {
      return (
        <Checkbox
            {...value.props || {}}
            key={value.key || index}
            value={value.value}
            label={value.label}
            className="switchOption"
            // defaultChecked={this.state.defaultChecked.indexOf(value.value) > -1}
            checked={this.state.value.indexOf(value.value) > -1}
            onCheck={(event, isInputChecked) => {
              this.onChange(Object.assign({}, event, { isInputChecked }));
            }}
        />
      );
    }

    render() {
      // return null;
      const className = `switchList ${this.props.className || ''}`;
      switch (this.props.type) {
        case 'radio':
          return (
            <RadioButtonGroup
                {...this.props.props}
                name={this.props.name}
                // defaultChecked={this.props.value}
                onChange={this.onChange}
                className={className}
            >
              {this.props.options.map(this.renderRadioButton)}
            </RadioButtonGroup>
          );
        case 'checkbox':
          return (
            <div
                // onChange={this.onChange}
                {...this.props.props}
                className={className}
            >
              {this.props.options.map(this.renderCheckbox)}
            </div>
          );
        default:
          return null;
      }
    }
}

export default Switches;
