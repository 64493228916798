import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import { utils } from 'lib/utils';
import { ONLINE_TASK_REQUIRED_FEATURE } from 'lib/data/constants';

import { Button } from 'components/common';

class RequirementNotices extends Component {
    constructor() {
        super();
        this.state = { featureIndex: 0 };
    }
    render() {
        const nextButton = (
            <Button
                label="Next"
                labelColor="#fff"
                bgColor="#FF5266"
                type="button"
                className="mtop40 _widthAuto"
                onClick={() => {
                    this.setState(state => _.set(state, 'featureIndex', _.get(state, 'featureIndex', 0) + 1));
                }}
            />
        );
        const features = _.get(this.props.booking, 'config.online_task.required_features', []);
        switch (features[this.state.featureIndex]) {
            case ONLINE_TASK_REQUIRED_FEATURE.CAMERA:
                return (
                    <Fragment>
                        <div className="mtop40 mbottom30 textCenter"><img src="/assets/quant-icon-webcam.svg" alt="Webcam icon" height="70" /></div>
                        <h2>This study requires a camera</h2>
                        <p>Please make sure your camera is working and that you’re in a well lit room before you begin</p>
                        {nextButton}
                    </Fragment>
                );
            case ONLINE_TASK_REQUIRED_FEATURE.MICROPHONE:
                return (
                    <Fragment>
                        <div className="mtop40 mbottom30 textCenter"><img src="/assets/quant-icon-mic.svg" alt="Microphone icon" height="70" /></div>
                        <h2>This study requires a mic</h2>
                        <p>Please make sure your mic is working and that you’re in a quiet environment before you begin</p>
                        {nextButton}
                    </Fragment>
                );
            default:
                this.props.onContinue();
                return null;
        }
    }
}

export default RequirementNotices;
