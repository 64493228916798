import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
// import { Link } from 'react-router-dom';
import { Dialog, Input, LoadingOverlay } from 'components/common';

import deleteMe from 'data/mutations/deleteMe';

class DeleteAccountDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    render() {
        if (this.state.loading) {
            return (
                <Dialog
                    open
                    modal
                    title="Delete your account"
                    customActions={[]}
                    className="referral-dialog"
                >
                    <LoadingOverlay style={{ height: '200px' }} />
                </Dialog>
            );
        }
        return (
            <Dialog
                open={this.props.open}
                onCancel={this.props.onCancel}
                modal={false}
                title="Delete your account"
                customActions={[
                    {
                        label: 'Cancel',
                        primary: true,
                    },
                    {
                        label: 'Confirm',
                        primary: true,
                        disabled: !this.state.canContinue,
                        onClick: () => {
                            this.setState({ loading: true });
                            this.props.deleteMe()
                                .then(() => {
                                    window.location.href = '/';
                                });
                        }
                    }
                ]}
                className="referral-dialog"
            >
                <p>Are you sure you want to delete your account? You will be immidiately logged out, and <strong>we won’t be able recover your details</strong>.</p>
                <p>If you have any pending incentive payments for this account, you may not receive them.</p>
                <p>If you accept the aboce, and you’re sure you want to proceed, please type “<strong>DELETE&nbsp;MY&nbsp;ACCOUNT</strong>” into the text area below:</p>
                <Input
                  id="MenuDeleteAccountDialogInpput"
                  onChange={value => this.setState({ canContinue: !!value.replace(/[^a-zA-Z]/g, '').match(/^deletemyaccount$/i) })}
                />
            </Dialog>
        );
    }
}

const deleteMeContainer = graphql(deleteMe, {
    props: ({ mutate }) => ({
        deleteMe: mutate,
    }),
});


export default compose(deleteMeContainer)(DeleteAccountDialog);
