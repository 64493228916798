import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
// import moment from 'lib/moment';
import _ from 'lodash';

import StarsIcon from 'material-ui/svg-icons/action/stars';

import { utils } from 'lib/utils';
// import { localStorage } from 'lib/storage';

import participantConfirmCompletion from 'data/mutations/participantConfirmCompletion';
import participantSubmitFeedback from 'data/mutations/participantSubmitFeedback';
import participantCancelSession from 'data/mutations/participantCancelSession';

import { Dialog, Button, LoadingOverlay, Input } from 'components/common';

// const confirmAttendance = (this.props) => {
class ConfirmAttendance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewStage: this.props.viewStage,
            loading: false,
            cancelReason: '',
            feedback: ''
        };
    }
    render() {
        const viewStage = this.state.loading ? null : this.state.viewStage;

        const payment = {};
        if (viewStage === 'payment') {
            switch (_.get(this.props.booking.user, 'settings.billing.preferred')) {
            // switch ('giftpay') {
                case 'giftpay':
                    payment.name = 'eGiftCard';
                    payment.image = <div className="ignore-margin mtop30 mbottom20"><img src="/assets/giftcard-examples.png" alt="Examples of gift cards" width="100%" /></div>;
                break;
                case 'paypal':
                    payment.name = 'PayPal';
                    payment.image = <div className="mtop30 mbottom20"><img src="/assets/onboarding-general-paypal.png" alt="PayPal logo" /></div>;
                break;
                default:
            }
        }
        return (
            <div>
                <Dialog
                  open={viewStage === 'confirm-1'}
                  className="confirmAttendanceDialog finished onboardingDialog"
                  customActions={[]}
                >
                    <h2>Did you attend this session?</h2>
                    <p className="mtop40 mbottom20">
                        <strong>{this.props.booking.time.format('dddd Do MMM')}</strong>
                        <strong style={{ fontSize: '1.5em', display: 'block' }}>{this.props.booking.time.formatZoneFallback('h:mm A')}</strong>
                    </p>
                    {this.props.booking.location}
                    <Button
                      label="Yes, I attended"
                      labelColor="#fff"
                      bgColor="#FF5266"
                      type="button"
                      onClick={() => {
                        if (this.props.confirmAttendanceAnonymous) {
                            utils.window.location.href = `/login?loginDestination=/booking/${this.props.booking._id}`;
                            return;
                        }
                        this.setState({ loading: true });
                        this.props.participantConfirmCompletion(this.props.booking.booking_participant._id)
                          .then(() => {
                              this.props.participantSessionsByBooking.refetch();
                              this.setState({ loading: false, viewStage: 'payment' });
                          })
                          .catch((err) => { console.error(err); utils.window.location.reload(); });
                      }}
                      className="widthAuto mtop40"
                    />
                    <br />
                    <Button
                      label="No I didn&rsquo;t make it"
                      labelColor={'#999'/* TODO - v1.0 (PPTS-15) */}
                      bgColor="transparent"
                      type="button"
                      onClick={() => {
                          if (this.props.confirmAttendanceAnonymous) {
                              utils.window.location.href = `/login?loginDestination=/booking/${this.props.booking._id}`;
                              return;
                          }
                          this.setState({ viewStage: 'cancel-reason' });
                      }}
                      className="widthAuto mtop20"
                    />
                </Dialog>

                <Dialog
                  open={viewStage === 'payment'}
                  className="confirmAttendanceDialog finished onboardingDialog"
                  modal
                  customActions={[]}
                >
                {payment.name ? (
                    <React.Fragment >
                        <h2>Your payment:</h2>
                        {payment.image && payment.image}
                        {payment.name && <p>Since you selected {payment.name} as your payout method, you should get your payment <strong>within two business days</strong>.</p>}
                        <Button
                            label="Next"
                            labelColor="#fff"
                            bgColor="#FF5266"
                            type="button"
                            onClick={() => { this.setState({ viewStage: 'feedback' }); }}
                            className="mtop20"
                        />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <h2>Your payment:</h2>
                        <div className="textLeft">
                            <p>In order to receive your incentive, you’ll need to select a preferred payment method.</p>
                            <p>You can select a method by selecting “Payout preferences” in the menu.</p>
                        </div>
                        <Button
                            label="Next"
                            labelColor="#fff"
                            bgColor="#FF5266"
                            type="button"
                            onClick={() => { this.setState({ viewStage: 'feedback' }); }}
                            className="mtop20"
                        />
                    </React.Fragment>
                )}
                </Dialog>

                <Dialog
                  open={viewStage === 'feedback'}
                  className="confirmAttendanceDialog finished onboardingDialog"
                  modal
                  customActions={[]}
                >
                    <div className="textLeft">
                        <h2>Please leave a review for your session facilitator</h2>
                        <Input
                            value={this.state.feedback}
                            onChange={(value) => { this.setState({ feedback: value }); }}
                            multiLine
                            rowsMax="10"
                            hintText={`How was the session? Leave your feedback here:`}
                        />
                        <Button
                          label="Next"
                          labelColor="#fff"
                          bgColor="#FF5266"
                          type="button"
                          disabled={this.state.feedback.replace(/^\s+|\s+$/g, '').length < 1}
                          onClick={() => {
                            this.setState({ loading: true });
                            this.props.participantSubmitFeedback(this.props.booking.booking_participant._id, this.state.feedback)
                              .then(() => {
                                  this.setState({ loading: false, viewStage: 'review' });
                              })
                              .catch((err) => { console.error(err); utils.window.location.reload(); });
                          }}
                          className="mtop40"
                        />
                    </div>
                </Dialog>

                <Dialog
                  open={viewStage === 'review'}
                  className="confirmAttendanceDialog finished onboardingDialog"
                  customActions={[]}
                  modal={false}
                  onRequestClose={() => { this.setState({ viewStage: null }); }}
                  closeButton
                >
                    <h2>Want more opportunities?</h2>
                    <p>Help Askable grow by giving us a review on Facebook!</p>
                    <Button
                      label="Review Askable"
                      labelColor="#fff"
                      bgColor="#2F80ED"
                      type="button"
                      icon={<StarsIcon />}
                      onClick={() => { utils.window.open('https://www.facebook.com/askableapp/reviews/', '_blank'); }}
                      className="mtop40"
                    />
                </Dialog>

                <Dialog
                  open={viewStage === 'cancel-reason'}
                  className="confirmAttendanceDialog finished onboardingDialog"
                  modal
                  customActions={[]}
                >
                    <div className="textLeft">
                        <h2>What happened?</h2>
                        <Input
                            value={this.state.cancelReason}
                            onChange={(value) => { this.setState({ cancelReason: value }); }}
                            multiLine
                            rowsMax="10"
                            hintText="Please describe why you weren’t able to make it"
                        />
                        <Button
                          label="Next"
                          labelColor="#fff"
                          bgColor="#FF5266"
                          type="button"
                          disabled={this.state.cancelReason.replace(/^\s+|\s+$/g, '').length < 1}
                          onClick={() => {
                            this.setState({ loading: true });
                            this.props.participantCancelSession(this.props.booking.booking_participant._id, this.state.cancelReason)
                              .then(() => {
                                  utils.window.location.reload();
                              })
                              .catch((err) => { console.error(err); utils.window.location.reload(); });
                          }}
                          className="mtop40"
                        />
                    </div>
                </Dialog>

                <Dialog
                  open={!!this.state.loading}
                  className="confirmAttendanceDialog finished onboardingDialog"
                  modal
                  customActions={[]}
                >
                    <div
                        style={{
                            position: 'relative',
                            height: 220
                        }}
                    >
                        <LoadingOverlay />
                    </div>
                </Dialog>
            </div>
        );
    }
}

const participantConfirmCompletionContainer = graphql(participantConfirmCompletion, {
    props: ({ mutate }) => ({
        participantConfirmCompletion: participant_session_id => mutate({
            variables: { participant_session_id }
        }),
    }),
});
const participantSubmitFeedbackContainer = graphql(participantSubmitFeedback, {
    props: ({ mutate }) => ({
        participantSubmitFeedback: (participant_session_id, feedback) => mutate({
            variables: { participant_session_id, feedback }
        }),
    }),
});
const participantCancelSessionContainer = graphql(participantCancelSession, {
    props: ({ mutate }) => ({
        participantCancelSession: (participant_session_id, cancel_reason) => mutate({
            variables: { participant_session_id, cancel_reason }
        }),
    }),
});

export default compose(
    participantConfirmCompletionContainer,
    participantSubmitFeedbackContainer,
    participantCancelSessionContainer
)(ConfirmAttendance);
