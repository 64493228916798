import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
// import { Link } from 'react-router-dom';
import { ListItem } from 'material-ui/List';
import { Dialog, SneakyConfirmation } from 'components/common';

import { utils } from 'lib/utils';

import CopyIcon from 'material-ui/svg-icons/content/content-copy';
import ErrorIcon from 'material-ui/svg-icons/alert/error';

class ReferralDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    render() {
        return (
            <Dialog
                open={this.props.open}
                onCancel={this.props.onCancel}
                modal={false}
                title="Refer a friend"
                customActions={[{
                    label: 'Close'
                }]}
                className="referral-dialog"
            >
                <p>Share this link with your friends so that we know that they were referred by you!</p>
                <Query query={gql`query participantReferralLink { participantReferralLink }`} >
                    {({ loading, error, data }) => {
                        if (loading) return <ListItem primaryText="Loading..." disabled className="referral-link loading" />;
                        if (error || !(data && data.participantReferralLink)) {
                            console.error(error);
                            return <ListItem primaryText="There was a problem getting your link..." disabled className="referral-link error" rightIcon={<ErrorIcon />}/>;
                        }
                        return (
                            <ListItem
                                primaryText={data.participantReferralLink}
                                className="referral-link"
                                rightIcon={<CopyIcon />}
                                onClick={() => {
                                    utils.copy(
                                        data.participantReferralLink,
                                        () => { this.setState({ copyConfirmation: new Date().valueOf() }); },
                                        () => { throw new Error('Failed to copy the link'); }
                                    );
                                }}
                            />
                        );
                    }}
                </Query>
                <SneakyConfirmation
                    open={this.state.copyConfirmation}
                    onClose={() => { this.setState({ copyConfirmation: null }); }}
                    style={{ top: '8px' }}
                >
                    Link copied to clipboard!
                </SneakyConfirmation>
            </Dialog>
        );
    }
}

export default ReferralDialog;
