import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';

import _ from 'lodash';
import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';

import { LoadingOverlay } from 'components/common';
import { withKinde } from '../../AuthWrapper';

class RegisterEmailLead extends Component {
    constructor() {
        super();
        this.state = { redirect: null };

        this.setInitialRedirect = this.setInitialRedirect.bind(this);
        this.registerSuccess = this.registerSuccess.bind(this);
    }

    componentDidMount() {
        const { search } = this.props.location;
        const urlParams = utils.urlParams(search);
        const email = _.get(urlParams, 'email');
        const booking = _.get(urlParams, 'booking');

        const user = {};
        if (urlParams.firstname) {
            _.set(user, 'meta.identity.firstname', urlParams.firstname);
        }
        if (urlParams.lastname) {
            _.set(user, 'meta.identity.lastname', urlParams.lastname);
        }
        if (urlParams.utm_source) {
            _.set(user, 'meta.acquisition.source', urlParams.utm_source);
        }
        if (urlParams.utm_campaign) {
            _.set(user, 'meta.acquisition.campaign', urlParams.utm_campaign);
        }

        if (email && !this.props?.isAuthenticated?.()) {
            this.props.registerParticipantLead(email, user)
                .then((response) => {
                    // save auth
                    // redirect
                    this.registerSuccess({
                        user: _.get(response, 'data.registerParticipantLead'),
                        booking,
                        urlParams,
                        search
                    });
                })
                .catch((error) => {
                    switch (_.get(error, 'graphQLErrors.0.message')) {
                        case 'This e-mail is already in use.':
                            if (booking) localStorage.save('tagetPath', `/booking/${booking}`);
                            this.setState({ redirect: { to: { pathname: '/login', state: { email } } } });
                        break;
                        default:
                            if (booking) {
                                return this.setState({ redirect: { to: { pathname: `/booking/${urlParams.booking}`, search } } });
                            }
                            return this.setState({ redirect: { to: { pathname: '/', search } } });
                    }
                });
        } else {
            this.setInitialRedirect({ authenticated: this.props?.isAuthenticated?.(), urlParams, search: this.props.location.search });
        }
    }

    setInitialRedirect({ authenticated, urlParams, search }) {
        if (urlParams.booking) {
            return this.setState({ redirect: { to: `/booking/${urlParams.booking}` } });
        }
        if (authenticated) {
            return this.setState({ redirect: { to: { pathname: '/', search } } });
        }
        this.setState({ redirect: { to: { pathname: '/signup/email', search } } });
        // const redirect = null;
        // this.setState({ redirect });
    }

    async registerSuccess({
        user,
        booking,
        urlParams,
        search
    }) {
        if (!user) {
            return this.setInitialRedirect({ urlParams, search });
        }
        localStorage.save('connectedParticipant', user._id);
        localStorage.save('participant_access_token', user.access_token);
    localStorage.save(
      'participant_details',
      JSON.stringify({
            email: user.email,
            name: {
                firstname: _.get(user, 'meta.identity.firstname'),
          lastname: _.get(user, 'meta.identity.lastname'),
        },
      }),
    );

        // await mixpanel.track({ event: 'Login', _user_id: user._id });
        // await mixpanel.profile(user._id, { $last_login: new Date() });

        if (booking) {
            this.setState({ redirect: { to: `/booking/${urlParams.booking}` } });
        } else {
            this.setState({ redirect: { to: localStorage.get('tagetPath') || '/' } });
        }
        localStorage.delete('tagetPath');
    }

    render() {
        if (this.state.redirect) {
            return <Redirect {...this.state.redirect} />;
        }
        return <LoadingOverlay />;
    }
}

const RegisterParticipantLeadMutation = graphql(gql`mutation registerParticipantLead ($email: String!, $user: UserInput) {
      registerParticipantLead(email: $email, details: $user) {
        _id email access_token
        meta { identity { firstname lastname } }
      }
    }`, {
    props: ({ mutate }) => ({
        registerParticipantLead: (email, user) => mutate({
            variables: { email, user }
        }),
    }),
});

export default withKinde(compose(RegisterParticipantLeadMutation)(RegisterEmailLead));
