import React from 'react';
import Snackbar from 'material-ui/Snackbar';

export default class Snack extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            message: ''
        };

        this.open = this.open.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
    }

    open(message) {
        this.setState({
            open: true,
            message
        });
    }

    handleRequestClose() {
        this.setState({ open: false });
    }

    render() {
        return (
            <Snackbar
                open={this.state.open}
                message={this.state.message}
                autoHideDuration={4000}
                onRequestClose={this.handleRequestClose}
            />
        );
    }
}
