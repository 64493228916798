import React, { Component } from 'react';
import _ from 'lodash';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import ReactMarkdown from 'react-markdown';

import { utils } from 'lib/utils';
import { localStorage } from 'lib/storage';
import { BOOKING_STATUS } from 'lib/data/constants';

import { Wrapper, LoadingOverlay, Button } from 'components/common';
import { withKinde } from '../../AuthWrapper';

import './styles/bookingLandingPage.scss';

class ByoLandingView extends Component {
    componentDidMount() {
        if (!this?.props?.isAuthenticated?.()) {
            localStorage.save('signup_settings', JSON.stringify({
                restrictions: {
                    _booking_id: _.get(this.props, 'match.params.id')
                }
            }));
            localStorage.save('utm_campaign', _.get(this.props, 'match.params.id'));
            localStorage.save('utm_source', 'Researcher share');
        }
        // mixpanel.track({
        //     event: 'View BYO DB landing page',
        //     payload: {
        //         _booking_id: _.get(this.props, 'match.params.id'),
        //     }
        // });
    }

    render() {
        const isPreview = _.get(this.props, 'location.search', '').match(/\bpreview\b/);

        return (
            <Query
                query={bookingQuery}
                variables={{ id: _.get(this.props, 'match.params.id') }}
            >
            {({ loading, data }) => {
                if (loading) return <LoadingOverlay />;

                const booking = _.get(data, 'bookingByID');
                if (!booking) {
                    return (
                        <Wrapper className="byoLandingView">
                            <div className="innerWrapper">
                                <div style={{ paddingTop: '75px' }}>
                                    <h2 className="title">Page not found</h2>
                                </div>
                            </div>
                        </Wrapper>
                    );
                }

                const blurb = _.get(booking, 'byo_recruitment.blurb') ? (
                    <div className="blurb">
                        <ReactMarkdown>{booking.byo_recruitment.blurb}</ReactMarkdown>
                    </div>
                ) : null;

                let logo = _.get(booking, 'byo_recruitment.logo');
                if (!logo && isPreview) {
                    logo = '/researcher-logo-placeholder.svg';
                }

                let nextButton = (
                    <Button
                        label="Next"
                        labelColor="#fff"
                        bgColor="#FF5266"
                        type="button"
                        className="mtop40"
                        onClick={() => {
                            if (isPreview) {
                                this.props.history.push(`/booking/preview/${_.get(this.props, 'match.params.id')}`);
                                return;
                            }
                            // mixpanel.track({
                            //     event: 'BYO DB landing page click Next',
                            //     payload: {
                            //         _booking_id: _.get(this.props, 'match.params.id'),
                            //     }
                            // });
                            this.props.history.push(`/booking/${_.get(this.props, 'match.params.id')}`);
                        }}
                    />
                );
                if (booking.status !== BOOKING_STATUS.ACTIVE && !isPreview) {
                    nextButton = (
                        <Button
                            disabled
                            label="This opportunity has closed"
                            labelColor="#fff"
                            bgColor="#FF5266"
                            type="button"
                            className="mtop40"
                        />
                    );
                }

                return (
                    <Wrapper className="byoLandingView">
                        <div className="topCard">
                            <div className="innerWrapper">
                                {logo ? (
                                    <div className="logo">
                                        <img src={logo} alt="Researcher Logo" />
                                    </div>
                                ) : <div style={{ paddingTop: '75px' }} />}
                                <h2 className="title">{_.get(booking, 'byo_recruitment.title') || 'Customer Feedback Program'}</h2>
                                {blurb}
                            </div>
                        </div>
                        <div className="innerWrapper details">
                            <div className="badge">
                                <span>powered by</span>
                                <img src="/askable-logo.svg" alt="Askable" />
                            </div>
                            <div className="next">{nextButton}</div>
                            <div className="faq">
                                <p>Askable is a trusted and reputable company, based in Australia, that connects researchers with potential participants like you.</p>
                                <p>Askable takes care of:</p>
                                <ul>
                                    <li>Scheduling session times</li>
                                    <li>Sending reminder messages and invitations to take part in research</li>
                                    <li>Quickly and easily paying you for your insights via Paypal or Gift Card</li>
                                </ul>
                                <p>By proceeding, you will be asked to create an Askable account so that the above can be facilitated. You can view <a href="https://www.askable.com/legal?tab=terms" className="link" target="_blank" rel="noopener noreferrer">Askable’s Term of Service here</a>.</p>
                            </div>
                            <div className="contact">
                                <h6>Askable Pty Ltd</h6>

                                <p>
                                    Heritage Building
                                    <br />
                                    Level 1, Suite A
                                    <br />
                                    61 Petrie Terrace
                                    <br />
                                    Brisbane, QLD 4000
                                </p>

                                <p><a href="tel:+6130404719">(07) 3040 4719</a></p>

                                <p>
                                    <a href="mailto:contact@askable.com">contact@askable.com</a>
                                    <br />
                                    <a href="https://www.askable.com" target="_blank" rel="noopener noreferrer">www.askable.com</a>
                                </p>

                                <p>ABN 72 621 754 096</p>
                            </div>
                        </div>
                    </Wrapper>
                );
            }}
            </Query>
        );
    }
}


const bookingQuery = gql`query my_bookingLandingPage($id:ID!) {
    bookingByID(id:$id) {
        _id status
        byo_recruitment { title logo blurb }
    }
}`;

export default withKinde(ByoLandingView);
