import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { Link } from 'react-router-dom';

import _ from 'lodash';
import moment from 'lib/moment';

import { utils } from 'lib/utils';
import { localStorage } from 'lib/storage';
import { MESSAGE_TYPE, MESSAGE_DIRECTION } from 'lib/data/constants';

import participantBookingsWithMessages from 'data/queries/booking/participantBookingsWithMessages';
import messagesSubscription from 'data/subscriptions/messages/messagesSubscription';

import { Wrapper, LoadingOverlay } from 'components/common';

// Styles
import './styles/messages.scss';

class Messages extends Component {
  constructor() {
    super();
    this.renderMessagesContents = this.renderMessagesContents.bind(this);
    this.renderBookingMessage = this.renderBookingMessage.bind(this);
  }

  componentWillMount() {
    this.unsubscribe = this.props.participantBookingsWithMessages.subscribeToMore({
      document: messagesSubscription,
      variables: { filter: { _to_user_id: utils.getUserId() } },
      updateQuery: (prev, { subscriptionData: { data } }) => {
        const message = _.get(data, 'messagesSubscription');
        if (!message) return;

        let bookingId = _.get(message, 'context._booking_id');
        if (!bookingId && (message.type === MESSAGE_TYPE.ADMIN || message.direction === MESSAGE_DIRECTION.ASKABLE_TO_PARTICIPANT)) {
          bookingId = '000000000000000000000000';
        }

        if (!bookingId) return;

        const next = _.cloneDeep(prev);
        const booking = _.chain(next).get('participantBookingsWithMessages').find({ _id: bookingId }).value();

        if (!booking) return;

        booking.messages = [message];

        return next;
      },
    });
  }

  getBookingLocation(booking) {
    if (_.get(booking, 'type') === 2) {
      return 'Remote';
    }
    if (_.get(booking, 'type') === 3) {
      if (_.get(booking, 'config.online_task.type') === 2) {
        return 'Survey';
      }
      if (_.get(booking, 'config.online_task.type') === 3) {
        return 'Self-managed video call';
      }
      return 'Online task';
    }
    if (_.get(booking, 'config.in_context.location_type') && _.get(booking, 'config.location.region')) {
      return booking.config.location.region;
    }
    if (_.get(booking, 'config.location.city')) {
      return booking.config.location.city;
    }
    if (_.get(booking, 'config.location.country')) {
      return (utils.getCountryByCode(booking.config.location.country) || {}).name;
    }
    return null;
  }

  getBookingStatus(booking) {
    let bookingParticipant = null;

    bookingParticipant = _.find(booking.booking_participant, { status: 1, cancel: 0 });
    if (bookingParticipant) {
      if (booking.status !== 1 || Date.now() > bookingParticipant.session.end) {
        return {
          classes: ['dark-grey'],
          status: 'completed',
          time: moment(bookingParticipant.session.start),
          label: 'Completed',
        };
      }
      if (Date.now() > bookingParticipant.session.start && Date.now() < bookingParticipant.session.end) {
        return {
          classes: ['green', 'dark-green'],
          status: 'happening',
          time: moment(bookingParticipant.session.start),
          label: 'Happening now...',
        };
      }
      return {
        classes: ['green'],
        status: 'attending',
        time: moment(bookingParticipant.session.start),
        label: `Attending - Starts ${moment(bookingParticipant.session.start).fromNow()}`,
      };
    }

    bookingParticipant = _.find(booking.booking_participant, { status: 5, cancel: 0 });
    if (bookingParticipant && booking.status === 1) {
      return {
        classes: ['green', 'dark-green'],
        status: 'in-progress',
        time: moment(bookingParticipant.session.start),
        label: 'In progress',
      };
    }

    bookingParticipant = _.find(booking.booking_participant, { status: 4, cancel: 0 });
    if (bookingParticipant && booking.status === 1) {
      return {
        classes: [],
        status: 'invited',
        time: moment(bookingParticipant.session.start),
        label: 'Invited',
      };
    }

    bookingParticipant = _.find(
      booking.booking_participant,
      doc => doc.status !== 3 && doc.cancel !== 0,
      // && doc.session.start > Date.now()
    );
    if (bookingParticipant) {
      let label = null;
      switch (bookingParticipant.cancel) {
        case 1:
        case 5:
          label = 'Cancelled by admin';
          break;
        case 2:
          label = 'Cancelled by researcher';
          break;
        case 3:
          label = 'Cancelled by you';
          break;
        case 4:
          label = 'You didn’t attend';
          break;
        default:
          label = 'Cancelled';
      }
      return {
        classes: ['red'],
        status: 'cancelled',
        time: moment(bookingParticipant.session.start),
        label,
      };
    }

    bookingParticipant = _.find(booking.booking_participant, { status: 2, cancel: 0 });
    if (bookingParticipant && booking.status === 1) {
      return {
        classes: [],
        status: 'waitlisted',
        time: moment(bookingParticipant.session.start),
        label: 'Waitlisted',
      };
    }

    bookingParticipant = _.find(booking.booking_participant, { cancel: 0 });
    if (bookingParticipant) {
      if (booking.status !== 1) {
        return {
          classes: ['light-grey'],
          status: 'expired',
          time: null,
          label: 'Expired',
        };
      }
      return {
        classes: [],
        status: 'applied',
        time: null,
        label: 'Applied',
      };
    }
    return {
      classes: [],
      time: null,
      label: null,
    };
  }

  renderMessagesContents() {
    if (this.props.participantBookingsWithMessages.loading || !this.props.participantBookingsWithMessages.participantBookingsWithMessages) {
      return <LoadingOverlay />;
    }

    if (!_.get(this.props.participantBookingsWithMessages, 'participantBookingsWithMessages.length')) {
      return <p className="mtop20">You don’t have any messages yet.</p>;
    }

    return (
      <ul className="messagesList">
        {/* this.props.participantBookingsWithMessages.participantBookingsWithMessages.map(this.renderBookingMessage) */}
        {_.chain(this.props.participantBookingsWithMessages)
          .get('participantBookingsWithMessages', [])
          .orderBy(booking => _.get(booking, 'messages.0.created', 0), 'desc')
          .map(this.renderBookingMessage)
          .value()}
      </ul>
    );
  }

  renderBookingMessage(booking) {
    const classes = ['messageItem'];
    const message = {
      ...(booking.messages[0] || {}),
      from: _.get(booking, 'messages.0.UserFrom.meta.identity.firstname'),
      sent: _.get(booking, 'messages.0.created'),
      you: false,
    };

    if (message._from_user_id === utils.getUserId()) {
      message.from = null;
      message.you = true;
    }

    if (!message.from) {
      message.from = _.get(booking, 'config.contact.name', 'Facilitator');
    }

    if (!message.you && !message.seen) {
      classes.push('unread');
    }

    if (booking._id === '000000000000000000000000') {
      return (
        <li key="admin" className={classes.concat('admin').join(' ')}>
          <Link to="/messages/admin" className="link">
            <span className="message">
              <span className="topRow">
                <span className="from">
                  <span className="icon" /> Askable Team
                </span>
                {message.sent && <span className="sent">{moment(message.sent).fromNow()}</span>}
              </span>
              <span className="body">
                {message.you && 'You: '}
                {message.body}
              </span>
            </span>
          </Link>
        </li>
      );
    }
    const status = this.getBookingStatus(booking);
    if (booking.type === 3) {
      switch (status.status) {
        case 'happening':
        case 'attending':
          status.label = 'Completed';
          break;
        default:
      }
    }
    return (
      <li key={booking._id} className={[...classes, ...status.classes].join(' ')}>
        <Link to={`/messages/booking/${booking._id}`} className="link">
          <span className="card">
            <span className="incentive">
              {_.get(booking, 'config.incentive.currency_symbol')}
              {_.get(booking, 'config.incentive.value')}
            </span>
            {this.renderBookingTime(booking, status.time)}
            {this.getBookingLocation(booking) && <span className="location">{this.getBookingLocation(booking)}</span>}
          </span>
          <span className="message">
            <span className="topRow">
              {message.from && <span className="from">{message.from}</span>}
              {message.sent && <span className="sent">{moment(message.sent).fromNow()}</span>}
            </span>
            <span className="body">
              {message.you && 'You: '}
              {message.body}
            </span>
            {status.label && <span className="status">{status.label}</span>}
          </span>
        </Link>
      </li>
    );
  }

  renderBookingTime(booking, time) {
    if (!time) {
      return null;
    }
    if (booking.type === 3) {
      return <span className="time">{time.formatZoneFallback('D MMM')}</span>;
    }
    return (
      <span className="time">
        {time.format('h:mm A')}
        <br />
        {time.formatZoneFallback('D MMM')}
      </span>
    );
  }

  render() {
    return (
      <Wrapper header="mainHeader" className="messagesInbox">
        <h1>Messages</h1>
        {this.renderMessagesContents()}
      </Wrapper>
    );
  }
}

const bookingsMessagesContainer = graphql(participantBookingsWithMessages, {
  name: 'participantBookingsWithMessages',
  options: () => ({
    fetchPolicy: 'network-only',
    variables: { _user_id: localStorage.get('connectedParticipant') },
  }),
});

export default compose(bookingsMessagesContainer)(Messages);
