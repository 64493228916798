import gql from 'graphql-tag';

export default gql`
    query LatestAgreementByType($user_type: AgreementUserTypeInput!) {
      latestAgreementByType(user_type: $user_type) {
        _id
        name
        issue_date
        url
      }
    }
`;
