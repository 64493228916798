import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { graphql, compose } from 'react-apollo';
// import { MenuItem, Menu } from 'material-ui';
// import EditorModeEdit from 'material-ui/svg-icons/editor/mode-edit';
// import AlertErrorOutline from 'material-ui/svg-icons/alert/error-outline';
// import NavigationMoreHoriz from 'material-ui/svg-icons/navigation/more-horiz';

import moment from 'lib/moment';
import _ from 'lodash';
// import { getWidth, getHeight } from 'viewport-size';

import { utils } from 'lib/utils';
import { Input, Button, SwitchList, LocationAutocomplete, Dialog, Select, Checkbox, CountryCodeSelect } from 'components/common';

import OccupationSelect from './pages/occupationSelectView';
import EmploymentTypeSelect from './pages/employmentTypeSelectView';
import PaymentMethod from './pages/payment-method';
import PhoneVerification from './pages/phoneVerification';

class FormContents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      valid: false,
      error: null,
      errorText: null,
      errorDisplay: false,
      birthdayInput: ['', '', ''],
      renderContents: props.activePage,
      userAgreementCheckbox: false,
      phoneCountry: null,
    };

    this.birthdateFormat = ['M', 'DD', 'YYYY'];

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.setStateFromProps = this.setStateFromProps.bind(this);
    this.dateFieldChange = this.dateFieldChange.bind(this);

    this.renderContentsTimeout = null;

    this.user = props.user || {};
  }

  componentWillMount() {
      this.setStateFromProps(this.props);
  }

  componentDidMount() {
      switch (this.props.userProp) {
          case 'timezone': {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (this.props.activePage) {
              this.props.valueChangeCallback(this.props.userProp, timezone || moment.tz.guess(), true);
            } else {
              this.setState({ value: timezone });
            }
            break;
          }
          default:
      }

      if (this.state.value) {
        switch (this.props.userProp) {
          case 'contact.phone.mobile':
            // BUGFIX: Force validation to enable the submit button when mobile number already exists
            this.onChangeHandler({ number: this.state.value, country: _.get(this.props, 'user.location.country') });
          break;
          default:
            // Probably a good idea but not tested on all questions, so leave logic as it was
            // this.onChangeHandler(this.state.value);
        }
      }
  }

  componentWillReceiveProps(newProps) {
    this.setStateFromProps(newProps);
    switch (this.props.userProp) {
        case 'timezone':
            if (newProps.activePage && !this.props.activePage) {
                if (this.state.value) {
                    this.props.valueChangeCallback(this.props.userProp, this.state.value, true);
                } else {
                    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    this.props.valueChangeCallback(this.props.userProp, timezone || moment.tz.guess(), true);
                }
            }
        break;
        default:
    }
  }

  onChangeHandler(inputValue) {
    let value = inputValue,
    valid = false,
    saveValue = null,
    error = null;

    switch (this.props.userProp) {
      case 'location.postal_code':
        value = value.replace(/[^0-9]/, '');
        if (value.length > 0) {
          value = parseInt(value, 10);
          if (value < 1000 || value > 9999) {
            error = 'Please enter a valid Australian postcode';
          } else {
            error = null;
            valid = true;
          }
        }
      break;
      case 'location.city':
        valid = !!(value.city && value.longitude && value.latitude);
        if (!valid && value.country !== undefined) {
            this.props.updateUser({ location: value });
        }
      break;
      case 'meta.identity.birthday.year':
        value = value.replace(/[^0-9]/, '');
        if (value.length > 0) {
          value = parseInt(value, 10);
          if (value >= moment().year() || value < moment().year() - 150) {
            error = 'Please enter a valid birth year';
          } else {
            valid = true;
          }
        }
      break;
      case 'meta.identity.birthday.timestamp': {
        // value = this.state.birthdayInput.join('/');
        // if (value.match(/^\d\d$/) && value.length > this.state.value.length) {
        //   value += '/';
        // } else if (value.match(/^\d\d\/\d\d$/) && value.length > this.state.value.length) {
        //   value += '/';
        // }
        value = value.replace(/\/+/g, '/').replace(/\/(\D)/g, '');
        if (!value.match(/^\d+\/\d+\/\d+$/)) {
          break;
        }
        const valueTime = moment(value, this.birthdateFormat.join('/'));
        if (!valueTime.isValid()) {
          error = 'The date you enteted is invalid';
          break;
        }
        if (valueTime.year() >= moment().year() || valueTime.year() < moment().year() - 150) {
          error = 'The date you enteted is invalid';
          break;
        }

        // value = valueTime.format(this.birthdateFormat.join('/'));
        saveValue = valueTime.format('YYYY-MM-DDTHH:mm:ss[Z]');
        valid = true;
        // this.setState({ valueTime });
        // value = value.replace(/[^0-9]/, '');
        // if (value.length > 0) {
        //   value = parseInt(value, 10);
        //   if (value >= moment().year() || value < moment().year() - 150) {
        //     error = 'Please enter a valid birth year';
        //   } else {
        //     valid = true;
        //   }
        // }
      }
      break;
      case 'contact.phone.mobile': {
        value = inputValue.number;
        const phoneCountry = inputValue.country;
        if (!value || value.replace(/\s+/, '').length === 0) {
          break;
        }
        if (!inputValue.country) {
            error = 'Please select your country';
            break;
        }
        const phoneNumber = utils.parsePhoneNumber(value, phoneCountry);
        if (!phoneNumber.valid) {
          error = (
            <React.Fragment>
              Your phone number looks invalid - make sure your phone number matches your country.&nbsp;
              <a
                className="link"
                href="#change-location"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.updateUser({ location: {} });
                  window.location.reload();
                }}
              >
                Change location
              </a>
            </React.Fragment>
          );
          break;
        }
        if (!phoneNumber.numberType.match(/MOBILE/)) {
          error = 'Please enter a mobile phone number';
          break;
        }
        saveValue = phoneNumber;
        // value = phoneNumber.formatted;
        valid = true;
        break;
      }
      case 'contact.phone.verification.mobile':
        value = inputValue.replace(/[^0-9]/g, '');
        valid = value.length === 6;
        if (value.length > 0) {
            error = 'Verification code should be 6 digits long';
        }
      break;
      case 'settings.agreement._id':
        valid = value === true || value === false;
        saveValue = value;
      break;
      case 'meta.education':
      case 'meta.family.status':
      case 'meta.identity.languages.english.speak':
        saveValue = {};
        utils.userPropValues(this.props.userProp).forEach((path) => {
            saveValue[path] = value.indexOf(path) === -1 ? null : true;
        });
        valid = value.length > 0;
        // if (valid && saveValue[value[0]] !== undefined) {
        //   saveValue[value[0]] = true;
        // }
      break;
      case 'meta.work.status':
        saveValue = {};
        utils.userPropValues(this.props.userProp).forEach((path) => {
            saveValue[path] = value.indexOf(path) === -1 ? null : true;
        });
        valid = value.length > 0;
        if (value.length > 2) {
            valid = false;
            error = 'Please select no more than 2 options';
        }
      break;
      case 'meta.work._industry_id':
          valid = !!(
              value._industry_id
              && value._subindustry_id
              && value.job_title
          );
      break;
      case 'meta.work.employment_type':
          valid = !!value.employment_type;
      break;
      case 'settings.billing':
          valid = true;
      break;
      case 'settings.tutorial.my':
        valid = true;
        saveValue = ['profile-welcome']
      break;
      default:
        valid = value.replace(/^\s+|\s+$/, '').length > 0;
    }
    this.setState({
      value,
      valid,
      error,
      errorDisplay: !valid && (error === this.state.errorText)
    });
    this.props.valueChangeCallback(this.props.userProp, valid ? saveValue || value : undefined, valid, error);
  }

  setStateFromProps(props) {
      let { errorText, errorDisplay, parentError } = this.state;

      if (_.get(props.errorMessage, 'message') && (props.errorMessage.updated !== _.get(this.state, 'parentError.updated'))) {
          errorDisplay = true;
          errorText = props.errorMessage.message;
          parentError = props.errorMessage;
          // this.setState({ parentError });
          this.onChangeHandler('');
      }

      if ((props.submissionAttempt || 0) > (this.props.submissionAttempt || 0) && !this.state.valid) {
        errorText = errorText || this.state.error;
        errorDisplay = true;
        if (!this.state.error) {
          switch (this.props.userProp) {
            case 'email':
              errorText = 'Your email address is required';
            break;
            case 'meta.identity.firstname':
              errorText = 'Your first name is required';
            break;
            case 'meta.identity.lastname':
              errorText = 'Your last name is required';
            break;
            case 'meta.identity.gender':
              errorText = 'Your gender is required';
            break;
            case 'location.postal_code':
              errorText = 'Your postcode is required';
            break;
            case 'location.city':
              errorText = 'Your suburb or town is required';
            break;
            case 'meta.identity.birthday.year':
              errorText = 'Your year of birth is required';
            break;
            case 'meta.identity.birthday.timestamp':
              errorText = 'Your date of birth is required';
            break;
            case 'contact.phone.mobile':
              errorText = 'Your mobile phone number is required';
            break;
            default:
              errorText = 'This field is required';
          }
        }
      }

      if (this.state.errorText !== errorText || this.state.errorDisplay !== errorDisplay) {
          this.setState({ errorText, errorDisplay, parentError });
      }

      if (props.activePage !== this.props.activePage) {
          clearTimeout(this.renderContentsTimeout);
          if (props.activePage) {
              this.setState({ renderContents: true });
          } else {
              this.renderContentsTimeout = setTimeout(() => { this.setState({ renderContents: false }); }, 600);
          }
      }
  }

  dateFieldChange(part, value) {
      const birthdayInput = this.state.birthdayInput.slice(0);
      const maxLength = this.birthdateFormat[part].length;
      const cleanValue = value.replace(/[^0-9]/g, '');
      birthdayInput[part] = cleanValue.substr(0, maxLength);
      this.onChangeHandler(birthdayInput.join('/'));
      // console.log(birthdayInput.join('/'));
      // if (cleanValue.length > maxLength && birthdayInput[part + 1] === '') {
      //     // const nextInput = document.getElementById(`meta.identity.birthday.timestamp.${part + 1}`); // eslint-disable-line
      //     const nextInput = utils.window[`meta.identity.birthday.timestamp.${part + 1}`]; // eslint-disable-line
      //     if (nextInput) {
      //         // setTimeout(() => { nextInput.focus(); }, 50);
      //         nextInput.focus();
      //     }
      //     birthdayInput[part + 1] = cleanValue.substr(maxLength);
      // }
      this.setState({
          birthdayInput
      });
  }

  NextButton(props) {
    return (
      <Button
        label="Next"
        // label={`Next ${this.props.activePage}`}
        labelColor="#fff"
        bgColor="#FF5266"
        className="btnNext mtop20"
        type="submit"
        {...props}
      />
    );
  }

  render() {
    if (!this.state.renderContents) {
        return <div />;
    }
    const signupSettings = localStorage.getItem('signup_settings');
    if (signupSettings) {
      const BYOsettings = signupSettings ? JSON.parse(signupSettings) : null;
      const userCreated = this.props?.user?.created ?? Date.now();
      // only apply BYO restrictions if account is less than an hour old
      if (BYOsettings?.restrictions && (Date.now() - userCreated) <= 3600000) {
        this.props.updateUser({
          settings: {
            restrictions: BYOsettings.restrictions,
            notification: {
              promotional: {
                sms: false,
                email: false,
                push: false
              }
            }
          },
        });
      }
      localStorage.removeItem('signup_settings');
    }
    const nextButton = (
      <this.NextButton
          disabled={!this.state.valid}
      />
    );
    const errorMessage = (this.state.errorDisplay && this.state.errorText) ? (
      <p className="error">{this.state.errorText}</p>
    ) : null;

    switch (this.props.userProp) {
      case 'email':
        return (
          <div>
            <h1>What&apos;s your email address?</h1>
            <Input
                name={this.props.userProp}
                type="email"
                value={this.state.value}
                onChange={(value) => { this.onChangeHandler(value); }}
                // activePage={this.props.activePage}
                autoComplete="off"
            />
            {errorMessage}
            {nextButton}
          </div>
        );
      case 'meta.identity.firstname':
        return (
          <div>
            <h1>What&apos;s your first name?</h1>
            <Input
                name={this.props.userProp}
                type="text"
                value={this.state.value}
                onChange={(value) => { this.onChangeHandler(value); }}
                // activePage={this.props.activePage}
                autoComplete="off"
            />
            {errorMessage}
            {nextButton}
          </div>
        );
      case 'meta.identity.lastname':
        return (
          <div>
            <h1>What&apos;s your last name?</h1>
            <Input
                name={this.props.userProp}
                type="text"
                value={this.state.value}
                onChange={(value) => { this.onChangeHandler(value); }}
                // activePage={this.props.activePage}
                autoComplete="off"
            />
            {errorMessage}
            {nextButton}
          </div>
        );
      case 'meta.identity.gender':
        return (
          <div>
            <h1>What&apos;s your gender?</h1>
            <Button
                label="Female"
                labelColor={this.state.value === 'female' ? '#fff' : '#888888'}
                bgColor={this.state.value === 'female' ? '#FF5266' : 'transparent'}
                borderColor={this.state.value === 'female' ? null : '#BBBBBB'}
                borderWidth={this.state.value === 'female' ? null : '1px'}
                className="btnNext mtop10"
                onClick={() => { this.onChangeHandler('female'); }}
                // type="button"
                type="submit"
            />
            <Button
                label="Male"
                labelColor={this.state.value === 'male' ? '#fff' : '#888888'}
                bgColor={this.state.value === 'male' ? '#FF5266' : 'transparent'}
                borderColor={this.state.value === 'male' ? null : '#BBBBBB'}
                borderWidth={this.state.value === 'male' ? null : '1px'}
                className="btnNext mtop10"
                onClick={() => { this.onChangeHandler('male'); }}
                // type="button"
                type="submit"
            />
            <Button
                label="Non-binary"
                labelColor={this.state.value === 'non-binary' ? '#fff' : '#888888'}
                bgColor={this.state.value === 'non-binary' ? '#FF5266' : 'transparent'}
                borderColor={this.state.value === 'non-binary' ? null : '#BBBBBB'}
                borderWidth={this.state.value === 'non-binary' ? null : '1px'}
                className="btnNext mtop10"
                onClick={() => { this.onChangeHandler('non-binary'); }}
                // type="button"
                type="submit"
            />
          </div>
        );
      case 'location.postal_code':
        return (
          <div>
            <h1>What&apos;s your postcode?</h1>
            <Input
                name={this.props.userProp}
                type="text"
                value={this.state.value}
                onChange={(value) => { this.onChangeHandler(value); }}
                // activePage={this.props.activePage}
                autoComplete="off"
            />
            {errorMessage}
            {nextButton}
          </div>
        );
      case 'location.city': {
        return (
          <div>
            <h1>Which town / suburb do you live in?</h1>
            <p>This won’t be displayed publicly, we only use this to show you projects near you.</p>
            {!_.isEmpty(this.state.value || {}) && !this.state.value.city && (
              <p
                style={{
                  backgroundColor: '#fff6cc',
                  padding: '0.5em 0.66em',
                  marginLeft: '-0.66em',
                  marginRight: '-0.66em',
                  color: '#583209',
                }}
              >
                The selected location is too large - please select a valid locality (town, city, suburb, county, municipality, village, postal code, etc)
              </p>
            )}
            <LocationAutocomplete userLocation={ this.user?.location } onChange={this.onChangeHandler} types="(regions)" />
            {errorMessage}
            {nextButton}
          </div>
        );
      }
      case 'meta.identity.birthday.year':
        return (
          <div>
            <h1>What year were you born in?</h1>
            <p>This won’t be displayed publicly, we only use this to show you suitable projects.</p>
            <Input
                name={this.props.userProp}
                type="number"
                value={this.state.value}
                onChange={(value) => { this.onChangeHandler(value); }}
                // activePage={this.props.activePage}
                autoComplete="off"
            />
            {errorMessage}
            {nextButton}
          </div>
        );
      case 'meta.identity.birthday.timestamp':
        return (
          <div>
            <h1>What&apos;s your date of birth?</h1>
            <p>This won’t be displayed publicly, we only use this to show you suitable projects.</p>
            <div className="flex flexWrap">
                <Select
                    placeholder="Month"
                    style={{ flexBasis: 120, flexGrow: 5 }}
                    className="mleft10 mright10"
                    onChange={(value) => { this.dateFieldChange(0, value); }}
                    id="dobMonthSelect"
                    values={[
                        { value: '1', label: 'January' },
                        { value: '2', label: 'February' },
                        { value: '3', label: 'March' },
                        { value: '4', label: 'April' },
                        { value: '5', label: 'May' },
                        { value: '6', label: 'June' },
                        { value: '7', label: 'July' },
                        { value: '8', label: 'August' },
                        { value: '9', label: 'September' },
                        { value: '10', label: 'October' },
                        { value: '11', label: 'November' },
                        { value: '12', label: 'December' }
                    ]}
                />
                <div
                    style={{ flexBasis: 35, flexGrow: 2 }}
                    className="mleft10 mright10"
                >
                    <Input
                        type="tel"
                        placeholder="Day"
                        value={this.state.birthdayInput[1]}
                        onChange={(value) => { this.dateFieldChange(1, value); }}
                        autoComplete="off"
                        id="dobDayInput"
                    />
                </div>
                <div
                    style={{ flexBasis: 50, flexGrow: 3 }}
                    className="mleft10 mright10"
                >
                    <Input
                        type="tel"
                        placeholder="Year"
                        value={this.state.birthdayInput[2]}
                        onChange={(value) => { this.dateFieldChange(2, value); }}
                        autoComplete="off"
                        id="dobYearInput"
                    />
                </div>
            </div>
            {errorMessage}
            {nextButton}
          </div>
        );
      case 'contact.phone.mobile':
        return (
          <div>
            {_.get(this, 'user.contact.phone.mobile') ? (
                <React.Fragment>
                    <h1>Is your mobile number still correct?</h1>
                    <p>Your number is not publicly displayed. We only use this to send you reminders and invitations.</p>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <h1>What’s your mobile phone number?</h1>
                    <p>This won’t be displayed publicly, we only use this to send you reminders and invitations.</p>
                </React.Fragment>
            )}
            <div className="flex flexRow">
                <CountryCodeSelect
                    value={this.state.phoneCountry || _.get(this.props, 'user.location.country', null)}
                    defaultNumber={this.props.value}
                    readonly
                />
                <Input
                    name={this.props.userProp}
                    type="tel"
                    value={this.state.value}
                    onChange={(value) => { this.onChangeHandler({ number: value, country: _.get(this.props, 'user.location.country') }); }}
                    autoComplete="off"
                />
            </div>
            {errorMessage}
            {errorMessage && <p><a className="link" href="/logout">Log in to a different account</a></p>}
            {nextButton}
          </div>
        );
      case 'contact.phone.verification.mobile': {
        // console.log('this.props.verificationResponse', this.props.verificationResponse);
        return (
          <div>
            <h1>Verify your phone number</h1>
            <PhoneVerification
              {...this.props}
              errorMessage={errorMessage}
              inputValue={this.state.value}
              onChangeHandler={this.onChangeHandler}
            />
            {nextButton}
          </div>
        );
      }
      case 'settings.agreement._id': {
        const termsLink = _.get(this.props.latestAgreementByType, 'url', '') > '' ?
            <a href={this.props.latestAgreementByType.url} target="_blank" rel="noopener noreferrer" className="link">{this.props.latestAgreementByType.name}</a> :
            <Link to="#terms" className="link">{this.props.latestAgreementByType.name}</Link>;
        return (
          <div>
            <h1>Before you continue</h1>
            <p>By joining, you’re agreeing to our {termsLink}. Please read them carefully.</p>
            <div className="mtop60">
                <Checkbox
                    label={`I have read, and agree to the ${this.props.latestAgreementByType.name}`}
                    checked={this.state.userAgreementCheckbox}
                    onClick={(event) => { this.setState({ userAgreementCheckbox: event.checked }); }}
                />
            </div>
            <Button
                label="Accept"
                labelColor="#fff"
                bgColor="#FF5266"
                className="btnNext mtop20"
                type="button"
                disabled={!this.state.userAgreementCheckbox}
                onClick={() => { this.onChangeHandler(true); }}
            />
            <Button
                label="Decline"
                labelColor="#888888"
                bgColor="transparent"
                className="btnNext mtop20"
                type="button"
                // buttonStyle={{ boxShadow: '10px 10px 50px 50px yellow' }}
                style={{ boxShadow: '10px 10px 50px 50px cyan' }}
                onClick={() => { this.setState({ agreementDeclineDialogOpen: true }); }}
            />
            <Dialog
                open={!!this.state.agreementDeclineDialogOpen}
                title={`You’ll need to read and accept to the ${this.props.latestAgreementByType.name} in order to use this app.`}
                onRequestClose={() => { this.setState({ agreementDeclineDialogOpen: false }); }}
                modal={false}
                customActions={[{
                    label: 'Go back',
                    primary: false,
                    onClick: () => { this.setState({ agreementDeclineDialogOpen: false }); }
                }]}
                // onCancel={() => this.setState({ endPreviewDialogOpen: false })}
                // onConfirm={() => { this.setState({ endPreviewDialogOpen: false }); this.props.history.push(`${this.props.bookingBaseUrl}/screener`); }}
            >
                <p>
                    <a
                        href="#"
                        className="link"
                        onClick={() => {
                            this.setState({ agreementDeclineDialogOpen: false });
                            this.onChangeHandler(false);
                        }}
                    >
                        I don’t accept the {this.props.latestAgreementByType.name}, please <strong>permanently</strong> delete my account.
                    </a>
                </p>
            </Dialog>
          </div>
        );
      }
      case 'meta.identity.nationality.AUS.permanent_resident':
        return (
          <div>
            <h1>Are you an Australian permanent resident?</h1>
            <Button
                label="Yes"
                labelColor={this.state.value === true ? '#fff' : '#888888'}
                bgColor={this.state.value === true ? '#FF5266' : 'transparent'}
                borderColor={this.state.value === true ? null : '#BBBBBB'}
                borderWidth={this.state.value === true ? null : '1px'}
                className="btnNext mtop10"
                onClick={() => { this.onChangeHandler(true); }}
                // type="button"
                type="submit"
            />
            <Button
                label="No"
                labelColor={this.state.value === false ? '#fff' : '#888888'}
                bgColor={this.state.value === false ? '#FF5266' : 'transparent'}
                borderColor={this.state.value === false ? null : '#BBBBBB'}
                borderWidth={this.state.value === false ? null : '1px'}
                className="btnNext mtop10"
                onClick={() => { this.onChangeHandler(false); }}
                // type="button"
                type="submit"
            />
          </div>
        );
      case 'meta.education':
        return (
          <div>
            <h1>What is your highest level of education?</h1>
            <SwitchList
              type="radio"
              name={`switchList_${this.props.userProp}`}
              onValueChange={this.onChangeHandler}
              options={utils.userPropValues(this.props.userProp).map((value) => {
                  return {
                    key: value,
                    value,
                    label: utils.getKeyDescription('meta.education', value)
                  };
              }).concat([{
                  key: 'meta_education_none',
                  value: 'none',
                  label: 'None of the above'

              }])}
            />
            {nextButton}
          </div>
        );
      case 'meta.family.status':
        return (
          <div>
            <h1>What best describes your current family situation?</h1>
            <SwitchList
              type="radio"
              name={`switchList_${this.props.userProp}`}
              onValueChange={this.onChangeHandler}
              options={utils.userPropValues(this.props.userProp).map((value) => {
                  return {
                    key: value,
                    value,
                    label: utils.getKeyDescription('meta.family.status', value)
                  };
              }).concat([{
                  key: 'meta_family_none',
                  value: 'none',
                  label: 'None of the above'

              }])}
            />
            {nextButton}
          </div>
        );
      case 'meta.work.status':
        return (
          <div>
            <h1>What best describes your current occupation status?</h1>
            <p>You may select two options if relevant</p>
            <SwitchList
              type="checkbox"
              name={`switchList_${this.props.userProp}`}
              onValueChange={this.onChangeHandler}
              options={utils.userPropValues(this.props.userProp).map((value) => {
                  return {
                    key: value,
                    value,
                    label: utils.getKeyDescription('meta.work.status', value)
                  };
              }).concat([{
                  key: 'meta_work_none',
                  value: 'none',
                  label: 'None of the above',
                  exclusive: true
              }])}
            />
            {errorMessage}
            {nextButton}
          </div>
        );
      case 'meta.work._industry_id':
          return (
            <div>
              <h1>What is your occupation?</h1>
              <OccupationSelect onChangeHandler={this.onChangeHandler} />
              {nextButton}
            </div>
          );
      case 'meta.work.employment_type':
          return (
            <div>
              <h1>Are you self-employed or a business owner?</h1>
              <EmploymentTypeSelect onChangeHandler={this.onChangeHandler} />
              {nextButton}
            </div>
          );
      case 'meta.identity.languages.english.speak':
        return (
          <div>
            <h1>Which of the following best describes your English speaking proficiency?</h1>
            <SwitchList
              type="radio"
              name={`switchList_${this.props.userProp}`}
              onValueChange={this.onChangeHandler}
              options={utils.userPropValues(this.props.userProp).map((value) => {
                  return {
                    key: value,
                    value,
                    label: utils.getKeyDescription('meta.identity.languages.english.speak', value)
                  };
              })}
            />
            {nextButton}
          </div>
        );
      case 'meta.social.linkedin.profile_url':
        return (
          <div>
            <h1>Please paste the link to your LinkedIn public profile</h1>
            <Input
                name={this.props.userProp}
                type="text"
                value={this.state.value}
                onChange={(value) => { this.onChangeHandler(value); }}
                // activePage={this.props.activePage}
                autoComplete="off"
            />
            <p className="mtop20">If you’re not on LinkedIn you can type “N/A”, but this may reduce your chance of being selected. <a href="https://www.linkedin.com/help/linkedin/answer/49315/finding-your-linkedin-public-profile-url" className="link" target="_blank" rel="noopener noreferrer" >How to find your public link</a></p>
            {errorMessage}
            {nextButton}
          </div>
        );
      case 'settings.billing': {
        let payoutError = null;
        if (_.get(this.props, 'errorMessage.graphQLErrors.0.message')) {
          payoutError = <p className="error">{this.props.errorMessage.graphQLErrors[0].message}</p>;
        }

        return (
          <PaymentMethod
            value={this.state.value}
            errorMessage={errorMessage || payoutError}
            nextButton={nextButton}
            user={_.get(this, 'user', {})}
            saveValue={value => new Promise((resolve) => {
                this.onChangeHandler(value);
                setTimeout(resolve, 50);
            })}
          />
        );
      }
      case 'settings.tutorial.my': {
        const name = this.props?.user?.meta?.identity?.firstname;
        return (
          <div>
            {name ? <h1>Welcome, {name}!</h1> : <h1>Welcome!</h1>}
            <p>Your profile information helps researchers understand if you’re a good fit for their projects. Your information is only shared if you choose to apply for an opportunity.</p>
            <this.NextButton
              label="Get Started"
              onClick={() => { this.onChangeHandler(true); }}
            />
          </div>
        );
      }
      default:
        console.log(`No controls for '${this.props.userProp}'`);
        return null;
    }
  }
}

export default FormContents;
