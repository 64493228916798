import * as React from 'react';
import { SplitClient, SplitTreatments, useTreatments } from '@splitsoftware/splitio-react';
import { QRCodeSVG } from 'qrcode.react';
import './MobileBetaAccessBanner.scss';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { ANDROID_STORE_LINK, APPLE_STORE_LINK } from '../../../lib/data/constants';
import AppleIcon from '../../../icons/apple';
import PlayStoreIcon from '../../../icons/playStore';

export const Banner = () => {
    const [showQR, setShowQR] = React.useState(false);
    const [selectedPlatform, setSelectedPlatform] = React.useState<'ios' | 'android' | null>(null);

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  
    const handleAppClick = (platform: 'ios' | 'android') => {
      if (isMobile) {
        if (platform === 'android' && isAndroid) {
          window.location.href = ANDROID_STORE_LINK;
        } else if (platform === 'ios' && isIOS) {
          window.location.href = APPLE_STORE_LINK;
        } else {
          // If on mobile but wrong platform, still open QR code
          setSelectedPlatform(platform);
          setShowQR(true);
        }
      } else {
        setSelectedPlatform(platform);
        setShowQR(true);
      }
    };
  
    const appStoreLink = selectedPlatform === 'android' ? ANDROID_STORE_LINK : APPLE_STORE_LINK;
  
    return (
      <>
        <div className="mobile-beta-banner">
          <span className="mobile-beta-banner__text">
            You've been invited to try our new mobile app.
          </span>
          <div className="mobile-beta-banner__cta-container">
            <button onClick={() => handleAppClick('ios')}
            >
              <AppleIcon color="black" width={119} height="100%"/>
            </button>
            <button
              onClick={() => handleAppClick('android')}
            >
                <PlayStoreIcon color="black" width={132} height="100%" />
            </button>
          </div>
        </div>
        {showQR && (
          <div className="qr-modal">
            <div className="qr-modal__content">
              <button className="qr-modal__close" onClick={() => setShowQR(false)}>×</button>
              <h2>Scan to download the {selectedPlatform === 'android' ? 'Android' : 'iOS'} app</h2>
              <QRCodeSVG value={appStoreLink} size={256} />
            </div>
          </div>
        )}
      </>
    );
  };

export const MobileBetaAccessBanner = () => {
  const { user, getClaim } = useKindeAuth();
  const askableUserId = getClaim?.('user_properties')?.value?.askable_user_id?.v || user?.id;

  return (
    <SplitClient splitKey={askableUserId}>
      <SplitTreatments names={['MOBILE_OPPORTUNITIES']}>
        {({ isReady, treatments }) => {
          if (!isReady || treatments.MOBILE_OPPORTUNITIES.treatment !== 'on') {
            return null;
          }

          return <Banner />;
        }}
      </SplitTreatments>
    </SplitClient>
  );
};
