import React, { Component, Fragment } from 'react';
import { graphql, compose, Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'lib/moment';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

import { utils } from 'lib/utils';
import { MESSAGE_TYPE, MESSAGE_DIRECTION } from 'lib/data/constants';

import quantBookingParticipantTimelimit from 'data/queries/booking/quantBookingParticipantTimelimit';
import completeSurvey from 'data/mutations/completeSurvey';
import participantRegisterBookingParticipant from 'data/mutations/participantRegisterBookingParticipant';
import participantConfirmCompletion from 'data/mutations/participantConfirmCompletion';
import createMessage from 'data/mutations/createMessage';
import participantCompleteQuant from 'data/mutations/participantCompleteQuant';
import participantRequestHelp from 'data/mutations/participantRequestHelp';
import participantCancelSession from 'data/mutations/participantCancelSession';

import { Card, CardChild } from 'components/common/Card/view';
import { LoadingOverlay, Button, List, Dialog, Input } from 'components/common';

import AccessTimeIcon from 'material-ui/svg-icons/device/access-time';
import MoneyIcon from 'material-ui/svg-icons/editor/attach-money';
import GiftCardIcon from 'material-ui/svg-icons/action/card-giftcard';
import { WhatToExpect } from './apply/quant/ai-moderated-what-to-expect';

const countdown = new utils.CountdownTimer({ period: 1000 });

class BookingOnlineTaskView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      helpDialog: false,
      helpDialogDetail: '',
      countdown: {},
    };

    let markAsComplete = null;

    // first look for uid-based params
    let completionParam = utils.urlParam(props.location.search, 'UID') || utils.urlParam(props.location.search, 'i');
    if (completionParam) {
      markAsComplete = !!(props.user && props.user._id === completionParam);
    }

    // settle for surveyCompleted=true
    if (markAsComplete === null) {
      completionParam = utils.urlParam(props.location.search, 'surveyCompleted') || '';
      markAsComplete = completionParam === 'true';
    }

    if (completionParam) {
      if (markAsComplete) {
        this.state.loading = true;
        props.completeSurvey().then(() => {
          props.findBookingSubmission.refetch();
          props.history.replace(props.bookingBaseUrl);
          this.setState({ loading: false });
        });
      } else {
        props.history.replace(props.bookingBaseUrl);
      }
    }

    this.saveStateFromProps = this.saveStateFromProps.bind(this);
    this.startCountdown = this.startCountdown.bind(this);
    this.confirmAction = this.confirmAction.bind(this);
    this.openHelpDialogLink = this.openHelpDialogLink.bind(this);
    this.taskCompleteAction = this.taskCompleteAction.bind(this);
    this.renderBookingHasSpots = this.renderBookingHasSpots.bind(this);
    this.renderPageContents = this.renderPageContents.bind(this);
    this.renderHelpDialog = this.renderHelpDialog.bind(this);
  }

  componentDidMount() {
    this.saveStateFromProps(this.props);
    if (this.props.isPreview) {
      this.startCountdown(moment(), _.get(this.props.booking, 'config.session.time_limit', 120));
    }
  }
  componentWillReceiveProps(newProps) {
    this.saveStateFromProps(newProps);
  }
  componentWillUnmount() {
    countdown.clearTimeout();
  }

  saveStateFromProps(props) {
    if (_.get(props.bookingTimelimit, 'findParticipantSessionById.history.last_quantitative_application_time') && !this.state.countdown.endpoint) {
      this.startCountdown(
        moment(props.bookingTimelimit.findParticipantSessionById.history.last_quantitative_application_time, false),
        _.get(props.bookingTimelimit.findParticipantSessionById, 'booking.config.session.time_limit', 0),
      );
    }
  }

  startCountdown(applicationTime, timeLimit) {
    // console.log({ applicationTime, timeLimit });
    countdown.start({
      endpoint: applicationTime.add(timeLimit, 'minutes'),
      callback: endpoint => {
        const timerVals = {
          endpoint: endpoint && endpoint.calendar(),
          countdown: moment().isBefore(endpoint) && countdown.getDurationAsTimer('h:mm:ss'),
        };
        if (timerVals.endpoint !== this.state.countdown.endpoint || timerVals.countdown !== this.state.countdown.countdown) {
          this.setState({ countdown: timerVals });
        }
      },
    });
  }

  confirmAction() {
    if (!this.state.confirmContinue) {
      return false;
    }
    this.setState({ loading: true, confirmDialog: false });
    if (_.get(this.props.bookedSessions, '0._id')) {
      this.setState({ loading: true });
      this.props
        .participantCompleteQuant(this.props.bookedSessions[0]._id)
        .then(() => {
          window.location.reload();
        })
        .catch(mutationError => {
          const errorMessage = _.get(mutationError, 'graphQLErrors.0.message', null);
          console.error(`Failed to complete quant task (${JSON.stringify(errorMessage)})`, mutationError);
        });
    } else {
      utils.window.location.href = this.props.bookingBaseUrl;
    }
  }

  openHelpDialogLink(helpDialog) {
    return e => {
      e.preventDefault();
      if (this.props.isPreview) {
        return;
      }
      this.setState({ helpDialog });
    };
  }

  taskCompleteAction(e) {
    e.preventDefault();
    if (this.props.isPreview) {
      return;
    }
    this.setState({ loading: true });
    this.props.completeSurvey().then(() => {
      this.props.findBookingSubmission.refetch();
      this.props.history.replace(this.props.bookingBaseUrl);
      this.setState({ loading: false });
    });
  }

  renderBookingHasSpots(bookedSession, summaryListItems) {
    if (bookedSession.status === 4) {
      // console.log('bookingpage_invited');
      return (
        <div className="bookingPage bookingOverview bookingRegistered waitlist">
          <h1 className="pageTitle">Congrats, you’ve been picked!</h1>
          <p>Click the button below to continue.</p>
          <Button
            label="Continue"
            labelColor="#fff"
            bgColor="#FF5266"
            type="button"
            className="mtop40 mbottom60"
            onClick={() => {
              if (this.props.isPreview) {
                return;
              }
              if (this.props.isAnonymous) {
                utils.window.location.href = `/login?loginDestination=${this.props.bookingBaseUrl}/apply/online-task`;
                return;
              }
              this.props.history.push(`${this.props.bookingBaseUrl}/apply/online-task`);
            }}
          />
          <div className="subtle-warning-box mleft-20 mright-20 mbottom20">
            <div className="mleft20 mright20 mtop10">
              <h3>Please note:</h3>
              <p>You have been invited, but there are still limited spots which may fill up before you have the chance to complete the task.</p>
              <p>
                You won’t be paid your {_.get(this.props.booking, 'config.incentive.currency_symbol', '')}
                {_.get(this.props.booking, 'config.incentive.value', '')} incentive unless you succesfully finish the task.
              </p>
            </div>
          </div>
          {_.get(this.props.booking, 'config.information.brief_summary', '') > '' && (
            <div className="mtop40">
              <h3>Opportunity details:</h3>
              <ReactMarkdown className="md-block">{this.props.booking.config.information.brief_summary}</ReactMarkdown>
            </div>
          )}
        </div>
      );
    }

    if (bookedSession.status === 2) {
      // console.log('bookingpage_waitlist');
      return (
        <div className="bookingPage bookingOverview bookingRegistered waitlist">
          <h1 className="pageTitle">You’re on the wait list for this task.</h1>
          <div className="subtle-warning-box mleft-20 mright-20 mbottom20">
            <div className="mleft20 mright20 mtop10">
              <h3>Please note:</h3>
              <p>
                You have been placed on the wait list and are not currently confirmed to attend this session. If there are any cancellations, we&rsquo;ll be in
                touch to see if you&rsquo;re still available.
              </p>
            </div>
          </div>
          {_.get(this.props.booking, 'config.information.brief_summary', '') > '' && (
            <div>
              <h3>Brief summary</h3>
              <ReactMarkdown className="md-block">{this.props.booking.config.information.brief_summary}</ReactMarkdown>
            </div>
          )}
          <div className="waitlist-summary mtop60">
            <Card>
              <CardChild type="text">
                <span className="incentive">
                  <strong>
                    {_.get(this.props.booking, 'config.incentive.currency_symbol', '')}
                    {_.get(this.props.booking, 'config.incentive.value', '')}
                  </strong>{' '}
                  incentive
                </span>
              </CardChild>
              <CardChild type="divider" />
              <CardChild type="text" className="card-cols wrap">
                <List
                  className="summary-list"
                  itemDefaultProps={{
                    disabled: true,
                    className: 'list-item',
                  }}
                  items={summaryListItems}
                />
              </CardChild>
            </Card>
          </div>
        </div>
      );
    }

    if (_.get(this.props.booking, 'config.options.review_submission')) {
      // console.log('bookingpage_cherrypicking');
      return (
        <div className="bookingPage bookingOverview bookingRegistered waitlist">
          <h1 className="pageTitle">Registered</h1>
          <p className="fontLarge mtop10 mbottom40">Thanks for applying. You’ll be notified if you’re selected to participate in this opportunity.</p>

          <h3>Now what?</h3>
          <p>The researcher will look over all of the applicants.</p>
          <p>If you’re chosen to participate, you’ll get a notification with further instructions.</p>

          {_.get(this.props.booking, 'config.information.brief_summary', '') > '' && (
            <div className="mtop40">
              <h3>Opportunity details:</h3>
              <ReactMarkdown className="md-block">{this.props.booking.config.information.brief_summary}</ReactMarkdown>
            </div>
          )}
          <div className="waitlist-summary mtop40">
            <Card>
              <CardChild type="text">
                <span className="incentive">
                  <strong>
                    {_.get(this.props.booking, 'config.incentive.currency_symbol', '')}
                    {_.get(this.props.booking, 'config.incentive.value', '')}
                  </strong>{' '}
                  incentive
                </span>
              </CardChild>
              <CardChild type="divider" />
              <CardChild type="text" className="card-cols wrap">
                <List
                  className="summary-list"
                  itemDefaultProps={{
                    disabled: true,
                    className: 'list-item',
                  }}
                  items={summaryListItems}
                />
              </CardChild>
            </Card>
          </div>
        </div>
      );
    }

    // console.log('bookingpage_available');
    return (
      <React.Fragment>
        <h1 className="pageTitle">Online task - application in progress</h1>
        {_.get(this.props.booking.config, 'information.brief_summary', '') > '' ? (
          <React.Fragment>
            <h3>Brief summary</h3>
            <ReactMarkdown className="md-block">{this.props.booking.config.information.brief_summary}</ReactMarkdown>
            <div className="subtle-warning-box mleft-20 mright-20 mbottom20">
              <div className="mleft20 mright20 mtop10">
                <h3>Please note:</h3>
                <p>Your online task is incomplete, please click the button below to continue</p>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        {_.get(this.props.booking.config, 'information.special_instructions', '') > '' ? (
          <React.Fragment>
            <h3>Special instructions</h3>
            <ReactMarkdown className="md-block">{this.props.booking.config.information.special_instructions}</ReactMarkdown>
          </React.Fragment>
        ) : null}
        <div className="waitlist-summary mtop40 mbottom60">
          <Card>
            <CardChild type="text">
              <span className="incentive">
                <strong>
                  {_.get(this.props.booking, 'config.incentive.currency_symbol', '')}
                  {_.get(this.props.booking, 'config.incentive.value', '')}
                </strong>{' '}
                incentive
              </span>
            </CardChild>
            <CardChild type="divider" />
            <CardChild type="text" className="card-cols wrap">
              <List
                className="summary-list"
                itemDefaultProps={{
                  disabled: true,
                  className: 'list-item',
                }}
                items={summaryListItems}
              />
            </CardChild>
            <CardChild type="divider" />
            <CardChild type="text">
              <Button
                label="Continue application"
                labelColor="#fff"
                bgColor="#FF5266"
                type="button"
                className="mtop20 mbottom20"
                onClick={() => {
                  if (this.props.isPreview) {
                    return;
                  }
                  this.props.history.push(`${this.props.bookingBaseUrl}/apply/online-task`);
                }}
              />
            </CardChild>
          </Card>
        </div>
      </React.Fragment>
    );
  }

  renderPageContents() {
    const bookedSession = _.get(this.props, 'bookedSessions.0', null);
    if (!bookedSession) {
      console.error('No booked session!');
      return null;
    }

    if (bookedSession.cancel) {
      // console.log('bookingpage_cancelled');
      return (
        <React.Fragment>
          <h1 className="pageTitle">Cancelled</h1>
          {(() => {
            switch (bookedSession.cancel) {
              case 5:
                return (
                  <p>
                    Your submission has been automatically cancelled. The most common reason for this is that you took too long to complete or confirm the task.
                  </p>
                );
              default:
                return <p>Your submission has been cancelled. Please contact support for more information.</p>;
            }
          })()}
          <p className="mtop20">
            <strong>Need help?</strong>&nbsp;&nbsp;
            <a href="#" className="link" onClick={this.openHelpDialogLink({ subject: 'Why was my submission cancelled?' })}>
              Contact support
            </a>
          </p>
          <p>Please note, you are not currently scheduled to be paid for this task.</p>
        </React.Fragment>
      );
    }
    if (bookedSession.status === 1 && bookedSession.completed) {
      const confirmedSession = _.find(this.props.bookedSessions, ['status', 1]);

      let paymentPref = null; // eslint-disable-line
      let payoutTransactionDetails = null;
      let paymentTime = ' within 3 business days';
      // if (_.get(confirmedSession, 'transaction.transactions.created')) {
      //     paymentTime = moment(confirmedSession.transaction.transactions.created).formatZoneFallback(' [on] MMM D [at] h:mm A');
      // } else if (_.get(confirmedSession, 'transaction.payment_due')) {
      //     // paymentTime = moment(utils.roundFactor(confirmedSession.transaction.payment_due, (1000 * 60 * 5), 'ceil')).formatZoneFallback(', [ETA] MMM D [at] h:mm A');
      //     paymentTime = ' within 3 business days';
      // }

      let payoutTransactionVerb = 'Sending';
      if (_.get(confirmedSession, 'transaction.transactions.provider.reference')) {
        payoutTransactionVerb = 'Sent';
        if (_.get(confirmedSession, 'transaction.transactions.created')) {
          paymentTime = moment(confirmedSession.transaction.transactions.created).formatZoneFallback(' [on] MMM D [at] h:mm A');
        }
      }

      switch (_.get(confirmedSession, 'transaction.transactions.provider.name') || _.get(this.props.user, 'settings.billing.preferred')) {
        case 'paypal':
          paymentPref = 'PayPal';
          payoutTransactionDetails = `${payoutTransactionVerb} via PayPal${paymentTime}`;
          break;
        case 'giftpay':
          paymentPref = 'eGiftCard';
          payoutTransactionDetails = `${payoutTransactionVerb} via email${paymentTime}`;
          if (_.get(confirmedSession, 'transaction.transactions.provider.url')) {
            payoutTransactionDetails = (
              <Fragment>
                {payoutTransactionDetails}
                <br />
                <a href={confirmedSession.transaction.transactions.provider.url} className="link" target="_blank" rel="noopener noreferrer">
                  View in browser
                </a>
              </Fragment>
            );
          }
          break;
        case 'bank':
          paymentPref = 'bank transfer';
          break;
        default:
          paymentPref = null;
      }

      let payoutDetails = null;

      if (paymentPref) {
        payoutDetails = (
          <div>
            <CardChild type="text" className="card-cols payment-content">
              <div className="card-col-dynamic">
                {confirmedSession.completed ? (
                  <Fragment>
                    <h3>Payment via {paymentPref}</h3>
                    {payoutTransactionDetails && <p>{payoutTransactionDetails}</p>}
                  </Fragment>
                ) : (
                  <p>Confirm your attendance to submit your payment for processing</p>
                )}
              </div>
              <div className="card-col-fixed">
                <h3>
                  {this.props.booking.config.incentive.currency_symbol || ''}
                  {this.props.booking.config.incentive.value}
                </h3>
              </div>
            </CardChild>
            {_.get(confirmedSession, 'transaction.payment_due') > Date.now() && (
              <div>
                <CardChild type="divider" />
                <CardChild type="actions" className="textRight payment-prefs">
                  <button
                    className="card-link textRight"
                    onClick={() => {
                      utils.setMenuState('menu=payout');
                    }}
                  >
                    Change payout
                    <br />
                    preferences
                  </button>
                </CardChild>
              </div>
            )}
          </div>
        );
      } else {
        payoutDetails = (
          <CardChild type="text" className="card-cols payment-content">
            <div className="card-col-dynamic">
              <h3>No payout method set</h3>
              <p>We won&apos;t be able to process your payment until you select a method</p>
            </div>
            <div className="card-col-dynamic textRight">
              <button
                className="card-link textRight mtop10"
                onClick={() => {
                  utils.setMenuState('menu=payout');
                }}
              >
                Set payout method
              </button>
            </div>
          </CardChild>
        );
      }

      if (_.get(confirmedSession, 'transaction.suspended') && !_.get(confirmedSession, 'transaction._id')) {
        payoutDetails = (
          <CardChild type="text" className="card-cols payment-content">
            <div className="subtle-warning-box mtop-20 mleft-20 mright-20 mbottom-20">
              <div className="mleft20 mright20 mtop20 mbottom20">
                <p>Your payment has been suspended because there’s a problem with your submission</p>
                <p>
                  For more information,&nbsp;
                  <a href="#" className="link" onClick={this.openHelpDialogLink({ subject: 'Why was my payment suspended?' })}>
                    contact Askable
                  </a>
                </p>
              </div>
            </div>
          </CardChild>
        );
      }

      const isAiModerated = _.get(this.props.booking, 'config.online_task.type') === 3;
      // console.log('bookingpage_completed');
      return (
        <React.Fragment>
          <h1 className="pageTitle">{isAiModerated ? 'Interview completed' : 'Completed task details:'}</h1>
          {_.get(this.props.booking.config, 'information.brief_summary', '') > '' ? (
            <React.Fragment>
              <h3>Brief summary</h3>
              <ReactMarkdown className="md-block">{this.props.booking.config.information.brief_summary}</ReactMarkdown>
              <Card className="mtop40">
                <CardChild type="text" className="card-cols payment-header">
                  <h1>Payment</h1>
                  <h1>
                    {this.props.booking.config.incentive.currency_symbol || ''}
                    {this.props.booking.config.incentive.value}
                  </h1>
                </CardChild>
                <CardChild type="divider" />
                {payoutDetails}
              </Card>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      );
    }

    const summaryListItems = [];

    if (_.get(this.props.booking, 'config.session.duration')) {
      summaryListItems.push({
        key: 'sessionLength',
        primaryText: `${this.props.booking.config.session.duration} minute task`,
        leftIcon: <AccessTimeIcon />,
      });
    }
    if (this.props.booking.config.incentive) {
      switch (this.props.booking.config.incentive.type) {
        case 1:
          summaryListItems.push({
            key: 'paymentMethod',
            primaryText: 'Paid via PayPal or E-Gift Card',
            leftIcon: <MoneyIcon />,
          });
          break;
        case 2:
          summaryListItems.push({
            key: 'paymentMethod-alt',
            primaryText: 'Paid via gift card / voucher',
            leftIcon: <GiftCardIcon />,
          });
          break;
        default:
      }
    }

    const summaryCard = (
      <React.Fragment>
        <CardChild type="text">
          <span className="incentive">
            <strong>
              {_.get(this.props.booking, 'config.incentive.currency_symbol', '')}
              {_.get(this.props.booking, 'config.incentive.value', '')}
            </strong>{' '}
            incentive
          </span>
        </CardChild>
        <CardChild type="divider" />
        <CardChild type="text" className="card-cols wrap">
          <List
            className="summary-list"
            itemDefaultProps={{
              disabled: true,
              className: 'list-item',
            }}
            items={summaryListItems}
          />
        </CardChild>
      </React.Fragment>
    );

    if (bookedSession.status === 5 || bookedSession.status === 1) {
      if (bookedSession.status === 1 || _.get(this.props.findBookingSubmission, 'findBookingSubmission.0.external_survey_completed')) {
        countdown.clearTimeout();
        // console.log('bookingpage_finalstep');
        return (
          <React.Fragment>
            <h1 className="pageTitle">Final step</h1>
            <p>Congratulations, it looks like you&apos;ve completed the task! If that&apos;s the case, please confirm below.</p>
            <p>If you had any problems or didn&apos;t completely finish yet, please let us know ASAP.</p>
            <Button
              label="Confirm"
              labelColor="#fff"
              bgColor="#FF5266"
              type="button"
              className="mtop20 mbottom40"
              onClick={() => {
                if (this.props.isPreview) {
                  return;
                }
                this.setState({ confirmDialog: true });
              }}
            />

            <p>
              <a
                href="#"
                className="link"
                onClick={() => {
                  this.setState({ loading: true });
                  this.props.completeSurvey(false).then(() => {
                    this.setState({ loading: false });
                    this.props.findBookingSubmission.refetch();
                    this.props.participantSessionsByBooking.refetch();
                  });
                }}
              >
                I didn’t actually complete my task
              </a>
            </p>
            <p>
              <a href="#" className="link" onClick={this.openHelpDialogLink({ subject: 'There was a problem with the task', type: 'help_request' })}>
                There was a problem with the task
              </a>
            </p>
          </React.Fragment>
        );
      }

      // console.log('bookingpage_inprogress');
      const conditionalInProgressComponents = {
        timeoutWarning: null,
        helpRequestedBanner: null,
        helpRequestedMessage: null,
      };

      if (_.get(this.props, 'bookedSessions.0.help_requested')) {
        if (_.get(this.props, 'bookedSessions.0.history.quantitative_help_request_followup')) {
          const quantCancelTime = moment(this.props.bookedSessions[0].history.quantitative_help_request_followup).add(1, 'day').calendar(null, {
            sameDay: '[today] [at] h:mma',
            nextDay: '[tomorrow] [at] h:mma',
            nextWeek: '[at] h:mma on MMM D',
            lastDay: '[at] h:mma on MMM D',
            lastWeek: '[at] h:mma on MMM D',
            sameElse: '[at] h:mma on MMM D',
          });
          conditionalInProgressComponents.helpRequestedBanner = (
            <div className="subtle-warning-box mtop-10 mleft-20 mright-20 mbottom20">
              <div className="mleft20 mright20 mtop10">
                <h2>You’ve submitted an issue with the task</h2>
                <p>
                  We’re sorry it’s taken so long to sort this out, but we’re going to have to close the issue soon. Please select one of the options below, or
                  your submission will be automatically cancelled at {quantCancelTime}
                </p>
                <p className="fontLarge">
                  <a href="#" className="link" onClick={this.taskCompleteAction}>
                    I succesfully completed the task
                  </a>
                  <br />
                  <a href="#" className="link" onClick={this.openHelpDialogLink({ subject: 'I couldn’t complete my task', type: 'could_not_complete' })}>
                    I was not able to complete the task
                  </a>
                </p>
              </div>
            </div>
          );
        } else {
          conditionalInProgressComponents.helpRequestedBanner = (
            <div className="subtle-warning-box mtop-10 mleft-20 mright-20 mbottom20">
              <div className="mleft20 mright20 mtop10">
                <h2>You’ve submitted an issue with the task</h2>
                <p>
                  The researcher will look into your issue and get back to you as soon as possible. In the meantime, your deadline has been extended so that you
                  won’t be automatically cancelled.
                </p>
              </div>
            </div>
          );
          conditionalInProgressComponents.helpRequestedMessage = (
            <div>
              <h2>You’ve submitted an issue you’re having with completing the task</h2>
              <p>The researcher will review your issue and get back to you.</p>
              <p>
                <Link to="/messages/admin" className="link">
                  Go to my messages
                </Link>
              </p>
              <p>If you manage to resolve the issue yourself and sucessfully finish the task, click the link below to confirm that you’ve completed it.</p>
              <CardChild type="divider" className="mbottom20" />
            </div>
          );
        }
      } else {
        conditionalInProgressComponents.timeoutWarning = (
          <div className="subtle-warning-box mleft-20 mright-20 mbottom20">
            <div className="mleft20 mright20 mtop10">
              <h3>Please note:</h3>
              <p>You won’t be paid your incentive unless you complete the online task, and mark your completion on this page.</p>
              {this.state.countdown.endpoint && (
                <p>
                  Complete the task by {this.state.countdown.endpoint} {this.state.countdown.countdown && `(${this.state.countdown.countdown})`} or your
                  submission will be cancelled.
                </p>
              )}
            </div>
          </div>
        );
      }

      const isAiModerated = _.get(this.props.booking, 'config.online_task.type') === 3;

      const getInProgressTitle = (() => {
        const taskType = _.get(this.props.booking, 'config.online_task.type');
        switch (taskType) {
          case 3:
            return 'You’ve already started the interview';
          case 2:
            return 'Survey in progress';
          default:
            return 'Online task in progress';
        }
      })();
      return (
        <React.Fragment>
          {conditionalInProgressComponents.helpRequestedBanner}
          <h1 className="pageTitle">{getInProgressTitle}</h1>
          {conditionalInProgressComponents.helpRequestedMessage}
          {_.get(this.props, 'location.search').match(/startTaskReminder/) && (
            <Card className="mbottom20">
              <CardChild type="text textCenter">
                <Button
                  label="I have finished my task"
                  labelColor="#fff"
                  bgColor="#FF5266"
                  type="button"
                  className="mbottom20"
                  onClick={this.taskCompleteAction}
                />
                <a
                  href="#"
                  className="link fontLarge"
                  onClick={this.openHelpDialogLink({ subject: 'There was a problem with the task', type: 'help_request' })}
                >
                  There was a problem with the task
                </a>
              </CardChild>
            </Card>
          )}
          {_.get(this.props.booking.config, 'information.brief_summary', '') > '' && !isAiModerated && (
            <React.Fragment>
              <h3>Brief summary</h3>
              <ReactMarkdown className="md-block">{this.props.booking.config.information.brief_summary}</ReactMarkdown>
            </React.Fragment>
          )}
          {isAiModerated && (
            <>
              <ReactMarkdown className="md-block">Either complete the existing interview, or start a new interview by clicking the button below</ReactMarkdown>
              <WhatToExpect />
            </>
          )}
          {_.get(this.props.booking.config, 'information.special_instructions', '') > '' ? (
            <React.Fragment>
              <h3>Special instructions</h3>
              <ReactMarkdown className="md-block">{this.props.booking.config.information.special_instructions}</ReactMarkdown>
            </React.Fragment>
          ) : null}
          {conditionalInProgressComponents.timeoutWarning}
          <div className="waitlist-summary mtop40 mbottom60">
            <Card className="mbottom40">
              <CardChild type="text">
                <p>
                  <em>
                    <strong>Note:</strong> You&apos;ll be directed away to an external site, but after you&apos;ve finished the task make sure you follow
                    directions to link back to Askable.
                  </em>
                </p>
                <Button
                  label={_.get(this.props.booking, 'config.online_task.type') === 2 ? 'Go to survey' : 'Go to task'}
                  labelColor="#fff"
                  bgColor="#FF5266"
                  type="button"
                  className="mtop10"
                  onClick={() => {
                    if (this.props.isPreview) {
                      return;
                    }
                    if (this.props.isAnonymous) {
                      utils.window.location.href = `/login?loginDestination=/booking/${this.props.booking._id}`;
                      return;
                    }
                    const taskUrl = _.get(this.props.bookedSessions, '0.OnlineTaskLink.url');
                    if (!taskUrl) {
                      utils.window.alert('There was a problem getting the task link. Please contact Askable for help');
                      return;
                    }
                    utils.window.location.href = utils.urlPatternReplace(taskUrl, { user: this.props.user });
                  }}
                />
              </CardChild>
              <CardChild type="divider" />
              <CardChild type="text">
                <p className="fontLarge">
                  <a href="#" className="link" onClick={this.taskCompleteAction}>
                    I’ve completed the task
                  </a>
                </p>
                <p className="fontLarge">
                  <a href="#" className="link" onClick={this.openHelpDialogLink({ subject: 'There was a problem with the task', type: 'help_request' })}>
                    There was a problem with the task
                  </a>
                </p>
              </CardChild>
            </Card>
            <Card>{summaryCard}</Card>
          </div>
        </React.Fragment>
      );
    }

    // Check if booking is full for completed
    return (
      <Query
        query={gql`
          query my_QuantBookingIsFull($id: ID) {
            bookingByID(id: $id) {
              _id
              total_participants
              ParticipantSessionsCount {
                in_progress
                scheduled
                completed
              }
            }
          }
        `}
        variables={{ id: this.props.booking._id }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingOverlay />;
          }
          if (error) {
            console.error(error);
          }
          if (!(data && _.get(data, 'bookingByID.total_participants') && _.has(data, 'bookingByID.ParticipantSessionsCount'))) {
            console.error(
              `Oh no, there’s been an error! ${JSON.stringify({
                data: !!data,
                total_participants: _.get(data, 'bookingByID.total_participants'),
                ParticipantSessionsCount: _.has(data, 'bookingByID.ParticipantSessionsCount'),
              })}`,
            );
            return (
              <React.Fragment>
                <h1 className="pageTitle">Oh no, there’s been an error!</h1>
                <p>
                  We encountered a problem with your task. Please&nbsp;
                  <a href="#" className="link" onClick={this.openHelpDialogLink({ subject: 'Online task - unknown error' })}>
                    contact Askable
                  </a>
                  for help.
                </p>
              </React.Fragment>
            );
          }

          if (
            _.get(data, 'bookingByID.ParticipantSessionsCount.scheduled', 0) + _.get(data, 'bookingByID.ParticipantSessionsCount.completed', 0) >=
            data.bookingByID.total_participants
          ) {
            // console.log('bookingpage_hard_full');
            return (
              <React.Fragment>
                <h1 className="pageTitle">Closed</h1>
                <p>This opportunity is now closed.</p>
                <p>
                  <strong>Opportunity details:</strong>
                </p>
                <ReactMarkdown className="md-block">{this.props.booking.config.information.brief_summary}</ReactMarkdown>
                <div className="waitlist-summary mtop40 mbottom60">
                  <Card>{summaryCard}</Card>
                </div>
              </React.Fragment>
            );
          }

          return this.renderBookingHasSpots(bookedSession, summaryListItems);
        }}
      </Query>
    );
  }

  renderHelpDialog() {
    const helpDialog = {
      onConfirm: () => {
        this.setState({ helpDialogState: 'submitted' });
        let confirmedSession = _.find(this.props.bookedSessions, ['status', 1]);
        if (!confirmedSession) {
          [confirmedSession] = this.props.bookedSessions;
        }
        this.props
          .createMessage({
            type: MESSAGE_TYPE.ADMIN,
            direction: MESSAGE_DIRECTION.PARTICIPANT_TO_ASKABLE,
            body: `${this.state.helpDialog.subject}:\n${this.state.helpDialogDetail}`.replace(/[:\s]+$/, ''),
            tag: 'unmoderated_task_problem',
            _from_user_id: utils.getUserId(),
            context: {
              _booking_id: this.props.booking._id,
              _booking_participant_id: confirmedSession && confirmedSession._id,
              _user_id: utils.getUserId(),
            },
          })
          .then(() => {})
          .catch(error => {
            console.error(error);
            utils.window.location.href = this.props.bookingBaseUrl;
          });
      },
      bodyInitial: (
        <Fragment>
          <p className="mbottom6">Please provide some details and we&apos;ll look into it</p>
          <Input
            value={this.state.helpDialogDetail}
            onChange={value => {
              this.setState({ helpDialogDetail: value });
            }}
            multiLine
            rowsMax="10"
            hintText="Enter details here"
          />
        </Fragment>
      ),
      bodySubmitted: (
        <p>
          Thanks, your message was sent.{' '}
          <Link to="/messages/admin" className="link">
            View in Messages
          </Link>
        </p>
      ),
    };

    switch (_.get(this.state, 'helpDialog.type')) {
      case 'help_request':
        helpDialog.bodyInitial = (
          <Fragment>
            <p>Your task will be put on hold until your issue is resolved.</p>
            {helpDialog.bodyInitial}
          </Fragment>
        );
        helpDialog.onConfirm = () => {
          this.setState({ helpDialogState: 'submitted' });
          let confirmedSession = _.find(this.props.bookedSessions, ['status', 1]);
          if (!confirmedSession) {
            [confirmedSession] = this.props.bookedSessions;
          } else {
            console.error('No sessions found');
            return;
          }
          this.props
            .participantRequestHelp(
              confirmedSession._id,
              `${this.state.helpDialog.subject}:\n${this.state.helpDialogDetail}`.replace(/[:\s]+$/, ''),
              'unmoderated_help_requested',
            )
            .then(() => {
              this.props.participantSessionsByBooking.refetch();
              this.props.findBookingSubmission.refetch();
            })
            .catch(error => {
              console.error(error);
              utils.window.location.href = this.props.bookingBaseUrl;
            });
        };
        break;
      case 'could_not_complete':
        helpDialog.bodyInitial = (
          <Fragment>
            <p>
              We apologise for the trouble you’ve had with this task. We recognise that even though you weren’t able to complete the task, you may have spent
              considerable time and effort trying. If you feel that you deserve to be compensated for this, click the link below, otherwise click{' '}
              <strong>Submit</strong> to cancel your application.
            </p>
            {this.state.pityPaymentAmount === '' || this.state.pityPaymentAmount > '' ? (
              <Input
                type="text"
                value={this.state.pityPaymentAmount ? `$${this.state.pityPaymentAmount.replace(/^\$+|[^$0-9.,]/g, '')}` : ''}
                onChange={value => {
                  this.setState(
                    {
                      pityPaymentAmount: value,
                    },
                    () => {
                      this.setState({
                        helpDialogDetail: this.state.pityPaymentAmount.replace(/[$\s]/g, '')
                          ? `I deserve to be paid a $${this.state.pityPaymentAmount.replace(/^\$+|[^$0-9.,]/g, '')} incentive`
                          : '',
                      });
                    },
                  );
                }}
                placeholder="How much of an incentive do you deserve for your efforts?"
              />
            ) : (
              <p>
                <a
                  href="#"
                  className="link"
                  onClick={() => {
                    this.setState({ pityPaymentAmount: '' });
                  }}
                >
                  I deserve to be paid an incentive
                </a>
              </p>
            )}
          </Fragment>
        );
        helpDialog.onConfirm = () => {
          this.setState({ helpDialogState: 'submitted' });
          let confirmedSession = _.find(this.props.bookedSessions, ['status', 1]);
          if (!confirmedSession) {
            [confirmedSession] = this.props.bookedSessions;
          } else {
            console.error('No sessions found');
            return;
          }
          Promise.all([
            this.props.createMessage({
              type: MESSAGE_TYPE.ADMIN,
              direction: MESSAGE_DIRECTION.PARTICIPANT_TO_ASKABLE,
              body: `${this.state.helpDialog.subject}:\n${this.state.helpDialogDetail}`.replace(/[:\s]+$/, ''),
              tag: 'unmoderated_task_problem_could_not_complete',
              _from_user_id: utils.getUserId(),
              context: {
                _booking_id: this.props.booking._id,
                _booking_participant_id: confirmedSession && confirmedSession._id,
                _user_id: utils.getUserId(),
              },
            }),
            this.props.participantCancelSession(confirmedSession._id, this.state.helpDialog.subject),
          ])
            .then(() => {
              this.props.participantSessionsByBooking.refetch();
            })
            .catch(error => {
              console.error(error);
            });
        };
        break;
      default:
    }

    return (
      <Dialog
        open={!!this.state.helpDialog}
        title={_.get(this.state, 'helpDialog.subject', '')}
        className="confirmAttendanceDialog finished"
        onCancel={() => {
          this.setState({ helpDialog: null, helpDialogDetail: '', helpDialogState: null });
        }}
        onConfirm={helpDialog.onConfirm}
        modal={false}
        // closeButton
        customActions={this.state.helpDialogState === 'submitted' ? [{ label: 'Done', primary: true }] : false}
        customActionLabels={{
          confirm: 'Submit',
        }}
      >
        <div className="textLeft">{this.state.helpDialogState === 'submitted' ? helpDialog.bodySubmitted : helpDialog.bodyInitial}</div>
      </Dialog>
    );
  }

  render() {
    if (this.state.loading) {
      return <LoadingOverlay />;
    }

    return (
      <div className="bookingPage bookingOverview bookingOnlineTask bookingRegistered waitlist available">
        {this.renderPageContents()}
        {/* <pre style={{ fontSize: '11px', overflowY: 'hidden' }}>{JSON.stringify(this.props, undefined, 2)}</pre> */}
        {this.renderHelpDialog()}

        <Dialog
          open={!!this.state.confirmDialog}
          title="Confirm"
          className="confirmAttendanceDialog finished"
          onCancel={() => {
            this.setState({ confirmDialog: false, confirmContinue: false });
          }}
          onConfirm={this.confirmAction}
          modal={false}
          customActions={[
            {
              label: 'Cancel',
              primary: true,
              onClick: this.props.onCancel,
            },
            {
              label: 'Confirm',
              primary: true,
              disabled: !this.state.confirmContinue,
              onClick: this.confirmAction,
            },
          ]}
        >
          <p>
            By continuing, you confirm that you have <u>thoroughly</u> and <u>truthfully</u> completed the online task to the best of your ability.
          </p>
          <p>
            Please type: &apos;<strong>I AGREE</strong>&apos; to continue
          </p>
          <Input id="BookingScheduleConfirmInput" onChange={value => this.setState({ confirmContinue: !!value.match(/^\s*i\s+AGREE\s*$/i) })} />
        </Dialog>
      </div>
    );
  }
}

const quantBookingParticipantTimelimitContainer = graphql(quantBookingParticipantTimelimit, {
  name: 'bookingTimelimit',
  options: props => ({
    // variables: { id: props.match.params.id, status: 1 },
    variables: { _id: _.get(props.bookedSessions, '0._id') },
  }),
});
const completeSurveyContainer = graphql(completeSurvey, {
  props: ({ mutate, ownProps }) => ({
    completeSurvey: completed =>
      mutate({
        variables: { booking_id: ownProps.booking._id, completed },
      }),
  }),
});
const createMessageContainer = graphql(createMessage, {
  props: ({ mutate }) => ({
    createMessage: message =>
      mutate({
        variables: { message },
      }),
  }),
});
const participantRegisterBookingParticipantContainer = graphql(participantRegisterBookingParticipant, {
  props: ({ mutate, ownProps }) => ({
    participantRegisterBookingParticipant: session_ids =>
      mutate({
        variables: { booking_id: ownProps.booking._id, session_ids },
      }),
  }),
});
const participantConfirmCompletionContainer = graphql(participantConfirmCompletion, {
  props: ({ mutate }) => ({
    participantConfirmCompletion: participant_session_id =>
      mutate({
        variables: { participant_session_id },
      }),
  }),
});
const participantCompleteQuantContainer = graphql(participantCompleteQuant, {
  props: ({ mutate }) => ({
    participantCompleteQuant: participant_session_id =>
      mutate({
        variables: { participant_session_id },
      }),
  }),
});
const participantRequestHelpContainer = graphql(participantRequestHelp, {
  props: ({ mutate }) => ({
    participantRequestHelp: (participant_session_id, message_body, message_tag) =>
      mutate({
        variables: { participant_session_id, message_body, message_tag },
      }),
  }),
});
const participantCancelSessionContainer = graphql(participantCancelSession, {
  props: ({ mutate }) => ({
    participantCancelSession: (participant_session_id, cancel_reason) =>
      mutate({
        variables: { participant_session_id, cancel_reason },
      }),
  }),
});

export default compose(
  quantBookingParticipantTimelimitContainer,
  completeSurveyContainer,
  createMessageContainer,
  participantRegisterBookingParticipantContainer,
  participantConfirmCompletionContainer,
  participantCompleteQuantContainer,
  participantRequestHelpContainer,
  participantCancelSessionContainer,
)(BookingOnlineTaskView);
