import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import _ from 'lodash';
import { withRouter } from 'react-router';

import { Query, graphql, compose } from 'react-apollo';
// import { Redirect } from 'react-router-dom';
import { Toolbar, ToolbarGroup, ToolbarSeparator, FlatButton } from 'material-ui';

import { BOOKING_TYPE, MESSAGE_TYPE, MESSAGE_DIRECTION } from 'lib/data/constants';
import { utils } from 'lib/utils';

import messagesSubscription from 'data/subscriptions/messages/messagesSubscription';
import { messagesFieldsFragment } from 'data/fragments/messages/messagesFields';

import Conversation from 'components/messages/messagesConversationView';
import { Dialog, Header } from 'components/common';

import Room from './askable-live-chat-room.view';

class DemoRoom extends Component {
    constructor() {
        super();
        this.state = { readyToConnect: true };
        // this.saveRoomDetails = this.saveRoomDetails.bind(this);
        this.renderErrorDialog = this.renderErrorDialog.bind(this);
    }

    // componentDidMount() {
    //     this.props.roomSetup()
    //         .then(this.saveRoomDetails)
    //         .catch((error) => {
    //             this.setState({ room: false, error: { name: 'room_connect_error', error } });
    //             console.error(error);
    //         });
    // }

    // saveRoomDetails(result) {
    //     this.setState({ room: _.get(result, 'data.askableLiveDemoRoomSetup') });
    // }

    renderErrorDialog() {
        if (!this.state.error) return null;
        const errorContents = {
            actions: [{
                label: 'Rejoin call',
                primary: true,
                onClick: () => {
                    const rejoinButton = document.getElementById('demo-chat-rejoin-call');
                    if (rejoinButton) {
                        window.location.href = rejoinButton.getAttribute('href');
                    } else {
                        window.location.reload();
                    }
                },
            }]
        };
        switch (this.state.error.name) {
            case 'room_connect_error':
                if (_.get(this.state.error, 'error.graphQLErrors.0.extensions.code') === 'GRAPHQL_VALIDATION_FAILED') {
                    errorContents.title = 'Invalid link';
                    errorContents.body = 'The room ID you’re trying to connect to is invalid or incorrect. Please check your link and try again.';
                    break;
                }
                if (_.get(this.state.error, 'error.graphQLErrors.0.extensions.code') === 400 && _.get(this.state.error, 'error.graphQLErrors.0.message')) {
                    errorContents.title = 'Failed to connect';
                    errorContents.body = _.get(this.state.error, 'error.graphQLErrors.0.message');
                    break;
                }
                errorContents.title = 'Connection error';
                errorContents.body = 'Unable to connect to the video chat. Please make sure that you’re using the right link';
            break;
            default:
                errorContents.title = 'Error';
                errorContents.body = _.get(this.state.error, 'error.message') || 'Unknown error';
        }

        return (
            <Dialog
                customActions={errorContents.actions || []}
                title={errorContents.title}
                open
                modal={false}
                onCancel={null}
            >
                {typeof errorContents.body === 'string' ? <p>{errorContents.body}</p> : errorContents.body}
            </Dialog>
        );
    }

    render() {
        const messages = (
            <Query
                query={gql`
                    query askableLiveDemoMessages ($room_id: ID!) {
                        askableLiveDemoMessages (room_id: $room_id) {
                            ...messagesFields
                        }
                    }
                    ${messagesFieldsFragment}
                `}
                variables={{ room_id: this.props._room_id }}
                fetchPolicy="network-only"
            >
            {({ subscribeToMore, ...result }) => (
                <Conversation
                    messages={_.get(result, 'data.askableLiveDemoMessages', [])}
                    messageFilters={{
                        type: MESSAGE_TYPE.IN_APP,
                        _live_demo_room_id: this.props._room_id,
                        direction: {
                            revieved: MESSAGE_DIRECTION.CLIENT_TO_PARTICIPANT,
                            sent: MESSAGE_DIRECTION.PARTICIPANT_TO_CLIENT,
                        }
                    }}
                    header={null}
                    wrapperHeader={null}
                    subscribeToMessages={() => subscribeToMore({
                        document: messagesSubscription,
                        variables: { filter: { _live_demo_room_id: this.props._room_id } },
                        updateQuery: (prev, { subscriptionData }) => {
                            if (_.get(prev, 'askableLiveDemoMessages') && _.get(subscriptionData, 'data.messagesSubscription')) {
                                const next = _.cloneDeep(prev);
                                next.askableLiveDemoMessages.push(subscriptionData.data.messagesSubscription);
                                return next;
                            }
                            return prev;
                        }
                    })}
                />
            )}
            </Query>
        );

        return (
            <Fragment>
                <Room
                    roomSetup={this.props.roomSetup}
                    onSetupError={(error) => {
                        console.error(error);
                        this.setState({
                            readyToConnect: false,
                            error: { name: 'room_connect_error', error }
                        });
                    }}
                    messages={messages}
                    readyToConnect={this.state.readyToConnect}
                    error={this.state.error}
                />
                {this.renderErrorDialog()}
            </Fragment>
        );
    }
}

const roomSetupContainer = graphql(gql`
    mutation askableLiveRoomSetup ($_room_id:ID!) {
        askableLiveDemoRoomSetup(room_id: $_room_id) {
            token
            room { sid uniqueName status }
        }
    }`, {
    props: ({ mutate, ownProps }) => ({
        roomSetup: () => mutate({
            variables: { _room_id: ownProps._room_id }
        }),
    }),
});

export const DemoHeader = withRouter(props => (
    <Toolbar
        style={{
        backgroundColor: 'rgb(255,255,255)'
        }}
        className="toolbar"
    >
        <ToolbarGroup>
            <img src="/askable-logo.svg" alt="Askable" style={{ marginTop: '6%' }} />
        </ToolbarGroup>
        <Query
            query={gql`query askableLiveDemoLink($_room_id: ID!) { askableLiveDemoLink (_room_id: $_room_id) { general_link } }`}
            variables={{ _room_id: _.get(props, 'match.params.room_id') }}
        >
        {({ data }) => {
            if (!_.get(data, 'askableLiveDemoLink.general_link')) return null;
            const { askableLiveDemoLink } = data;
                const { general_link } = askableLiveDemoLink;

            return (
                <ToolbarGroup
                    className="LiveDemoToolbarLinks"
                >
                    <span><strong>Demo joining link:</strong> {general_link}</span>
                    <a
                        className="link"
                        role="button"
                        href="#copy"
                        onClick={(e) => {
                            e.preventDefault();
                            const linkElem = e.target;
                            utils.copy(general_link, () => {
                                if (!linkElem) return;
                                linkElem.style.minWidth = `${linkElem.clientWidth}px`;
                                linkElem.dataset.initialText = linkElem.dataset.initialText || linkElem.innerText;
                                linkElem.innerText = 'Copied!';
                                setTimeout(() => {
                                    if (!linkElem) return;
                                    linkElem.innerText = linkElem.dataset.initialText;
                                }, 2500);
                            });
                        }}
                    >
                        Copy link
                    </a>
                    <span className="divider" />
                    <a className="link" role="button" href={general_link} id="demo-chat-rejoin-call">Rejoin call</a>
                </ToolbarGroup>
            );
        }}
        </Query>
    </Toolbar>
));

export default compose(roomSetupContainer)(DemoRoom);
