import React, { Component, Fragment } from 'react';
import { graphql, compose } from 'react-apollo';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

import moment from 'lib/moment';
import _ from 'lodash';
import dot from 'dot-object';
import stringSimilarity from 'string-similarity';

import { utils } from 'lib/utils';
import globals from 'settings/globals';
// import { localStorage } from 'lib/storage';
import { BOOKING_TYPE } from 'lib/data/constants';

import { fetchBookingByIdAdditional } from 'data/queries/booking/fetchBookingById';
// import FlatButton from 'material-ui/FlatButton';

import { Marker } from 'google-maps-react';
import { Map, LoadingOverlay, AddSessionToCalendar, Button } from 'components/common';
import { Card, CardChild } from 'components/common/Card/view';
import Onboarding from 'components/onboarding/view';

import ConfirmAttendance from './confirmAttendanceView';
import ChangeAttendanceDialog from './changeAttendanceView';

import { HELP_URL } from '../../lib/data/constants';
import { isMobileOnlyAskableLive } from './utils/bookingConfig';
import { MobileAppRequiredCallout } from './mobileAppRequiredCallout';

// Styles
import './styles/booking.scss';
class BookingRegisteredView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booking: props.booking,
      bookingAdditional: false,
      bookedSession: props.bookedSession
    };

    // utils.fbPixelTrack('ViewContent', {
    //   content_ids: props.booking._id,
    //   content_type: 'Booking - registered',
    // });
    // this.reloadBooking = this.reloadBooking.bind(this);

    this.setBookingState = this.setBookingState.bind(this);
  }

  componentWillMount() {
      this.setBookingState(this.props);
  }

  componentWillReceiveProps(newProps) {
      this.setBookingState(newProps);
  }

  setBookingState(props) {
    if (
        !this.state.bookingAdditional &&
        props.fetchBookingByIdAdditional.bookingByID &&
        !props.fetchBookingByIdAdditional.loading &&
        this.state.booking
    ) {
      const flatBooking = Object.assign({}, dot.dot(this.state.booking), dot.dot(props.fetchBookingByIdAdditional.bookingByID));
      this.setState({
        // booking: dot.object(Object.assign({}, dot.dot(this.state.booking), dot.dot(props.fetchBookingByIdAdditional.bookingByID))),
        booking: flatBooking ? dot.object(flatBooking) : {},
        bookingAdditional: true
      });
    }

    if (props.participantSessionsByBooking && props.participantSessionsByBooking.participantSessionsByBooking) {
        const bookedSession = _.find(props.participantSessionsByBooking.participantSessionsByBooking, ['status', 2]);
        if (bookedSession) {
            this.setState({
                bookedSession
            });
        }
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingOverlay />;
    }
    if (!this.state.bookingAdditional) {
      return <LoadingOverlay />;
    }

    const clientInfo = {};
    switch (this.props.booking.config.session.type) {
        case 1:
            clientInfo.sessionType = '1 on 1 chat';
        break;
        case 2:
            clientInfo.sessionType = 'focus group';
        break;
        default:
            clientInfo.sessionType = null;
    }

    const cancelInfo = (
      <CardChild type="text" className="mtop0">
        <h2 className="title">Cancellation</h2>
        <p>We understand sometimes life happens, but please be considerate when cancelling your attendance. You will not suffer any penalities as long as you cancel with at least 24 hours notice.</p>
        <button
            className="card-link"
            onClick={() => {
                if (this.props.isPreview) {
                    return false;
                }
                this.setState({ changeAttendanceDialog: true });
            }}
        >
            Cancel my attendance
        </button>
      </CardChild>
    );

    let phoneContact = null;
    // this.props.isPreview
    if (this.state.booking.type === BOOKING_TYPE.FACE_TO_FACE && this.state.booking.config.contact.phone) {
        phoneContact = (
            <a className="contactDetail phone" href={`tel:${this.state.booking.config.contact.phone}`}>
                Call facilitator
            </a>
        );
    }
    const isMobileOnly = isMobileOnlyAskableLive(this.props.booking);
    const contactDetailsSection = (
        <Fragment>
            <CardChild type="text" className="bookingContactDetails">
                <div className="flex flexRow">
                    {this.props.isPreview
                        ? <span className="contactDetail message">Send message</span>
                        : <Link className="contactDetail message" to={`/messages/booking/${this.state.booking._id}`}>Send message</Link>
                    }
                    {phoneContact}
                </div>
                <p className="fontSmall">
                    Please be mindful of business hours. If your enquiry is about payment or your account, please {
                        this.props.isPreview
                            ? <a className="link" href="#">contact Askable</a>
                            : <Link className="link" to="/messages/admin">contact Askable</Link>
                    } instead.
                </p>
                {isMobileOnly ? <MobileAppRequiredCallout /> : null}
            </CardChild>
            <CardChild type="divider" />
        </Fragment>
    );

    let contactPerson = null;
    if (_.get(this.state.booking, 'config.contact.name')) {
        contactPerson = (
            <Fragment>
                <CardChild type="divider" />
                <CardChild type="text">
                  <div className="card-col-dynamic">
                    <h3>Session Facilitator</h3>
                    <p>{this.state.booking.config.contact.name}</p>
                  </div>
                </CardChild>
            </Fragment>
        );
    }

    const agreementCallout = (
      _.get(this.props, 'bookingAgreement.link')
      && (_.get(this.props, 'bookingAgreement.status') === 3 || _.get(this.props, 'bookingAgreement.status') === 4)
      && !_.get(window, 'location.search', '').match(/\bsignedAgreement\b/)
    ) ? (
      <Fragment>
        <CardChild type="text">
          <h3>Sign agreement</h3>
          <p>The facilitator has provided an agreement for you to sign before the session.</p>
          <p>Please read it carefully and sign if you’re satisfied with the terms.</p>
          <div className="subtle-warning-box mbottom20">
              <div className="mleft20 mright20 mtop10">
                  <h3>Please note:</h3>
                  <p>By not signing, your session may be cancelled and you may not be paid the {this.state.booking.config.incentive.currency_symbol || ''}{this.state.booking.config.incentive.value || globals.incentive_value_preview_default} incentive.</p>
              </div>
          </div>
          <Button
            label="View Agreement"
            labelColor="#fff"
            bgColor="#FF5266"
            type="button"
            onClick={() => { window.open(this.props.bookingAgreement.link, '_blank'); }}
            // className="widthAuto"
          />
        </CardChild>
        <CardChild type="divider" />
      </Fragment>
    ) : null;

    let timeAndPlace = null;
    switch (this.props.booking.type) {
        case 1: {
            let bookingLocation = Object.assign({}, this.state.booking.config.location);
            if (_.get(this.props.booking, 'config.in_context.location_type') && this.props.inContextLocation) {
                bookingLocation = Object.assign({}, this.props.inContextLocation);
            }
            const vagueAddress = bookingLocation && (
                _.get(bookingLocation, 'city') === _.get(bookingLocation, 'name') ||
                _.get(bookingLocation, 'state') === _.get(bookingLocation, 'name') ||
                _.get(bookingLocation, 'postal_code') === _.get(bookingLocation, 'name')
            );
            if (bookingLocation.name && bookingLocation.street1 && stringSimilarity.compareTwoStrings(bookingLocation.name, bookingLocation.street1) > 0.7) {
                bookingLocation.name = undefined;
            }

            const timeAndPlaceAddress = (
                bookingLocation.city && bookingLocation.latitude && bookingLocation.longitude
            ) ? (
                <div>
                    <CardChild type="text">
                      {_.get(this.props.booking, 'config.information.special_instructions') > '' && (
                          <div className="textLeft mbottom40">
                              <h3>Special Instructions</h3>
                              <ReactMarkdown className="md-block">{this.props.booking.config.information.special_instructions}</ReactMarkdown>
                          </div>
                      )}
                      <div className="card-cols">
                          <div className="textLeft card-col-dynamic">
                            <h3>{vagueAddress ? 'Location' : 'Address'}</h3>
                            <address className="address">
                              {
                                  _.uniqBy(
                                      (
                                          vagueAddress ? ['city', 'state'] : ['name', 'level', 'street1', 'street2', 'city', 'state']
                                      )
                                      .map(line => (bookingLocation[line] && <span key={`address_${line}`} className={`line line-${line}`}>{bookingLocation[line]}</span>) || null),
                                      elem => elem && elem.props.children
                                  )
                              }
                            </address>
                          </div>
                          <div className="textRight card-col-fixed mtopauto">
                            <a
                              href={`https://www.google.com/maps/dir//${
                                encodeURIComponent((bookingLocation.street1 ? ['name', 'street1', 'street2', 'city', 'state'] : ['name', 'city', 'state'])
                                    .map(line => bookingLocation[line] || null)
                                    .join(' ')
                                    .replace(/\s+/g, ' '))
                              }/${
                                bookingLocation.latitude && bookingLocation.longitude ?
                                  `@${bookingLocation.latitude},${bookingLocation.longitude}` :
                                  ''
                                }`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="card-link textRight"
                            >
                              Get directions
                            </a>
                          </div>
                      </div>
                    </CardChild>
                    <CardChild type="media">
                      <div className="map-embed">
                        <Map
                          className="mapComponent"
                          lat={bookingLocation.latitude}
                          lng={bookingLocation.longitude}
                        >
                          <Marker lat={bookingLocation.latitude} lng={bookingLocation.longitude} />
                        </Map>
                      </div>
                    </CardChild>
                </div>
            ) : null;
            timeAndPlace = (
                <Card>
                  {contactDetailsSection}
                  {agreementCallout}
                  <CardChild type="text" className="card-cols wrap">
                    <div className="textLeft card-col-dynamic">
                      <h3>{ _.get(this.state, 'bookedSession.session.start', 0) > moment().valueOf() ? 'Please arrive on' : 'Session time' }</h3>
                      <p className="date">
                        {_.has(this.state, 'bookedSession.session.start') && moment(this.state.bookedSession.session.start).format('dddd Do MMM')}
                        <span className="time">
                          {_.has(this.state, 'bookedSession.session.start') && moment(this.state.bookedSession.session.start).formatZoneFallback('h:mm A')}
                        </span>
                      </p>
                      <AddSessionToCalendar
                        session={this.state.bookedSession.session}
                        booking={this.state.booking}
                        location={bookingLocation}
                      />
                    </div>
                    <div className="textRight card-col-fixed" style={{ margin: '0 0 0 auto' }}>
                      <p className="duration">Session Duration:</p>
                      <p className="duration">{this.props.sessionDuration}</p>
                    </div>
                  </CardChild>
                  {contactPerson}
                  <CardChild type="divider" />
                  {timeAndPlaceAddress}
                  {cancelInfo}
                </Card>
            );

            if (_.get(this.props.booking, 'config.in_context.location_type')) {
                let locationHeader = 'The researcher will meet you at your chosen location.';
                switch (this.props.booking.config.in_context.location_type) {
                    case 1:
                        locationHeader = 'The researcher will meet you at your home.';
                    break;
                    case 2:
                        locationHeader = 'The researcher will meet you at your business/workplace.';
                    break;
                    default:
                }
                timeAndPlace = (
                    <Card>
                      {contactDetailsSection}
                      {agreementCallout}
                      <CardChild type="text" className="card-cols wrap">
                        <div className="textLeft card-col-dynamic">
                          <h3>Session time</h3>
                          <p className="date">
                            {_.has(this.state, 'bookedSession.session.start') && moment(this.state.bookedSession.session.start).format('dddd Do MMM')}
                            <span className="time">
                              {_.has(this.state, 'bookedSession.session.start') && moment(this.state.bookedSession.session.start).formatZoneFallback('h:mm A')}
                            </span>
                          </p>
                          <AddSessionToCalendar
                            session={this.state.bookedSession.session}
                            booking={this.state.booking}
                            location={bookingLocation}
                          />
                        </div>
                        <div className="textRight card-col-fixed" style={{ margin: '0 0 0 auto' }}>
                          <p className="duration">Duration:</p>
                          <p className="duration">{this.props.sessionDuration}</p>
                        </div>
                      </CardChild>
                      {contactPerson}
                      <CardChild type="divider" />
                      <CardChild type="text">
                        {_.get(this.props.booking, 'config.information.special_instructions') > '' && (
                            <div className="textLeft mbottom40">
                                <h3>Special Instructions</h3>
                                <ReactMarkdown className="md-block">{this.props.booking.config.information.special_instructions}</ReactMarkdown>
                            </div>
                        )}
                        <div className="card-cols">
                            <div className="textLeft card-col-dynamic">
                              <h3>{locationHeader}</h3>
                              {/* <address className="address">
                                {
                                    _.uniqBy(
                                        (
                                            ['name', 'level', 'street1', 'street2', 'city', 'state']
                                        )
                                        .map(line => (bookingLocation[line] && <span key={`address_${line}`} className={`line line-${line}`}>{bookingLocation[line]}</span>) || null),
                                        elem => elem && elem.props.children
                                    )
                                }
                              </address> */}
                            </div>
                        </div>
                      </CardChild>
                      {cancelInfo}
                    </Card>
                );
            }
        }
        break;
        case 2:
            timeAndPlace = (
                <Fragment>
                    {_.get(this.props.booking, 'config.information.special_instructions') > '' && (
                        <div className="mbottom40">
                            <h3>Special Instructions</h3>
                            <ReactMarkdown className="md-block">{this.props.booking.config.information.special_instructions}</ReactMarkdown>
                        </div>
                    )}
                    <Card>
                      {contactDetailsSection}
                      {agreementCallout}
                      <CardChild type="text" className="card-cols">
                        <div className="textLeft card-col-dynamic">
                          <h3>Your session is scheduled for</h3>
                          <p className="date">{moment(this.state.bookedSession.session.start).format('dddd Do MMM')} <span className="time">{moment(this.state.bookedSession.session.start).formatZoneFallback('h:mm A')}</span></p>
                          <AddSessionToCalendar
                            session={this.state.bookedSession.session}
                            booking={this.state.booking}
                          />
                        </div>
                        <div className="textRight card-col-fixed">
                          <h3>Duration</h3>
                          <p className="duration">{this.props.sessionDuration}</p>
                        </div>
                      </CardChild>
                      {contactPerson}
                      {cancelInfo}
                    </Card>
                </Fragment>
            );
        break;
        default:
    }

    let paymentPref = null; // eslint-disable-line
    switch (this.props.user &&
        this.props.user.settings &&
        this.props.user.settings.billing &&
        this.props.user.settings.billing.preferred
    ) {
      case 'paypal':
          paymentPref = 'PayPal';
      break;
      case 'giftpay':
          paymentPref = 'electronic gift card';
      break;
      case 'bank':
          paymentPref = 'bank transfer';
      break;
      default:
          paymentPref = null;
    }

    let payoutDetails = null;
    switch (this.state.booking.config.incentive.type) {
      case 1:
        if (paymentPref) {
            payoutDetails = (
                <div>
                    <CardChild type="text" className="card-cols payment-content">
                      <div className="card-col-dynamic">
                        <h3>Paid out via {paymentPref}</h3>
                        <p>Pending completion of your session</p>
                      </div>
                      <div className="card-col-fixed">
                        <h3>{this.state.booking.config.incentive.currency_symbol || ''}{this.state.booking.config.incentive.value || globals.incentive_value_preview_default}</h3>
                      </div>
                    </CardChild>
                    <CardChild type="divider" />
                    <CardChild type="actions" className="textRight payment-prefs">
                      <button className="card-link textRight" onClick={() => { if (this.props.isPreview) return false; utils.setMenuState('menu=payout'); }} >Change payout<br />preferences</button>
                    </CardChild>
                </div>
            );
        } else {
            payoutDetails = (
              <CardChild type="text" className="card-cols payment-content">
                  <div className="card-col-dynamic">
                    <h3>No payout method set</h3>
                  </div>
                  <div className="card-col-dynamic textRight">
                    <button className="card-link textRight" onClick={() => { if (this.props.isPreview) return false; utils.setMenuState('menu=payout'); }} >Change payout preferences</button>
                  </div>
              </CardChild>
            );
        }
      break;
      case 2:
        payoutDetails = (
            <CardChild type="text" className="card-cols payment-content">
              <div className="card-col-dynamic">
                <h3>Payment via gift card / voucher</h3>
                {/* TODO - v1.0: review this copy (PPTS-11) */}
                <p>To be collected at the end of your session</p>
              </div>
              <div className="card-col-fixed">
                <h3>{this.state.booking.config.incentive.currency_symbol || ''}{this.state.booking.config.incentive.value || globals.incentive_value_preview_default}</h3>
              </div>
            </CardChild>
        );
      break;
      default:
        payoutDetails = null;
    }

    let confirmAttendanceDialog = (
        <ConfirmAttendance
          booking={this.state.booking}
          booking_participant={this.state.bookedSession._id}
          user_confirm={this.state.bookedSession.user_confirm}
          session={this.state.bookedSession.session}
          // session={{ start: moment('2018-01-19 16:00').valueOf() }}
          // confirmAction={this.props.participantReconfirmSession}
          // cancelAction={this.props.participantCancelSession}
          inContextLocation={this.props.inContextLocation}
        />
    );
    if (this.props.isPreview) {
        if (this.props.confirmAttendanceAnonymous && this.state.bookedSession._id) {
            confirmAttendanceDialog = (
                <ConfirmAttendance
                  booking={this.state.booking}
                  booking_participant={this.state.bookedSession._id}
                  user_confirm={this.state.bookedSession.user_confirm}
                  session={this.state.bookedSession.session}
                  confirmAttendanceAnonymous
                  // session={{ start: moment('2018-01-19 16:00').valueOf() }}
                  confirmAction={() => { utils.window.location.href = `/login?loginDestination=/booking/${this.state.booking._id}`; }}
                  cancelAction={() => { utils.window.location.href = `/login?loginDestination=/booking/${this.state.booking._id}`; }}
                  inContextLocation={this.props.inContextLocation}
                />
            );
        } else {
            confirmAttendanceDialog = null;
        }
    }

    let askableLiveLink = null;
    let askableLiveSetupCheckReminder = null;
    if (_.get(this.props.booking, 'config.remote.askable_live')) {
      if (_.get(this.state, 'bookedSession.session.start') && moment(this.state.bookedSession.session.start).subtract(15, 'minutes').isBefore(Date.now())) {
        const askableLiveHref = _.get(this.props, 'bookedSession.session.MeetingLink') || `/live/${this.props.booking._id}/${_.get(this.state, 'bookedSession.session._id', '')}`;
        askableLiveLink = (
          <p>
            <a className="askable-live-link-to-session" href={askableLiveHref}>
              <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 12L12 8.8V12H4V4H12V7.2L16 4V12ZM18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V2C20 0.89 19.1 0 18 0Z" fill="#2F80ED"/>
              </svg>
              <span>Link to your video chat session&nbsp;&rarr;</span>
            </a>
          </p>
        );
      }
    }
    if (_.get(this.props.booking, 'type') === BOOKING_TYPE.REMOTE) {
      // Add in setup check logic and info
      const reminderDeadline = (time, unit) => {
        if (!_.get(this.state, 'bookedSession.session.start')) return moment();
        return moment(this.state.bookedSession.session.start).subtract(time, unit);
      };
      if (!_.get(this.props.user, 'settings.remote.setup_check') && reminderDeadline(30, 'minutes').isAfter(Date.now())) {
        askableLiveSetupCheckReminder = (
          <div className="danger-box mleft-20 mright-20 mbottom30">
            <div className="mtop20 mleft20 mright20">
              <h3>Heads up!</h3>
              <p>
                To avoid disappointment, please do the laptop setup check ASAP to make sure there are no issues for your session.
              </p>
              <p><Link to="/live/setup-check" className="link">Click here - Laptop setup check</Link></p>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="bookingPage bookingRegistered">
        <h1 className="pageTitle">
            {_.get(this.state, 'bookedSession.session.start', 0) > moment().valueOf() ? 'You’re attending' : 'Your session is on now'}{
                (clientInfo.name || clientInfo.sessionType)
                ? <span>:<br /><small>{clientInfo.name}{(clientInfo.name && clientInfo.sessionType ? ' - ' : '')}{clientInfo.sessionType}</small></span>
                : '!'
            }
        </h1>
        {askableLiveLink}
        {askableLiveSetupCheckReminder}
        {(
            this.props.booking.config.information &&
            this.props.booking.config.information.brief_summary &&
            this.props.booking.config.information.brief_summary > ''
        ) ? (
            <div>
                <h3>Opportunity details:</h3>
                <ReactMarkdown className="md-block">{this.props.booking.config.information.brief_summary}</ReactMarkdown>
            </div>
        ) : null}

        {timeAndPlace}

        <Card className="mtop40">
          <CardChild type="text" className="card-cols payment-header">
            <h1>Payment</h1>
            <h1>{this.state.booking.config.incentive.currency_symbol || ''}{this.state.booking.config.incentive.value || globals.incentive_value_preview_default}</h1>
          </CardChild>
          <CardChild type="divider" />
          {payoutDetails}
        </Card>

        <div className="textContent">
        <Link to={this.props.isPreview ? '?registered' : HELP_URL }>Need help?</Link>
        </div>
        {confirmAttendanceDialog}
        <ChangeAttendanceDialog
            open={this.state.changeAttendanceDialog}
            pastSession
            onClose={() => { this.setState({ changeAttendanceDialog: false }); }}
            bookedSession={this.state.bookedSession}
            booking={this.state.booking}
        />
        {this.props.isPreview ? null : <Onboarding tutorial="booking-registraion" />}
      </div>
    );
  }
}

const BookingDetailsContainer = graphql(fetchBookingByIdAdditional, {
    name: 'fetchBookingByIdAdditional',
    options: props => ({
        variables: { id: props.booking._id },
    }),
});

export default compose(BookingDetailsContainer)(BookingRegisteredView);
