import React from 'react';
import { Query } from 'react-apollo';

import _ from 'lodash';

import { localStorage } from 'lib/storage';
import { MESSAGE_TYPE, MESSAGE_DIRECTION } from 'lib/data/constants';

import adminMessages from 'data/queries/messages/adminMessages';
import messagesSubscription from 'data/subscriptions/messages/messagesSubscription';

import { Header } from 'components/common';

import Conversation from './messagesConversationView';

const AdminMessages = (props) => {
    const messageFilters = {
        type: MESSAGE_TYPE.ADMIN,
        _user_id: localStorage.get('connectedParticipant'),
        direction: {
            revieved: MESSAGE_DIRECTION.ASKABLE_TO_PARTICIPANT,
            sent: MESSAGE_DIRECTION.PARTICIPANT_TO_ASKABLE,
        }
    };
    return (
        <Query
          query={adminMessages}
          variables={{ _user_id: messageFilters._user_id }}
          fetchPolicy="network-only"
        >
        {({ subscribeToMore, ...result }) => (
            <Conversation
                messages={_.get(result, 'data.userAdminMessages', [])}
                messageFilters={messageFilters}
                header={<Header
                  context="messagesConversation"
                />}
                subscribeToMessages={() => subscribeToMore({
                    document: messagesSubscription,
                    variables: { filter: _.pick(messageFilters, ['type', '_user_id']) },
                    updateQuery: (prev, { subscriptionData }) => {
                        if (_.get(prev, 'userAdminMessages') && _.get(subscriptionData, 'data.messagesSubscription')) {
                            const next = _.cloneDeep(prev);
                            next.userAdminMessages.push(subscriptionData.data.messagesSubscription);
                            return next;
                        }

                        return prev;
                    }
                })
              }
            />
        )}
        </Query>
    );
};

export default AdminMessages;
