import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import { utils } from 'lib/utils';

import { Wrapper, Button, Checkbox } from 'components/common';

const buttonProps = {
    label: 'Next',
    // label: {`Next ${this.this.props.activePage}`},
    labelColor: '#fff',
    bgColor: '#FF5266',
    className: 'btnNext mtop60',
    // className: {`btnNext mtop20${!this.state.valid ? '' : ' disabled'}`},
    type: 'button',
};

class Primer extends Component {
    constructor(props) {
        super(props);

        this.pages = {};

        if (_.get(props, 'booking.type') === 3) {
            this.pages.quant = (
                <Fragment>
                    <h1>Are you eligible?</h1>
                    <p>Before you can apply for this online task, you will need to make sure you are eligible by answering some questions.</p>
                </Fragment>
            );
        }

        this.pages.honesty = (
            <Fragment>
                <h1>Don’t forget...</h1>
                <h1>We value <u className="isBadIsGood">honesty</u></h1>
                <p>Our clients depend on your insight and feedback to make important decisions. It’s vital that every answer  you provide is with complete honesty.</p>
                <p>We track inconsistencies in responses, conduct frequent quality checks and may request proof. If we deem your responses to be dishonest, <span className="highlighter warning">we reserve the right to permanently block your Askable account</span></p>
                <Checkbox
                    onClick={(value) => { this.setState({ confirm_honesty: value.checked }); }}
                    label="I understand and I will be honest."
                    className="mtop20"
                />
            </Fragment>
        );

        this.pages.recording = (
            <Fragment>
                <h1>Your screen will be <u>recorded</u></h1>
                <p>In order to verify that you successfully and <strong>honestly</strong> answered the questions, we will record your screen as you answer these questions.</p>
                <p>This does <strong>not</strong> record anything from your camera.</p>
            </Fragment>
        );

        this.state = { page: utils.firstKey(this.pages) };

        this.validation = this.validation.bind(this);
    }

    validation() {
        switch (this.state.page) {
            case 'honesty':
                return !!this.state.confirm_honesty;
            default:
                return true;
        }
    }

    render() {
        // const mixpanelEvent = { event: 'Screener primer continue', payload: { page: this.state.page, _booking_id: this.props.booking._id } };


        if (this.state.page === utils.lastKey(this.pages)) {
            return (
                <Wrapper>
                    {this.pages[this.state.page]}
                    <Button
                        {...buttonProps}
                        label="Begin"
                        disabled={!this.validation()}
                        onClick={() => {
                            // mixpanel.track(mixpanelEvent);
                            if (this.props.onPrimed) this.props.onPrimed();
                        }}
                    />
                </Wrapper>
            );
        }

        return (
            <Wrapper>
                {this.pages[this.state.page]}
                <Button
                    {...buttonProps}
                    disabled={!this.validation()}
                    onClick={() => {
                        // mixpanel.track(mixpanelEvent);
                        this.setState((state) => {
                            const index = _.chain(this.pages).keys().indexOf(state.page).value();
                            state.page = _.keys(this.pages)[index + 1];
                            return state;
                        });
                    }}
                />
            </Wrapper>
        );
    }
}

export default Primer;
