import React, { Component } from 'react';
import Checkbox from 'material-ui/Checkbox';

class CheckBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: false,
        };

        this.onUpdateCheck = this.onUpdateCheck.bind(this);
    }

    onUpdateCheck() {
        const newState = !this.state.checked;
        this.setState({
            checked: newState
        });

        if (this.props.onClick) this.props.onClick({ id: this.props.id, checked: newState });
    }

    render() {
        return (
            <Checkbox
                label={this.props.label}
                checked={this.state.checked}
                onCheck={this.onUpdateCheck}
                style={this.props.style && this.props.style}
                className={this.props.className}
                labelStyle={this.props.labelStyle && this.props.labelStyle}
                iconStyle={this.props.iconStyle && this.props.iconStyle}
            />
        );
    }
}

export default CheckBox;
