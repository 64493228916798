import React from 'react';
import { Link } from 'react-router-dom';

export default function (props) {
    const eligibilityListItems = [];

    if (props.short_description) {
        eligibilityListItems.push(<li key="short_description">{props.short_description}</li>);
    }

    eligibilityListItems.push(...props.eligibilitySummary.map(item => (
        <li key={item.key}>
            {item.description}{item.children
                ? (
                    <ul>
                        {item.children.map(child => <li key={child.key}>{child.description}</li>)}
                    </ul>
                ) : null}
        </li>
    )));

    return (
        <div key={props.bookingId} className={`upcoming-session-card ${props.className}`}>
            <Link
                to={`/booking/${props.bookingId}`}
                // className="upcoming-session-card opportunity"
                className="card-child"
            >
              <div className="card-col-full opportunity-top-row">
                  <div className="card-col-dynamic">
                      <div>{props.date}</div>
                      <div className="location">{props.location}</div>
                      <div>{props.duration}</div>
                      <div>{props.sessionType}</div>
                  </div>
                  <div className="card-col-fixed textRight">
                      {props.incentive ? <div className="incentive">{props.incentive}</div> : null }
                      Incentive
                  </div>
              </div>
              <div className="card-col-full eligibility">
                  <ul className="eligibility-summary liPadx2">{eligibilityListItems}</ul>
              </div>
            </Link>
        </div>
    );
}
