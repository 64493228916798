import React from 'react';
import { Button } from 'components/common';

export default ({ history }) => (
    <div className="centre-message narrow">
        <img
            src="/assets/setup-check-header.png"
            alt="Laptop setup check"
            style={{
                margin: '0 auto 20px',
                display: 'block',
            }}
        />
        <h2>Laptop setup check</h2>
        <p>To make sure there won’t be any technical issues on the day, you need to do a compulsory setup check.</p>
        <p>We’ll be testing your:</p>
        <ul>
            <li>Webcam</li>
            <li>Microphone</li>
            <li>Screen sharing</li>
        </ul>
        <Button
            label="Begin"
            labelColor="#fff"
            bgColor="#FF5266"
            type="button"
            className="mtop40"
            onClick={() => {
                history.push('/live/setup-check/in-progress');
            }}
        />
        <p className="mtop20 fontSmall textCenter">Takes just 1-2 minutes!</p>
    </div>
);
