import React from 'react';
import MaterialCard, { CardMedia, CardText, CardActions } from 'material-ui/Card';
import Divider from 'material-ui/Divider';

export const Card = (props) => {
    return (
        <MaterialCard
            {...props}
            className={`materialCard ${props.className || ''}`}
        />
    );
};

export const CardChild = (props) => {
  const className = `card-child${props.type ? ` card-${props.type}` : ''}${props.className ? ` ${props.className}` : ''}`;
  switch (props.type) {
    case 'media':
      return <CardMedia {...props} className={className} />;
    case 'actions':
      return <CardActions {...props} className={className} />;
    case 'divider':
      return <CardText {...props} className={className} ><Divider /></CardText>;
    default:
      return <CardText {...props} className={className} />;
  }
};
